import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { KTSVG } from '../../../../compliancemasters/helpers';
import FileCategoryDropdown from './components/FileCategoryDropdown';
import FileInput from './components/FileInput';
import { finishUpload, getCompanyMember, getFileCategories, startUpload } from '../../../../shared';
import axios from 'axios';
import Button from '../../../../app/modules/buttons/Button';
import { ICompany, IMember } from '../../../../models';
import { UserModel, useAuth } from '../../../../app/modules/auth';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface IProps {
    memberId: string | number;
    onAdd?: () => void;
    location?: string;
    subscription_type?: string;
}

interface FileCategory {
    id: number;
    title: string;
}

const desiredOrder = [
    "pre-employment drug test with result",
    "pre-employment dach query",
    "annual dach query",
    "post-accident test with result and chain of custody form",
    "reasonable suspicion test with result and chain of custody form",
    "return-to-duty test with result and chain of custody form",
    "follow up test with result and chain of custody form",
    "random test with result and chain of custody form"
].map(item => item.toLowerCase().trim());

const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required').min(3, 'Title must be at least 3 characters'),
    categoryId: Yup.number().optional(),
    expiration: Yup.date().optional(),
    files: Yup.array().min(1).required('File is required'),
});

const initialValues = {
    title: '',
    file: undefined,
    files: [] as File[],
    categoryId: undefined,
    expiration: undefined,
    description: "",
    quarter: "",
    year: "",
    date: ""
};


const SuccessCallout = () => (
    <div className="text-center">
        <div className="mb-10">
            <div className="row">
                <span className="fs-2x fw-bolder text-success">Upload Successful</span>
            </div>
            <div className="row justify-content-center pt-10">
                <div className="col">
                    <Button dataBsDismiss="modal">
                        <span className="indicator-label">Close</span>
                    </Button>
                </div>
            </div>
        </div>
    </div>
);

const FileSection = ({
    formik
}: {
    formik: ReturnType<typeof useFormik>
}) => (
    <div className="fv-row mb-7">
        <label className="d-block fw-bold fs-6 mb-5 required">File</label>
        <FileInput
            // value={formik.getFieldProps('file').value}
            values={formik.values.files}
            onChange={(e) => {
                formik.setFieldValue('file', e.target.files?.[0])
                if (e.target.files) {
                    formik.setFieldValue('files', [...formik.values.files, ...e.target.files])
                }


            }}
            onClear={() => {
                formik.setFieldValue('file', undefined)
                formik.setFieldValue('files', [])
            }}
        />
    </div>
);

const CategoryInput = ({
    formik,
    member,
    categories,
}: {
    formik: ReturnType<typeof useFormik>;
    member?: IMember;
    categories: FileCategory[];
}) => {
    const { value } = formik.getFieldProps('categoryId');
    return (
        <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">Category</label>
            <FileCategoryDropdown
                member={member}
                value={value}
                onChange={(e) => {
                    formik.setFieldValue('categoryId', e);
                    const category = categories.find((item) => item.id === e);
                    if (category) {
                        formik.setFieldValue("title", category.title)
                    }
                }}
                categories={member?.pre_employment_screened ? categories : categories.filter((item) => item.title !== "Pre-Employment MVR Annual Review")}
            />
        </div>
    );
};

const ExpirationSection = ({
    formik,
    company,
    categoryId
}: {
    formik: ReturnType<typeof useFormik>;
    company: ICompany
    categoryId: number
}) => {
    const { value, onChange } = formik.getFieldProps('expiration');
    return (
        <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">Expiration</label>
            <input
                type="date"
                className="form-control form-control-solid"
                min={new Date().toLocaleDateString('en-ca')}
                value={value}
                onChange={onChange}
                name="expiration"
            />
            {categoryId === 25 && company?.opted_out_of_pre_employment && <span className="text-danger">All pre-employment DACH Query’s and MVR’s expire in one year. Leave field blank if upload has no expiration date.</span>}
        </div>
    );
};

const DescriptionField = ({
    formik,
}: {
    formik: ReturnType<typeof useFormik>;
}) => {
    const { value, onChange } = formik.getFieldProps('description');
    return (
        <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">Description</label>
            <textarea
                className="form-control form-control-solid"
                value={value}
                onChange={onChange}
                name="description"
            />
        </div>
    );
}

const QuarterAndYear = ({ formik }: { formik: ReturnType<typeof useFormik> }) => {
    return (
        <>
            <label className="fw-bold fs-6 mb-2">Quarter</label>
            <select
                className="form-select form-select-solid"
                aria-label="Select example"
                value={formik.values.quarter}
                onChange={(e) => formik.setFieldValue("quarter", e.target.value)}
            >
                <option>Open this select menu</option>
                <option value="First Quarter">
                    First Quarter
                </option>
                <option value="Second Quarter">
                    Second Quarter
                </option>
                <option value="Third Quarter">
                    Third Quarter
                </option>
                <option value="Fourth Quarter">
                    Fourth Quarter
                </option>
            </select>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
                <label className="fw-bold fs-6 mb-2 mt-4">Year</label>
                <DatePicker sx={{ width: "100%" }} views={['year']}
                    onYearChange={e => {
                        formik.setFieldValue('year', e)
                    }} />
            </LocalizationProvider>
        </>
    )
}

const Actions = ({
    formik,
    isSubmitting,
    formDisabled
}: {
    formik: ReturnType<typeof useFormik>;
    isSubmitting: boolean;
    formDisabled: boolean;
}) => {
    return (
        <div className="text-center pt-15">
            <Button
                disabled={isSubmitting || !formik.isValid || formDisabled}
                loading={isSubmitting}
                loadingText={'Submitting...'}
                onClick={formik.handleSubmit}
            >
                <span className="indicator-label">Submit</span>
            </Button>
        </div>
    )
};

const Form = ({
    formik,
    member,
    categories,
    formDisabled,
    currentUser,
    isSubmitting,
    company
}: {
    formik: ReturnType<typeof useFormik>;
    member?: IMember;
    categories: FileCategory[];
    formDisabled: boolean;
    currentUser?: UserModel;
    isSubmitting: boolean;
    company: ICompany
}) => (
    <form className="form" onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
            className="d-flex flex-column scroll-y me-n7 pe-7"
            id="kt_modal_add_user_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_add_user_header"
            data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
            data-kt-scroll-offset="300px"
        >
            <CategoryInput categories={categories} formik={formik} member={member} />

            <FileSection formik={formik} />

            {formik.values.categoryId === 25 || (currentUser?.role === "SuperAdmin" && formik.values.categoryId === 10) ? <DescriptionField formik={formik} /> : null}

            {formik.values.categoryId === 16 ? <QuarterAndYear formik={formik} /> : null}

            <div className="fv-row mb-7">

                <span className='text-danger' style={{
                    textAlign: 'center',
                }}>
                    {formDisabled ? "This file is uploaded by Compliance Masters" : null}
                </span>
            </div>
            <div className="fv-row mb-7">
                <span>
                    <span>
                        {currentUser?.role === "Owner" && formik.values.categoryId && ["Record of Road Test"].includes(categories?.find((item) => item?.id! === formik?.values?.categoryId!)?.title!) ? "This file is uploaded by Compliance Masters" : null}
                    </span>
                </span>
            </div>

            {(window.location.pathname.includes("drug-documents") && formik.values.categoryId !== 26) || (formik.values.categoryId && [7, 9, 10, 11, 25].includes(formik.values.categoryId)) || (formik.values.categoryId && formik.values.categoryId === 4 && !member?.pre_employment_screened) || (formik.values.categoryId && formik.values.categoryId === 2 && !member?.pre_employment_screened) ? <ExpirationSection categoryId={formik.values.categoryId} company={company} formik={formik} /> : null}
            {window.location.pathname.includes("drug-documents") ? (
                <div className="fv-row mb-7">
                    {/* begin::Label */}
                    <label className="fw-bold fs-6 mb-2">Date</label>
                    <input
                        type="date"
                        className="form-control form-control-solid"
                        min={new Date().toLocaleDateString('en-ca')}
                        value={formik.values.date}
                        onChange={(e) => formik.setFieldValue("date", e.target.value)}
                        name="Date"
                    />
                </div>
            ) : null}
        </div>

        <Actions formDisabled={formDisabled} formik={formik} isSubmitting={isSubmitting} />
    </form>
);
const orderMap = new Map(desiredOrder.map((item, index) => [item, index]));

export function NewFileModal({ memberId, onAdd, location, subscription_type }: IProps) {
    const { currentUser } = useAuth()
    const [uploaded, setUploaded] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [member, setMember] = useState<IMember | undefined>(undefined);
    const [formDisabled, setFormDisabled] = useState(false);
    const [categories, setCategories] = useState<FileCategory[]>([]);
    useEffect(() => {
        if (member && currentUser && memberId) {
            let category_type = "";
            if (window.location.pathname.includes("drug-documents")) {
                category_type = "Drug File";
            } else if (window.location.pathname.includes("driver-documents")) {
                category_type = "Driver File";
            }
            getFileCategories({ category_type, memberId: member?.id, subscription: subscription_type }).then((res: FileCategory[]) => {
                if (subscription_type === "Drug and Alcohol Package" && ["Admin", "SuperAdmin", "Owner"].includes(currentUser?.role!)) {
                    // Sort the API response based on the desired order map
                    const sortedCategories = res.sort((a, b) => {
                        let nameA = a.title.toLowerCase().trim(); // Normalize the title
                        let nameB = b.title.toLowerCase().trim(); // Normalize the title

                        let indexA = orderMap.get(nameA) ?? Infinity; // Get the index or Infinity if not found
                        let indexB = orderMap.get(nameB) ?? Infinity; // Same for b
                        return indexA - indexB;
                    });

                    setCategories(sortedCategories);
                } else {
                    setCategories(res);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [member, currentUser]);

    useEffect(() => {
        if (memberId) {
            getCompanyMember(String(memberId)).then((mem) => {
                setMember(mem);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [memberId])


    useEffect(() => {
        formik.validateForm();
        const modelEl = document.getElementById('add_file_modal');

        modelEl?.addEventListener('hidden.bs.modal', () => {
            setUploaded(false);
            formik.resetForm();
            formik.validateForm();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const formik = useFormik({
        initialValues: initialValues,
        validateOnMount: true,
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setIsSubmitting(true);
            if (isSubmitting) return;

            const { title, expiration, categoryId, description, quarter, year, files, date } = values;

            const fileList: string[] = []

            files.forEach(async (item) => {
                const res = await startUpload({
                    fileName: (item! as File).name,
                    fileType: (item! as File).type,
                    title,
                    expiration,
                    categoryId,
                    memberId,
                    location,
                    description,
                    quarter,
                    year,
                    date
                })
                const { url, id } = res

                let options = {
                    headers: {
                        'Content-Type': (item! as File).type
                    }
                };
                let instance = axios.create();

                await instance.put(url, item, options)

                await finishUpload(id)

                fileList.push(id)

                if (fileList.length === files.length) {
                    setSubmitting(false);
                    setIsSubmitting(false);
                    setUploaded(true)
                    onAdd?.()
                }
            })
            if (fileList.length === files.length) {
                setSubmitting(false);
                setIsSubmitting(false);
                setUploaded(true)
                onAdd?.()
            }
        }
    });

    React.useEffect(() => {
        const category = categories.find((item) => item.id === formik.values.categoryId);
        if (currentUser?.role === "Owner" && category && [
            "Pre-Employment MVR",
            "Criminal Background Check",
            "Pre-Employment Full DACH Query",
            "Pre-Employment Screening Program (PSP) (Optional)",
            "Post-Accident Drug Test and Result with Chain of Custody form",
        ].includes(category?.title)) {
            setFormDisabled(true)
        } else {
            setFormDisabled(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.categoryId]);

    return (
        <div className="modal fade" id="add_file_modal" role="dialog" tabIndex={-1} aria-modal="true">
            <div className="modal-dialog modal-dialog-centered mw-650px">
                <div className="modal-content">
                    <div className="modal-header">
                        {/* begin::Modal title */}
                        <h2 className="fw-bolder">Add File</h2>
                        {/* end::Modal title */}

                        {/* begin::Close */}
                        <div
                            className="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            style={{ cursor: 'pointer' }}
                        >
                            <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
                        </div>
                        {/* end::Close */}
                    </div>

                    <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                        {uploaded ? SuccessCallout() : (
                            <Form
                                categories={categories}
                                currentUser={currentUser}
                                formDisabled={formDisabled}
                                // @ts-ignore
                                formik={formik}
                                isSubmitting={isSubmitting}
                                member={member}
                                company={member?.company!}
                            />
                        )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
