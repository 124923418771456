import { Button } from '@mui/material'
import { IMember } from '../../models'


interface IProps {
    member: IMember | undefined
    prevButton?: { title: string, path: string }
    nextButton?: { title: string, path: string }
}

const ActionButtons = ({ member, nextButton, prevButton }: IProps) => {
    return (
        <div style={{
            display: "flex",
            width: "47vw",
            justifyContent: "space-between",
            marginLeft: 'auto',
            marginRight: 'auto',
        }}>
            {prevButton && prevButton.path && <Button variant="outlined" fullWidth={!nextButton} color="primary" onClick={() => {
                if (member?.id) {
                    window.location.assign(`/member/${member?.id}/re-form/${prevButton?.path}`)
                }
            }}>
                Prev: {prevButton?.title}
            </Button>}
            {nextButton && nextButton.path && <Button variant="contained" fullWidth={!prevButton} color="primary" onClick={() => {
                if (member?.id) {
                    window.location.assign(`/member/${member?.id}/re-form/${nextButton?.path}`)
                }
            }}>
                Next: {nextButton?.title}
            </Button>}
        </div>
    )
}

export default ActionButtons