import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    IDriversHandbookReceipt,
    IDriversHandbookReceiptDto
} from '../../interfaces/DriversHandbookReceipt/DriversHandbookReceipt';

export const driversHandbookReceiptApi = createApi({
    reducerPath: 'drivers_handbook_receipt',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/drivers-handbook-receipt`
    }),
    tagTypes: ['DriversHandbookReceipt'],
    endpoints: (builder) => ({
        createDriversHandbookReceipt: builder.mutation<IDriversHandbookReceipt, IDriversHandbookReceiptDto>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'DriversHandbookReceipt', id: 'DriversHandbookReceipt' }]
        }),
        updateDriversHandbookReceipt: builder.mutation<IDriversHandbookReceipt, IDriversHandbookReceipt>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'DriversHandbookReceipt', id: 'DriversHandbookReceipt' }]
        }),
        deleteDriversHandbookReceipt: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'DriversHandbookReceipt', id: 'DriversHandbookReceipt' }]
        }),
        getDriversHandbookReceipt: builder.query<IDriversHandbookReceipt, number | void>({
            query: (id) => ({
                url: '/get-drivers-handbook-receipt/',
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'DriversHandbookReceipt', id: 'DriversHandbookReceipt' }]
        }),
        getDriversHandbookReceiptByUser: builder.query<IDriversHandbookReceipt, void>({
            query: () => ({
                url: '/get-drivers-handbook-receipt-by-user/',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'DriversHandbookReceipt', id: 'DriversHandbookReceipt' }]
        }),
        getDriversHandbookReceiptByUserId: builder.query<IDriversHandbookReceipt, number | void>({
            query: (id) => ({
                url: '/get-drivers-handbook-receipt-by-user-id/',
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'DriversHandbookReceipt', id: 'DriversHandbookReceipt' }]
        })
    })
});

export const {
    useCreateDriversHandbookReceiptMutation,
    useUpdateDriversHandbookReceiptMutation,
    useGetDriversHandbookReceiptQuery,
    useGetDriversHandbookReceiptByUserQuery,
    useGetDriversHandbookReceiptByUserIdQuery,
    useDeleteDriversHandbookReceiptMutation
} = driversHandbookReceiptApi;
