import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, Slide, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, FunctionComponent } from 'react';
import { IServiceTypes } from '../../../../store/interfaces/PreventableMaintenance/service.types';
import { useDeleteServiceTypeMutation } from '../../../../store/reducers/PreventableMaintenance/service.types';

interface IProps {
    open: boolean;
    setOpen: (val: boolean) => void;
    serviceType?: IServiceTypes;
    setServiceType?: (serviceType?: IServiceTypes) => void;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const DeleteServiceTypeModal: FunctionComponent<IProps> = ({ open, setOpen, serviceType, setServiceType }) => {
    const [deleteServiceType] = useDeleteServiceTypeMutation();
    const handleClose = () => {
        setServiceType?.(undefined);
        setOpen(false);
        window.location.reload();
    };

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle sx={{ textAlign: 'center' }} id="alert-dialog-slide-title">
                    Delete {serviceType?.name}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5" align="center">
                                Are you sure you want to delete {serviceType?.name}?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button fullWidth variant="contained" color="error" onClick={handleClose}>
                                No
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="contained"
                                color="success"
                                fullWidth
                                onClick={() => {
                                    if (serviceType) {
                                        deleteServiceType(serviceType.id);
                                    }
                                    handleClose();
                                }}
                            >
                                Yes
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};
