import React from 'react'
import { IMember } from '../../../models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Grid, TextField, Typography } from '@mui/material'
import {
    ICertificationDto
} from '../../../store/interfaces/CertificateOfComplianceWithDriverLicenseRequirements/certification'
import {
    useCreateCertificationMutation,
    useUpdateCertificationMutation,
    useGetCertificationByUserIdQuery
} from '../../../store/reducers/CertificateOfComplianceWithDriverLicenseRequirements/certification'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'
import { useGetProcessRecordByUserIdQuery } from '../../../store/reducers/DriversApplicationForEmployment/process-record'
import { useGetExperieneAndQualificationsByUserIdQuery } from '../../../store/reducers/EmploymentHistory/experience-and-qualification'
import { useGetApplicantInformationByUserIdQuery } from '../../../store/reducers/DriversApplicationForEmployment/applicant-information'
import { changeStringLast4 } from '../../../shared'


const initialValues: Partial<ICertificationDto> = {
    drivers_license_number: '',
    state: '',
    expiration_date: '',
    drivers_name: '',
    drivers_signature: '',
    date_of_hire: '',
    notes: '',
}

const validationSchema = Yup.object({
    drivers_license_number: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    expiration_date: Yup.string().required('Required'),
    drivers_name: Yup.string().required('Required'),
    drivers_signature: Yup.string().required('Required'),
    date_of_hire: Yup.string().required('Required'),
})



const CertificateOfComplianceWithDriverLicenseRequirements = ({ member, isDriverFile }: { member?: IMember, isDriverFile?: boolean }) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data } = useGetCertificationByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })
    const { data: experience = [] } = useGetExperieneAndQualificationsByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })
    const { data: applicantInfo } = useGetApplicantInformationByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
        pollingInterval: 1000 * 5,
    })
    const { data: processRecord } = useGetProcessRecordByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })

    const { data: cert } = useGetCertificationByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })
    const [createCertification, { isLoading: isCreating }] = useCreateCertificationMutation()
    const [updateCertification, { isLoading: isUpdating }] = useUpdateCertificationMutation()


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                await updateCertification({
                    // @ts-ignore
                    ...values,
                    // @ts-ignore
                    id: data.id,
                })
            } else {
                await createCertification({
                    ...values,
                    // @ts-ignore
                    member: member?.id,
                    // @ts-ignore
                    company: member?.company?.id
                })
            }
            resetForm()
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate: (values) => {
            const errors: Partial<ICertificationDto> = {}
            if (!values.drivers_license_number) {
                errors.drivers_license_number = 'Required'
            }
            if (!values.state) {
                errors.state = 'Required'
            }
            if (!values.expiration_date) {
                errors.expiration_date = 'Required'
            }
            if (!values.drivers_name) {
                errors.drivers_name = 'Required'
            }
            if (!values.drivers_signature) {
                errors.drivers_signature = 'Required'
            }
            if (!values.date_of_hire) {
                errors.date_of_hire = 'Required'
            }
            return errors
        }
    })


    React.useEffect(() => {
        if (data) {
            formik.setValues({
                ...data,
                drivers_license_number: ["Member", "Owner"].includes(currentUser?.role!) ? changeStringLast4(data?.drivers_license_number, "*")! : data?.drivers_license_number
            })
        } else if (member) {
            // @ts-ignore
            formik.setValues({
                ...initialValues,
                drivers_name: member?.user?.first_name + ' ' + member?.user?.last_name,
                state: member?.state,
            })


            if (experience.length > 0) {
                const mostRecent = experience?.reduce((prev, current) => (prev.created_at < current?.created_at) ? prev : current)
                if (mostRecent) {
                    formik.setFieldValue("drivers_license_number", mostRecent?.license_number)
                    formik.setFieldValue("expiration_date", mostRecent?.expiration_date)
                    formik.setFieldValue("state", mostRecent?.state)
                }
            } else {
                formik.setFieldValue("drivers_license_number", member?.drivers_license_number)
            }

        }
        if (!data && processRecord) {
            formik.setFieldValue("date_of_hire", processRecord.date_employed)
        } else if (!data && cert) {
            formik.setFieldValue("date_of_hire", cert?.date_of_hire)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, member, processRecord, applicantInfo])


    React.useEffect(() => {
        if (experience.length > 0) {
            const firstExperience = experience.find((item) => item.license_number === member?.drivers_license_number)

            if (firstExperience) {
                formik.setFieldValue("expiration_date", firstExperience?.expiration_date)
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [experience])


    return (
        <div style={{
            padding: 10,
            margin: 10,
            userSelect: 'none'
        }}>
            <Typography sx={{ textAlign: "center" }} variant="h4" gutterBottom component="h4">
                Certification
            </Typography>
            <Typography sx={{ textAlign: "center" }} variant="h5" gutterBottom component="h5">
                MOTOR VEHICLE DRIVER'S
            </Typography>
            <Typography sx={{ textAlign: "center" }} variant="h5" gutterBottom component="h5">
                CERTIFICATION OF COMPLIANCE WITH DRIVER LICENSE REQUIREMENTS
            </Typography>
            <Typography sx={{ textAlign: "justify", mt: 2, color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }} variant="body1" gutterBottom component="p">
                Section 383.21. 391.11(b)(5) No person who operates a commercial motor vehicle
                shall at any time have more than one driver's license. This Certification of
                Compliance with Driver License Requirements asks the driver to certify that
                he/she meets the single driver's license requirement.
            </Typography>
            <Typography sx={{ textAlign: "center" }} variant="h5" gutterBottom component="h5">
                License Requirements
            </Typography>
            <Typography sx={{ textAlign: "center", mt: 2, color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }} variant="body1" gutterBottom component="p">
                Parts 383 and 391 of the Federal Motor Carrier Safety Regulations provide the following
                driver license requirements that you as a driver must comply with:
            </Typography>
            <div style={{ marginTop: 10 }}>
                <ol style={{ textDecorationColor: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }}>
                    <li style={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }}>
                        <Typography sx={{ mt: 2, color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }} variant="body1" gutterBottom component="p">
                            POSSESS ONLY ONE LICENSE: As a commercial motor vehicle driver, you may not possess more than one motor vehicle operator's license.
                        </Typography>
                    </li>
                    <li style={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }}>
                        <Typography sx={{ mt: 2, color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }} variant="body1" gutterBottom component="p">
                            NOTIFICATION OF LICENSE SUSPENSION, REVOCATION OR CANCELLATION: Sections 391.15(b)(2) and 383.33 of the Federal Motor Carrier Safety Regulations require that you notify your employer the NEXT BUSINESS DAY after any revocation, suspension, cancellation, or disqualification of your driver's license or driving privilege.
                            In addition, Section 383.31 requires that any time you are convicted of violating a state or local traffic law (other than parking), you must report it to your employer within 30 days and in writing.
                        </Typography>
                    </li>
                    <li style={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }}>
                        <Typography sx={{ mt: 2, color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }} variant="body1" gutterBottom component="p">
                            CDL DOMICILE REQUIREMENT: Section 383.23(a)(2) requires that your commercial driver's license be
                            issued by your legal state of domicile, where you have your true, fixed, and permanent home and principal residence,
                            and to which you have the intention of returning whenever you are absent.
                            If you establish a new domicile in another state, you must apply to transfer your CDL within 30 days.
                        </Typography>
                    </li>
                </ol>
                <Typography sx={{ mt: 2, color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    The following license is the only one I posses
                </Typography>
            </div>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Driver's License #
                    </Typography>
                    <TextField
                        fullWidth
                        id="drivers_license_number"
                        placeholder='Driver License #'
                        size="small"
                        variant="outlined"
                        value={formik.values.drivers_license_number}
                        disabled={member?.terminated_at || data ? true : false}
                        onChange={(e) => {
                            formik.setFieldValue('drivers_license_number', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_license_number && Boolean(formik.errors.drivers_license_number) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_license_number && Boolean(formik.errors.drivers_license_number) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_license_number && Boolean(formik.errors.drivers_license_number) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        State
                    </Typography>
                    <TextField
                        fullWidth
                        id="state"
                        placeholder='State'
                        size="small"
                        variant="outlined"
                        value={formik.values.state}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        onChange={(e) => {
                            formik.setFieldValue('state', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.state && Boolean(formik.errors.state) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.state && Boolean(formik.errors.state) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.state && Boolean(formik.errors.state) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Expiration Date
                    </Typography>
                    <TextField
                        fullWidth
                        id="expiration_date"
                        placeholder='Expiration Date'
                        size="small"
                        variant="outlined"
                        type='date'
                        value={formik.values.expiration_date}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        onChange={(e) => {
                            formik.setFieldValue('expiration_date', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.expiration_date && Boolean(formik.errors.expiration_date) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.expiration_date && Boolean(formik.errors.expiration_date) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.expiration_date && Boolean(formik.errors.expiration_date) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', mt: 2 }} variant="body1" gutterBottom component="p">
                        Driver Certification: I certify that I have read and understood the above requirements
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Employee Name (Print)
                    </Typography>
                    <TextField
                        fullWidth
                        id="drivers_name"
                        placeholder='Employee Name'
                        size="small"
                        variant="outlined"
                        value={formik.values.drivers_name}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        onChange={(e) => {
                            formik.setFieldValue('drivers_name', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_name && Boolean(formik.errors.drivers_name) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_name && Boolean(formik.errors.drivers_name) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_name && Boolean(formik.errors.drivers_name) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Employee's Signature
                    </Typography>
                    <TextField
                        fullWidth
                        id="drivers_signature"
                        placeholder="Employee's Signature"
                        size="small"
                        variant="outlined"
                        value={formik.values.drivers_signature}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        onChange={(e) => {
                            formik.setFieldValue('drivers_signature', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_signature && Boolean(formik.errors.drivers_signature) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_signature && Boolean(formik.errors.drivers_signature) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_signature && Boolean(formik.errors.drivers_signature) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                fontStyle: "italic"
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Date of Hire
                    </Typography>
                    <TextField
                        fullWidth
                        id="date_of_hire"
                        placeholder='Date of Hire'
                        size="small"
                        variant="outlined"
                        type='date'
                        value={formik.values.date_of_hire}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        onChange={(e) => {
                            formik.setFieldValue('date_of_hire', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date_of_hire && Boolean(formik.errors.date_of_hire) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date_of_hire && Boolean(formik.errors.date_of_hire) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date_of_hire && Boolean(formik.errors.date_of_hire) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Notes
                    </Typography>
                    <TextField
                        fullWidth
                        id="notes"
                        placeholder='Notes'
                        size="small"
                        variant="outlined"
                        multiline
                        rows={4}
                        value={formik.values.notes}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        onChange={(e) => {
                            formik.setFieldValue('notes', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.notes && Boolean(formik.errors.notes) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.notes && Boolean(formik.errors.notes) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.notes && Boolean(formik.errors.notes) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                {(!data || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role))) && !isDriverFile ? <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={isCreating || isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                        Save
                    </Button>
                    {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                        <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                            Once you click save, you will not be able to edit this form.
                        </Typography>
                    )}
                </Grid> : null}
            </Grid>
        </div>
    )
}

export default CertificateOfComplianceWithDriverLicenseRequirements