import { Box, Button, Container, Paper, Typography } from '@mui/material';
import React from 'react'
import { useParams } from 'react-router-dom';
import { getCompanyMember } from '../../../shared';
import { IMember } from '../../../models';
import { StatusTag, useThemeMode } from '../../partials';
import ProgressBar from '../../partials/widgets/statistics/ProgressBar';
import EmployerHistory from './EmployerHistory';
import AccidentRecord from './AccidentRecord';
import TrafficConvictions from './TrafficConvictions';
import ExperienceAndQualifications from './ExperienceAndQualifications';
import ApplicantQuestions from './ApplicantQuestions';
import Education from './Education';
import DriverSignature from './DriverSignature';
import { TabPanel, TabRow, links } from '../Tabs';
import ActionButtons from '../ActionButtons';
import { useGetEmploymentHistoryProgressMutation, useGetMemberEmploymentHistoryProgressMutation } from '../../../store/reducers/EmploymentHistory/progress';
import { useAuth } from '../../../app/modules/auth';





const EmploymentHistory = () => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const [member, setMember] = React.useState<IMember>()
    const [getProgress, { data: progress }] = useGetEmploymentHistoryProgressMutation()
    const [getMemberProgress, { data: memberProgress }] = useGetMemberEmploymentHistoryProgressMutation()
    const { id } = useParams()
    const [value, setValue] = React.useState(0);


    React.useEffect(() => {
        if (id) {
            getCompanyMember(id).then((data) => {
                setMember(data)
            })
            getProgress(Number(id))
            getMemberProgress(Number(id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Container style={{ userSelect: 'none' }}>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ mr: 5 }} variant="h4" gutterBottom component="h4">
                        Employment History
                    </Typography>
                    <Typography sx={{ mb: 2 }} variant="h4" gutterBottom component="h4">
                        <StatusTag
                            status={progress && (progress?.progress > 0 && progress?.progress < 100) ? "In Progress" : progress && progress.progress > 99 ? "Completed" : "Not Started"}
                        />
                    </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ mr: 5 }} variant="h6" gutterBottom component="h6">
                        7 Sections
                    </Typography>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="h6" gutterBottom component="h6">
                        40 Items
                    </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ mr: 5 }} variant="h6" gutterBottom component="h6">
                        Total:
                    </Typography>
                    <ProgressBar progress={progress ? progress.progress : 0} size={10} backgroundColor='bg-secondary' direction='right' fontSize={7} />
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                    <Typography sx={{ mr: 5, color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="subtitle1" gutterBottom component="span">
                        {member ? member?.user?.first_name : "Member: "} {member ? ` ${member?.user?.last_name} :` : null}
                    </Typography>
                    <ProgressBar progress={memberProgress ? memberProgress.progress : 0} size={10} backgroundColor='bg-secondary' direction='right' fontSize={7} />
                </div>
                <div style={{
                    display: "flex",
                    width: "47vw",
                    justifyContent: "space-between",
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}>
                    <Button variant="outlined" color="primary" onClick={() => {
                        if (currentUser?.role === "Member") {
                            window.location.assign(`/dashboard/overview`)
                        } else {

                            if (member?.id) {
                                window.location.assign(`/member/${member?.id}/profile/driver-documents`)
                            }
                        }
                    }}>Back to Forms Overview</Button>
                </div>
                <TabRow current={value} links={links} setCurrent={setValue} />
            </Box>
            <TabPanel value={value} index={0}>
                <Box sx={{
                    pl: 3,
                    pr: 3,
                    Radius: 2,
                    backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: "47vw",
                        md: "70vw",
                        sm: "80vw",
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 3
                }} component={Paper}>
                    <EmployerHistory member={member} />
                </Box>
                <Box sx={{
                    pl: 3,
                    pr: 3,
                    Radius: 2,
                    backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: "47vw",
                        md: "70vw",
                        sm: "80vw",
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 3
                }} component={Paper}>
                    <AccidentRecord member={member} />
                </Box>
                <Box sx={{
                    pl: 3,
                    pr: 3,
                    Radius: 2,
                    backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: "47vw",
                        md: "70vw",
                        sm: "80vw",
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 3
                }} component={Paper}>
                    <TrafficConvictions member={member} />
                </Box>
                <Box sx={{
                    pl: 3,
                    pr: 3,
                    Radius: 2,
                    backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: "47vw",
                        md: "70vw",
                        sm: "80vw",
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 3
                }} component={Paper}>
                    <ExperienceAndQualifications member={member} />
                </Box>
                <Box sx={{
                    pl: 3,
                    pr: 3,
                    Radius: 2,
                    backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: "47vw",
                        md: "70vw",
                        sm: "80vw",
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 3
                }} component={Paper}>
                    <ApplicantQuestions member={member} />
                </Box>
                <Box sx={{
                    pl: 3,
                    pr: 3,
                    Radius: 2,
                    backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: "47vw",
                        md: "70vw",
                        sm: "80vw",
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 3
                }} component={Paper}>
                    <Education member={member} />
                </Box>
                <Box sx={{
                    pl: 3,
                    pr: 3,
                    Radius: 2,
                    backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: "47vw",
                        md: "70vw",
                        sm: "80vw",
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 3
                }} component={Paper}>
                    <DriverSignature member={member} />
                </Box>
                <ActionButtons
                    member={member}
                    prevButton={{
                        title: "Application for Employment",
                        path: "driver-application-for-employment"
                    }}
                    nextButton={{
                        title: (!member?.pre_employment_screened || (member?.pre_employment_screened && member?.pre_employment_investigation.length > 0)) ? "Previous Employee Safety Performance History" : "Cert Of Compliance...",
                        path: (!member?.pre_employment_screened || (member?.pre_employment_screened && member?.pre_employment_investigation.length > 0)) ? "previous-employee-safety-performance-history" : "certificate-of-compliance-with-driver-license-requirements"
                    }}
                />
                <div style={{
                    display: "flex",
                    width: "47vw",
                    justifyContent: "center",
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 10,
                }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (currentUser?.role === "Member") {
                                window.location.assign(`/dashboard/overview`)
                            } else {

                                if (member?.id) {
                                    window.location.assign(`/member/${member?.id}/profile/driver-documents`)
                                }
                            }
                        }}
                        fullWidth
                    >Back to Forms Overview</Button>
                </div>
            </TabPanel>
        </Container>
    )
}

export default EmploymentHistory