import { KTSVG } from '../../../helpers';
import { NewFileModal } from './NewFileModal';

interface IProps {
    memberId: string | number;
    onAdd?: () => void;
    location?: string;
    subscription_type?: string;
}
export default function NewFileModalButton({ memberId, onAdd, location, subscription_type }: IProps) {
    return (
        <>
            <NewFileModal memberId={memberId} onAdd={onAdd} location={location} subscription_type={subscription_type} />

            <div
                className="btn btn-sm btn-light-primary d-inline-flex align-items-center"
                data-bs-toggle="modal"
                data-bs-target="#add_file_modal"
            >
                <KTSVG path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
                Add File
            </div>
        </>
    );
}
