import {totalHoursApi, hoursWorkedApi} from './applicant-info-and-hours'
import {driverCertificateForOtherCompensatedWorkApi} from './driver-certificate-for-other-compensated-work'
import {driverSignatureApi as driverStatementSignatureApi} from './driver-signature'
import {witnessDriverCertificationForOtherCompensatedWorkApi} from './witness-driver-certificate-for-other-compensated-work'
import {driverStatementDutyHoursProgress} from './progress'

export const DriverStatementDutyHoursReducers = {
  [totalHoursApi.reducerPath]: totalHoursApi.reducer,
  [hoursWorkedApi.reducerPath]: hoursWorkedApi.reducer,
  [driverCertificateForOtherCompensatedWorkApi.reducerPath]:
    driverCertificateForOtherCompensatedWorkApi.reducer,
  [driverStatementSignatureApi.reducerPath]: driverStatementSignatureApi.reducer,
  [witnessDriverCertificationForOtherCompensatedWorkApi.reducerPath]:
    witnessDriverCertificationForOtherCompensatedWorkApi.reducer,
  [driverStatementDutyHoursProgress.reducerPath]: driverStatementDutyHoursProgress.reducer,
}

export const DriverStatementDutyHoursMiddleware = [
  totalHoursApi.middleware,
  hoursWorkedApi.middleware,
  driverCertificateForOtherCompensatedWorkApi.middleware,
  driverStatementSignatureApi.middleware,
  witnessDriverCertificationForOtherCompensatedWorkApi.middleware,
  driverStatementDutyHoursProgress.middleware,
]
