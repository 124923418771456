import React, { useCallback, useEffect, useRef, useState } from 'react';
import TableContainerHeaderTitle from '../components/TableContainerHeaderTitle';
import TableContainerHeader from '../components/TableContainerHeader';
import TableWrapper from '../components/TableWrapper';
import TableContainer from '../components/TableContainer';
import TableHeader from '../components/TableHeader';
import TableHeaderColumn from '../components/TableHeaderColumn';
import MemberRow from './components/MemberRow';
import NewMemberModalButton from './components/NewMemberModalButton';
import { useCompany } from '../../../../../context/CompanyContext';
import { getSubscriptionPlans } from '../../../../../shared';
import { StripeProduct } from '../../../../../app/modules/models';
import { IMember } from '../../../../../models';
import { useAuth } from '../../../../../app/modules/auth';
import { Button, Grid, IconButton, Menu, MenuItem, TextField, Tooltip } from '@mui/material';
import { MoreVertRounded } from '@mui/icons-material';
import { KTSVG as Ktsvg } from '../../../../helpers';
import NewRequestModal from './MemberRequestModal';
import InviteDriversModal from './InviteDriversModal';
import useCompanyInvitations from '../../../../../context/CompanyInvitation/CompanyInvitationContextProvider';

enum Filter {
    Active = 'Active',
    Terminated = 'Terminated'
}

interface IProps {
    subscriptions: StripeProduct[];
}

export default function MemberTable({ subscriptions: companySubscriptions }: IProps) {
    const [filter, setFilter] = useState<Filter>(Filter.Active);
    const [userFilterInput, setUserFilterInput] = useState<string>('');
    const { currentCompanyMembers, currentCompany } = useCompany();
    const { currentUser } = useAuth();
    const [subscriptions, setSubscriptions] = useState<StripeProduct[]>([]);
    const [filteredMembers, setFilteredMembers] = useState<IMember[]>([]);
    const [owner, setOwner] = useState<IMember>();
    const [groupedData, setGroupedData] = React.useState<IMember[]>([]);
    const [requestModalOpen, setRequestModalOpen] = useState<boolean>(false);
    const [inviteDriversModalOpen, setInviteDriversModalOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { invitation, getInvitation } = useCompanyInvitations();
    const getInvitationsRef = useRef(getInvitation);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        const ownerAccount = filteredMembers?.find((x: IMember) => x.user.role === 'Owner');
        setOwner(ownerAccount);
    }, [filteredMembers]);

    const getSubs = useCallback(async () => {
        getSubscriptionPlans().then((res) => {
            const optionList = [];
            for (const pkg of currentCompany?.package ?? []) {
                const option = res.data.find((x: any) => x.product_id === pkg.id);
                if (option) {
                    optionList.push(option);
                }
            }
            setSubscriptions(optionList);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCompanyMembers, currentCompany]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (getInvitationsRef.current && currentCompany) {
                getInvitationsRef.current(currentCompany?.user?.email!);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [currentCompany]);

    useEffect(() => {
        getSubs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getSubs]);

    useEffect(() => {
        if (currentCompanyMembers) {
            if (filter === Filter.Active) {
                setFilteredMembers(currentCompanyMembers.filter((x: IMember) => !x.terminated_at));
            } else if (filter === Filter.Terminated) {
                setFilteredMembers(currentCompanyMembers.filter((x: IMember) => x.terminated_at));
            }
        }
    }, [filter, currentCompanyMembers]);

    useEffect(() => {
        if (currentCompanyMembers) {
            if (userFilterInput.length > 0) {
                setFilteredMembers(
                    currentCompanyMembers.filter((x: IMember) =>
                        `${x?.user?.first_name} ${x?.user?.last_name}`
                            .toLowerCase()
                            .includes(userFilterInput.toLowerCase())
                    )
                );
            } else {
                setFilteredMembers(currentCompanyMembers);
            }
        }
    }, [userFilterInput, currentCompanyMembers]);

    React.useEffect(() => {
        const groupedData = filteredMembers.reduce((groups: any, item) => {
            const product_id = item.product_id;

            if (!groups[product_id]) {
                groups[product_id] = [];
            }

            groups[product_id].push(item);
            return groups;
        }, {});

        // Sort each group by date_joined in descending order
        for (const key in groupedData) {
            if (groupedData.hasOwnProperty(key)) {
                groupedData[key].sort((a: any, b: any) => {
                    const dateA = new Date(a.created_at).getTime();
                    const dateB = new Date(b.created_at).getTime();
                    return dateB - dateA;
                });
            }
        }
        const members = Object.values(groupedData).flat() as IMember[];
        setGroupedData(members.filter((x: IMember) => (filter === Filter.Active ? !x.terminated_at : x.terminated_at)));
    }, [filteredMembers, filter]);

    return (
        <>
            <TableWrapper>
                <TableContainerHeader>
                    <Grid container spacing={2} p={2}>
                        <Grid item xs={12} sm={6} md={7.5} lg={8.5}>
                            <TableContainerHeaderTitle title="Employees" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4.5} lg={3.5}>
                            {currentCompany &&
                            invitation &&
                            invitation?.company_package.reduce((a, b) => a + b.numberOfDrivers, 0) >
                                currentCompany?.members?.length ? (
                                <Button
                                    color="success"
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                        handleClose();
                                        window.open(
                                            'https://compliance-masters-videos.s3.us-east-2.amazonaws.com/BulkDriverUploadTemplateFile.csv',
                                            '_blank'
                                        );
                                    }}
                                >
                                    <Ktsvg path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
                                    Download Driver List Template
                                </Button>
                            ) : null}
                            {currentUser?.role &&
                            ['Owner', 'Admin', 'SuperAdmin'].includes(currentUser?.role) &&
                            filter !== Filter.Terminated ? (
                                <Tooltip title="More Options">
                                    <IconButton onClick={handleClick}>
                                        <MoreVertRounded />
                                    </IconButton>
                                </Tooltip>
                            ) : null}
                        </Grid>
                    </Grid>
                    {currentUser?.role &&
                    ['Owner', 'Admin', 'SuperAdmin'].includes(currentUser?.role) &&
                    filter !== Filter.Terminated ? (
                        <NewMemberModalButton />
                    ) : null}
                    {currentUser?.role &&
                    ['Owner', 'Admin', 'SuperAdmin'].includes(currentUser?.role) &&
                    filter !== Filter.Terminated ? (
                        <NewRequestModal
                            memberSubscriptions={companySubscriptions}
                            open={requestModalOpen}
                            setOpen={setRequestModalOpen}
                        />
                    ) : null}
                </TableContainerHeader>
                {/* begin::Body */}
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            fullWidth
                            value={filter}
                            variant="outlined"
                            size="small"
                            select
                            helperText="Filter Employees"
                        >
                            {Object.values(Filter).map((option: Filter) => (
                                <MenuItem key={option} value={option} onClick={() => setFilter(option)}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            fullWidth
                            value={userFilterInput}
                            onChange={(e) => setUserFilterInput(e.target.value)}
                            variant="outlined"
                            size="small"
                            placeholder="Search for Employees"
                        />
                    </Grid>
                </Grid>
                <TableContainer>
                    <TableHeader>
                        <TableHeaderColumn>Employee</TableHeaderColumn>
                        <TableHeaderColumn>Driver Status / Screened</TableHeaderColumn>
                        <TableHeaderColumn>Date Screened</TableHeaderColumn>
                        <TableHeaderColumn>Confirmation Date</TableHeaderColumn>
                        <TableHeaderColumn>Status</TableHeaderColumn>
                        <TableHeaderColumn>Subscription</TableHeaderColumn>
                        <TableHeaderColumn end>Form Progress</TableHeaderColumn>
                    </TableHeader>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                        {owner ? (
                            <MemberRow
                                member={owner}
                                key={owner.id}
                                product_name={
                                    subscriptions.find((x: StripeProduct) => x.product_id === owner.product_id)?.name
                                }
                            />
                        ) : null}
                        {groupedData
                            .filter((x) => x?.user?.role !== 'Owner')
                            .sort((a, b) => new Date(b.created_at!).getMinutes() - new Date(a.created_at!).getMinutes())
                            ?.map((member: IMember) => {
                                return (
                                    <MemberRow
                                        member={member}
                                        key={member.id}
                                        product_name={
                                            subscriptions.find((x: StripeProduct) => x.product_id === member.product_id)
                                                ?.name
                                        }
                                    />
                                );
                            })}
                    </tbody>
                </TableContainer>
                {/* begin::Body */}
            </TableWrapper>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={handleClose}>
                    <div data-bs-toggle="modal" data-bs-target="#kt_modal_1">
                        <Ktsvg path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
                        New Employee
                    </div>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setInviteDriversModalOpen(true);
                    }}
                >
                    <Ktsvg path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
                    Invite Drivers
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setRequestModalOpen(true);
                    }}
                >
                    <Ktsvg path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
                    New Bulk Request
                </MenuItem>
            </Menu>
            <InviteDriversModal open={inviteDriversModalOpen} setOpen={setInviteDriversModalOpen} />
        </>
    );
}
