import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    IPreviousEmployerInformation,
    IPreviousEmployerInformationDto,
    IDriverAccidentRecord,
    IDriverAccidentRecordDto,
    IDriversAccidentHistory,
    IDriversAccidentHistoryDto,
    IEmploymentVerification,
    IEmploymentVerificationDto,
    IFTADachInfo,
    IFTADachInfoDto
} from '../../interfaces/PreviousEmployeeSafetyPerformanceHistory/previous-employer-information';

export const previousEmployerInformationApi = createApi({
    reducerPath: 'previous_employer_information',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/previous-employer-information`
    }),
    tagTypes: ['PreviousEmployerInformation', 'EmploymentInformation'],
    endpoints: (builder) => ({
        createPreviousEmployerInformation: builder.mutation<
            IPreviousEmployerInformation,
            IPreviousEmployerInformationDto
        >({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        updatePreviousEmployerInformation: builder.mutation<IPreviousEmployerInformation, IPreviousEmployerInformation>(
            {
                query: (body) => ({
                    url: `/${body.id}/`,
                    method: 'PUT',
                    body,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                    }
                }),
                invalidatesTags: [
                    { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                    { type: 'EmploymentInformation', id: 'EmploymentInformation' }
                ]
            }
        ),
        getPreviousEmployerInformation: builder.query<IPreviousEmployerInformation[], number | void>({
            query: (id) => ({
                url: `/get-previous-employer-information/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        getPreviousEmployerInformationByUser: builder.query<IPreviousEmployerInformation[], void>({
            query: () => ({
                url: `/get-previous-employer-information-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        getPreviousEmployerInformationByUserId: builder.query<IPreviousEmployerInformation[], number | void>({
            query: (id) => ({
                url: `/get-previous-employer-information-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        deletePreviousEmployerInformation: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        //    Email Verification
        createEmploymentVerification: builder.mutation<IEmploymentVerification, IEmploymentVerificationDto>({
            query: (body) => ({
                url: '/create-employment-verification/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        updateEmploymentVerification: builder.mutation<IEmploymentVerification, IEmploymentVerification>({
            query: (body) => ({
                url: `/update-employment-verification/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        getEmploymentVerification: builder.query<IEmploymentVerification[], number | void>({
            query: (id) => ({
                url: `/get-employment-verification/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        getEmploymentVerificationByUser: builder.query<IEmploymentVerification[], void>({
            query: () => ({
                url: `/get-employment-verification-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        getEmploymentVerificationByUserId: builder.query<IEmploymentVerification[], number | void>({
            query: (id) => ({
                url: `/get-employment-verification-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        //   Accident History
        createDriversAccidentHistory: builder.mutation<IDriversAccidentHistory, IDriversAccidentHistoryDto>({
            query: (body) => ({
                url: '/create-drivers-accident-history/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        updateDriversAccidentHistory: builder.mutation<IDriversAccidentHistory, IDriversAccidentHistory>({
            query: (body) => ({
                url: `/update-drivers-accident-history/`,
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),

            invalidatesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        getDriversAccidentHistory: builder.query<IDriversAccidentHistory, number | void>({
            query: (id) => ({
                url: `/get-drivers-accident-history/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),

            providesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        getDriversAccidentHistoryByUser: builder.query<IDriversAccidentHistory, void>({
            query: () => ({
                url: `/get-drivers-accident-history-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),

            providesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        getDriversAccidentHistoryByUserId: builder.query<IDriversAccidentHistory, number | void>({
            query: (id) => ({
                url: `/get-drivers-accident-history-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),

            providesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        //   Accident Record
        createDriverAccidentRecord: builder.mutation<IDriverAccidentRecord, IDriverAccidentRecordDto>({
            query: (body) => ({
                url: '/create-driver-accident-record/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),

            invalidatesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        updateDriverAccidentRecord: builder.mutation<IDriverAccidentRecord, IDriverAccidentRecord>({
            query: (body) => ({
                url: `/update-driver-accident-record/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),

            invalidatesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        getDriverAccidentRecord: builder.query<IDriverAccidentRecord[], number | void>({
            query: (id) => ({
                url: `/get-driver-accident-record/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),

            providesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        getDriverAccidentRecordByUser: builder.query<IDriverAccidentRecord[], void>({
            query: () => ({
                url: `/get-driver-accident-record-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),

            providesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        getDriverAccidentRecordByUserId: builder.query<IDriverAccidentRecord[], number | void>({
            query: (id) => ({
                url: `/get-driver-accident-record-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        createFTADachInfo: builder.mutation<IFTADachInfo, IFTADachInfoDto>({
            query: (body) => ({
                url: '/create-fta-dach-info/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        updateFTADachInfo: builder.mutation<IFTADachInfo, IFTADachInfo>({
            query: (body) => ({
                url: `/update-fta-dach-info/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        getFTADachInfo: builder.query<IFTADachInfo[], number | void>({
            query: (id) => ({
                url: `/get-fta-dach-info/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        getFtaDachInfoByUser: builder.query<IFTADachInfo[], void>({
            query: () => ({
                url: `/get-fta-dach-info-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        }),
        getFtaDachInfoByUserId: builder.query<IFTADachInfo[], number | void>({
            query: (id) => ({
                url: `/get-fta-dach-info-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                { type: 'PreviousEmployerInformation', id: 'PreviousEmployerInformation' },
                { type: 'EmploymentInformation', id: 'EmploymentInformation' }
            ]
        })
    })
});

export const {
    useCreatePreviousEmployerInformationMutation,
    useUpdatePreviousEmployerInformationMutation,
    useGetPreviousEmployerInformationQuery,
    useGetPreviousEmployerInformationByUserQuery,
    useGetPreviousEmployerInformationByUserIdQuery,
    useDeletePreviousEmployerInformationMutation,
    useCreateEmploymentVerificationMutation,
    useUpdateEmploymentVerificationMutation,
    useGetEmploymentVerificationQuery,
    useGetEmploymentVerificationByUserQuery,
    useGetEmploymentVerificationByUserIdQuery,
    useCreateDriversAccidentHistoryMutation,
    useUpdateDriversAccidentHistoryMutation,
    useGetDriversAccidentHistoryQuery,
    useGetDriversAccidentHistoryByUserQuery,
    useGetDriversAccidentHistoryByUserIdQuery,
    useCreateDriverAccidentRecordMutation,
    useUpdateDriverAccidentRecordMutation,
    useGetDriverAccidentRecordQuery,
    useGetDriverAccidentRecordByUserQuery,
    useGetDriverAccidentRecordByUserIdQuery,
    useCreateFTADachInfoMutation,
    useUpdateFTADachInfoMutation,
    useGetFTADachInfoQuery,
    useGetFtaDachInfoByUserQuery,
    useGetFtaDachInfoByUserIdQuery
} = previousEmployerInformationApi;
