import React from 'react'
import { IMember } from '../../../models'
import { Button, Grid, TextField, Typography } from '@mui/material'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { IReleaseFormAuthorizationDto } from '../../../store/interfaces/ReleaseFormBackgroundCheck/ReleaseFormBackgroundChecks'
import {
    useCreateReleaseFormAuthorizationMutation,
    useUpdateReleaseFormAuthorizationMutation,
    useGetReleaseFormAuthorizationByUserIdQuery
} from '../../../store/reducers/ReleaseFormBackgroundCheck/release-form-authorization'
import { useDeleteReleaseFormBackgroundCheckMutation, useGetReleaseFormBackgroundCheckByUserIdQuery } from '../../../store/reducers/ReleaseFormBackgroundCheck/release-form-background-check'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'
import { changeStringLast4 } from '../../../shared'


const initialValues: Partial<IReleaseFormAuthorizationDto> = {
    applicant_name: '',
    applicant_email: '',
    social_security_number: '',
    date_of_birth: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    applicant_signature: '',
    date: ''
}

const validationSchema = Yup.object().shape({
    applicant_name: Yup.string().required('Applicant Name is required'),
    applicant_email: Yup.string().required('Applicant Email is required'),
    social_security_number: Yup.string().required('SSN is required'),
    date_of_birth: Yup.string().required('Date of Birth is required'),
    address: Yup.string().required('Address is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zip_code: Yup.string().required('Zip Code is required'),
    applicant_signature: Yup.string().required('Applicant Signature is required'),
    date: Yup.string().required('Date is required'),
})


const DriverReleaseFormBC = ({ member, isDriverFile }: { member?: IMember, isDriverFile?: boolean }) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data } = useGetReleaseFormAuthorizationByUserIdQuery(Number(member?.user?.id), {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
        refetchOnFocus: true,
    })
    const { data: releaseForm } = useGetReleaseFormBackgroundCheckByUserIdQuery(Number(member?.user?.id), {
        skip: !member?.id,
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
        refetchOnFocus: true,
    })
    const [createReleaseFormAuthorization, { isLoading: isCreating }] = useCreateReleaseFormAuthorizationMutation()
    const [updateReleaseFormAuthorization, { isLoading: isUpdating }] = useUpdateReleaseFormAuthorizationMutation()
    const [deleteDriverReleaseFormBC, { isLoading: isDeleting }] = useDeleteReleaseFormBackgroundCheckMutation()

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                updateReleaseFormAuthorization({
                    ...data,
                    ...values
                }).then(() => {
                    resetForm()
                })
            } else {
                // @ts-ignore
                createReleaseFormAuthorization({
                    ...values,
                    member: Number(member?.id),
                    company: Number(member?.company?.id)
                }).then(() => {
                    resetForm()
                })
            }
        },
        validateOnBlur: true,
        validateOnChange: true,
    })

    React.useEffect(() => {

        if (member) {
            if (data) {
                formik.setValues({
                    applicant_name: data.applicant_name,
                    applicant_email: data.applicant_email,
                    social_security_number: ["Member", "Owner"].includes(currentUser?.role!) ? changeStringLast4(data?.social_security_number, "*")! : data?.social_security_number,
                    date_of_birth: data.date_of_birth,
                    address: data.address,
                    city: data.city,
                    state: data.state,
                    zip_code: data.zip_code,
                    applicant_signature: data.applicant_signature,
                    date: data.date,
                })
            } else if (releaseForm) {
                formik.setValues({
                    applicant_name: releaseForm.applicant_name,
                    applicant_email: releaseForm.applicant_email,
                    social_security_number: releaseForm?.social_security_number,
                    date_of_birth: releaseForm.date_of_birth,
                    address: releaseForm.address,
                    city: releaseForm.city,
                    state: releaseForm.state,
                    zip_code: releaseForm.zip_code,
                    applicant_signature: releaseForm.applicant_signature,
                    date: releaseForm.date,
                })
            } else {
                const utcDate = new Date(member?.date_of_birth);

                const utcDateToday = new Date();

                const year = utcDate.getUTCFullYear();
                const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0');
                const day = String(utcDate.getUTCDate()).padStart(2, '0');
                const formattedDate = `${year}-${month}-${day}`;


                const yearToday = utcDateToday.getUTCFullYear();
                const monthToday = String(utcDateToday.getUTCMonth() + 1).padStart(2, '0');
                const dayToday = String(utcDateToday.getUTCDate()).padStart(2, '0');
                const formattedDateToday = `${yearToday}-${monthToday}-${dayToday}`;
                formik.setFieldValue('applicant_name', ` ${member?.user?.first_name} ${member?.user?.last_name}`)
                formik.setFieldValue('applicant_email', member?.user?.email)
                formik.setFieldValue('social_security_number', member?.social_security_number)
                formik.setFieldValue('date_of_birth', formattedDate)
                formik.setFieldValue('date', formattedDateToday)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, member, releaseForm])

    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ textAlign: "center" }} variant="h5" gutterBottom component="h5">
                NOTICE - BACKGROUND INVESTIGATION
            </Typography>
            <Typography sx={{ textAlign: "justify", color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                In connection with your employment application with {member?.company ? (
                    <span style={{
                        textDecoration: "underline"
                    }}>
                        {`${member?.company?.name} `}
                    </span>
                ) : ("__________________________")
                } (the "Company"),
                it’s Agencies, Affiliates and or Associates, that you are directly employed by/with: this notice is
                intended to inform you that an investigative consumer report(s) may be obtained on you from
                a consumer reporting agency for employment purposes. These reports may contain information
                about your character, general reputation, personal characteristics, and mode of living. They may
                involve personal interviews with sources such as your neighbors, friends, or associates.
                The reports may also contain information about you relating to your criminal information or history,
                credit history, driving and/or motor vehicle records, education or employment history, or other
                background checks.
            </Typography>
            <Typography sx={{ textAlign: "justify", mt: 3, color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                You have the right, upon written request made within a reasonable time after the receipt of this
                notice, to request disclosure of the nature and scope of any investigative consumer report
                prepared by our background screening provider Amerisearch background Alliance by contacting
                the Company.
            </Typography>
            <Typography sx={{ textAlign: "justify", mt: 3, color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                The scope of this notice and below authorization is not limited to the present and, if you are
                hired, will continue throughout the course of your employment, and allow the Company, it’s
                Agencies, Affiliates and or Associates, that you are directly employed by/with to conduct future
                screenings for retention, promotion, or reassignment, as permitted by law and unless revoked
                by you in writing.
            </Typography>
            <Typography sx={{ textAlign: "center", mt: 3, color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="h6" gutterBottom component="h6">
                AUTHORIZATION
            </Typography>
            <Typography sx={{ textAlign: "justify", mt: 3, color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                By signing below, you hereby authorize the obtaining of consumer reports and/or investigative
                consumer reports by the Company, it’s Agencies, Affiliates and or Associates, that you are
                directly employed by/with at any time after receipt of this authorization and throughout the
                course of your employment, as described above.
            </Typography>
            <Grid container spacing={3} p={1} sx={{
                alignItems: "center",
            }}>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Applicant Name
                    </Typography>
                    <TextField
                        fullWidth
                        id="applicant_name"
                        placeholder='Applicant Name'
                        size="small"
                        variant="outlined"
                        value={formik.values.applicant_name}
                        className={formik.touched.applicant_name && Boolean(formik.errors.applicant_name) ? "green" : "red"}
                        error={formik.touched.applicant_name && Boolean(formik.errors.applicant_name)}
                        helperText={formik.touched.applicant_name && formik.errors.applicant_name}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.applicant_name && Boolean(formik.errors.applicant_name) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.applicant_name && Boolean(formik.errors.applicant_name) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.applicant_name && Boolean(formik.errors.applicant_name) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Applicant Email
                    </Typography>
                    <TextField
                        fullWidth
                        id="applicant_email"
                        placeholder='Applicant Email'
                        size="small"
                        variant="outlined"
                        value={formik.values.applicant_email}
                        className={formik.touched.applicant_email && Boolean(formik.errors.applicant_email) ? "green" : "red"}
                        error={formik.touched.applicant_email && Boolean(formik.errors.applicant_email)}
                        helperText={formik.touched.applicant_email && formik.errors.applicant_email}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.applicant_email && Boolean(formik.errors.applicant_email) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.applicant_email && Boolean(formik.errors.applicant_email) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.applicant_email && Boolean(formik.errors.applicant_email) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        SSN
                    </Typography>
                    <TextField
                        fullWidth
                        id="ssn"
                        placeholder='SSN'
                        size="small"
                        variant="outlined"
                        value={formik.values.social_security_number}
                        className={formik.touched.social_security_number && Boolean(formik.errors.social_security_number) ? "green" : "red"}
                        error={formik.touched.social_security_number && Boolean(formik.errors.social_security_number)}
                        helperText={formik.touched.social_security_number && formik.errors.social_security_number}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.social_security_number && Boolean(formik.errors.social_security_number) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.social_security_number && Boolean(formik.errors.social_security_number) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.social_security_number && Boolean(formik.errors.social_security_number) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Date of Birth
                    </Typography>
                    <TextField
                        fullWidth
                        id="date_of_birth"
                        type='date'
                        size="small"
                        variant="outlined"
                        value={formik.values.date_of_birth}
                        className={formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth) ? "green" : "red"}
                        error={formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)}
                        helperText={formik.touched.date_of_birth && formik.errors.date_of_birth}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Applicant Address
                    </Typography>
                    <TextField
                        fullWidth
                        id="applicant_address"
                        placeholder='Applicant Address'
                        size="small"
                        variant="outlined"
                        value={formik.values.address}
                        className={formik.touched.address && Boolean(formik.errors.address) ? "green" : "red"}
                        error={formik.touched.address && Boolean(formik.errors.address)}
                        helperText={formik.touched.address && formik.errors.address}
                        onChange={e => formik.setFieldValue('address', e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.address && Boolean(formik.errors.address) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.address && Boolean(formik.errors.address) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.address && Boolean(formik.errors.address) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        City
                    </Typography>
                    <TextField
                        fullWidth
                        id="city"
                        placeholder='City'
                        size="small"
                        variant="outlined"
                        value={formik.values.city}
                        className={formik.touched.city && Boolean(formik.errors.city) ? "green" : "red"}
                        error={formik.touched.city && Boolean(formik.errors.city)}
                        helperText={formik.touched.city && formik.errors.city}
                        onChange={e => formik.setFieldValue('city', e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.city && Boolean(formik.errors.city) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.city && Boolean(formik.errors.city) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.city && Boolean(formik.errors.city) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        State
                    </Typography>
                    <TextField
                        fullWidth
                        id="state"
                        placeholder='State'
                        size="small"
                        variant="outlined"
                        value={formik.values.state}
                        className={formik.touched.state && Boolean(formik.errors.state) ? "green" : "red"}
                        error={formik.touched.state && Boolean(formik.errors.state)}
                        helperText={formik.touched.state && formik.errors.state}
                        onChange={e => formik.setFieldValue('state', e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.state && Boolean(formik.errors.state) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.state && Boolean(formik.errors.state) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.state && Boolean(formik.errors.state) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Zip Code
                    </Typography>
                    <TextField
                        fullWidth
                        id="zip_code"
                        placeholder='Zip Code'
                        size="small"
                        variant="outlined"
                        value={formik.values.zip_code}
                        className={formik.touched.zip_code && Boolean(formik.errors.zip_code) ? "green" : "red"}
                        error={formik.touched.zip_code && Boolean(formik.errors.zip_code)}
                        helperText={formik.touched.zip_code && formik.errors.zip_code}
                        onChange={e => formik.setFieldValue('zip_code', e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.zip_code && Boolean(formik.errors.zip_code) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.zip_code && Boolean(formik.errors.zip_code) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.zip_code && Boolean(formik.errors.zip_code) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Applicant Employee Signature
                    </Typography>
                    <TextField
                        fullWidth
                        id="applicant_employee_signature"
                        placeholder='Applicant Employee Signature'
                        size="small"
                        variant="outlined"
                        value={formik.values.applicant_signature}
                        className={formik.touched.applicant_signature && Boolean(formik.errors.applicant_signature) ? "green" : "red"}
                        error={formik.touched.applicant_signature && Boolean(formik.errors.applicant_signature)}
                        helperText={formik.touched.applicant_signature && formik.errors.applicant_signature}
                        onChange={e => formik.setFieldValue('applicant_signature', e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.applicant_signature && Boolean(formik.errors.applicant_signature) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.applicant_signature && Boolean(formik.errors.applicant_signature) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.applicant_signature && Boolean(formik.errors.applicant_signature) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                fontStyle: "italic"
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Date
                    </Typography>
                    <TextField
                        fullWidth
                        id="date"
                        type='Date'
                        size="small"
                        variant="outlined"
                        value={formik.values.date}
                        className={formik.touched.date && Boolean(formik.errors.date) ? "green" : "red"}
                        error={formik.touched.date && Boolean(formik.errors.date)}
                        helperText={formik.touched.date && formik.errors.date}
                        onChange={e => formik.setFieldValue('date', e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                {(!data || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role))) && !isDriverFile ? <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={isCreating || isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                        Save
                    </Button>
                    {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                        <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                            Once you click save, you will not be able to edit this form.
                        </Typography>
                    )}
                </Grid> : null}
                {data && currentUser?.role === "SuperAdmin" && <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={isDeleting || (!!member?.terminated_at)} onClick={() => {
                        const res = window.confirm("Are you sure you want to delete this form?")
                        if (res && data) {
                            deleteDriverReleaseFormBC(data.id)
                        }
                    }} variant="contained" color="primary">
                        Delete Form
                    </Button>
                </Grid>}
            </Grid>
        </div>
    )
}

export default DriverReleaseFormBC