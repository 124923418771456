import React, { useEffect } from 'react';
import { EmailEmployee } from '../../../../../../app/modules/auth/components/EmailEmployee';
import { KTSVG } from '../../../../../helpers';
import { useCompany } from '../../../../../../context/CompanyContext';
import { getConnectedPaymentMethods } from '../../../../../../shared/client/payments';

const AddNewUser = () => {
    return (
        <div className="modal-content">
            <div className="modal-header">
                <h1 className="modal-title">Send registration email to driver</h1>
                <div
                    className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                >
                    <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-2x" />
                </div>
            </div>
            <EmailEmployee />
        </div>
    );
};

export default function NewMemberModalButton() {
    const { setPaymentInfo } = useCompany();

    const getPaymentInfo = async () => {
        let payments = await getConnectedPaymentMethods();
        if (payments.length > 0 && setPaymentInfo) {
            setPaymentInfo(payments);
        }
    };

    useEffect(() => {
        getPaymentInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <div className="modal fade" tabIndex={-1} id="kt_modal_1">
            <div className="modal-dialog">
                <AddNewUser />
            </div>
        </div>
    );
}
