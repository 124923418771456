import React from 'react'
import { IMember } from '../../../models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Grid, TextField, Typography } from '@mui/material'
import { IDrugAndAlcoholWrittenPolicyDto } from '../../../store/interfaces/DrugAlcoholWrittenPolicy/drug-alcohol-written-policy'
import { useCreateDrugAlcoholWrittenPolicyMutation, useGetDrugAlcoholWrittenPolicyByUserIdQuery, useUpdateDrugAlcoholWrittenPolicyMutation, useDeleteDrugAlcoholWrittenPolicyMutation } from '../../../store/reducers/DrugAlcoholWrittenPolicy/drug-alcohol-written-policy'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'
import { useGetReleaseFormBackgroundCheckByUserIdQuery } from '../../../store/reducers/ReleaseFormBackgroundCheck/release-form-background-check'
import { useGetDriverReleaseFormByUserIdQuery } from '../../../store/reducers/DriverReleaseForm/driver-release-form'




const WrittenPolicySignatureSchema = Yup.object().shape({
    signature: Yup.string().required('Signature is required'),
    date: Yup.string().required('Date is required'),
    member: Yup.number().required('Member is required'),
    company: Yup.number().required('Company is required'),
})

const initialValues: Partial<IDrugAndAlcoholWrittenPolicyDto> = {
    signature: '',

}


const WrittenPolicySignature = ({ member }: { member?: IMember }) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data } = useGetDrugAlcoholWrittenPolicyByUserIdQuery(Number(member?.user?.id), {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
        refetchOnFocus: true,
    })

    const { data: releaseFormBC } = useGetReleaseFormBackgroundCheckByUserIdQuery(Number(member?.user?.id), {
        skip: !member?.id,
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
        refetchOnFocus: true,
    })

    const { data: releaseForm } = useGetDriverReleaseFormByUserIdQuery(member?.user?.id!, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })

    const [createDrugAlcoholWrittenPolicy, { isLoading }] = useCreateDrugAlcoholWrittenPolicyMutation()
    const [updateDrugAlcoholWrittenPolicy, { isLoading: isUpdating }] = useUpdateDrugAlcoholWrittenPolicyMutation()
    const [deleteDrugAlcoholWrittenPolicy] = useDeleteDrugAlcoholWrittenPolicyMutation()

    const formik = useFormik({
        initialValues,
        validationSchema: WrittenPolicySignatureSchema,
        onSubmit: (values, { resetForm }) => {
            console.log("HERE: ", data)
            if (data) {
                // @ts-ignore
                updateDrugAlcoholWrittenPolicy({
                    ...data,
                    signature: values.signature!,
                    date: values.date!,
                })
            } else {
                createDrugAlcoholWrittenPolicy({
                    signature: values.signature!,
                    date: values.date!,
                    member: Number(member?.id),
                    company: Number(member?.company?.id)
                }).then(() => {
                    resetForm()
                })
            }
        },
        validateOnBlur: true,
        validateOnChange: true,
    })

    React.useEffect(() => {
        if (data) {
            const utcDate = new Date(data.date);

            const offsetMinutes = utcDate.getTimezoneOffset();
            utcDate.setMinutes(utcDate.getMinutes() + offsetMinutes);

            const year = utcDate.getFullYear();
            const month = String(utcDate.getMonth() + 1).padStart(2, '0');
            const day = String(utcDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            formik.setValues({
                signature: data.signature,
                date: formattedDate,
                member: Number(member?.id),
                company: Number(member?.company?.id)
            })
        } else if (releaseFormBC) {
            formik.setFieldValue('date', releaseFormBC.date)
        } else if (releaseForm) {
            formik.setFieldValue('date', releaseForm.date)
        } else if (member) {
            const utcDate = new Date();

            const offsetMinutes = utcDate.getTimezoneOffset();
            utcDate.setMinutes(utcDate.getMinutes() + offsetMinutes);

            const year = utcDate.getFullYear();
            const month = String(utcDate.getMonth() + 1).padStart(2, '0');
            const day = String(utcDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            formik.setValues({
                signature: '',
                date: formattedDate,
                member: Number(member?.id),
                company: Number(member?.company?.id)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, member, releaseFormBC, releaseForm])


    console.log(formik.errors)

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                Written Policy Signature
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Signature
                    </Typography>
                    <TextField
                        fullWidth
                        id="signature"
                        size="small"
                        variant="outlined"
                        placeholder='Signature'
                        value={formik.values.signature}
                        onChange={(e) => {
                            formik.setFieldValue('signature', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.signature && Boolean(formik.errors.signature) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.signature && Boolean(formik.errors.signature) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.signature && Boolean(formik.errors.signature) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                fontStyle: "italic"
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Date
                    </Typography>
                    <TextField
                        fullWidth
                        id="date"
                        size="small"
                        variant="outlined"
                        value={formik.values.date}
                        type='date'
                        onChange={(e) => {
                            formik.setFieldValue('date', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                        FormHelperTextProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                {!data || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role)) ? <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={isLoading || isUpdating || (!!member?.terminated_at)} onClick={() => {
                        console.log("HERE: ", data)
                        formik.handleSubmit()
                    }} variant="contained" color="primary">
                        Save
                    </Button>
                    {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                        <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                            Once you click save, you will not be able to edit this form.
                        </Typography>
                    )}
                </Grid> : null}
                {data && currentUser?.role === "SuperAdmin" && <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={!!member?.terminated_at} onClick={() => {
                        const res = window.confirm("Are you sure you want to delete this form?")
                        if (res && data) {
                            deleteDrugAlcoholWrittenPolicy(data.id)
                        }
                    }} variant="contained" color="primary">
                        Delete Form
                    </Button>
                </Grid>}
            </Grid>
        </div>
    )
}

export default WrittenPolicySignature