import React from 'react'
import { IMember } from '../../../models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material'
import {
    IPerformanceRatingAndOwnerSignatureDto
} from '../../../store/interfaces/RecordOfRoadTest/performance-rating-and-owner-signature'
import {
    useCreatePerformanceRatingAndOwnerSignatureMutation,
    useUpdatePerformanceRatingAndOwnerSignatureMutation,
    useGetPerformanceRatingAndOwnerSignatureByUserIdQuery,
    useDeletePerformanceRatingAndOwnerSignatureMutation
} from '../../../store/reducers/RecordOfRoadTest/performance-rating-owner-signature'
import { InspectionTextChoices } from '../../../store/interfaces/RecordOfRoadTest/backing-and-parking'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'


const initialValues: Partial<IPerformanceRatingAndOwnerSignatureDto> = {
    general_performance: InspectionTextChoices.NA,
    qualified_for_truck: false,
    qualified_for_truck_semitrailer: false,
    qualified_for_other: '',
    signature_of_examiner: '',
}


const validationSchema = Yup.object({
    general_performance: Yup.string().required('Required'),
    qualified_for_other: Yup.string().required('Required'),
    signature_of_examiner: Yup.string().required('Required'),
})


interface IProps {
    member?: IMember
    isDriverFile?: boolean
}


export const PerformanceRatingAndOwnerSignature: React.FC<IProps> = ({ member, isDriverFile }) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data } = useGetPerformanceRatingAndOwnerSignatureByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    })

    const [createPerformanceRatingAndOwnerSignature, { isLoading: isCreating }] = useCreatePerformanceRatingAndOwnerSignatureMutation()
    const [updatePerformanceRatingAndOwnerSignature, { isLoading: isUpdating }] = useUpdatePerformanceRatingAndOwnerSignatureMutation()
    const [deletePerformanceRatingAndOwnerSignature] = useDeletePerformanceRatingAndOwnerSignatureMutation()

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                updatePerformanceRatingAndOwnerSignature({
                    ...values,
                    id: data.id,
                })
            } else {
                createPerformanceRatingAndOwnerSignature({
                    ...values,
                    // @ts-ignore
                    company: member?.company?.id,
                    // @ts-ignore
                    member: member?.id,
                })
            }
        },
        validateOnBlur: true,
        validateOnChange: true,
    })

    React.useEffect(() => {
        if (data) {
            formik.setValues(data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                Performance Rating and Owner Signature
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify" }} variant="body1" gutterBottom component="p">
                Driver performance and qualifications, check which apply
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        General Performance
                    </Typography>
                    <TextField
                        fullWidth
                        id="general_performance"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.general_performance}
                        onChange={(e) => {
                            formik.setFieldValue('general_performance', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.general_performance && Boolean(formik.errors.general_performance) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.general_performance && Boolean(formik.errors.general_performance) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.general_performance && Boolean(formik.errors.general_performance) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Qualified for Truck
                    </Typography>
                    <TextField
                        fullWidth
                        id="qualified_for_truck"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.qualified_for_truck ? "Yes" : "No"}
                        onChange={(e) => {
                            formik.setFieldValue('qualified_for_truck', e.target.value === "Yes" ? true : false)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.qualified_for_truck && Boolean(formik.errors.qualified_for_truck) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.qualified_for_truck && Boolean(formik.errors.qualified_for_truck) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.qualified_for_truck && Boolean(formik.errors.qualified_for_truck) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        <MenuItem key={"Yes"} value={"Yes"}>
                            Yes
                        </MenuItem>
                        <MenuItem key={"No"} value={"No"}>
                            No
                        </MenuItem>
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Qualified for Tractor-Semitrailer
                    </Typography>
                    <TextField
                        fullWidth
                        id="qualified_for_truck_semitrailer"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.qualified_for_truck_semitrailer ? "Yes" : "No"}
                        onChange={(e) => {
                            formik.setFieldValue('qualified_for_truck_semitrailer', e.target.value === "Yes" ? true : false)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.qualified_for_truck_semitrailer && Boolean(formik.errors.qualified_for_truck_semitrailer) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.qualified_for_truck_semitrailer && Boolean(formik.errors.qualified_for_truck_semitrailer) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.qualified_for_truck_semitrailer && Boolean(formik.errors.qualified_for_truck_semitrailer) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        <MenuItem key={"Yes"} value={"Yes"}>
                            Yes
                        </MenuItem>
                        <MenuItem key={"No"} value={"No"}>
                            No
                        </MenuItem>
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Qualified for other (specify)
                    </Typography>
                    <TextField
                        fullWidth
                        id="qualified_for_other"
                        size="small"
                        variant="outlined"
                        placeholder='Specify'
                        value={formik.values.qualified_for_other}
                        onChange={(e) => {
                            formik.setFieldValue('qualified_for_other', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.qualified_for_other && Boolean(formik.errors.qualified_for_other) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.qualified_for_other && Boolean(formik.errors.qualified_for_other) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.qualified_for_other && Boolean(formik.errors.qualified_for_other) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Signature of Examiner
                    </Typography>
                    <TextField
                        fullWidth
                        id="signature_of_examiner"
                        size="small"
                        variant="outlined"
                        placeholder='Specify'
                        value={formik.values.signature_of_examiner}
                        onChange={(e) => {
                            formik.setFieldValue('signature_of_examiner', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.signature_of_examiner && Boolean(formik.errors.signature_of_examiner) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.signature_of_examiner && Boolean(formik.errors.signature_of_examiner) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.signature_of_examiner && Boolean(formik.errors.signature_of_examiner) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                fontStyle: "italic"
                            }
                        }}
                    />
                </Grid >
                {(!data || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role))) && !isDriverFile ? <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={isCreating || isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                        Save
                    </Button>
                    {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                        <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                            Once you click save, you will not be able to edit this form.
                        </Typography>
                    )}
                </Grid > : null}
                {data && currentUser?.role === "SuperAdmin" && <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={!!member?.terminated_at} onClick={() => {
                        const res = window.confirm("Are you sure you want to delete this form?")
                        if (res && data) {
                            deletePerformanceRatingAndOwnerSignature(data.id)
                        }
                    }} variant="contained" color="primary">
                        Delete Form
                    </Button>
                </Grid>}
            </Grid >
        </div >
    )
}