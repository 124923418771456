/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import TableWrapper from '../../../../compliancemasters/partials/widgets/tables/components/TableWrapper'
import TableContainerHeader from '../../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader'
import TableContainerHeaderTitle from '../../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle'
import TableContainer from '../../../../compliancemasters/partials/widgets/tables/components/TableContainer'
import TableHeader from '../../../../compliancemasters/partials/widgets/tables/components/TableHeader'
import TableHeaderColumn from '../../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn'
import { IMember } from '../../../../models'
import DriverApplicationForEmployment from './DriverApplicationForEmployment'
import EmploymentHistory from './EmploymentHistory'
import CertificateOfCompliance from './CertOfCompliance'
import RequestForCheckDrivingRecords from './RequestForCheckDrivingRecords'
import DriverStatementDutyHours from './DriverStatementDutyHours'
import MedicalExaminerRegistryVerification from './MedicalExaminerRegistryVerification'
import DriversHandbookReceipt from './DriversHandbookReceipt'
import RecordOfRoadTest from './RecordOfRoadTest'
import CertificateOfRoadTest from './CertificateOfRoadTest'
import DachClearingHouseQueries from './DachClearingHouseQueries'
import PrevEmployeeSafetyPerformanceHistory from './PrevEmployeeSafetyPerformanceHistory'
import PrevPreEmploymentDachTestStatement from './PrevPreEmploymentDachTestStatement'
import DachCertifiedReceipt from './DachCertifiedReceipt'
import DocsOfActualKnowledge from './DocsOfActualKnowledge'
import AnnualReviewOfDrivingRecords from './AnnualReviewOfRoadRecord'
import { StripeProduct } from '../../models'
import { useAuth } from '../../auth'
import WrittenDrugAlcoholPolicy from './WrittenDrugAlcoholPolicy'
import { getCompanyAddOns } from '../../../../shared'
import ViewDriverFileButton from '../../../../compliancemasters/partials/driver-file-pdf/ViewDriverFileButton'
import ViewDriverFileModel from '../../../../compliancemasters/partials/driver-file-pdf/ViewDriverFileModel'





const NewFormTable = ({ member, subscription, pageLoading }: { member: IMember; subscription: StripeProduct; pageLoading?: boolean }) => {
    const { currentUser } = useAuth()
    const [driverFileOpen, setDriverFileOpen] = React.useState<boolean>(false)
    const [addons, setAddons] = React.useState<StripeProduct[]>([])
    const [showWrittenPolicy, setShowWrittenPolicy] = React.useState<boolean>(false)

    const getAddOns = React.useCallback(async () => {
        const res = await getCompanyAddOns(currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role) ? Number(member?.company?.id) : undefined)
        setAddons(res)
    }, [currentUser, member])

    React.useEffect(() => {
        getAddOns()
    }, [getAddOns])

    React.useEffect(() => {
        if (addons.some(addon => addon.name === "D&A Written Policy") || subscription?.name === "Master Package") {
            setShowWrittenPolicy(true)
        } else {
            setShowWrittenPolicy(false)
        }
    }, [addons, subscription])

    return (
        <TableWrapper>
            <TableContainerHeader>
                <TableContainerHeaderTitle title="Forms Overview" />
                {member?.pre_employment_screened && <ViewDriverFileButton memberId={member?.id!} setOpen={setDriverFileOpen} />}
            </TableContainerHeader>
            <TableContainer>
                <TableHeader>
                    <TableHeaderColumn>Form</TableHeaderColumn>
                    <TableHeaderColumn>Progress</TableHeaderColumn>
                    <TableHeaderColumn>Status</TableHeaderColumn>
                    <TableHeaderColumn>{""}</TableHeaderColumn>
                    <TableHeaderColumn>Actions</TableHeaderColumn>
                </TableHeader>
                <tbody>
                    {!["Drug and Alcohol Package", "Driver File"].includes(subscription?.name) ? <>
                        {showWrittenPolicy ? <WrittenDrugAlcoholPolicy id={Number(member?.id)} /> : null}
                        <DriverApplicationForEmployment id={Number(member?.id)} />
                        <EmploymentHistory id={Number(member?.id)} />
                        {(!member?.pre_employment_screened || (member?.pre_employment_screened && member?.pre_employment_investigation.length > 0)) ? (
                            <PrevEmployeeSafetyPerformanceHistory userId={member?.user?.id} id={Number(member?.id)} />
                        ) : null}
                        {member?.pre_employment_screened ? (

                            <CertificateOfCompliance id={Number(member?.id)} />

                        ) : null}
                        {member?.pre_employment_screened ? (

                            <RequestForCheckDrivingRecords id={Number(member?.id)} />

                        ) : null}
                        {member?.pre_employment_screened ? (

                            <DriverStatementDutyHours id={Number(member?.id)} />

                        ) : null}
                        {member?.pre_employment_screened ? (

                            <MedicalExaminerRegistryVerification id={Number(member?.id)} />

                        ) : null}
                        {member?.pre_employment_screened ? (

                            <DriversHandbookReceipt id={Number(member?.id)} />

                        ) : null}
                        {member?.pre_employment_screened && currentUser && currentUser.role !== "Member" ? (

                            <RecordOfRoadTest id={Number(member?.id)} />

                        ) : null}
                        {member?.pre_employment_screened ? (
                            <>
                                {currentUser && currentUser.role !== "Member" && <CertificateOfRoadTest id={Number(member?.id)} />}
                            </>
                        ) : null}
                    </> : null}
                    {member?.pre_employment_screened || (!member?.pre_employment_screened && ["Drug and Alcohol Package"].includes(subscription?.name)) ? <DachClearingHouseQueries id={Number(member?.id)} /> : null}
                    {!["Drug and Alcohol Package", "Driver File"].includes(subscription?.name) && member?.pre_employment_screened ? (
                        <>
                            <PrevPreEmploymentDachTestStatement id={Number(member?.id)} />
                            <DachCertifiedReceipt id={Number(member?.id)} />

                            {currentUser && currentUser.role !== "Member" && <AnnualReviewOfDrivingRecords id={Number(member?.id)} />}
                        </>
                    ) : null}
                </tbody>
            </TableContainer>
            <ViewDriverFileModel open={driverFileOpen} setOpen={setDriverFileOpen} memberId={member?.id} />
        </TableWrapper>
    )
}

export default NewFormTable