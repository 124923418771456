import React from 'react'
import TableWrapper from '../components/TableWrapper'
import TableContainerHeader from '../components/TableContainerHeader'
import TableContainerHeaderTitle from '../components/TableContainerHeaderTitle'
import TableContainer from '../components/TableContainer'
import { useAuth } from '../../../../../app/modules/auth'
import NewRandomTestingSelection from '../../../modals/new-file/NewRandomTestingSelection'
import RandomTestingSelectionModal from '../../../modals/new-file/RandomTestingModal'
import { useGetRandomDACHTestingByCompanyQuery, useGetRandomDACHTestingQuery, useSetRandomDachTestViewedMutation } from '../../../../../store/reducers/RandomDach/random-dach'
import { IRandomDACHTesting } from '../../../../../store/interfaces/RandomDach/random-dach'
import { IconButton, ListItemIcon, Menu, MenuItem, TableCell, TableHead, TableRow } from '@mui/material'
import { Link } from 'react-router-dom'
import RandomTestingModal from './RandomTestingModal'
import { AdminPanelSettingsRounded, MoreVertRounded } from '@mui/icons-material'
import UpdateRandomTesting from './UpdateRandomTesting'

const MenuComponent = ({
    anchorEl,
    open,
    handleClose,
    randomTest,
    getDachTesting,
    setModalOpen,
    setCompanyModalOpen,
}: {
    anchorEl: null | HTMLElement
    open: boolean
    handleClose: () => void
    getDachTesting?: () => void
    setModalOpen: (open: boolean) => void
    randomTest?: IRandomDACHTesting
    companyModalOpen?: boolean
    setCompanyModalOpen?: (open: boolean) => void
}) => {
    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem
                onClick={() => {
                    setModalOpen(true)
                    handleClose()
                }}>
                <ListItemIcon>
                    <AdminPanelSettingsRounded fontSize="medium" />
                </ListItemIcon>
                Update Selection
            </MenuItem>
            {/* <MenuItem
                onClick={() => {
                    // setCompanyModalOpen(true)
                    handleClose()
                }}>
                <ListItemIcon>
                    <AdminPanelSettingsRounded fontSize="medium" />
                </ListItemIcon>
                Delete Selection
            </MenuItem> */}
        </Menu>
    )
}

const RandomTestingTables = () => {
    const [open, setOpen] = React.useState(false);
    const [detailModalOpen, setDetailModalOpen] = React.useState(false);
    const [randomTesting, setRandomTesting] = React.useState<IRandomDACHTesting>()
    const [dachTesting, setDachTesting] = React.useState<IRandomDACHTesting[]>([])
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [randomTestingOpen, setRandomTestingOpen] = React.useState<boolean>(false)
    const menuOpen = Boolean(anchorEl);
    const { currentUser } = useAuth()

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const [setRandomSelectionViewed] = useSetRandomDachTestViewedMutation()

    const { data = [] } = useGetRandomDACHTestingByCompanyQuery(Number(currentUser?.company?.id), {
        skip: !currentUser?.company || currentUser?.role !== "Owner",
        refetchOnReconnect: true,
        refetchOnFocus: true,
    })
    const { data: list = [] } = useGetRandomDACHTestingQuery(undefined, {
        skip: !["Admin", "SuperAdmin"].includes(currentUser?.role!),
        refetchOnReconnect: true,
        refetchOnFocus: true,
    })

    const getDachTesting = () => {
        if (currentUser?.role === "Owner") {
            setDachTesting(data)
        } else {
            setDachTesting(list)
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    };


    React.useEffect(() => {
        getDachTesting()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list, data, currentUser])


    return (
        <>
            <TableWrapper>
                <TableContainerHeader>
                    <TableContainerHeaderTitle title="Random Drug and Alcohol Testing Selections" />
                    {currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role) ? (
                        <NewRandomTestingSelection open={open} setOpen={setOpen} />
                    ) : null}
                    {currentUser && ["Owner"].includes(currentUser?.role) ? (
                        <a
                            href='https://compliance-masters-videos.s3.us-east-2.amazonaws.com/1cec89c9-add8-4162-ac27-0c7b5333375e.pdf'
                            target='_blank'
                            rel='noreferrer'
                            style={{ textDecoration: 'underline' }}
                        >
                            What Employees Need To Know About DOT Drug and Alcohol Testing
                        </a>
                    ) : null}
                </TableContainerHeader>
                <TableContainer>
                    <TableHead>
                        <TableCell align="center">
                            Company
                        </TableCell>
                        <TableCell align="center">
                            Quarter
                        </TableCell>
                        <TableCell align="center">
                            Year
                        </TableCell>
                        <TableCell align="center">
                            Date Requested
                        </TableCell>
                        <TableCell align="center">
                            Date Completed
                        </TableCell>
                        <TableCell align="center">
                        </TableCell>
                        <TableCell align="center">
                            {["Admin", "SuperAdmin"].includes(currentUser?.role!) ? (
                                <>
                                    Action
                                </>
                            ) : null}
                        </TableCell>
                    </TableHead>
                    {dachTesting.map((dach) => (
                        <TableRow key={dach.id} style={{
                            cursor: "pointer"
                        }}>
                            <TableCell align="center">
                                {dach.company?.name}
                            </TableCell>
                            <TableCell align="center">
                                {dach.quarter}
                            </TableCell>
                            <TableCell align="center">
                                {Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(new Date(dach.year))}
                            </TableCell>
                            <TableCell align="center">
                                {Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date(dach.created_at))}
                            </TableCell>
                            <TableCell align="center">
                                {dach.date_completed ? Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date(dach.date_completed)) : "N/A"}
                            </TableCell>
                            <TableCell align="center">
                                <Link onClick={(e) => {
                                    e.preventDefault()
                                    setRandomTesting(dach)
                                    if (!dach.viewed && currentUser?.role === "Owner") {
                                        setRandomSelectionViewed(dach.id)
                                    }
                                    setDetailModalOpen(true)
                                }} to={`/random-testing-selections/${dach.id}`}>
                                    View Selection
                                </Link>
                            </TableCell>
                            <TableCell align="center" className="text-end">
                                {["Admin", "SuperAdmin"].includes(currentUser?.role!) ? (
                                    <IconButton onClick={(e) => {
                                        e.stopPropagation()
                                        setRandomTesting(dach)
                                        handleClick(e)
                                    }}>
                                        <MoreVertRounded />
                                    </IconButton>
                                ) : null}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableContainer>
            </TableWrapper >
            <RandomTestingSelectionModal
                open={open}
                setOpen={setOpen}
            />
            {randomTesting ? <RandomTestingModal
                open={detailModalOpen}
                setOpen={setDetailModalOpen}
                randomTesting={randomTesting}
                setRandomTesting={setRandomTesting}
            /> : null}
            <MenuComponent
                getDachTesting={getDachTesting}
                setModalOpen={setRandomTestingOpen}
                randomTest={randomTesting}
                anchorEl={anchorEl}
                open={menuOpen}
                handleClose={handleClose}
            />
            <UpdateRandomTesting
                getDachTesting={getDachTesting}
                open={randomTestingOpen}
                setOpen={setRandomTestingOpen}
                randomTesting={randomTesting}
            />
        </>
    )
}

export default RandomTestingTables