import React from 'react'
import { useParams } from 'react-router-dom';
import { IMember } from '../../../models';
import { getCompanyMember } from '../../../shared';
import { Box, Button, Container, Paper } from '@mui/material';
import FormHeaderCard from '../HeaderCard';
import ApplicantInfoAndHours from './ApplicantInfoAndHours';
import DriverSignature from './DriverSignature';
import Certification from './Certification';
import WitnessToDriversCertification from './WitnessToDriversCertification';
import ActionButtons from '../ActionButtons';
import { useGetDriverStatementDutyHoursProgressMutation, useGetMemberDriverStatementDutyHoursProgressMutation } from '../../../store/reducers/DriverStatementDutyHours/progress';
import { useAuth } from '../../../app/modules/auth';
import { useThemeMode } from '../../partials';

const DriversStatementDutyHours = () => {
    const { currentUser } = useAuth()
    const { mode } = useThemeMode()
    const [getProgress, { data: progress }] = useGetDriverStatementDutyHoursProgressMutation()
    const [getMemberProgress, { data: memberProgress }] = useGetMemberDriverStatementDutyHoursProgressMutation()
    const [member, setMember] = React.useState<IMember>()
    const { id } = useParams()
    const [value, setValue] = React.useState(0);


    React.useEffect(() => {
        if (id) {
            getCompanyMember(id).then((data) => {
                setMember(data)
            })
            getProgress(Number(id))
            getMemberProgress(Number(id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Container style={{ userSelect: 'none' }}>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <FormHeaderCard
                    headerTitle='Driver Statement of on Duty Hours'
                    status={progress && (progress?.progress > 0 && progress?.progress < 100) ? "In Progress" : progress && progress.progress > 99 ? "Completed" : "Not Started"}
                    sectionCount={4}
                    itemCount={29}
                    totalProgress={progress ? progress?.progress : 0}
                    memberProgress={memberProgress ? memberProgress?.progress : 0}
                    nextButton={{
                        title: currentUser && currentUser.role === "Member" ? "" : "",
                        path: currentUser && currentUser.role === "Member" ? "" : ""
                    }}
                    member={member}
                    setValue={setValue}
                    value={value}
                />
            </Box>
            <>
                <ApplicantInfoAndHours member={member} />
            </>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <DriverSignature member={member} />
            </Box>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <Certification member={member} />
            </Box>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                {currentUser && currentUser.role !== "Member" && <WitnessToDriversCertification member={member} />}
            </Box>
            <ActionButtons
                member={member}
                prevButton={{
                    title: "Check of Driving Records",
                    path: "request-for-check-of-driving-record"
                }}
                nextButton={{
                    title: currentUser && currentUser.role === "Member" ? "Driver Handbook Receipt" : "Medical Examiner Verification",
                    path: currentUser && currentUser.role === "Member" ? "driver-handbook-receipt" : "medical-examiner-national-registry-verification"
                }}
            />
            <div style={{
                display: "flex",
                width: "47vw",
                justifyContent: "center",
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 10
            }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (currentUser?.role === "Member") {
                            window.location.assign(`/dashboard/overview`)
                        } else {

                            if (member?.id) {
                                window.location.assign(`/member/${member?.id}/profile/driver-documents`)
                            }
                        }
                    }}
                    fullWidth
                >Back to Forms Overview</Button>
            </div>
        </Container>
    )
}

export default DriversStatementDutyHours