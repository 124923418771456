import React from 'react'
import { IMember } from '../../../models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material'
import {
    IDriversHandbookReceiptDto
} from '../../../store/interfaces/DriversHandbookReceipt/DriversHandbookReceipt'
import {
    useCreateDriversHandbookReceiptMutation,
    useUpdateDriversHandbookReceiptMutation,
    useGetDriversHandbookReceiptByUserIdQuery,
    useDeleteDriversHandbookReceiptMutation
} from '../../../store/reducers/DriversHandbookReceipt/drivers-handbook-receipt'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'
import { useGetProcessRecordByUserIdQuery } from '../../../store/reducers/DriversApplicationForEmployment/process-record'
import { useGetCertificationByUserIdQuery } from '../../../store/reducers/CertificateOfComplianceWithDriverLicenseRequirements/certification'


const initialValues: Partial<IDriversHandbookReceiptDto> = {
    drivers_name: '',
    date_of_hire: '',
    drivers_signature: '',
    supervisor_or_carrier_representative_signature: '',
}

const validationSchema = Yup.object({
    drivers_name: Yup.string(),
    date_of_hire: Yup.string(),
    drivers_signature: Yup.string(),
    supervisor_or_carrier_representative_signature: Yup.string(),
})


const DriversSignature = ({ member, isDriverFile }: { member?: IMember, isDriverFile?: boolean }) => {
    const { currentUser } = useAuth()
    const { mode } = useThemeMode()
    const { data } = useGetDriversHandbookReceiptByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    })
    const { data: processRecord } = useGetProcessRecordByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    })

    const { data: cert } = useGetCertificationByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })

    const [createDriversHandbookReceipt, { isLoading: isCreating }] = useCreateDriversHandbookReceiptMutation()
    const [updateDriversHandbookReceipt, { isLoading: isUpdating }] = useUpdateDriversHandbookReceiptMutation()
    const [deleteDriversHandbookReceipt] = useDeleteDriversHandbookReceiptMutation()

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (member?.user?.id) {
                if (data) {
                    // @ts-ignore
                    updateDriversHandbookReceipt({
                        ...values,
                        id: data.id,
                    })
                } else {
                    // @ts-ignore
                    createDriversHandbookReceipt({
                        ...values,
                        // @ts-ignore
                        company: member.company.id,
                        // @ts-ignore
                        member: member.id,
                    })
                }
            }
            resetForm()
        },
        validateOnBlur: true,
        validateOnChange: true,
    })

    React.useEffect(() => {
        if (data) {
            formik.setValues(data)
        } else {
            formik.setValues({
                ...initialValues,
                drivers_name: `${member?.user?.first_name} ${member?.user?.last_name}`,
            })
            if (processRecord) {
                formik.setFieldValue('date_of_hire', processRecord?.date_employed)
            } else if (cert) {
                formik.setFieldValue("date_of_hire", cert?.date_of_hire)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, member, processRecord, cert])

    return (
        <>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "55vw",
                    sm: "60vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>

                <div style={{
                    padding: 10,
                    margin: 10
                }}>
                    <Typography sx={{ textAlign: "center" }} variant="h4" gutterBottom component="h4">
                        Driver's Signature
                    </Typography>
                    <Typography sx={{ textAlign: "justify" }} variant="h6" gutterBottom component="h6">
                        This issue of the FMCSR Pocketbook includes all revisions issued on or before February 1, 2022.
                    </Typography>
                    <Typography sx={{ textAlign: "justify", color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        I acknowledge receipt of this Federal Motor Carrier Safety Regulations Pocketbook (347).
                        In addition, I agree to familiarize myself with the Federal Motor Carrier Safety Regulations (FMCSR) of the U.S.
                        Department of Transportation, Parts 40, 380, 382, 383, 387, 390-397, and 399 of Subchapter B, Chapter III, Title 49 of the Code of Federal Regulations, as contained therein.
                    </Typography>
                    {member && member?.pre_employment_investigation.length > 0 ? <Typography sx={{ textAlign: "justify", color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', mt: 2, mb: 2 }} variant="body1" gutterBottom component="p">
                        A driver’s handbook will be mailed to your home address after completion of driver file
                    </Typography> : null}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                Employee's Name
                            </Typography>
                            <TextField
                                fullWidth
                                id="drivers_name"
                                placeholder="Employee's Name"
                                size="small"
                                variant="outlined"
                                value={formik.values.drivers_name}
                                // onChange={(e) => {
                                //     formik.setFieldValue('drivers_name', e.target.value)
                                // }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor: formik.touched.drivers_name && Boolean(formik.errors.drivers_name) ? "red" : "green",
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor: formik.touched.drivers_name && Boolean(formik.errors.drivers_name) ? "red" : "green",
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor: formik.touched.drivers_name && Boolean(formik.errors.drivers_name) ? "red" : "green",
                                        },
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                    }
                                }}
                                disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                                InputProps={{
                                    style: {
                                        color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                Date of Hire
                            </Typography>
                            <TextField
                                fullWidth
                                id="date_of_hire"
                                type='date'
                                size="small"
                                variant="outlined"
                                value={formik.values.date_of_hire}
                                onChange={(e) => {
                                    formik.setFieldValue('date_of_hire', e.target.value)
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor: formik.touched.date_of_hire && Boolean(formik.errors.date_of_hire) ? "red" : "green",
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor: formik.touched.date_of_hire && Boolean(formik.errors.date_of_hire) ? "red" : "green",
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor: formik.touched.date_of_hire && Boolean(formik.errors.date_of_hire) ? "red" : "green",
                                        },
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                    }
                                }}
                                disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                                InputProps={{
                                    style: {
                                        color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                Drivers Signature
                            </Typography>
                            <TextField
                                fullWidth
                                id="drivers_signature"
                                placeholder='Drivers Signature'
                                size="small"
                                variant="outlined"
                                value={formik.values.drivers_signature}
                                onChange={(e) => {
                                    formik.setFieldValue('drivers_signature', e.target.value)
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor: formik.touched.drivers_signature && Boolean(formik.errors.drivers_signature) ? "red" : "green",
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor: formik.touched.drivers_signature && Boolean(formik.errors.drivers_signature) ? "red" : "green",
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor: formik.touched.drivers_signature && Boolean(formik.errors.drivers_signature) ? "red" : "green",
                                        },
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                    }
                                }}
                                disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                                InputProps={{
                                    style: {
                                        color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        fontStyle: "italic"
                                    }
                                }}
                            />
                        </Grid>
                        {((!data?.date_of_hire || !data?.drivers_name || !data?.drivers_signature) || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role))) && !isDriverFile ? (
                            <Grid item xs={12} sm={12}>
                                <Button fullWidth disabled={isCreating || isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                                    Save
                                </Button>
                                {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                                    <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                                        Once you click save, you will not be able to edit this form.
                                    </Typography>
                                )}
                            </Grid>
                        ) : null}
                    </Grid >
                </div >
            </Box >
            {currentUser?.role !== "Member" && (
                <Box sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: "47vw",
                        md: "55vw",
                        sm: "60vw",
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }} component={Paper}>

                    <div style={{
                        padding: 10,
                        margin: 10
                    }}>
                        <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                            Supervisors Signature
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                    Supervisor or Carrier Representative Signature
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="supervisor_or_carrier_representative_signature"
                                    placeholder='Supervisor or Carrier Representative Signature'
                                    size="small"
                                    variant="outlined"
                                    value={formik.values.supervisor_or_carrier_representative_signature}
                                    onChange={(e) => {
                                        formik.setFieldValue('supervisor_or_carrier_representative_signature', e.target.value)
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.supervisor_or_carrier_representative_signature && Boolean(formik.errors.supervisor_or_carrier_representative_signature) ? "red" : "green",
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.supervisor_or_carrier_representative_signature && Boolean(formik.errors.supervisor_or_carrier_representative_signature) ? "red" : "green",
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.supervisor_or_carrier_representative_signature && Boolean(formik.errors.supervisor_or_carrier_representative_signature) ? "red" : "green",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                    disabled={member?.terminated_at || data?.supervisor_or_carrier_representative_signature ? true : false}
                                    InputProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                            fontStyle: "italic"
                                        }
                                    }}
                                />
                            </Grid>
                            {(!data || (data && !data?.supervisor_or_carrier_representative_signature) || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role))) && !isDriverFile ? <Grid item xs={12} sm={12}>
                                <Button fullWidth disabled={isCreating || isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                                    Save
                                </Button>
                                {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                                    <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                                        Once you click save, you will not be able to edit this form.
                                    </Typography>
                                )}
                            </Grid> : null}
                            {data && currentUser?.role === "SuperAdmin" && <Grid item xs={12} sm={12}>
                                <Button fullWidth disabled={!!member?.terminated_at} onClick={() => {
                                    const res = window.confirm("Are you sure you want to delete this form?")
                                    if (res && data) {
                                        deleteDriversHandbookReceipt(data.id)
                                    }
                                }} variant="contained" color="primary">
                                    Delete Form
                                </Button>
                            </Grid>}
                        </Grid >
                    </div >
                </Box >
            )}
        </>
    )
}

export default DriversSignature