import * as Yup from 'yup';
import * as React from 'react';
import { useFormik } from 'formik';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { TransitionProps } from '@mui/material/transitions';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, Chip, Grid, InputLabel, MenuItem, Paper, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ICompany, IMember } from '../../../../models';
import { getCompanies } from '../../../../shared';
import { IRandomDACHTestingDto } from '../../../../store/interfaces/RandomDach/random-dach';
import { useCreateRandomDACHTestingMutation } from '../../../../store/reducers/RandomDach/random-dach';
import { toast } from 'react-toastify';

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const initialValues: IRandomDACHTestingDto = {
    company: '',
    quarter: '',
    year: '',
    members: []
}

const validationSchema = Yup.object({
    company: Yup.string().required('Company is required'),
    quarter: Yup.string().required('Quarter is required'),
    year: Yup.string().required('Year is required'),
    members: Yup.array().required('Drivers are required')
})


export default function RandomTestingSelectionModal({ open, setOpen }: IProps) {
    const [companies, setCompanies] = React.useState<ICompany[]>([])
    const [members, setMembers] = React.useState<IMember[]>([])
    const [member, setMember] = React.useState<IMember>()
    const [type, setType] = React.useState<string>()
    const [createRandomDach] = useCreateRandomDACHTestingMutation()
    const handleClose = () => {
        formik.resetForm()
        setMember(undefined)
        setType(undefined)
        setOpen(false);
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            createRandomDach(values).unwrap().then(() => {
                toast.success('Random Drug and Alcohol selection notification created successfully')
                resetForm()
                setMember(undefined)
                setType(undefined)
                setOpen(false)
            }).catch((err) => {
                toast.error(err.message)
                toast.error('Something went wrong')
                setOpen(false)
            })
        },
        validateOnBlur: true,
        validateOnChange: true
    })

    React.useEffect(() => {
        if (open) {
            getCompanies().then(res => {
                setCompanies(res)
            })
        }
    }, [open])

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle sx={{
                backgroundColor: '#F5F5F5',
                color: '#000000',
                fontSize: '16px',
                fontWeight: 'bold',
                padding: '20px 20px 20px 20px',
                textAlign: 'center'
            }}>
                Add a random Drug and Alcohol selection notification
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12} sm={12}>
                        <InputLabel>
                            Select Company
                        </InputLabel>
                        <TextField
                            fullWidth
                            placeholder="Company"
                            variant="outlined"
                            size="small"
                            select
                            value={formik.values.company}
                            onChange={e => {
                                formik.setFieldValue('company', e.target.value)
                                const company = companies.find(company => company.id === e.target.value)
                                if (company) {
                                    setMembers(company.members)
                                }
                            }}
                        >
                            {companies.map(company => (
                                <MenuItem key={company.id} value={company.id}>
                                    {company.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel>
                            Select Quarter
                        </InputLabel>
                        <TextField
                            fullWidth
                            placeholder="Company"
                            variant="outlined"
                            size="small"
                            select
                            value={formik.values.quarter}
                            onChange={e => formik.setFieldValue('quarter', e.target.value)}
                        >
                            <MenuItem value="First Quarter">
                                First Quarter
                            </MenuItem>
                            <MenuItem value="Second Quarter">
                                Second Quarter
                            </MenuItem>
                            <MenuItem value="Third Quarter">
                                Third Quarter
                            </MenuItem>
                            <MenuItem value="Fourth Quarter">
                                Fourth Quarter
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DatePicker sx={{ width: "100%" }} views={['year']} onYearChange={e => {
                                formik.setFieldValue('year', e)
                            }} />
                        </LocalizationProvider>
                    </Grid>
                    {formik.values.company ? <>
                        <Grid item xs={12} sm={12}>
                            <InputLabel>
                                Select Members
                            </InputLabel>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                disabled={!formik.values.company}
                                select
                                value={member?.id || ''}
                                onChange={e => {
                                    setMember(members.find(x => Number(x.id) === Number(e.target.value)))
                                }}
                            >
                                {members.filter((m) => Number(m.id) !== Number(formik.values.members.find(x => Number(x) === Number(m.id)))).map(member => (
                                    <MenuItem key={member.id} value={member.id}>
                                        {`${member.user.first_name} ${member.user.last_name} - (${member?.user?.email})`}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <InputLabel>
                                Select Type
                            </InputLabel>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                disabled={!formik.values.company}
                                select
                                value={type || ''}
                                onChange={e => {
                                    setType(e.target.value)
                                }}
                            >
                                <MenuItem value={"Drug Test"}>
                                    Drug Test
                                </MenuItem>
                                <MenuItem value={"Alcohol Test"}>
                                    Alcohol Test
                                </MenuItem>
                                <MenuItem value={"Drug and Alcohol Test"}>
                                    Drug and Alcohol Test
                                </MenuItem>
                            </TextField>
                        </Grid>
                    </> : null}
                    {member && type &&
                        <Grid item xs={12} sm={12}>
                            <Button
                                disabled={!member || !type}
                                fullWidth
                                onClick={() => {
                                    if (member && type) {
                                        formik.setFieldValue('members', [...formik.values.members, {
                                            memberId: member.id,
                                            testType: type
                                        }])
                                        setMember(undefined)
                                        setType(undefined)
                                    }
                                }}>
                                Add
                            </Button>
                        </Grid>
                    }
                    <Grid item xs={12} sm={12}>
                        <Box
                            sx={{
                                height: '200px',
                                border: '1px solid #000000',
                                borderRadius: '5px',
                                overflowY: 'scroll'
                            }}
                            component={Paper}
                        >
                            {formik.values.members.map(member => {
                                const memberObj = members.find(m => Number(m.id) === member.memberId)
                                if (member) {
                                    return (
                                        <Chip
                                            key={member.memberId}
                                            label={`${memberObj?.user.first_name} ${memberObj?.user.last_name} - (${memberObj?.user?.email}) - ${member.testType}`}
                                            onDelete={() => {
                                                formik.setFieldValue('members', formik.values.members.filter(m => m !== member))
                                            }}
                                            sx={{ margin: '5px' }}
                                        />
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="small"
                            // disabled={isLoading || !formik.isValid}
                            onClick={() => formik.handleSubmit()}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
