import {applicantBasicInfoApi} from './applicant.basic.info'
import {applicantInformationApi} from './applicant-information'
import {applicantSignatureApi} from './applicant-signature'
import {processRecordApi} from './process-record'
import {terminationOfEmploymentApi} from './termination-of-employment'
import {driversApplicationProgress} from './progress'

export const DriversApplicationReducers = {
  [applicantBasicInfoApi.reducerPath]: applicantBasicInfoApi.reducer,
  [applicantInformationApi.reducerPath]: applicantInformationApi.reducer,
  [applicantSignatureApi.reducerPath]: applicantSignatureApi.reducer,
  [processRecordApi.reducerPath]: processRecordApi.reducer,
  [terminationOfEmploymentApi.reducerPath]: terminationOfEmploymentApi.reducer,
  [driversApplicationProgress.reducerPath]: driversApplicationProgress.reducer,
}

export const DriversApplicationMiddleware = [
  applicantBasicInfoApi.middleware,
  applicantInformationApi.middleware,
  applicantSignatureApi.middleware,
  processRecordApi.middleware,
  terminationOfEmploymentApi.middleware,
  driversApplicationProgress.middleware,
]
