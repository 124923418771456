import React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Box, Container, Divider, Grid, MenuItem, Paper, TextField, Typography } from '@mui/material'
import { IAnnualReviewOfDrivingRecord, Options } from '../../../../../../store/interfaces/AnnualReviewOfDrivingRecord/annual-review-of_driving-record'
import { useThemeMode } from '../../../../../../compliancemasters/partials'
import { IMember } from '../../../../../../models'
import { useParams } from 'react-router-dom'
import { getCompanyMember } from '../../../../../../shared'
import FormHeaderCard from '../../../../../../compliancemasters/reforms/HeaderCard'
import { useGetNonCurrentAnnualReviewOfDrivingRecordByUserIdQuery } from '../../../../../../store/reducers/AnnualReviewOfDrivingRecord/annual-review-of-driving-record'





const validationSchema = Yup.object({
    name_of_driver: Yup.string().required('Required'),
    he_she: Yup.string().required('Required'),
    action_taken_with_driver: Yup.string().required('Required'),
    reviewed_by_signature: Yup.string().required('Required'),
    date_of_mvr_review: Yup.string().required('Required'),
    print: Yup.string().required('Required'),
    title: Yup.string().required('Required'),
    motor_carrier_name: Yup.string().required('Required'),
    motor_carrier_address: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state_zip: Yup.string().required('Required'),
})

const AnnualReviewDrivingRecordItem = ({ annualReview }: { annualReview: IAnnualReviewOfDrivingRecord }) => {
    const { mode } = useThemeMode()
    const formik = useFormik({
        initialValues: annualReview,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
        },
        validateOnBlur: true,
        validateOnChange: true
    })
    return (
        <>

            <Grid item xs={12} sm={12} mt={2}>
                <Typography sx={{ textAlign: "center" }} variant="h4" gutterBottom component="h4">
                    Annual Review of Driving Record
                </Typography>
            </Grid >
            <Grid item xs={12} sm={12} mt={2}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h6" gutterBottom component="h6">
                    File Status:   {annualReview.is_current ? "Current File" : annualReview.is_original ? "Original File" : ""}
                </Typography>
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    I have hereby reviewed the driving record of (name of driver)
                </Typography>
                <TextField
                    fullWidth
                    id="name_of_driver"
                    placeholder='Name of Driver'
                    size="small"
                    variant="outlined"
                    value={formik.values.name_of_driver}

                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: formik.touched.name_of_driver && Boolean(formik.errors.name_of_driver) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                borderColor: formik.touched.name_of_driver && Boolean(formik.errors.name_of_driver) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: formik.touched.name_of_driver && Boolean(formik.errors.name_of_driver) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    In accordance with Section 391.25 and find that he/she (select one)
                </Typography>
                <TextField
                    fullWidth
                    id="he_she"
                    select
                    size="small"
                    variant="outlined"
                    value={formik.values.he_she}
                    onChange={(e) => {
                        formik.setFieldValue('he_she', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: formik.touched.he_she && Boolean(formik.errors.he_she) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                borderColor: formik.touched.he_she && Boolean(formik.errors.he_she) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: formik.touched.he_she && Boolean(formik.errors.he_she) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                        }
                    }}
                >
                    {Object.values(Options).map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Action taken with driver
                </Typography>
                <TextField
                    fullWidth
                    id="action_taken_with_driver"
                    placeholder='Action taken with driver'
                    multiline
                    rows={4}
                    size="small"
                    variant="outlined"
                    value={formik.values.action_taken_with_driver}
                    onChange={(e) => {
                        formik.setFieldValue('action_taken_with_driver', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: formik.touched.action_taken_with_driver && Boolean(formik.errors.action_taken_with_driver) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                borderColor: formik.touched.action_taken_with_driver && Boolean(formik.errors.action_taken_with_driver) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: formik.touched.action_taken_with_driver && Boolean(formik.errors.action_taken_with_driver) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Reviewed by Signature
                </Typography>
                <TextField
                    fullWidth
                    id="reviewed_by_signature"
                    placeholder='Reviewed by Signature'
                    size="small"
                    variant="outlined"
                    value={formik.values.reviewed_by_signature}
                    onChange={(e) => {
                        formik.setFieldValue('reviewed_by_signature', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: formik.touched.reviewed_by_signature && Boolean(formik.errors.reviewed_by_signature) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                borderColor: formik.touched.reviewed_by_signature && Boolean(formik.errors.reviewed_by_signature) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: formik.touched.reviewed_by_signature && Boolean(formik.errors.reviewed_by_signature) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            fontStyle: 'italic'
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            fontStyle: 'italic'

                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Date of MVR review
                </Typography>
                <TextField
                    fullWidth
                    id="date_of_mvr_review"
                    type='date'
                    size="small"
                    variant="outlined"
                    value={formik.values.date_of_mvr_review}
                    onChange={(e) => {
                        formik.setFieldValue('date_of_mvr_review', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: formik.touched.date_of_mvr_review && Boolean(formik.errors.date_of_mvr_review) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                borderColor: formik.touched.date_of_mvr_review && Boolean(formik.errors.date_of_mvr_review) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: formik.touched.date_of_mvr_review && Boolean(formik.errors.date_of_mvr_review) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                        }
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Title
                </Typography>
                <TextField
                    fullWidth
                    id="title"
                    placeholder='Title'
                    size="small"
                    variant="outlined"
                    value={formik.values.title}
                    onChange={(e) => {
                        formik.setFieldValue('title', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: formik.touched.title && Boolean(formik.errors.title) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                borderColor: formik.touched.title && Boolean(formik.errors.title) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: formik.touched.title && Boolean(formik.errors.title) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Company Name
                </Typography>
                <TextField
                    fullWidth
                    id="motor_carrier_name"
                    placeholder='Company Name'
                    size="small"
                    variant="outlined"
                    value={formik.values.motor_carrier_name}
                    onChange={(e) => {
                        formik.setFieldValue('motor_carrier_name', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: formik.touched.motor_carrier_name && Boolean(formik.errors.motor_carrier_name) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                borderColor: formik.touched.motor_carrier_name && Boolean(formik.errors.motor_carrier_name) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: formik.touched.motor_carrier_name && Boolean(formik.errors.motor_carrier_name) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Company Address
                </Typography>
                <TextField
                    fullWidth
                    id="motor_carrier_address"
                    placeholder='Company Address'
                    size="small"
                    variant="outlined"
                    value={formik.values.motor_carrier_address}
                    onChange={(e) => {
                        formik.setFieldValue('motor_carrier_address', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: formik.touched.motor_carrier_address && Boolean(formik.errors.motor_carrier_address) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                borderColor: formik.touched.motor_carrier_address && Boolean(formik.errors.motor_carrier_address) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: formik.touched.motor_carrier_address && Boolean(formik.errors.motor_carrier_address) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    City
                </Typography>
                <TextField
                    fullWidth
                    id="city"
                    placeholder='City'
                    size="small"
                    variant="outlined"
                    value={formik.values.city}
                    onChange={(e) => {
                        formik.setFieldValue('city', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: formik.touched.city && Boolean(formik.errors.city) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                borderColor: formik.touched.city && Boolean(formik.errors.city) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: formik.touched.city && Boolean(formik.errors.city) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    State / ZIP
                </Typography>
                <TextField
                    fullWidth
                    id="state_zip"
                    placeholder='State / ZIP'
                    size="small"
                    variant="outlined"
                    value={formik.values.state_zip}
                    onChange={(e) => {
                        formik.setFieldValue('state_zip', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: formik.touched.state_zip && Boolean(formik.errors.state_zip) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                borderColor: formik.touched.state_zip && Boolean(formik.errors.state_zip) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: formik.touched.state_zip && Boolean(formik.errors.state_zip) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Divider sx={{ bordercolor: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', mt: 2, mb: 2 }} />
            </Grid>
        </>
    )
}

const NonCurrentAnnualReviewDrivingRecords = () => {
    const [member, setMember] = React.useState<IMember>()
    const { id } = useParams()
    const [value, setValue] = React.useState(0);
    const { mode } = useThemeMode()
    const [annualReviews, setAnnualReviews] = React.useState<IAnnualReviewOfDrivingRecord[]>([])




    const { data = [], } = useGetNonCurrentAnnualReviewOfDrivingRecordByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })

    React.useEffect(() => {

        if (id) {
            getCompanyMember(id).then(async (data: IMember) => {
                setMember(data)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (data.length && member) {
            if (member?.pre_employment_screened && member?.pre_employment_investigation.length > 0) {
                setAnnualReviews(data.filter((item) => !item.is_original && !item.is_current))
            } else if (member?.pre_employment_screened && member?.pre_employment_investigation.length === 0) {
                setAnnualReviews(data.filter((item) => !item.is_current))
            }
        }
    }, [data, member])



    return (
        <Container>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                width: {
                    lg: "47vw",
                    md: "55vw",
                    sm: "60vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
            }} component={Paper}>
                <FormHeaderCard
                    headerTitle='Annual Review of Driving Record'
                    sectionCount={4}
                    itemCount={29}
                    nextButton={{ title: "", path: "" }}
                    member={member}
                    setValue={setValue}
                    value={value}
                    nonCurrent={true}
                />
            </Box>
            {annualReviews && annualReviews.length > 0 ? annualReviews.map((item) => (
                <Box
                    sx={{
                        pl: 3,
                        pr: 3,
                        pt: 3,
                        m: 3,
                        borderRadius: 2,
                        width: {
                            lg: "47vw",
                            md: "55vw",
                            sm: "60vw",
                        },
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)'
                    }}
                    key={item.id}
                    component={Paper}>
                    <div
                        style={{
                            padding: 10,
                            margin: 10
                        }}>
                        <AnnualReviewDrivingRecordItem annualReview={item} />
                    </div>
                </Box>
            )) : null}
            {annualReviews?.length === 0 ? (
                <Box sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    width: {
                        lg: "47vw",
                        md: "55vw",
                        sm: "60vw",
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)'
                }}
                    component={Paper}>
                    <div
                        style={{
                            padding: 10,
                            margin: 10
                        }}>
                        <Typography sx={{ textAlign: "center" }} variant="h4" gutterBottom component="h4">
                            Non Current Annual Review of Driving Records are not available
                        </Typography>
                    </div>
                </Box>
            ) : null}
        </Container>
    )
}

export default NonCurrentAnnualReviewDrivingRecords