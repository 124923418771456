import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { TransitionProps } from '@mui/material/transitions';
import { IMemberRequest, IRequestStatus } from '../../../../models';
import { DialogActions, Grid, TextField, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getRequestStatuses, updateMemberRequest } from '../../../../shared/client/memberRequest';
import { toast } from 'react-toastify';
import { StripeProduct } from '../../../../app/modules/models';
import { createCheckoutSessionMemberRequest, getMemberRequestProducts } from '../../../../shared';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    pendingRequest: IMemberRequest
    memberSubscription?: StripeProduct
}

const initialValues: Partial<IMemberRequest> = {
    id: 0,
    member: undefined,
    type: undefined,
    status: '',
    created_at: '',
    city: '',
    state: '',
    zip_code: '',
    date_completed: undefined,
    confirm_driver_notified: false
}

export default function ConfirmRequestModal({ open, setOpen, pendingRequest, memberSubscription }: Readonly<IProps>) {
    const [subscriptions, setSubscriptions] = React.useState<Array<StripeProduct>>([])
    // eslint-disable-next-line
    const { data: statuses = [] } = useQuery<IRequestStatus[]>('requestStatuses', async () => getRequestStatuses(), {
        refetchOnWindowFocus: false,
    })
    const handleClose = () => {
        formik.setValues({
            ...formik.values,
            city: pendingRequest?.city,
            state: pendingRequest?.state,
            zip_code: pendingRequest?.zip_code
        })
        setOpen(false);
    };

    const queryClient = useQueryClient()

    const checkout = async (id: number) => {
        const selected = subscriptions.find((item) => item.name === 'Random Drug and Alcohol Test')
        if (selected) {
            const url = await createCheckoutSessionMemberRequest(
                selected.price_id,
                selected.type,
                selected.tiers_mode,
                id,
            )
            window.open(url.url, '_self')
        }
    }

    const memberRequestMutation = useMutation(updateMemberRequest, {
        onSuccess: (data) => {
            if (memberSubscription?.name === "Deluxe Mini Package") {
                checkout(data.id).then(() => {
                    queryClient.invalidateQueries('member-requests')
                    toast.success('Request Confirmed')
                })
            } else {
                queryClient.invalidateQueries('member-requests')
                toast.success('Request Confirmed')
            }
        }
    })

    const getPlanOptions = React.useCallback(async () => {
        try {
            if (memberSubscription) {
                const res = await getMemberRequestProducts(memberSubscription?.name)
                const lookupKeyItems = res.data.filter((item: any) => item.lookup_key)
                const itemsWithOutLookupKey: any[] = []
                for (const item of res.data as any) {
                    if (!item.lookup_key) {
                        const exists = lookupKeyItems.find((lookup: any) => lookup.name === item.name)
                        if (!exists) {
                            itemsWithOutLookupKey.push(item)
                        }
                    }
                }
                const itemsList = [...lookupKeyItems, ...itemsWithOutLookupKey]
                setSubscriptions(itemsList)

            }
        } catch (error) {
            console.log(error)
        }
    }, [memberSubscription]);

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: yup.object().shape({
            city: yup.string().required('City is required'),
            state: yup.string().required('State is required'),
            zip_code: yup.string().required('Zip Code is required')
        }),
        onSubmit: (values) => {
            memberRequestMutation.mutate({
                memberRequest: {
                    id: pendingRequest?.id,
                    member: Number(pendingRequest?.member?.id),
                    type: pendingRequest?.type?.id,
                    city: values.city,
                    state: values.state,
                    zip_code: values.zip_code,
                    confirm_driver_notified: true
                }, request_type: "Pending", subscription: memberSubscription?.name!
            })
            setOpen(false)
        }
    })

    React.useEffect(() => {
        formik.setValues({
            ...formik.values,
            city: pendingRequest?.city,
            state: pendingRequest?.state,
            zip_code: pendingRequest?.zip_code
        })
        // eslint-disable-next-line
    }, [])

    React.useEffect(() => {
        getPlanOptions();
    }, [getPlanOptions])

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth='sm'
            fullWidth
        >
            <DialogTitle sx={{
                textAlign: 'center'
            }}>
                Confirm Random Test Request
            </DialogTitle>
            <DialogContent>
                <Typography variant='body1'>
                    By clicking confirm, you are confirming that {pendingRequest?.member?.user?.first_name} {pendingRequest?.member?.user?.last_name} has been informed of
                    their random selection and the test can now be scheduled using this current location...
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            size='small'
                            label='City'
                            variant='outlined'
                            name='city'
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            error={formik.touched.city && Boolean(formik.errors.city)}
                            helperText={formik.touched.city && formik.errors.city}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            size='small'
                            label='State'
                            variant='outlined'
                            name='state'
                            value={formik.values.state}
                            onChange={formik.handleChange}
                            error={formik.touched.state && Boolean(formik.errors.state)}
                            helperText={formik.touched.state && formik.errors.state}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            size='small'
                            label='Zip Code'
                            variant='outlined'
                            name='zip_code'
                            value={formik.values.zip_code}
                            onChange={formik.handleChange}
                            error={formik.touched.zip_code && Boolean(formik.errors.zip_code)}
                            helperText={formik.touched.zip_code && formik.errors.zip_code}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button size='small' fullWidth onClick={() => formik.handleSubmit()} variant='contained' color="primary">
                    Confirm
                </Button>
                <Button size="small" fullWidth onClick={handleClose} variant='contained' color="error">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
