import React from 'react'
import { IMember } from '../../../models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Grid, TextField, Typography } from '@mui/material'
import {
    IRequestBySignatureDto
} from '../../../store/interfaces/RequestForCheckOfDrivingRecords/request-by-signature'
import {
    useCreateRequestBySignatureMutation,
    useUpdateRequestBySignatureMutation,
    useGetRequestbySignatureByUserIdQuery,
    useDeleteRequestBySignatureMutation
} from '../../../store/reducers/RequestForCheckOfDrivingRecords/request-by-signature'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'

const initialValues: Partial<IRequestBySignatureDto> = {
    name_of_company: '',
    typed_name: '',
    address: '',
    signature: '',
}

const validationSchema = Yup.object({
    name_of_company: Yup.string().required('Required'),
    typed_name: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    signature: Yup.string().required('Required'),
})

const RequestedBySignature = ({ member, isDriverFile }: { member?: IMember, isDriverFile?: boolean }) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data } = useGetRequestbySignatureByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })

    const [createRequestBySignature, { isLoading: isCreating }] = useCreateRequestBySignatureMutation()
    const [updateRequestBySignature, { isLoading: isUpdating }] = useUpdateRequestBySignatureMutation()
    const [deleteRequestBySignature] = useDeleteRequestBySignatureMutation()

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                await updateRequestBySignature({
                    // @ts-ignore
                    ...values,
                    // @ts-ignore
                    id: data.id,
                })
            } else {
                await createRequestBySignature({
                    ...values,
                    // @ts-ignore
                    member: member?.id,
                    // @ts-ignore
                    company: member?.company?.id
                })
            }
            resetForm()
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate: (values) => {
            const errors: Partial<IRequestBySignatureDto> = {}
            if (!values.name_of_company) {
                errors.name_of_company = 'Required'
            }
            if (!values.typed_name) {
                errors.typed_name = 'Required'
            }
            if (!values.address) {
                errors.address = 'Required'
            }
            if (!values.signature) {
                errors.signature = 'Required'
            }
            return errors
        }
    })

    React.useEffect(() => {
        if (data) {
            formik.setValues(data)
        } else {
            formik.setValues({
                ...initialValues,
                name_of_company: member?.company?.name,
                typed_name: `${member?.company?.user?.first_name} ${member?.company?.user?.last_name}`,
                address: `${member?.company?.address1}, ${member?.company?.address2}, ${member?.company?.city}, ${member?.company?.state ?? member?.company?.country}, ${member?.company?.zip_code}`,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, member])


    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                Requested By Signature
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Name of Company
                    </Typography>
                    <TextField
                        fullWidth
                        id="name_of_company"
                        placeholder='Name of Company'
                        size="small"
                        variant="outlined"
                        value={formik.values.name_of_company}
                        // onChange={(e) => {
                        //     formik.setFieldValue('name_of_company', e.target.value)
                        // }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.name_of_company && Boolean(formik.errors.name_of_company) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.name_of_company && Boolean(formik.errors.name_of_company) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.name_of_company && Boolean(formik.errors.name_of_company) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Typed Name
                    </Typography>
                    <TextField
                        fullWidth
                        id="typed_name"
                        placeholder='Typed Name'
                        size="small"
                        variant="outlined"
                        value={formik.values.typed_name}
                        onChange={(e) => {
                            formik.setFieldValue('typed_name', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.typed_name && Boolean(formik.errors.typed_name) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.typed_name && Boolean(formik.errors.typed_name) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.typed_name && Boolean(formik.errors.typed_name) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Address
                    </Typography>
                    <TextField
                        fullWidth
                        id="address"
                        placeholder='Address'
                        size="small"
                        variant="outlined"
                        value={formik.values.address}
                        onChange={(e) => {
                            formik.setFieldValue('address', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.address && Boolean(formik.errors.address) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.address && Boolean(formik.errors.address) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.address && Boolean(formik.errors.address) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Signature
                    </Typography>
                    <TextField
                        fullWidth
                        id="signature"
                        placeholder='Signature'
                        size="small"
                        variant="outlined"
                        value={formik.values.signature}
                        onChange={(e) => {
                            formik.setFieldValue('signature', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.signature && Boolean(formik.errors.signature) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.signature && Boolean(formik.errors.signature) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.signature && Boolean(formik.errors.signature) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                fontStyle: "italic"
                            }
                        }}
                    />
                </Grid >
                {(!data || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role))) && !isDriverFile ? <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={isCreating || isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                        Save
                    </Button>
                    {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                        <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                            Once you click save, you will not be able to edit this form.
                        </Typography>
                    )}
                </Grid > : null}
                {data && currentUser?.role === "SuperAdmin" && <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={!!member?.terminated_at} onClick={() => {
                        const res = window.confirm("Are you sure you want to delete this form?")
                        if (res && data) {
                            deleteRequestBySignature(data.id)
                        }
                    }} variant="contained" color="primary">
                        Delete Form
                    </Button>
                </Grid>}
            </Grid >
        </div >
    )
}

export default RequestedBySignature