/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../app/modules/auth';
import { toAbsoluteUrl } from '../../../helpers';

const HeaderUserMenu: FC = () => {
    const { currentUser, logout } = useAuth();

    return (
        <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
            data-kt-menu="true"
        >
            <div className="menu-item px-3">
                <div className="menu-content d-flex align-items-center px-3">
                    <div className="symbol symbol-50px me-5">
                        <img alt="Logo" src={toAbsoluteUrl('/media/avatars/blank.png')} />
                    </div>

                    <div className="d-flex flex-column">
                        <div className="fw-bolder d-flex align-items-center fs-5">
                            {currentUser?.first_name} {currentUser?.last_name}
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
                        {currentUser?.email}
                    </a>
                </div>
            </div>

            <div className="separator my-2"></div>

            <div className="menu-item px-5">
                <a onClick={() => {
                    window.location.assign('/terms')
                }} className="menu-link px-5" target=''>
                    View Terms and Conditions
                </a>
            </div>
            {currentUser?.role === "Owner" && <div className="menu-item px-5">
                <Link to={'/subscription/plan'} className="menu-link px-5">
                    My Subscription
                </Link>
            </div>}

            <div className="menu-item px-5">
                <a onClick={logout} className="menu-link px-5">
                    Logout
                </a>
            </div>
        </div>
    );
};

export { HeaderUserMenu };
