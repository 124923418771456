import React, { useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { TransitionProps } from '@mui/material/transitions';
import { Button, Grid, InputLabel, MenuItem, TextField } from '@mui/material';
import useCompanyInvitations from '../../../context/CompanyInvitation/CompanyInvitationContextProvider';
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export interface ICompanyCreate {
    company_name: string;
    address1: string;
    address2: string;
    zip_code: string;
    city: string;
    state: string;
    country: string;
    phone_number: string;
    email: string;
    dot_number: string;
    requires_custom_pricing: boolean;
}

const initialValues: ICompanyCreate = {
    company_name: '',
    address1: '',
    address2: '',
    zip_code: '',
    city: '',
    state: '',
    country: '',
    phone_number: '',
    email: '',
    dot_number: '',
    requires_custom_pricing: false,
}

const validationSchema = Yup.object().shape({
    company_name: Yup.string().required('Company name is required'),
    address1: Yup.string().required('Address is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    phone_number: Yup.string().required('Phone number is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    dot_number: Yup.string().required('Dot number is required'),
});

export default function CreateCompanyModal({ open, setOpen }: Readonly<IProps>) {
    const { getInvitations, invitations, createCompanyProfile } = useCompanyInvitations()
    const handleClose = () => {
        setOpen(false);
    };

    const getInvitationsRef = useRef(getInvitations)

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            createCompanyProfile(values).then(() => {
                setOpen(false)
            }).then(() => {
                getInvitationsRef.current()
                toast.success('Company created')
            }).catch((error) => {
                console.error('Error creating company', error)
                toast.error('Error creating company')
            })
        }
    })

    useEffect(() => {
        if (open) {
            getInvitationsRef.current()
        }
    }, [open])


    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
        >
            <DialogTitle sx={{
                textAlign: 'center',
                fontWeight: 'bold'
            }}>
                Create Company
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} p={2}>
                    <Grid item sm={12}>
                        <InputLabel>
                            Select Company Invite
                        </InputLabel>
                        <TextField
                            size='small'
                            fullWidth
                            name='email'
                            select
                            value={formik.values.email}
                            onChange={(e) => formik.setFieldValue('email', e.target.value)}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                        >
                            {invitations.map((invitation) => (
                                <MenuItem key={invitation.email} value={invitation.email}>
                                    {invitation.first_name} {invitation.last_name} - {invitation.email}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item sm={12}>
                        <InputLabel>
                            Company Name
                        </InputLabel>
                        <TextField
                            size='small'
                            fullWidth
                            name='company_name'
                            value={formik.values.company_name}
                            onChange={formik.handleChange}
                            error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <InputLabel>
                            Address Line 1
                        </InputLabel>
                        <TextField
                            size='small'
                            fullWidth
                            name='address1'
                            value={formik.values.address1}
                            onChange={formik.handleChange}
                            error={formik.touched.address1 && Boolean(formik.errors.address1)}
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <InputLabel>
                            Address Line 2
                        </InputLabel>
                        <TextField
                            size='small'
                            fullWidth
                            name='address2'
                            value={formik.values.address2}
                            onChange={formik.handleChange}
                            error={formik.touched.address2 && Boolean(formik.errors.address2)}
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <InputLabel>
                            City
                        </InputLabel>
                        <TextField
                            size='small'
                            fullWidth
                            name='city'
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            error={formik.touched.city && Boolean(formik.errors.city)}
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <InputLabel>
                            State
                        </InputLabel>
                        <TextField
                            size='small'
                            fullWidth
                            name='state'
                            value={formik.values.state}
                            onChange={formik.handleChange}
                            error={formik.touched.state && Boolean(formik.errors.state)}
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <InputLabel>
                            ZIP Code
                        </InputLabel>
                        <TextField
                            size='small'
                            fullWidth
                            name='zip_code'
                            value={formik.values.zip_code}
                            onChange={formik.handleChange}
                            error={formik.touched.zip_code && Boolean(formik.errors.zip_code)}
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <InputLabel>
                            Phone Number
                        </InputLabel>
                        <TextField
                            size='small'
                            fullWidth
                            name='phone_number'
                            value={formik.values.phone_number}
                            onChange={formik.handleChange}
                            error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <InputLabel>
                            DOT number
                        </InputLabel>
                        <TextField
                            size='small'
                            fullWidth
                            name='dot_number'
                            value={formik.values.dot_number}
                            onChange={formik.handleChange}
                            error={formik.touched.dot_number && Boolean(formik.errors.dot_number)}
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <InputLabel>
                            Requires Custom Pricing
                        </InputLabel>
                        <TextField
                            size='small'
                            fullWidth
                            name='requires_custom_pricing'
                            select
                            value={formik.values.requires_custom_pricing ? 'Yes' : 'No'}
                            onChange={(e) => formik.setFieldValue('requires_custom_pricing', e.target.value === 'Yes')}
                            error={formik.touched.requires_custom_pricing && Boolean(formik.errors.requires_custom_pricing)}
                        >
                            <MenuItem value={"Yes"}>Yes</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item sm={12}>
                        <Button variant='contained' size="small" fullWidth onClick={() => formik.handleSubmit()}>
                            Create Company
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
