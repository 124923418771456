import { FunctionComponent } from 'react';
import { INotesAndLinks, NotesAndLinksType } from '../../../../store/interfaces/PreventableMaintenance/notes.and.links';
import { IMaintenanceVehicle } from '../../../../store/interfaces/PreventableMaintenance/maintenance.vehicles';
import { useGetNotesAndLinksByVehicleQuery } from '../../../../store/reducers/PreventableMaintenance/notes.links';
import LinksTable from './LinksTable';
import NotesTable from './NotesTable';

interface IProps {
    notesAndLinks?: INotesAndLinks[];
    vehicle?: IMaintenanceVehicle;
}

const NotesAndLinks: FunctionComponent<IProps> = ({ vehicle }) => {
    const { data: notesAndLinks = [] } = useGetNotesAndLinksByVehicleQuery(Number(vehicle?.id) ?? 0, {
        skip: !vehicle?.id,
        refetchOnFocus: true
    });
    return (
        <>
            <NotesTable
                notesAndLinks={notesAndLinks?.filter((item) => item.type === NotesAndLinksType.NOTE)}
                vehicle={vehicle}
            />
            <LinksTable
                notesAndLinks={notesAndLinks?.filter((item) => item.type === NotesAndLinksType.LINK)}
                vehicle={vehicle}
            />
        </>
    );
};

export default NotesAndLinks;
