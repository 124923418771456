import React from 'react'
import { IMember } from '../../../models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Checkbox, FormControlLabel, Grid, MenuItem, TextField, Typography } from '@mui/material'
import { IAccidentRecord, IAccidentRecordDto } from '../../../store/interfaces/EmploymentHistory/accident-record'
import {
    useCreateAccidentRecordMutation,
    useDeleteAccidentRecordMutation,
    useGetAccidentRecordByUserIdQuery,
    useUpdateAccidentRecordMutation,
    useGetHasAccidentRecordQuery,
    useHasAccidentRecordMutation,

} from '../../../store/reducers/EmploymentHistory/accident-record'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'



const initialValues: Partial<IAccidentRecordDto> = {
    date_of_accident: '',
    nature_of_accident: '',
    fatalities: false,
    injuries: false,
    harzadous_material_spill: false,
}

const validationSchema = Yup.object({
    date_of_accident: Yup.string().required('Required'),
    nature_of_accident: Yup.string().required('Required'),
    fatalities: Yup.boolean().required('Required'),
    injuries: Yup.boolean().required('Required'),
    harzadous_material_spill: Yup.boolean().required('Required'),
})


const AccidentRecordItem = ({ item, member }: { item: IAccidentRecord; member?: IMember }) => {
    const { currentUser } = useAuth()
    const { mode } = useThemeMode()
    const [updateAccidentHistory, { isLoading: isUpdating }] = useUpdateAccidentRecordMutation()
    const [deleteAccidentRecord, { isLoading: isDeleting }] = useDeleteAccidentRecordMutation()

    const formik = useFormik({
        initialValues: item,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                // @ts-ignore
                await updateAccidentHistory({
                    ...values,
                } as IAccidentRecordDto)
                resetForm()
            } catch (error) {
                console.log(error)
            }
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate: (values) => {
            const errors: Partial<IAccidentRecordDto> = {}
            if (!values.date_of_accident) {
                errors.date_of_accident = 'Required'
            }
            if (!values.nature_of_accident) {
                errors.nature_of_accident = 'Required'
            }
            return errors
        }
    })

    React.useEffect(() => {
        formik.setValues(item)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])

    return (
        <>
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Date of Accident
                </Typography>
                <TextField
                    fullWidth
                    id="date_of_accident"
                    placeholder="Date of Accident"
                    size="small"
                    variant="outlined"
                    {...formik.getFieldProps('date_of_accident')}
                    type='date'
                    className={formik.touched.date_of_accident && Boolean(formik.errors.date_of_accident) ? "green" : "red"}
                    error={formik.touched.date_of_accident && Boolean(formik.errors.date_of_accident)}
                    helperText={formik.touched.date_of_accident && formik.errors.date_of_accident}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: formik.touched.date_of_accident && Boolean(formik.errors.date_of_accident) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: formik.touched.date_of_accident && Boolean(formik.errors.date_of_accident) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: formik.touched.date_of_accident && Boolean(formik.errors.date_of_accident) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    disabled={!!member?.terminated_at}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Nature of Accident
                </Typography>
                <TextField
                    fullWidth
                    id="nature_of_accident"
                    placeholder="Nature of Accident"
                    size="small"
                    variant="outlined"
                    {...formik.getFieldProps('nature_of_accident')}
                    multiline
                    rows={4}
                    className={formik.touched.nature_of_accident && Boolean(formik.errors.nature_of_accident) ? "green" : "red"}
                    error={formik.touched.nature_of_accident && Boolean(formik.errors.nature_of_accident)}
                    helperText={formik.touched.nature_of_accident && formik.errors.nature_of_accident}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: formik.touched.nature_of_accident && Boolean(formik.errors.nature_of_accident) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: formik.touched.nature_of_accident && Boolean(formik.errors.nature_of_accident) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: formik.touched.nature_of_accident && Boolean(formik.errors.nature_of_accident) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    disabled={!!member?.terminated_at}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Were there any fatalities?
                </Typography>
                <TextField
                    fullWidth
                    id="fatalities"
                    size="small"
                    variant="outlined"
                    value={formik.values.fatalities ? 'Yes' : 'No'}
                    onChange={(e) => {
                        formik.setFieldValue('fatalities', e.target.value === 'Yes' ? true : false)
                    }}
                    select
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: formik.touched.fatalities && Boolean(formik.errors.fatalities) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: formik.touched.fatalities && Boolean(formik.errors.fatalities) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: formik.touched.fatalities && Boolean(formik.errors.fatalities) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    disabled={!!member?.terminated_at}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                </TextField>
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Were there any injuries?
                </Typography>
                <TextField
                    fullWidth
                    id="injuries"
                    size="small"
                    variant="outlined"
                    value={formik.values.injuries ? 'Yes' : 'No'}
                    onChange={(e) => {
                        formik.setFieldValue('injuries', e.target.value === 'Yes' ? true : false)
                    }}
                    select
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: formik.touched.injuries && Boolean(formik.errors.injuries) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: formik.touched.injuries && Boolean(formik.errors.injuries) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: formik.touched.injuries && Boolean(formik.errors.injuries) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    disabled={!!member?.terminated_at}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                </TextField>
            </Grid >
            {!item || (item && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role)) ? <>
                <Grid item xs={12} sm={6}>
                    <Button fullWidth disabled={isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                        Update
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button fullWidth disabled={isDeleting || (!!member?.terminated_at)} onClick={() => {
                        const res = window.confirm('Are you sure you want to delete this item?')
                        if (res) {
                            deleteAccidentRecord(item.id)
                        }
                    }} variant="contained" color="error">
                        Delete
                    </Button>
                </Grid>
            </> : null}
        </>
    )
}


const AccidentRecord = ({ member }: { member?: IMember }) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data = [] } = useGetAccidentRecordByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnReconnect: true,
    })
    const { data: hasAccidentRecord } = useGetHasAccidentRecordQuery(Number(member?.id), {
        skip: !member?.id,
        refetchOnFocus: true,
        refetchOnReconnect: true,
    })
    const [hasAccidentRecordMutation] = useHasAccidentRecordMutation()
    const [createAccidentHistory, { isLoading: isCreating }] = useCreateAccidentRecordMutation()

    const [addNewItem, setAddNewItem] = React.useState(true)

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                // @ts-ignore
                await createAccidentHistory({
                    ...values,
                    // @ts-ignore
                    company: member?.company?.id,
                    // @ts-ignore
                    member: member?.id,
                } as IAccidentRecordDto)
                resetForm()
            } catch (error) {
                console.log(error)
            }
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate: (values) => {
            const errors: Partial<IAccidentRecordDto> = {}
            if (!values.date_of_accident) {
                errors.date_of_accident = 'Required'
            }
            if (!values.nature_of_accident) {
                errors.nature_of_accident = 'Required'
            }
            return errors
        }
    })

    React.useEffect(() => {
        if (data.length > 0) {
            setAddNewItem(false)
        } else {
            setAddNewItem(true)
        }
    }, [data])

    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                Accident Record
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                Accident Record for the past three years or more
            </Typography>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <FormControlLabel
                    sx={{
                        color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                    }}
                    control={
                        <Checkbox sx={{
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }}
                            onClick={() => {
                                hasAccidentRecordMutation({
                                    company: Number(member?.company?.id),
                                    member: Number(member?.id),
                                    has_accident_record: !hasAccidentRecord?.has_accident_record,
                                    id: hasAccidentRecord?.id
                                })
                            }}
                            disabled={member?.terminated_at || data.length > 0 ? true : false}
                            checked={hasAccidentRecord?.has_accident_record ? true : false} />
                    }
                    label="Do you have any records of accidents?"

                />
            </div>
            {
                hasAccidentRecord && hasAccidentRecord?.has_accident_record ? (
                    <Grid container spacing={3}>
                        {data.map((item) => (
                            <AccidentRecordItem member={member} key={item.id} item={item} />
                        ))}
                        {data.length > 0 && <Grid item xs={12} sm={12} sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <FormControlLabel sx={{
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }} control={<Checkbox sx={{
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }} checked={addNewItem} />} onChange={() => setAddNewItem(!addNewItem)} label="Add Another Accident Record" />
                        </Grid>}
                        {addNewItem && <>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                    Date of Accident
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="date_of_accident"
                                    placeholder="Date of Accident"
                                    size="small"
                                    variant="outlined"
                                    {...formik.getFieldProps('date_of_accident')}
                                    type='date'
                                    className={formik.touched.date_of_accident && Boolean(formik.errors.date_of_accident) ? "green" : "red"}
                                    error={formik.touched.date_of_accident && Boolean(formik.errors.date_of_accident)}
                                    helperText={formik.touched.date_of_accident && formik.errors.date_of_accident}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.date_of_accident && Boolean(formik.errors.date_of_accident) ? "red" : "green",
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.date_of_accident && Boolean(formik.errors.date_of_accident) ? "red" : "green",
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.date_of_accident && Boolean(formik.errors.date_of_accident) ? "red" : "green",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                    InputProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                    Nature of Accident
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="nature_of_accident"
                                    placeholder="Nature of Accident"
                                    size="small"
                                    variant="outlined"
                                    {...formik.getFieldProps('nature_of_accident')}
                                    multiline
                                    rows={4}
                                    className={formik.touched.nature_of_accident && Boolean(formik.errors.nature_of_accident) ? "green" : "red"}
                                    error={formik.touched.nature_of_accident && Boolean(formik.errors.nature_of_accident)}
                                    helperText={formik.touched.nature_of_accident && formik.errors.nature_of_accident}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.nature_of_accident && Boolean(formik.errors.nature_of_accident) ? "red" : "green",
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.nature_of_accident && Boolean(formik.errors.nature_of_accident) ? "red" : "green",
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.nature_of_accident && Boolean(formik.errors.nature_of_accident) ? "red" : "green",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                    InputProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                    Were there any fatalities?
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="fatalities"
                                    size="small"
                                    variant="outlined"
                                    value={formik.values.fatalities ? 'Yes' : 'No'}
                                    onChange={(e) => {
                                        formik.setFieldValue('fatalities', e.target.value === 'Yes' ? true : false)
                                    }}
                                    select
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.fatalities && Boolean(formik.errors.fatalities) ? "red" : "green",
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.fatalities && Boolean(formik.errors.fatalities) ? "red" : "green",
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.fatalities && Boolean(formik.errors.fatalities) ? "red" : "green",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                    InputProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                >
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                </TextField>
                            </Grid >
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                    Were there any injuries?
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="injuries"
                                    size="small"
                                    variant="outlined"
                                    value={formik.values.injuries ? 'Yes' : 'No'}
                                    onChange={(e) => {
                                        formik.setFieldValue('injuries', e.target.value === 'Yes' ? true : false)
                                    }}
                                    select
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.injuries && Boolean(formik.errors.injuries) ? "red" : "green",
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.injuries && Boolean(formik.errors.injuries) ? "red" : "green",
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.injuries && Boolean(formik.errors.injuries) ? "red" : "green",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                    InputProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                >
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                </TextField>
                            </Grid >
                            <Grid item xs={12} sm={12}>
                                <Button fullWidth disabled={isCreating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                                    Save
                                </Button>
                                {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                                    <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                                        Once you click save, you will not be able to edit this form.
                                    </Typography>
                                )}
                            </Grid>
                        </>}
                    </Grid >
                ) : null}
        </div >
    )
}

export default AccidentRecord