import React from 'react'
import Button from '../../../../app/modules/buttons/Button'
import { IMember } from '../../../../models'
import RequestModal from './RequestModal'
import { StripeProduct } from '../../../../app/modules/models'

interface IProps {
    member: IMember
    memberSubscription?: StripeProduct
}

const RequestButton = ({ member, memberSubscription }: IProps) => {

    return (
        <>
            <RequestModal member={member} memberSubscription={memberSubscription} />
            <Button
                className={'btn btn-sm btn-light mx-1'}
                dataBsToggle='modal'
                dataBsTarget='#request_modal'
                disabled={!!member?.terminated_at}
            >
                Make Request
            </Button>
        </>
    )
}

export default RequestButton
