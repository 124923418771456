
import { Button } from "react-bootstrap";
import "./terms_conditions.css"


const TermsConditions = ({ isModal }: { isModal?: boolean }) => {

    return (
        <div style={{
            margin: 10,
            padding: 30,
            width: "100vw",
        }}>
            <div style={{
                width: "70%",
                marginLeft: "auto",
                marginRight: "auto"
            }}>
                <div style={{
                    display: "flex",
                    marginBottom: 15
                }}>
                    {!isModal && (
                        <Button style={{
                            width: "40%",
                            marginLeft: "auto",
                            marginRight: "auto"
                        }}

                            onClick={() => window.history.back()}>
                            Back
                        </Button>
                    )}
                </div>
                <h1 style={{ textAlign: "center" }}>Terms and Conditions</h1>
                <p>
                    These Terms and Conditions, together with the Purchase Order which is fully
                    incorporated herein by reference (this "Agreement") is effective as of the date
                    it becomes fully executed as evidenced below, and constitutes a binding agreement
                    between Compliance Masters LLC, a North Carolina limited liability company
                    ("Company") and You, ("The Customer").
                </p>
                <ol>
                    <li>Definitions:
                        <ul>
                            <li>
                                SERVICE(S): The Services refer to the subscription-based service provided by the Company,
                                as defined in the Purchase Order, and identified in the Subscription Package which includes
                                access to the software, tools, and content provided by the Company.
                            </li>
                            <li>
                                SUBSCRIPTION FEE: A subscription is a recurring payment made by the Customer for access
                                to the Services, as defined in the Purchase Order, for a specified period of time.
                            </li>
                            <li>
                                SUBSCRIPTION PACKAGE: A Subscription Package refers to the specific set of features,
                                functionalities and Services offered by Company that the Customer selects on the Purchase Order.
                            </li>
                            <li>
                                THIRD-PARTY VENDORS: Third-party vendors refer to any third-party providers or partners
                                that the Company may engage with to provide certain aspects of the Services, such as payment
                                processing, hosting, or content delivery.
                            </li>
                        </ul>
                    </li>
                    <li>Subscription:
                        <p>
                            By subscribing to our Service and any Subscription Package, you agree to pay the monthly Subscription
                            Fee and to abide by these Terms and Conditions. Customer’s subscription will automatically renew each
                            month unless and until Customer cancels Customer’s Subscription prior to the renewal date or we terminate this Agreement.
                        </p>
                    </li>
                    <li>Payment:
                        <p>
                            The Subscription Fee for Customer’s Subscription Package must be made in advance of each month's service.
                            Company accepts payment by credit card or other forms of electronic payment. If payment is not received,
                            Company reserves the right to suspend or terminate Customer’s Subscription Package and the Services.
                        </p>
                    </li>
                    <li>Changes to the Subscription Fee:
                        <p>
                            Company reserves the right to change the Subscription Fee at any time. Company will notify Customer
                            in advance of any changes to the Subscription Fee and provide Customer with the opportunity to cancel
                            Customer’s Subscription if you do not agree to the new fee.
                        </p>
                    </li>
                    <li>Refunds:
                        <p>
                            All Subscription Fees are non-refundable. If you cancel your subscription, you will not receive a refund for any remaining days in the current billing cycle.
                        </p>
                    </li>
                    <li>Termination:
                        <ul>
                            <li>
                                We reserve the right to terminate your subscription at any time, for any reason, without notice.
                                In the event of termination, we will provide you with a prorated refund for any remaining days in the current billing cycle.
                                Customer may terminate Customer’s Subscription at any time by contacting us via email or through the account portal on our website.
                                Your cancellation will take effect at the end of the current billing cycle.
                            </li>
                            <li>
                                Either party may terminate this agreement immediately upon written notice to the other party in the event of a material breach of these terms and conditions.
                                A material breach shall include, but is not limited to, any failure by the Customer to pay the fees due under this agreement or any violation of
                                the acceptable use policy outlined in these terms and conditions.
                            </li>
                            <li>
                                Upon termination, the Customer shall immediately cease all use of the Services and any access to the Company's proprietary software,
                                tools, and content. The Company shall not be liable to the Customer or any third party for any damages, costs, or expenses arising from or
                                related to such termination, except for any fees paid by the Customer in advance for the Services that have not yet been provided as of the
                                date of termination. Termination the Customer of its obligation to pay any fees or charges accrued prior to the effective date of termination.
                                The provisions of the sections relating to warranties, indemnification, limitations of liability, and confidentiality shall survive any termination of this agreement.
                            </li>
                            <li>
                                Upon termination, Customer may request copies of the driver qualification files associated with their account.
                                The customer acknowledges that each driver file contains sensitive and proprietary information and agrees to pay
                                Company a fee of $600.00 per driver file to obtain copies. Upon receipt of the request and payment, Company will
                                provide the customer with a copy of the requested driver qualification file(s) within ten (10) business days.
                                If the customer fails to pay the required fee for any driver file(s), Company reserves the right to withhold the
                                requested driver file(s) until payment is received in full.
                            </li>
                        </ul>
                    </li>
                    <li>Changes to the Service:
                        <p>
                            Company reserves the right to change or discontinue the service at any time, without notice. We will not be
                            liable to Customer or any third party for any modification, suspension, or discontinuation of the service.
                        </p>
                    </li>
                    <li>Use of the Service:
                        <p>
                            Customer agrees to use the Service only for lawful purposes and
                            in accordance with these terms and conditions. Customer agrees not to use the service to transmit
                            any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar,
                            obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise
                            objectionable.
                        </p>
                    </li>
                    <li>Customer Responsibility:
                        <p>
                            As a customer, Customer is responsible for providing
                            accurate and complete information to us, including your billing and contact information.
                            Customer also responsible for maintaining the security of your account login information and
                            ensuring that unauthorized individuals do not access your account. Customer agrees to promptly
                            notify us of any unauthorized use of your account or any other breach of security.
                        </p>
                    </li>
                    <li>Customer Representation:
                        <p>
                            By subscribing to our Services, including any
                            Subscription Package, Customer represents and warrant that Customer is at least 18 years of age
                            and have the legal capacity to enter into this agreement. Customer also represents and warrants
                            that any information Customer provides to us, including your billing and contact information, is
                            accurate and complete.
                        </p>
                    </li>
                    <li>Force Majeure:
                        <p>
                            Company will not be liable for any failure or delay in the performance
                            of Company’s obligations under this agreement if such failure or delay is caused by an event
                            beyond our reasonable control, including but not limited to acts of God, acts of government, war,
                            terrorism, natural disasters, and internet outages.
                        </p>
                    </li>
                    <li>Intellectual Property:
                        <p>
                            All content included in the service, including but not
                            limited to text, graphics, logos, images, and software, is the Company’s property or the property
                            of our licensors and is protected by copyright and other intellectual property laws. You may not
                            copy, distribute, modify, or create derivative works of any content without our prior written
                            consent.
                        </p>
                    </li>
                    <li>Contact Us:
                        <p>
                            If you have any questions about these Terms and Conditions or the
                            Service, please contact us via email or through the account portal on our website.
                        </p>
                    </li>
                    <li>Privacy Policy:
                        <p>
                            We respect your privacy and are committed to protecting your
                            personal information. This privacy policy explains how we collect, use, and disclose personal
                            information in connection with our Services.
                        </p>
                        <ul>
                            <li>
                                Collection of Personal Information: We may collect personal
                                information from you when you sign up for our Services, such as your name, email
                                address, payment information, and other contact information. We may also collect
                                information about how you use our Services, such as your IP address, device type,
                                and browser type.
                            </li>
                            <li>
                                Use of Personal Information: We may use your personal information
                                to provide you with the Services, to communicate with you about the Services, and
                                to improve and personalize the Services. We may also use your personal
                                information to analyze how you use our Services and to improve our Services.
                            </li>
                            <li>
                                Disclosure of Personal Information: We may disclose your
                                personal information to third-party vendors and service providers who assist us in
                                providing the Services, such as payment processors, hosting providers, and
                                customer support providers. We may also disclose your personal information if
                                required by law or in connection with a legal proceeding.
                            </li>
                            <li>
                                Security of Personal Information: We take reasonable measures to
                                protect your personal information from unauthorized access, disclosure, or use.
                                However, no method of transmission over the Internet or method of electronic
                                storage is 100% secure.
                            </li>
                            <li>
                                Updates to Privacy Policy: Company may update the privacy policy from
                                time to time. We will notify you of any material changes to this privacy policy by
                                posting a notice on our website or sending you an email.
                            </li>
                        </ul>
                    </li>
                    <li>Not Responsible for Third Party Vendors:
                        <p>
                            Company may use third-party
                            vendors to provide certain aspects of the Services, including any Subscription Package at
                            Company’s sole discretion. While we make every effort to ensure that these vendors meet our
                            high standards, we are not responsible for any acts or omissions of third-party vendors. We are
                            not liable for any damages or losses that you may suffer as a result of the actions or inactions of
                            third-party vendors. Any disputes or issues with third-party vendors must be resolved directly
                            with the vendor.
                        </p>
                    </li>
                    <li>Indemnification:
                        <p>
                            Customer agrees to indemnify, defend, and hold Company,
                            Company’s affiliates, directors, officers, employees, agents, and licensors harmless from and
                            against any claims, liabilities, damages, losses, and expenses, including without limitation
                            reasonable attorneys' fees and costs, arising out of or in any way connected with: (a) Customer’s
                            use of the Services and/or Subscription Package; (b) Customer’s breach of these terms and
                            conditions; (c) Customer’s violation of any applicable law or regulation; (d) Customer’s violation
                            of any rights of another party, including but not limited to intellectual property rights, privacy
                            rights, and publicity rights; (e) Customer’s interactions with other users of the Services; or (f)
                            Any content that Customer submits, posts, transmits, or otherwise makes available through the
                            Services. Company reserves the right, at Company’s own expense, to assume the exclusive
                            defense and control of any matter otherwise subject to indemnification by you, and in such case,
                            you agree to cooperate with our defense of such claim. Customer agrees not to settle any such
                            matter without our prior written consent. Company will use reasonable efforts to notify you of
                            any such claim, action, or proceeding upon becoming aware of it.
                        </p>
                    </li>
                    <li>Disclaimer of Warranties:
                        <p>
                            The Services, including the Subscription Package(s)
                            are provided on an "as is" and "as available" basis without warranty of any kind, whether express
                            or implied, including but not limited to the implied warranties of merchantability, fitness for a
                            particular purpose, and non-infringement. Company does not warrant that the services will meet
                            your requirements, that the services will be uninterrupted or error-free, that defects in the services
                            will be corrected, or that the services are free of viruses or other harmful components.
                        </p>
                    </li>
                    <li>Limitation of Liabilities:
                        <p>
                            TO THE MAXIMUM EXTENT PERMITTED BY LAW,
                            THE COMPANY SHALL NOT BE LIABLE TO THE CUSTOMER OR ANY THIRD PARTY
                            FOR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL, OR PUNITIVE
                            DAMAGES, INCLUDING, WITHOUT LIMITATION, LOSS OF PROFITS, DATA, OR
                            BUSINESS OPPORTUNITIES, ARISING OUT OF OR RELATED TO THESE TERMS AND
                            CONDITIONS, THE SERVICES, OR THE USE OR INABILITY TO USE THE SERVICES,
                            WHETHER BASED IN CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE, EVEN
                            IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                            IN NO EVENT SHALL THE LIABILITY OF THE COMPANY TO THE CUSTOMER FOR
                            ANY CLAIMS ARISING OUT OF OR RELATED TO THESE TERMS AND CONDITIONS
                            OR THE SERVICES EXCEED THE TOTAL AMOUNT PAID BY THE CUSTOMER TO THE
                            COMPANY FOR THE SERVICES DURING THE TWELVE (12) MONTHS IMMEDIATELY
                            PRECEDING THE EVENT GIVING RISE TO SUCH LIABILITY. THE LIMITATIONS OF
                            LIABILITY SET FORTH IN THESE TERMS AND CONDITIONS SHALL APPLY
                            NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED
                            REMEDY PROVIDED HEREIN. THE CUSTOMER ACKNOWLEDGES THAT THESE
                            LIMITATIONS OF LIABILITY ARE A MATERIAL INDUCEMENT TO THE COMPANY
                            TO PROVIDE THE SERVICES HEREUNDER.
                        </p>
                    </li>
                    <li>AAA Arbitration:
                        <p>
                            Any dispute arising out of or relating to these terms and conditions,
                            Agreement or your use of the Services, including any Subscription Package, other than an action
                            brought by Company to collect any sums due to Company under this, shall be settled exclusively
                            by arbitration administered by the American Arbitration Association (AAA) in accordance with
                            its Commercial Arbitration Rules, and judgment on the award rendered by the arbitrator(s) may
                            be entered in any court having jurisdiction thereof. The arbitration shall be conducted in the
                            English language by a single neutral arbitrator. The arbitration shall be conducted in Charlotte,
                            Mecklenburg County, North Carolina unless Customer and Company mutually agree otherwise.
                            The arbitration shall be conducted on an individual basis and not in a class, consolidated or
                            representative action. Customer agrees that any claim or dispute that cannot be resolved through
                            the arbitration process must be brought in a court of competent jurisdiction in the jurisdiction in
                            which we are located. The costs of the arbitration, including administrative and arbitrator fees,
                            shall be shared equally by the parties, unless the arbitrator(s) determines that one party should
                            bear a greater portion of the costs.
                        </p>
                    </li>
                    <li>Waiver of Jury Trial:
                        <p>
                            To the maximum extent permissible under applicable law, Customer
                            and Company waive any constitutional and statutory rights to go to court and have a trial in front
                            of a jury. Company and Customer are instead electing to have claims and disputes resolved by
                            arbitration. This section shall survive any termination or expiration of these terms and conditions.
                        </p>
                    </li>
                    <li>Waiver of Class Actions:
                        <p>
                            Company and Customer agree that any and all disputes or claims
                            that have arisen or may arise between Company and Customer shall be resolved on an individual
                            basis and not in any class, consolidated or representative action. If for any reason a claim or
                            dispute proceeds in court rather than in arbitration, both Customer and Company each waive any
                            right to a jury trial. This section shall survive any termination or expiration of these terms and
                            conditions.
                        </p>
                    </li>
                    <li>Governing Law and Jurisdiction:
                        <p>
                            These terms and conditions shall be governed by and
                            construed in accordance with the laws of North Carolina. You agree that any action at law or in
                            equity arising out of or relating to these terms and conditions or your use of the services shall be
                            filed only in the courts located in Greensboro, Guilford County, North Carolina, and you hereby
                            consent and submit to the personal jurisdiction of such courts for the purposes of litigating any
                            such action.
                        </p>
                    </li>
                    <li>Amendments:
                        <p>
                            Company may update or modify these terms and conditions at any time in
                            Company’s sole discretion. Company will post any updates or modifications to these terms and
                            conditions on Company’s website. Customer’s continued use of the Services, including any
                            Subscription Package after any such updates or modifications shall constitute Customer’s
                            acceptance of these terms and conditions as modified. This Agreement may not be amended or
                            modified by Customer except in writing signed by authorized representatives of both parties.
                        </p>
                    </li>
                    <li>Waiver:
                        <p>
                            No waiver of any provision of this agreement shall be effective unless it is in
                            writing and signed by the party against whom it is sought to be enforced. Any waiver of any
                            provision of this agreement shall not be construed as a waiver of any other provision or a
                            continuing waiver of the same provision in the future.
                        </p>
                    </li>
                    <li>Severability:
                        <p>
                            If any provision of this agreement is held to be invalid, illegal, or
                            unenforceable under any applicable law, the validity, legality, and enforceability of the remaining
                            provisions shall not be affected or impaired in any way, and the parties shall negotiate in good
                            faith to replace such provision with a valid and enforceable provision that most closely achieves
                            the intended economic and legal effect of the invalid provision.
                        </p>
                    </li>
                    <li>Assignment:
                        <p>
                            Customer may not assign this agreement, or any of its rights or obligations
                            under this agreement, without the prior written consent of the Company, which shall not be
                            unreasonably withheld or delayed. Company may assign this agreement without the Customer’s
                            consent in connection with a merger, acquisition, or sale of all or substantially all of Company’s
                            assets. Any purported assignment in violation of this section shall be void.
                        </p>
                    </li>
                    <li>Entire Agreement:
                        <p>
                            This Agreement the entire agreement between Company and Customer
                            and supersedes all prior agreements and understandings, whether written or oral, relating to the
                            subject matter of these terms and conditions.
                        </p>
                    </li>
                    <li>Copies and Counterparts:
                        <p>
                            This agreement may be executed in counterparts, each of which
                            shall be deemed an original and all of which together shall constitute one and the same instrument.
                            Facsimile, PDF, or other electronic signatures shall be deemed original signatures for purposes
                            of this agreement.
                        </p>
                    </li>
                </ol>
                <div style={{
                    display: "flex"
                }}>
                    {!isModal && (
                        <Button style={{
                            width: "40%",
                            marginLeft: "auto",
                            marginRight: "auto"
                        }}

                            onClick={() => window.history.back()}>
                            Back
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default TermsConditions