import React from 'react'
import TableWrapper from '../components/TableWrapper'
import TableContainerHeader from '../components/TableContainerHeader'
import TableContainerHeaderTitle from '../components/TableContainerHeaderTitle'
import TableContainer from '../components/TableContainer'
import TableHeader from '../components/TableHeader'
import TableHeaderColumn from '../components/TableHeaderColumn'
import { useQuery } from 'react-query'
import { IPreEmploymentInvestigation } from '../../../../../models'
import { getPreEmploymentScreeningByStatus } from '../../../../../shared/client/pre-employment'
import PreEmploymentRow from './PreEmploymentRow'

const PreEmploymentTable = () => {
    const { data = [] } = useQuery<IPreEmploymentInvestigation[]>('pre-employment-investigations', async () => getPreEmploymentScreeningByStatus(true))

    return (
        <>
            <TableWrapper>
                <TableContainerHeader>
                    <TableContainerHeaderTitle title="Pre-Employment Requests" />
                </TableContainerHeader>
                <TableContainer>
                    <TableHeader>
                        <TableHeaderColumn>
                            Member
                        </TableHeaderColumn>
                        <TableHeaderColumn>
                            Company
                        </TableHeaderColumn>
                        <TableHeaderColumn>
                            Status
                        </TableHeaderColumn>
                        <TableHeaderColumn>
                            Type
                        </TableHeaderColumn>
                        <TableHeaderColumn>
                            Date Requested
                        </TableHeaderColumn>
                        <TableHeaderColumn>
                            Date Completed
                        </TableHeaderColumn>
                    </TableHeader>
                    <tbody>
                        {data.map((request) => {
                            return <PreEmploymentRow investigation={request} key={request.id} />
                        })}
                    </tbody>
                </TableContainer>
            </TableWrapper>
        </>
    )
}

export default PreEmploymentTable