import React from 'react';

interface IProps {
    title: string;
    subtitle?: string;
    subtitleColor?: string;
    successText?: string;
}
export default function TableContainerHeaderTitle({ title, subtitle, successText, subtitleColor }: IProps) {
    return (
        <>
            <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">{title}</span>
                {subtitle ? (
                    <span className={`text-${subtitleColor ? subtitleColor : 'muted'} fw-semibold fs-7`}>
                        {subtitle}
                    </span>
                ) : null}
                {successText ? <span className="text-success fw-semibold fs-7">{successText}</span> : null}
            </h3>
        </>
    );
}
