/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PasswordMeterComponent } from '../../../../compliancemasters/assets/ts/components';
import { IPackage, useAuth } from '../core/Auth';
import useNavigation from '../../../../shared/useNavigation/useNavigation';
import { ISubscription } from '../../../../models/IPayments';
import { getSubscriptionPlans } from '../../../../shared';
import { Button, IconButton } from '@mui/material';
import { DeleteForeverRounded } from '@mui/icons-material';


interface ICompanyCreate {
    name: string;
    address1: string;
    address2: string;
    zipCode: string;
    city: string;
    country: string;
    package: IPackage[];
    owner_driver: boolean;
    only_driver: boolean;
    product_id?: string;
    social_security_number?: string;
    phone_number?: string;
    driver_license_number?: string;
    date_of_birth: string
    dot_number: string;
    requires_custom_pricing: boolean
    has_dot_compliant_files: boolean
}


const initialValues: ICompanyCreate = {
    name: '',
    address1: '',
    address2: '',
    zipCode: '',
    city: '',
    country: '',
    package: [],
    product_id: '',
    social_security_number: '',
    phone_number: '',
    owner_driver: false,
    only_driver: false,
    driver_license_number: '',
    date_of_birth: "",
    dot_number: "",
    requires_custom_pricing: false,
    has_dot_compliant_files: false
};

const registrationSchema = Yup.object().shape({
    name: Yup.string().min(1, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Company name is required'),
    address1: Yup.string().min(1, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Address 1 is required'),
    zipCode: Yup.string().min(1, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Zipcode is required'),
    city: Yup.string().min(1, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('City is required'),
    country: Yup.string().min(1, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('State is required'),
    dot_number: Yup.string().min(1, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('DOT Number is required'),
});

export function CreateCompany() {
    const [loading, setLoading] = useState(false);
    const [multiPackage, setMultiPackage] = useState(false)
    const [subPackage, setSubPackage] = React.useState<IPackage>({
        id: "",
        numberOfDrivers: 0
    })
    const { createCompany, currentUser } = useAuth();
    const [subscriptions, setSubscriptions] = React.useState<ISubscription[]>([])
    const [renderSubs, setRenderSubs] = React.useState<ISubscription[]>([])
    const { navigateToDashboard } = useNavigation();
    const [ownerPackageAdded, setOwnerPackageAdded] = useState(false)
    const [ownerPackage, setOwnerPackage] = useState<IPackage>({
        id: "",
        numberOfDrivers: 0
    })
    const formik = useFormik({
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            function errorHandler(error: any) {
                completedRequest();
            }

            function completedRequest() {
                setSubmitting(false);
                setLoading(false);
            }

            setLoading(true);

            createCompany(
                values.name,
                values.address1,
                values.address2,
                values.zipCode,
                values.city,
                values.country,
                values.package,
                values.owner_driver,
                values.only_driver,
                values.dot_number,
                values.product_id,
                values.social_security_number,
                values.phone_number?.includes("+") ? values.phone_number : `+1${values.phone_number}`,
                values.driver_license_number,
                values.date_of_birth,
                values.requires_custom_pricing,
                values.has_dot_compliant_files
            )
                .then(() => {
                    navigateToDashboard();
                })
                .catch((error) => errorHandler(error));
        }
    });


    const getSubs = async () => {
        const subs = await getSubscriptionPlans()
        if (subs) {
            setLoading(false)
            setSubscriptions(subs.data as ISubscription[])
        } else {
            setLoading(false)
        }
    }

    const driverPackageHandler = () => {
        if (subPackage.id !== "" && subPackage.numberOfDrivers !== 0) {
            formik.setFieldValue("package", [{
                id: subPackage.id,
                numberOfDrivers: subPackage.numberOfDrivers
            }, ...formik.values.package])
            setOwnerPackage({
                ...ownerPackage,
                id: subPackage.id
            })
            if (formik.values.owner_driver) {
                setOwnerPackageAdded(true)
            }
            // Reset the subPackage
            setSubPackage({
                id: "",
                numberOfDrivers: 0
            })
        }
    }

    useEffect(() => {
        getSubs()
        PasswordMeterComponent.bootstrap();
    }, []);

    React.useEffect(() => {
        if (currentUser?.drivers_license_number && formik.values.owner_driver) {
            formik.setFieldValue("driver_license_number", currentUser?.drivers_license_number)
        } else {
            formik.setFieldValue("driver_license_number", "")
        }
        // eslint-disable-next-line
    }, [currentUser, formik.values.owner_driver])


    React.useEffect(() => {
        if (!multiPackage && subPackage.id !== "" && subPackage?.numberOfDrivers !== 0) {
            formik.setFieldValue("package", [subPackage])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subPackage, multiPackage])

    React.useEffect(() => {
        if (formik.values.owner_driver && !formik.values.only_driver && !multiPackage) {
            formik.setFieldValue("product_id", formik.values.package[0].id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multiPackage, formik.values.package,])

    React.useEffect(() => {
        const filteredSubscriptions = subscriptions.filter(
            (x) => x.product_id !== formik.values.package.find(t => t.id)?.id
        );
        setRenderSubs(filteredSubscriptions)
    }, [formik.values, subscriptions])

    console.log(formik.values.product_id)

    return (
        <form
            className="form w-100 p-5 fv-plugins-bootstrap5 fv-plugins-framework"
            noValidate
            id="kt_login_signup_form"
            onSubmit={formik.handleSubmit}
        >
            {/* begin::Heading */}
            <div className="mb-10 text-center">
                {/* begin::Title */}
                <h1 className="text-dark mb-3">Create a Company</h1>
                {/* end::Title */}
            </div>

            {formik.status && (
                <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">{formik.status}</div>
                </div>
            )}

            {/* begin::Form group Firstname */}
            <div className="mb-10">
                <label className="form-label mt-3">Enter your company name</label>
                <input
                    type="text"
                    className="form-control form-control-solid"
                    {...formik.getFieldProps('name')}
                    placeholder="Company Name"
                />
                <label className="form-label mt-3">Enter Address line 1:</label>
                <input
                    type="text"
                    className="form-control form-control-solid"
                    {...formik.getFieldProps('address1')}
                    placeholder="Address line 1"
                />
                <label className="form-label mt-3">Enter Address line 2:</label>
                <input
                    type="text"
                    className="form-control form-control-solid"
                    {...formik.getFieldProps('address2')}
                    placeholder="Address line 2"
                />
                <label className="form-label mt-3">Enter City:</label>
                <input
                    type="text"
                    className="form-control form-control-solid"
                    {...formik.getFieldProps('city')}
                    placeholder="City"
                />
                <label className="form-label mt-3">Enter State:</label>
                <input
                    type="text"
                    className="form-control form-control-solid"
                    {...formik.getFieldProps('country')}
                    placeholder="State"
                />
                <label className="form-label mt-3">Enter ZIP / Postal code:</label>
                <input
                    type="text"
                    className="form-control form-control-solid"
                    {...formik.getFieldProps('zipCode')}
                    placeholder="ZIP / Postal code"
                />
                <label className="form-label mt-3">DOT Number:</label>
                <input
                    type="text"
                    className="form-control form-control-solid"
                    {...formik.getFieldProps('dot_number')}
                    placeholder="DOT Number"
                />
                <label className="form-label mt-3">Do you currently have DOT compliant driver qualification files?</label>
                <select
                    className="form-control form-control-solid"
                    value={formik.values.has_dot_compliant_files ? 'Yes' : 'No'}
                    onChange={(e) => {
                        formik.setFieldValue("has_dot_compliant_files", e.target.value === 'Yes')
                    }}
                >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                </select>
                <label className="form-label mt-3">As the owner, are you also a driver?</label>
                <select
                    className="form-control form-control-solid"
                    value={formik.values.owner_driver ? 'Yes' : 'No'}
                    onChange={(e) => {
                        formik.setFieldValue("owner_driver", e.target.value === 'Yes')
                    }}
                >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                </select>
                {formik.values.owner_driver ?
                    <>
                        <label className="form-label mt-3">Are you the only driver or do you have other drivers?</label>
                        <select
                            className="form-control form-control-solid"
                            value={formik.values.only_driver ? 'Yes' : 'No'}
                            onChange={(e) => formik.setFieldValue("only_driver", e.target.value === 'Yes')}
                        >
                            <option value="No">No, I'm not the only driver</option>
                            <option value="Yes">Yes, I'm the only driver</option>
                        </select>
                    </>
                    : null}
                {formik.values.only_driver && formik.values.owner_driver ? (
                    <>
                        <label className="form-label mt-3">Which package will you subscribe to?</label>
                        <select
                            className="form-control form-control-solid"
                            onChange={e => {
                                formik.setFieldValue("product_id", e.target.value)
                                setOwnerPackage({
                                    ...ownerPackage,
                                    id: e.target.value
                                })
                            }}
                            value={formik.values.package[0]?.id}
                        >
                            <option value={0}>
                                Select Package
                            </option>
                            {subscriptions.map((item) => (
                                <option key={item.product_id} value={item.product_id}>
                                    {item.name} - <div className='text-center'>
                                        <span className='mb-2 text-primary'>$</span>
                                        <span
                                            className='fs-3x fw-bold text-primary'
                                            data-kt-plan-price-month={99}
                                            data-kt-plan-price-annual={999}
                                        >
                                            {item?.tiers[0].unit_amount && item?.tiers[0].unit_amount / 100}
                                        </span>
                                        <span
                                            className='fs-7 fw-semibold opacity-50'
                                            data-kt-plan-price-month='Mon'
                                            data-kt-plan-price-annual='Ann'
                                        >
                                            / Month per driver
                                        </span>
                                    </div>
                                </option>
                            ))}
                        </select>
                    </>
                ) : (
                    <>
                        <label className="form-label mt-3">Will all drivers be under the same package?</label>
                        <select
                            className="form-control form-control-solid"
                            value={multiPackage ? 'No' : 'Yes'}
                            onChange={(e) => setMultiPackage(e.target.value === 'No')}
                        >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                        {formik.values.owner_driver && !formik.values.only_driver && multiPackage && !ownerPackageAdded && formik.values.package.length < 2 ? (
                            <>
                                <label className="form-label mt-3">Which package will you subscribe to {!ownerPackageAdded && <span style={{ color: "red" }}>(For Yourself)</span>}?</label>
                                <select
                                    className="form-control form-control-solid"
                                    onChange={e => {
                                        formik.setFieldValue("product_id", e.target.value)
                                        setSubPackage({
                                            ...subPackage,
                                            id: e.target.value
                                        })
                                    }}
                                    value={formik.values.product_id}
                                >
                                    <option value={0}>
                                        Select Package
                                    </option>
                                    {subscriptions.map((item) => {
                                        const sub = formik.values.package.find(x => item.product_id === x.id)
                                        if (sub) {
                                            return null
                                        } else return (
                                            <option key={item.product_id} value={item.product_id}>
                                                {item.name} - <div className='text-center'>
                                                    <span className='mb-2 text-primary'>$</span>
                                                    <span
                                                        className='fs-3x fw-bold text-primary'
                                                        data-kt-plan-price-month={99}
                                                        data-kt-plan-price-annual={999}
                                                    >
                                                        {item?.tiers[0].unit_amount && item?.tiers[0].unit_amount / 100}
                                                    </span>
                                                    <span
                                                        className='fs-7 fw-semibold opacity-50'
                                                        data-kt-plan-price-month='Mon'
                                                        data-kt-plan-price-annual='Ann'
                                                    >
                                                        / Month per driver
                                                    </span>
                                                </div>
                                            </option>
                                        )
                                    })}
                                </select>
                                <label className="form-label mt-3">How many drivers for this package {!ownerPackageAdded ? <span style={{ color: "red" }}>(include yourself, if included in this package)</span> : null}</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    value={subPackage.numberOfDrivers}
                                    onChange={e => setSubPackage({
                                        ...subPackage,
                                        numberOfDrivers: Number(e.target.value)
                                    })}
                                    placeholder="Number of Drivers"
                                />
                                <div className="text-center">
                                    <Button
                                        sx={{ mt: 2 }}
                                        variant="outlined"
                                        onClick={driverPackageHandler}>
                                        <span className="indicator-label">Add Package</span>
                                    </Button>
                                </div>
                            </>
                        ) : null}
                        {!formik.values.owner_driver && multiPackage && formik.values.package.length < 2 ? (
                            <>
                                <label className="form-label mt-3">Which package will you subscribe to ?</label>
                                <select
                                    className="form-control form-control-solid"
                                    onChange={e => {
                                        formik.setFieldValue("product_id", e.target.value)
                                        setSubPackage({
                                            ...subPackage,
                                            id: e.target.value
                                        })
                                    }}
                                    value={formik.values.product_id}
                                >
                                    <option value={0}>
                                        Select Package
                                    </option>
                                    {subscriptions.map((item) => {
                                        const sub = formik.values.package.find(x => item.product_id === x.id)
                                        if (sub) {
                                            return null
                                        } else return (
                                            <option key={item.product_id} value={item.product_id}>
                                                {item.name} - <div className='text-center'>
                                                    <span className='mb-2 text-primary'>$</span>
                                                    <span
                                                        className='fs-3x fw-bold text-primary'
                                                        data-kt-plan-price-month={99}
                                                        data-kt-plan-price-annual={999}
                                                    >
                                                        {item?.tiers[0].unit_amount && item?.tiers[0].unit_amount / 100}
                                                    </span>
                                                    <span
                                                        className='fs-7 fw-semibold opacity-50'
                                                        data-kt-plan-price-month='Mon'
                                                        data-kt-plan-price-annual='Ann'
                                                    >
                                                        / Month per driver
                                                    </span>
                                                </div>
                                            </option>
                                        )
                                    })}
                                </select>
                                <label className="form-label mt-3">How many drivers for this package</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    value={subPackage.numberOfDrivers}
                                    onChange={e => setSubPackage({
                                        ...subPackage,
                                        numberOfDrivers: Number(e.target.value)
                                    })}
                                    placeholder="Number of Drivers"
                                />
                                <div className="text-center">
                                    <Button
                                        sx={{ mt: 2 }}
                                        variant="outlined"
                                        onClick={driverPackageHandler}>
                                        <span className="indicator-label">Add Package</span>
                                    </Button>
                                </div>
                            </>
                        ) : null}
                        {multiPackage && ownerPackageAdded && formik.values.package.length < 2 ? (
                            <>
                                <label className="form-label mt-3">which package will you be choosing?</label>
                                <select
                                    className="form-control form-control-solid"
                                    onChange={e => setSubPackage({
                                        ...subPackage,
                                        id: e.target.value
                                    })}
                                    value={subPackage.id}
                                >
                                    <option value={0}>
                                        Select Package
                                    </option>
                                    {renderSubs.map((item) => {
                                        const sub = formik.values.package.find(x => item.product_id === x.id)
                                        if (sub) {
                                            return null
                                        } else return (
                                            <option key={item.product_id} value={item.product_id}>
                                                {item.name} - <div className='text-center'>
                                                    <span className='mb-2 text-primary'>$</span>
                                                    <span
                                                        className='fs-3x fw-bold text-primary'
                                                        data-kt-plan-price-month={99}
                                                        data-kt-plan-price-annual={999}
                                                    >
                                                        {item?.tiers[0].unit_amount && item?.tiers[0].unit_amount / 100}
                                                    </span>
                                                    <span
                                                        className='fs-7 fw-semibold opacity-50'
                                                        data-kt-plan-price-month='Mon'
                                                        data-kt-plan-price-annual='Ann'
                                                    >
                                                        / Month per driver
                                                    </span>
                                                </div>
                                            </option>
                                        )
                                    })}
                                </select>
                                <label className="form-label mt-3">Number of drivers for this Package:</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    value={subPackage.numberOfDrivers}
                                    onChange={e => setSubPackage({
                                        ...subPackage,
                                        numberOfDrivers: Number(e.target.value)
                                    })}
                                    placeholder="Number of Drivers"
                                />
                                <div className="text-center">
                                    <Button
                                        sx={{ mt: 2 }}
                                        variant="outlined"
                                        onClick={() => {
                                            const selected_package = subscriptions.find(item => item.product_id === subPackage.id)
                                            if (selected_package) {
                                                //  Check if the package is already in the list
                                                if (formik.values.package.find(item => item.id === selected_package.product_id)) {
                                                    // Increase the number of drivers for the package by 1
                                                    const new_package = formik.values.package.map(item => {
                                                        if (item.id === selected_package.product_id) {
                                                            return {
                                                                ...item,
                                                                numberOfDrivers: item.numberOfDrivers + subPackage.numberOfDrivers
                                                            }
                                                        }
                                                        return item
                                                    })
                                                    formik.setFieldValue("package", new_package)
                                                    setSubPackage({
                                                        id: "",
                                                        numberOfDrivers: 0
                                                    })
                                                } else {
                                                    formik.setFieldValue("package", [...formik.values.package, {
                                                        id: subPackage.id,
                                                        numberOfDrivers: subPackage.numberOfDrivers
                                                    }])
                                                    setSubPackage({
                                                        id: "",
                                                        numberOfDrivers: 0
                                                    })
                                                }
                                            }
                                        }}>
                                        <span className="indicator-label">Add Package</span>
                                    </Button>
                                </div>
                            </>
                        ) : null}
                        {!multiPackage ? (
                            <>
                                <label className="form-label mt-3">which package will you be choosing?</label>
                                <select
                                    className="form-control form-control-solid"
                                    onChange={e => {
                                        setSubPackage({
                                            ...subPackage,
                                            id: e.target.value
                                        })
                                    }
                                    }
                                    value={subPackage.id}
                                >
                                    <option value={0}>
                                        Select Package
                                    </option>
                                    {subscriptions.map((item) => (
                                        <option key={item.product_id} value={item.product_id}>
                                            {item.name} - <div className='text-center'>
                                                <span className='mb-2 text-primary'>$</span>
                                                <span
                                                    className='fs-3x fw-bold text-primary'
                                                    data-kt-plan-price-month={99}
                                                    data-kt-plan-price-annual={999}
                                                >
                                                    {item?.tiers[0].unit_amount && item?.tiers[0].unit_amount / 100}
                                                </span>
                                                <span
                                                    className='fs-7 fw-semibold opacity-50'
                                                    data-kt-plan-price-month='Mon'
                                                    data-kt-plan-price-annual='Ann'
                                                >
                                                    / Month per driver
                                                </span>
                                            </div>
                                        </option>
                                    ))}
                                </select>
                                <label className="form-label mt-3">Number of drivers for this Package {formik.values.owner_driver && !formik.values.only_driver ? "(including yourself)" : ""}:</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    value={subPackage.numberOfDrivers}
                                    onChange={e => setSubPackage({
                                        ...subPackage,
                                        numberOfDrivers: Number(e.target.value)
                                    })}
                                    placeholder="Number of Drivers"
                                />
                            </>
                        ) : null}

                        {multiPackage && formik.values.package.length > 0 && (
                            <>
                                <label className="form-label mt-3">Selected Packages</label>
                                {formik.values.package.map((p) => (
                                    <div
                                        key={p.id}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                        }}>
                                        <div>

                                            <p className='text-gray-800 fw-bolder fs-3 mb-3'>{subscriptions.find(item => item.product_id === p.id)?.name}</p>
                                            <p className='text-dark'>Number of Drivers: {p.numberOfDrivers}</p>
                                        </div>
                                        <div>
                                            <IconButton
                                                onClick={() => {
                                                    if (p.id === ownerPackage.id && p.id === formik.values.product_id) {
                                                        setOwnerPackageAdded(false)
                                                        setOwnerPackage({
                                                            ...ownerPackage,
                                                            id: ""
                                                        })
                                                        formik.setFieldValue("product_id", "")
                                                    }
                                                    formik.setFieldValue("package", formik.values.package.filter(item => item.id !== p.id))
                                                }}
                                                className='mt-5'
                                            >
                                                <DeleteForeverRounded fontSize="large" color='error' />
                                            </IconButton>
                                        </div>
                                    </div>
                                )
                                )}
                            </>
                        )}
                    </>
                )}

                <>
                    <label className="form-label mt-3">Driver License Number:</label>
                    <input
                        type="text"
                        className="form-control form-control-solid"
                        {...formik.getFieldProps('driver_license_number')}
                        placeholder="Driver License Number"
                    />
                    <label className="form-label mt-3">Social Security Number:</label>
                    <input
                        type="text"
                        className="form-control form-control-solid"
                        {...formik.getFieldProps('social_security_number')}
                        placeholder="Social Security Number"
                    />
                    <div className="fv-row mb-5">
                        <label className="form-label mt-3">Date of Birth:</label>
                        <input
                            placeholder=""
                            type="date"
                            autoComplete="off"
                            {...formik.getFieldProps('date_of_birth')}
                            className="form-control form-control-solid"
                        />
                        {formik.touched.date_of_birth && formik.errors.date_of_birth && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">{JSON.stringify(formik.errors.date_of_birth)}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <label className="form-label mt-3">Phone Number:</label>
                    <input
                        type="text"
                        className="form-control form-control-solid"
                        {...formik.getFieldProps('phone_number')}
                        placeholder="Phone Number"
                    />
                </>
                <label className="form-label mt-3">Does your company require custom features?</label>
                <select
                    className="form-control form-control-solid"
                    value={formik.values.requires_custom_pricing ? 'Yes' : 'No'}
                    onChange={(e) => formik.setFieldValue("requires_custom_pricing", e.target.value === 'Yes')}
                >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                </select>
            </div>
            <div className="text-center">
                <button
                    type="submit"
                    id="kt_sign_up_submit"
                    className="btn btn-lg btn-primary w-100 mb-5"
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className="indicator-label">Create</span>}
                    {loading && (
                        <span className="indicator-progress" style={{ display: 'block' }}>
                            Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    )}
                </button>
            </div>
        </form>
    );
}
