import TableContainerHeaderTitle from '../components/TableContainerHeaderTitle';
import TableContainerHeader from '../components/TableContainerHeader';
import TableWrapper from '../components/TableWrapper';
import TableContainer from '../components/TableContainer';
import TableHeader from '../components/TableHeader';
import TableHeaderColumn from '../components/TableHeaderColumn';
import { IMemberRequest } from '../../../../../models';
import { useState } from 'react';
import MemberRequestRow from './components/MemberRequestRow';
import TableLoader from '../components/TableLoader';
import { useQuery } from 'react-query';
import { getMemberRequests } from '../../../../../shared/client/memberRequest';
import UpdateMemberRequestStatus from './components/UpdateMemberRequestStatus';

export default function MemberRequestTable() {
    const { data } = useQuery<IMemberRequest[]>('member-requests', async () => getMemberRequests())

    const [memberRequest, setMemberRequest] = useState<IMemberRequest | undefined>(undefined);

    return (
        <>
            <TableWrapper>
                <TableContainerHeader>
                    <TableContainerHeaderTitle title="Requests for Members" />
                </TableContainerHeader>
                {/* begin::Body */}
                <TableContainer>
                    <TableHeader>
                        <TableHeaderColumn>Member</TableHeaderColumn>
                        <TableHeaderColumn>Company</TableHeaderColumn>
                        <TableHeaderColumn>Request</TableHeaderColumn>
                        <TableHeaderColumn>City</TableHeaderColumn>
                        <TableHeaderColumn>State</TableHeaderColumn>
                        <TableHeaderColumn>Zip Code</TableHeaderColumn>
                        <TableHeaderColumn>Status</TableHeaderColumn>
                        <TableHeaderColumn>Confirmation Status</TableHeaderColumn>
                        <TableHeaderColumn>Date Requested / Confirmed</TableHeaderColumn>
                        <TableHeaderColumn>Date Completed</TableHeaderColumn>
                        <TableHeaderColumn>Action</TableHeaderColumn>
                    </TableHeader>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                        {data?.map((request) => {
                            return <MemberRequestRow request={request} key={request.id} setMemberRequest={setMemberRequest} />;
                        })}
                    </tbody>
                </TableContainer>
                <TableLoader length={data?.length} emptyText={'No requests, for now!'} />
            </TableWrapper>
            <UpdateMemberRequestStatus memberRequest={memberRequest} />
        </>
    );
}
