import React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { IAddressDto, IApplicantInformationDto } from '../../../store/interfaces/DriversApplicationForEmployment/applicant.information'
import { IMember } from '../../../models'
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, MenuItem, Paper, TextField, Typography } from '@mui/material'
import { useCreateAddressMutation, useCreateApplicantInformationMutation, useGetAddressQuery, useGetApplicantInformationByUserIdQuery, useUpdateAddressMutation, useUpdateApplicantInformationMutation, useDeleteApplicantInformationMutation } from '../../../store/reducers/DriversApplicationForEmployment/applicant-information'
import { useDeleteAddressMutation } from '../../../store/reducers/DriversApplicationForEmployment/applicant-information'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'
import { changeStringLast4 } from '../../../shared'
import FTADetails from './FTADetails'

const initialValues: Partial<IApplicantInformationDto> = {
    position_applied_for: '',
    full_name: '',
    social_security_number: '',
    drivers_license_number: '',
    legal_authority_to_work: false,
    date_of_birth: "",
    worked_for_company_before: false,
    previous_company: '',
    previous_company_from: "",
    previous_company_to: "",
    previous_company_position: '',
    reason_for_leaving: '',
    referred_by: '',
    bonded: false,
    essential_job_functions: false,
    mothers_maiden_name: '',
    criminal_record: false,
    fathers_names: '',
    worked_for_fta: false,
}

const addressInitialValues: IAddressDto = {
    company: undefined,
    member: undefined,
    street: "",
    city: '',
    state: "",
    zip_code: '',
    phone: '',
    duration: '',
    is_current: false
}



const validationSchema = Yup.object({
    position_applied_for: Yup.string().required('Required').min(3, 'Must be at least 3 characters'),
    full_name: Yup.string().required('Required').min(3, 'Must be at least 3 characters'),
    social_security_number: Yup.string().required('Required').min(3, 'Must be at least 3 characters'),
    drivers_license_number: Yup.string().required('Required').min(3, 'Must be at least 3 characters'),
    legal_authority_to_work: Yup.boolean().required('Required'),
    date_of_birth: Yup.string().required('Required').min(3, 'Must be at least 3 characters'),
    worked_for_company_before: Yup.boolean().required('Required'),
    bonded: Yup.boolean().required('Required'),
    essential_job_functions: Yup.boolean().required('Required'),
    mothers_maiden_name: Yup.string().required('Required').min(3, 'Must be at least 3 characters'),
    fathers_names: Yup.string().required('Required').min(3, 'Must be at least 3 characters'),
})

const addressValidationSchema = Yup.object({
    street: Yup.string().required('Required').min(3, 'Must be at least 3 characters'),
    city: Yup.string().required('Required').min(3, 'Must be at least 3 characters'),
    state: Yup.string().required('Required').min(2, 'Must be at least 3 characters'),
    zip_code: Yup.string().required('Required').min(3, 'Must be at least 3 characters'),
    phone: Yup.string().required('Required').min(3, 'Must be at least 3 characters'),
    duration: Yup.string().required('Required').min(3, 'Must be at least 3 characters'),
    is_current: Yup.boolean().required('Required'),
})



const AddressComponent = ({
    address,
    deleteAddress,
    member
}: {
    address: IAddressDto;
    deleteAddress: (val: number) => void;
    member?: IMember
}) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const [updateAddress] = useUpdateAddressMutation()
    const addressFormik = useFormik({
        initialValues: address,
        validationSchema: addressValidationSchema,
        onSubmit: (values) => {
            updateAddress(values)
        }
    })
    return (
        <>
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Street
                </Typography>
                <TextField
                    fullWidth
                    id="street"
                    placeholder='Street'
                    size="small"
                    variant="outlined"
                    {...addressFormik.getFieldProps('street')}
                    className={addressFormik.touched.street && Boolean(addressFormik.errors.street) ? "green" : "red"}
                    error={addressFormik.touched.street && Boolean(addressFormik.errors.street)}
                    helperText={addressFormik.touched.street && addressFormik.errors.street}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: addressFormik.touched.street && Boolean(addressFormik.errors.street) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: addressFormik.touched.street && Boolean(addressFormik.errors.street) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: addressFormik.touched.street && Boolean(addressFormik.errors.street) ? "red" : "green",
                            },
                        },
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    disabled={!!member?.terminated_at}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    City
                </Typography>
                <TextField
                    fullWidth
                    id="city"
                    placeholder='City'
                    size="small"
                    variant="outlined"
                    {...addressFormik.getFieldProps('city')}
                    className={addressFormik.touched.city && Boolean(addressFormik.errors.city) ? "green" : "red"}
                    error={addressFormik.touched.city && Boolean(addressFormik.errors.city)}
                    helperText={addressFormik.touched.city && addressFormik.errors.city}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: addressFormik.touched.city && Boolean(addressFormik.errors.city) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: addressFormik.touched.city && Boolean(addressFormik.errors.city) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: addressFormik.touched.city && Boolean(addressFormik.errors.city) ? "red" : "green",
                            },
                        },
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    disabled={!!member?.terminated_at}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    State
                </Typography>
                <TextField
                    fullWidth
                    id="state"
                    placeholder='State / Zip'
                    size="small"
                    variant="outlined"
                    {...addressFormik.getFieldProps('state')}
                    className={addressFormik.touched.state && Boolean(addressFormik.errors.state) ? "green" : "red"}
                    error={addressFormik.touched.state && Boolean(addressFormik.errors.state)}
                    helperText={addressFormik.touched.state && addressFormik.errors.state}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: addressFormik.touched.state && Boolean(addressFormik.errors.state) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: addressFormik.touched.state && Boolean(addressFormik.errors.state) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: addressFormik.touched.state && Boolean(addressFormik.errors.state) ? "red" : "green",
                            },
                        },
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    disabled={!!member?.terminated_at}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Zip Code
                </Typography>
                <TextField
                    fullWidth
                    id="zip_code"
                    placeholder='Zip Code'
                    size="small"
                    variant="outlined"
                    {...addressFormik.getFieldProps('zip_code')}
                    className={addressFormik.touched.zip_code && Boolean(addressFormik.errors.zip_code) ? "green" : "red"}
                    error={addressFormik.touched.zip_code && Boolean(addressFormik.errors.zip_code)}
                    helperText={addressFormik.touched.zip_code && addressFormik.errors.zip_code}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: addressFormik.touched.zip_code && Boolean(addressFormik.errors.zip_code) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: addressFormik.touched.zip_code && Boolean(addressFormik.errors.zip_code) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: addressFormik.touched.zip_code && Boolean(addressFormik.errors.zip_code) ? "red" : "green",
                            },
                        },
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    disabled={!!member?.terminated_at}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Phone Number
                </Typography>
                <TextField
                    fullWidth
                    id="phone"
                    placeholder='Phone Number'
                    size="small"
                    variant="outlined"
                    {...addressFormik.getFieldProps('phone')}
                    className={addressFormik.touched.phone && Boolean(addressFormik.errors.phone) ? "green" : "red"}
                    error={addressFormik.touched.phone && Boolean(addressFormik.errors.phone)}
                    helperText={addressFormik.touched.phone && addressFormik.errors.phone}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: addressFormik.touched.phone && Boolean(addressFormik.errors.phone) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: addressFormik.touched.phone && Boolean(addressFormik.errors.phone) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: addressFormik.touched.phone && Boolean(addressFormik.errors.phone) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    disabled={!!member?.terminated_at}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    How long have you lived here? (yr./mo.):
                </Typography>
                <TextField
                    fullWidth
                    id="duration"
                    placeholder='How long have you lived here? (yr./mo.):'
                    size="small"
                    variant="outlined"
                    {...addressFormik.getFieldProps('duration')}
                    className={addressFormik.touched.duration && Boolean(addressFormik.errors.duration) ? "green" : "red"}
                    error={addressFormik.touched.duration && Boolean(addressFormik.errors.duration)}
                    helperText={addressFormik.touched.duration && addressFormik.errors.duration}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: addressFormik.touched.duration && Boolean(addressFormik.errors.duration) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: addressFormik.touched.duration && Boolean(addressFormik.errors.duration) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: addressFormik.touched.duration && Boolean(addressFormik.errors.duration) ? "red" : "green",
                            },
                        },
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    disabled={!!member?.terminated_at}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Is this your current address?
                </Typography>
                <TextField
                    fullWidth
                    id="duration"
                    size="small"
                    variant="outlined"
                    value={addressFormik.values.is_current ? "Yes" : "No"}
                    select
                    onChange={(e) => {
                        addressFormik.setFieldValue('is_current', e.target.value === "Yes")
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: addressFormik.touched.is_current && Boolean(addressFormik.errors.is_current) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: addressFormik.touched.is_current && Boolean(addressFormik.errors.is_current) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: addressFormik.touched.is_current && Boolean(addressFormik.errors.is_current) ? "red" : "green",
                            },
                        },
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    disabled={!!member?.terminated_at}
                >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                </TextField>
            </Grid>
            {!address || (address && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role)) ? <>
                <Grid item xs={12} sm={6}>
                    <Button fullWidth disabled={!!member?.terminated_at} variant="contained" color="primary" onClick={() => addressFormik.handleSubmit()}>
                        Update Address
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button disabled={!!member?.terminated_at} fullWidth variant="contained" color="error" onClick={() => {
                        const res = window.confirm("Are you sure you want to delete this address?")
                        if (res) {
                            deleteAddress(address.id!)
                        }
                    }}>
                        Delete Address
                    </Button>
                </Grid>
            </> : null}
            <Grid item xs={12} sm={12}>
                <Divider style={{
                    marginTop: 5,
                    marginBottom: 5,
                    borderTopWidth: 1,
                    borderTopStyle: "solid",
                    borderTopColor: "gray"
                }} />
            </Grid>
        </>
    )
}


const ApplicantInformation = ({ member, isDriverFile }: { member?: IMember, isDriverFile?: boolean }) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data } = useGetApplicantInformationByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
        pollingInterval: 1000 * 5,
    })

    const { data: addresses = [] } = useGetAddressQuery(Number(member?.user?.id), {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    })

    const [deleteAddress] = useDeleteAddressMutation()
    const [createApplicantInfo, { isLoading: isCreating }] = useCreateApplicantInformationMutation()
    const [updateApplicantInformation, { isLoading: isUpdating }] = useUpdateApplicantInformationMutation()
    const [createAddress, { isLoading: isCreatingAddress }] = useCreateAddressMutation()
    const [deleteApplicantInformation] = useDeleteApplicantInformationMutation()

    const [addNewItem, setAddNewItem] = React.useState(true)

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            if (data) {
                // @ts-ignore
                updateApplicantInformation({
                    // @ts-ignore
                    ...values,
                    // @ts-ignore
                    member: member?.id,
                    // @ts-ignore
                    company: member?.company?.id,
                    id: data.id
                })
            } else {
                createApplicantInfo({
                    ...values,
                    // @ts-ignore
                    member: member?.id,
                    // @ts-ignore
                    company: member?.company?.id,
                })
            }
        },
        validateOnBlur: true,
    })


    const addressFormik = useFormik({
        initialValues: addressInitialValues,
        validationSchema: addressValidationSchema,
        onSubmit: (values) => {
            createAddress({
                ...values,
                company: Number(member?.company?.id),
                member: Number(member?.id)
            }).then((res) => {
                addressFormik.resetForm()
            })

        },
        validateOnBlur: true,
        validate: (values) => {
            const errors: Partial<IAddressDto> = {}

            if (!values.duration) {
                errors.duration = 'Required'
            }

            if (!values.phone) {
                errors.phone = 'Required'
            }

            if (!values.state) {
                errors.state = 'Required'
            }
            if (!values.zip_code) {
                errors.zip_code = 'Required'
            }

            if (!values.street) {
                errors.street = 'Required'
            }
            if (!values.city) {
                errors.city = 'Required'
            }

            return errors
        }
    })


    React.useEffect(() => {
        if (member) {
            const utcDate = new Date(member?.date_of_birth)

            const offsetMinutes = utcDate.getTimezoneOffset();
            utcDate.setMinutes(utcDate.getMinutes() + offsetMinutes);

            // Format the date to "YYYY-MM-DD"
            const adjustedYear = utcDate.getFullYear();
            const adjustedMonth = String(utcDate.getMonth() + 1).padStart(2, '0');
            const adjustedDay = String(utcDate.getDate()).padStart(2, '0');
            const formattedDate = `${adjustedYear}-${adjustedMonth}-${adjustedDay}`;

            if (data) {
                formik.setValues({
                    ...data,
                    drivers_license_number: ["Member", "Owner"].includes(currentUser?.role!) ? changeStringLast4(data?.drivers_license_number, "*")! : data?.drivers_license_number,
                    social_security_number: ["Member", "Owner"].includes(currentUser?.role!) ? changeStringLast4(data?.social_security_number, "*")! : data?.social_security_number,
                })
            } else {
                formik.setValues({
                    ...initialValues,
                    // @ts-ignore
                    member: member?.id,
                    full_name: `${member?.user?.last_name}, ${member?.user?.first_name}`,
                    social_security_number: member?.social_security_number,
                    drivers_license_number: member?.drivers_license_number,
                    date_of_birth: formattedDate,
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, member])

    React.useEffect(() => {
        if (addresses && addresses.length > 0) {
            setAddNewItem(false)
        } else {
            setAddNewItem(true)
        }
    }, [addresses])


    return (
        <>
            <div style={{
                padding: 10,
                margin: 10
            }}>
                <Box sx={{
                    pl: 3,
                    pr: 3,
                    Radius: 2,
                    backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: "47vw",
                        md: "70vw",
                        sm: "80vw",
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 3
                }} component={Paper}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                        Applicant Information
                    </Typography>
                    <Grid container spacing={3} p={1} sx={{
                        alignItems: "center",
                    }}>
                        <Grid item xs={12} sm={12}>
                            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h6" gutterBottom component="h6">
                                List your addresses of residency for the past 3 years:
                            </Typography>
                        </Grid>
                        {addresses?.map((address, index) => {
                            return <AddressComponent member={member} key={address.id} address={address} deleteAddress={deleteAddress} />
                        })}
                        {addresses.length > 0 ? <Grid item xs={12} sm={12} sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <FormControlLabel sx={{
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }} control={<Checkbox sx={{
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }} checked={addNewItem} />} onChange={() => setAddNewItem(!addNewItem)} label="Add Another Previous Address" />
                        </Grid> : null}
                        {addNewItem && <>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                    Street
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="street"
                                    placeholder='Street'
                                    size="small"
                                    variant="outlined"
                                    {...addressFormik.getFieldProps('street')}
                                    className={addressFormik.touched.street && Boolean(addressFormik.errors.street) ? "green" : "red"}
                                    error={addressFormik.touched.street && Boolean(addressFormik.errors.street)}
                                    helperText={addressFormik.touched.street && addressFormik.errors.street}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor: addressFormik.touched.street && Boolean(addressFormik.errors.street) ? "red" : "green",
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor: addressFormik.touched.street && Boolean(addressFormik.errors.street) ? "red" : "green",
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor: addressFormik.touched.street && Boolean(addressFormik.errors.street) ? "red" : "green",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                    disabled={!!member?.terminated_at}
                                    InputProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                    City
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="city"
                                    placeholder='City'
                                    size="small"
                                    variant="outlined"
                                    {...addressFormik.getFieldProps('city')}
                                    className={addressFormik.touched.city && Boolean(addressFormik.errors.city) ? "green" : "red"}
                                    error={addressFormik.touched.city && Boolean(addressFormik.errors.city)}
                                    helperText={addressFormik.touched.city && addressFormik.errors.city}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor: addressFormik.touched.city && Boolean(addressFormik.errors.city) ? "red" : "green",
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor: addressFormik.touched.city && Boolean(addressFormik.errors.city) ? "red" : "green",
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor: addressFormik.touched.city && Boolean(addressFormik.errors.city) ? "red" : "green",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                    disabled={!!member?.terminated_at}
                                    InputProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                    State
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="state"
                                    placeholder='State'
                                    size="small"
                                    variant="outlined"
                                    {...addressFormik.getFieldProps('state')}
                                    className={addressFormik.touched.state && Boolean(addressFormik.errors.state) ? "green" : "red"}
                                    error={addressFormik.touched.state && Boolean(addressFormik.errors.state)}
                                    helperText={addressFormik.touched.state && addressFormik.errors.state}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor: addressFormik.touched.state && Boolean(addressFormik.errors.state) ? "red" : "green",
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor: addressFormik.touched.state && Boolean(addressFormik.errors.state) ? "red" : "green",
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor: addressFormik.touched.state && Boolean(addressFormik.errors.state) ? "red" : "green",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                    disabled={!!member?.terminated_at}
                                    InputProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                    Zip Code
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="zip_code"
                                    placeholder='Zip Code'
                                    size="small"
                                    variant="outlined"
                                    {...addressFormik.getFieldProps('zip_code')}
                                    className={addressFormik.touched.zip_code && Boolean(addressFormik.errors.zip_code) ? "green" : "red"}
                                    error={addressFormik.touched.zip_code && Boolean(addressFormik.errors.zip_code)}
                                    helperText={addressFormik.touched.zip_code && addressFormik.errors.zip_code}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor: addressFormik.touched.zip_code && Boolean(addressFormik.errors.zip_code) ? "red" : "green",
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor: addressFormik.touched.zip_code && Boolean(addressFormik.errors.zip_code) ? "red" : "green",
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor: addressFormik.touched.zip_code && Boolean(addressFormik.errors.zip_code) ? "red" : "green",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                    disabled={!!member?.terminated_at}
                                    InputProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                    Phone Number
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="phone"
                                    placeholder='Phone Number'
                                    size="small"
                                    variant="outlined"
                                    {...addressFormik.getFieldProps('phone')}
                                    className={addressFormik.touched.phone && Boolean(addressFormik.errors.phone) ? "green" : "red"}
                                    error={addressFormik.touched.phone && Boolean(addressFormik.errors.phone)}
                                    helperText={addressFormik.touched.phone && addressFormik.errors.phone}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor: addressFormik.touched.phone && Boolean(addressFormik.errors.phone) ? "red" : "green",
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor: addressFormik.touched.phone && Boolean(addressFormik.errors.phone) ? "red" : "green",
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor: addressFormik.touched.phone && Boolean(addressFormik.errors.phone) ? "red" : "green",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                    disabled={!!member?.terminated_at}
                                    InputProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                    How long have you lived here? (yr./mo.):
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="duration"
                                    placeholder='How long have you lived here? (yr./mo.):'
                                    size="small"
                                    variant="outlined"
                                    {...addressFormik.getFieldProps('duration')}
                                    className={addressFormik.touched.duration && Boolean(addressFormik.errors.duration) ? "green" : "red"}
                                    error={addressFormik.touched.duration && Boolean(addressFormik.errors.duration)}
                                    helperText={addressFormik.touched.duration && addressFormik.errors.duration}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor: addressFormik.touched.duration && Boolean(addressFormik.errors.duration) ? "red" : "green",
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor: addressFormik.touched.duration && Boolean(addressFormik.errors.duration) ? "red" : "green",
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor: addressFormik.touched.duration && Boolean(addressFormik.errors.duration) ? "red" : "green",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                    disabled={!!member?.terminated_at}
                                    InputProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                    Is this your current address?
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="duration"
                                    size="small"
                                    variant="outlined"
                                    value={addressFormik.values.is_current ? "Yes" : "No"}
                                    select
                                    onChange={(e) => {
                                        addressFormik.setFieldValue('is_current', e.target.value === "Yes")
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor: addressFormik.touched.duration && Boolean(addressFormik.errors.duration) ? "red" : "green",
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor: addressFormik.touched.duration && Boolean(addressFormik.errors.duration) ? "red" : "green",
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor: addressFormik.touched.duration && Boolean(addressFormik.errors.duration) ? "red" : "green",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                    disabled={!!member?.terminated_at}
                                    InputProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                >
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Button fullWidth disabled={isCreatingAddress || (!!member?.terminated_at)} onClick={() => addressFormik.handleSubmit()} variant="contained" color="primary">
                                    Save
                                </Button>
                            </Grid>
                        </>}
                    </Grid>
                </Box>
            </div>
            <div style={{
                padding: 10,
                margin: 10
            }}>
                <Box sx={{
                    pl: 3,
                    pr: 3,
                    Radius: 2,
                    backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: "47vw",
                        md: "70vw",
                        sm: "80vw",
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 3,
                    p: 3
                }} component={Paper}>
                    <Grid item xs={12} sx={{ mt: 3 }} sm={12}>
                        <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h6" gutterBottom component="h6">
                            Applicant Details
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                            Positions Applied For
                        </Typography>
                        <TextField
                            fullWidth
                            id="position_applied_for"
                            placeholder='Positions Applied For'
                            size="small"
                            variant="outlined"
                            {...formik.getFieldProps('position_applied_for')}
                            className={formik.touched.position_applied_for && Boolean(formik.errors.position_applied_for) ? "green" : "red"}
                            error={formik.touched.position_applied_for && Boolean(formik.errors.position_applied_for)}
                            helperText={formik.touched.position_applied_for && formik.errors.position_applied_for}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.position_applied_for && Boolean(formik.errors.position_applied_for) ? "red" : "green",
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.position_applied_for && Boolean(formik.errors.position_applied_for) ? "red" : "green",
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.position_applied_for && Boolean(formik.errors.position_applied_for) ? "red" : "green",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={member?.terminated_at || data?.position_applied_for ? true : false}
                            InputProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                            Full Name (Last, First, Middle)
                        </Typography>
                        <TextField
                            fullWidth
                            id="full_name"
                            placeholder='Full Name (Last, First, Middle)'
                            size="small"
                            variant="outlined"
                            {...formik.getFieldProps('full_name')}
                            className={formik.touched.full_name && Boolean(formik.errors.full_name) ? "green" : "red"}
                            error={formik.touched.full_name && Boolean(formik.errors.full_name)}
                            helperText={formik.touched.full_name && formik.errors.full_name}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.full_name && Boolean(formik.errors.full_name) ? "red" : "green",
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.full_name && Boolean(formik.errors.full_name) ? "red" : "green",
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.full_name && Boolean(formik.errors.full_name) ? "red" : "green",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={member?.terminated_at || data?.full_name ? true : false}
                            InputProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                            Social Security Number
                        </Typography>
                        <TextField
                            fullWidth
                            id="social_security_number"
                            placeholder='Social Security Number'
                            size="small"
                            variant="outlined"
                            {...formik.getFieldProps('social_security_number')}
                            className={formik.touched.social_security_number && Boolean(formik.errors.social_security_number) ? "green" : "red"}
                            error={formik.touched.social_security_number && Boolean(formik.errors.social_security_number)}
                            helperText={formik.touched.social_security_number && formik.errors.social_security_number}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.social_security_number && Boolean(formik.errors.social_security_number) ? "red" : "green",
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.social_security_number && Boolean(formik.errors.social_security_number) ? "red" : "green",
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.social_security_number && Boolean(formik.errors.social_security_number) ? "red" : "green",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={member?.terminated_at || data?.social_security_number ? true : false}
                            InputProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                            Drivers License Number
                        </Typography>
                        <TextField
                            fullWidth
                            id="drivers_license_number"
                            placeholder='Drivers License Number'
                            size="small"
                            variant="outlined"
                            {...formik.getFieldProps('drivers_license_number')}
                            className={formik.touched.drivers_license_number && Boolean(formik.errors.drivers_license_number) ? "green" : "red"}
                            error={formik.touched.drivers_license_number && Boolean(formik.errors.drivers_license_number)}
                            helperText={formik.touched.drivers_license_number && formik.errors.drivers_license_number}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.drivers_license_number && Boolean(formik.errors.drivers_license_number) ? "red" : "green",
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.drivers_license_number && Boolean(formik.errors.drivers_license_number) ? "red" : "green",
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.drivers_license_number && Boolean(formik.errors.drivers_license_number) ? "red" : "green",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={member?.terminated_at || data?.drivers_license_number ? true : false}
                            InputProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                            Mothers Maiden Name
                        </Typography>
                        <TextField
                            fullWidth
                            id="mothers_maiden_name"
                            placeholder='Mothers Maiden Name'
                            size="small"
                            variant="outlined"
                            {...formik.getFieldProps('mothers_maiden_name')}
                            className={formik.touched.mothers_maiden_name && Boolean(formik.errors.mothers_maiden_name) ? "green" : "red"}
                            error={formik.touched.mothers_maiden_name && Boolean(formik.errors.mothers_maiden_name)}
                            helperText={formik.touched.mothers_maiden_name && formik.errors.mothers_maiden_name}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.mothers_maiden_name && Boolean(formik.errors.mothers_maiden_name) ? "red" : "green",
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.mothers_maiden_name && Boolean(formik.errors.mothers_maiden_name) ? "red" : "green",
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.mothers_maiden_name && Boolean(formik.errors.mothers_maiden_name) ? "red" : "green",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={member?.terminated_at || data?.mothers_maiden_name ? true : false}
                            InputProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                            Fathers Names (First, Last and any Middle Names)
                        </Typography>
                        <TextField
                            fullWidth
                            id="fathers_names"
                            placeholder='Fathers Names (First, Last and any Middle Names)'
                            size="small"
                            variant="outlined"
                            {...formik.getFieldProps('fathers_names')}
                            className={formik.touched.fathers_names && Boolean(formik.errors.fathers_names) ? "green" : "red"}
                            error={formik.touched.fathers_names && Boolean(formik.errors.fathers_names)}
                            helperText={formik.touched.fathers_names && formik.errors.fathers_names}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.fathers_names && Boolean(formik.errors.fathers_names) ? "red" : "green",
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.fathers_names && Boolean(formik.errors.fathers_names) ? "red" : "green",
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.fathers_names && Boolean(formik.errors.fathers_names) ? "red" : "green",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={member?.terminated_at || data?.fathers_names ? true : false}
                            InputProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 3 }} sm={12}>
                        <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h6" gutterBottom component="h6">
                            Answer the following questions:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                            Do you have legal right to work in the United States?
                        </Typography>
                        <TextField
                            fullWidth
                            id="legal_authority_to_work"
                            placeholder='Do you have legal right to work in the United States?'
                            size="small"
                            variant="outlined"
                            value={formik.values.legal_authority_to_work ? "Yes" : "No"}
                            select
                            onChange={(e) => {
                                formik.setFieldValue('legal_authority_to_work', e.target.value === "Yes")
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.legal_authority_to_work && Boolean(formik.errors.legal_authority_to_work) ? "red" : "green",
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.legal_authority_to_work && Boolean(formik.errors.legal_authority_to_work) ? "red" : "green",
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.legal_authority_to_work && Boolean(formik.errors.legal_authority_to_work) ? "red" : "green",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                            InputProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }
                            }}
                        >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                            Date of Birth
                        </Typography>
                        <TextField
                            fullWidth
                            id="date_of_birth"
                            placeholder='Date of Birth'
                            size="small"
                            variant="outlined"
                            type='date'
                            {...formik.getFieldProps('date_of_birth')}
                            className={formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth) ? "green" : "red"}
                            error={formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)}
                            helperText={formik.touched.date_of_birth && formik.errors.date_of_birth}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth) ? "red" : "green",
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth) ? "red" : "green",
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth) ? "red" : "green",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={member?.terminated_at || data?.date_of_birth ? true : false}
                            InputProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                            Have you worked for this company before?
                        </Typography>
                        <TextField
                            fullWidth
                            id="worked_for_company_before"
                            placeholder='Have you worked for this company before?'
                            size="small"
                            variant="outlined"
                            value={formik.values.worked_for_company_before ? "Yes" : "No"}
                            select
                            onChange={(e) => {
                                formik.setFieldValue('worked_for_company_before', e.target.value === "Yes")
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.worked_for_company_before && Boolean(formik.errors.worked_for_company_before) ? "red" : "green",
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.worked_for_company_before && Boolean(formik.errors.worked_for_company_before) ? "red" : "green",
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.worked_for_company_before && Boolean(formik.errors.worked_for_company_before) ? "red" : "green",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={member?.terminated_at || data?.worked_for_company_before !== undefined ? true : false}
                            InputProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }
                            }}
                        >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </TextField>
                    </Grid>
                    {formik.values.worked_for_company_before && (
                        <>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                    Where
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="previous_company"
                                    placeholder='Where'
                                    size="small"
                                    variant="outlined"
                                    {...formik.getFieldProps('previous_company')}
                                    className={formik.touched.previous_company && Boolean(formik.errors.previous_company) ? "green" : "red"}
                                    error={formik.touched.previous_company && Boolean(formik.errors.previous_company)}
                                    helperText={formik.touched.previous_company && formik.errors.previous_company}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.previous_company && Boolean(formik.errors.previous_company) ? "red" : "green",
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.previous_company && Boolean(formik.errors.previous_company) ? "red" : "green",
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.previous_company && Boolean(formik.errors.previous_company) ? "red" : "green",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                    disabled={member?.terminated_at || data?.previous_company ? true : false}
                                    InputProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                    From
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="previous_company_from"
                                    placeholder='From'
                                    size="small"
                                    variant="outlined"
                                    type='date'
                                    {...formik.getFieldProps('previous_company_from')}
                                    className={formik.touched.previous_company_from && Boolean(formik.errors.previous_company_from) ? "green" : "red"}
                                    error={formik.touched.previous_company_from && Boolean(formik.errors.previous_company_from)}
                                    helperText={formik.touched.previous_company_from && formik.errors.previous_company_from}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.previous_company_from && Boolean(formik.errors.previous_company_from) ? "red" : "green",
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.previous_company_from && Boolean(formik.errors.previous_company_from) ? "red" : "green",
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.previous_company_from && Boolean(formik.errors.previous_company_from) ? "red" : "green",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                    disabled={member?.terminated_at || data?.previous_company_from ? true : false}
                                    InputProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                    To
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="previous_company_to"
                                    placeholder='To'
                                    size="small"
                                    variant="outlined"
                                    type='date'
                                    {...formik.getFieldProps('previous_company_to')}
                                    className={formik.touched.previous_company_to && Boolean(formik.errors.previous_company_to) ? "green" : "red"}
                                    error={formik.touched.previous_company_to && Boolean(formik.errors.previous_company_to)}
                                    helperText={formik.touched.previous_company_to && formik.errors.previous_company_to}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.previous_company_to && Boolean(formik.errors.previous_company_to) ? "red" : "green",
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.previous_company_to && Boolean(formik.errors.previous_company_to) ? "red" : "green",
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.previous_company_to && Boolean(formik.errors.previous_company_to) ? "red" : "green",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                    disabled={member?.terminated_at || data?.previous_company_to ? true : false}
                                    InputProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                    Position
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="previous_company_position"
                                    placeholder='Position'
                                    size="small"
                                    variant="outlined"
                                    {...formik.getFieldProps('previous_company_position')}
                                    className={formik.touched.previous_company_position && Boolean(formik.errors.previous_company_position) ? "green" : "red"}
                                    error={formik.touched.previous_company_position && Boolean(formik.errors.previous_company_position)}
                                    helperText={formik.touched.previous_company_position && formik.errors.previous_company_position}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.previous_company_position && Boolean(formik.errors.previous_company_position) ? "red" : "green",
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.previous_company_position && Boolean(formik.errors.previous_company_position) ? "red" : "green",
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.previous_company_position && Boolean(formik.errors.previous_company_position) ? "red" : "green",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                    disabled={member?.terminated_at || data?.previous_company_position ? true : false}
                                    InputProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                    Reason For Leaving
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="reason_for_leaving"
                                    placeholder='Reason For Leaving'
                                    size="small"
                                    variant="outlined"
                                    {...formik.getFieldProps('reason_for_leaving')}
                                    className={formik.touched.reason_for_leaving && Boolean(formik.errors.reason_for_leaving) ? "green" : "red"}
                                    error={formik.touched.reason_for_leaving && Boolean(formik.errors.reason_for_leaving)}
                                    helperText={formik.touched.reason_for_leaving && formik.errors.reason_for_leaving}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.reason_for_leaving && Boolean(formik.errors.reason_for_leaving) ? "red" : "green",
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.reason_for_leaving && Boolean(formik.errors.reason_for_leaving) ? "red" : "green",
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.reason_for_leaving && Boolean(formik.errors.reason_for_leaving) ? "red" : "green",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                    disabled={member?.terminated_at || data?.reason_for_leaving ? true : false}
                                    InputProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                    Who Referred You?
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="referred_by"
                                    placeholder='Who Referred You?'
                                    size="small"
                                    variant="outlined"
                                    {...formik.getFieldProps('referred_by')}
                                    className={formik.touched.referred_by && Boolean(formik.errors.referred_by) ? "green" : "red"}
                                    error={formik.touched.referred_by && Boolean(formik.errors.referred_by)}
                                    helperText={formik.touched.referred_by && formik.errors.referred_by}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.referred_by && Boolean(formik.errors.referred_by) ? "red" : "green",
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.referred_by && Boolean(formik.errors.referred_by) ? "red" : "green",
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.referred_by && Boolean(formik.errors.referred_by) ? "red" : "green",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                    disabled={member?.terminated_at || data?.referred_by ? true : false}
                                    InputProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                            Have you ever been bonded?
                        </Typography>
                        <TextField
                            fullWidth
                            id="bonded"
                            placeholder='Have you ever been bonded?'
                            size="small"
                            variant="outlined"
                            value={formik.values.bonded ? "Yes" : "No"}
                            select
                            onChange={(e) => {
                                formik.setFieldValue('bonded', e.target.value === "Yes")
                            }}
                            helperText={"(Answer only if job requires)"}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.bonded && Boolean(formik.errors.bonded) ? "red" : "green",
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.bonded && Boolean(formik.errors.bonded) ? "red" : "green",
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.bonded && Boolean(formik.errors.bonded) ? "red" : "green",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={!!member?.terminated_at}
                            InputProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }
                            }}
                            FormHelperTextProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }
                            }}
                        >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                            Can you perform, with or without reasonable accommodation, the essential functions of the job?
                        </Typography>
                        <TextField
                            fullWidth
                            id="bonded"
                            placeholder='Can you perform, with or without reasonable accommodation, the essential functions of the job?'
                            size="small"
                            variant="outlined"
                            value={formik.values.essential_job_functions ? "Yes" : "No"}
                            select
                            onChange={(e) => {
                                formik.setFieldValue('essential_job_functions', e.target.value === "Yes")
                            }}
                            helperText={"(Answer only if job requires)"}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.essential_job_functions && Boolean(formik.errors.essential_job_functions) ? "red" : "green",
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.essential_job_functions && Boolean(formik.errors.essential_job_functions) ? "red" : "green",
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.essential_job_functions && Boolean(formik.errors.essential_job_functions) ? "red" : "green",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={!!member?.terminated_at}
                            InputProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }
                            }}
                            FormHelperTextProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }
                            }}
                        >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                            Do you have any known criminal / civil records?
                        </Typography>
                        <TextField
                            fullWidth
                            id="criminal_record"
                            placeholder='Do you have any known criminal / civil records?'
                            size="small"
                            variant="outlined"
                            value={formik.values.criminal_record ? "Yes" : "No"}
                            select
                            onChange={(e) => {
                                formik.setFieldValue('criminal_record', e.target.value === "Yes")
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.criminal_record && Boolean(formik.errors.criminal_record) ? "red" : "green",
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.criminal_record && Boolean(formik.errors.criminal_record) ? "red" : "green",
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.criminal_record && Boolean(formik.errors.criminal_record) ? "red" : "green",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={!!member?.terminated_at}
                            InputProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }
                            }}
                        >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </TextField>
                    </Grid>
                    {!member?.pre_employment_screened || (member?.pre_employment_screened && member.pre_employment_investigation.length > 2) ? <Grid item xs={12} sm={12} mb={1}>
                        <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                            Have you worked for another agency under the Department of Transportation (DOT) in the past 3 years, other than the FMCSA? For example, the Federal Transit Authority (FTA) or the Federal Aviation Administration (FAA).
                        </Typography>
                        <TextField
                            fullWidth
                            id="worked_for_fta"
                            size="small"
                            variant="outlined"
                            value={formik.values.worked_for_fta ? "Yes" : "No"}
                            select
                            onChange={(e) => {
                                formik.setFieldValue('worked_for_fta', e.target.value === "Yes")
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.worked_for_fta && Boolean(formik.errors.worked_for_fta) ? "red" : "green",
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.worked_for_fta && Boolean(formik.errors.worked_for_fta) ? "red" : "green",
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.worked_for_fta && Boolean(formik.errors.worked_for_fta) ? "red" : "green",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                            InputProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }
                            }}
                        >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </TextField>
                    </Grid> : null}
                    {(!data || (currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role))) && !isDriverFile ? (
                        <Grid item xs={12} sm={12}>
                            <Button fullWidth disabled={isCreating || isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                                Save
                            </Button>
                            {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                                <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                                    Once you click save, you will not be able to edit this form.
                                </Typography>
                            )}
                        </Grid>
                    ) : null}
                    {data && currentUser?.role === "SuperAdmin" && <Grid item xs={12} sm={12}>
                        <Button fullWidth disabled={!!member?.terminated_at} onClick={() => {
                            const res = window.confirm("Are you sure you want to delete this form?")
                            if (res && data) {
                                deleteApplicantInformation(data.id)
                            }
                        }} variant="contained" color="primary">
                            Delete Form
                        </Button>
                    </Grid>}
                </Box>
            </div>
            {(!member?.pre_employment_screened || (member?.pre_employment_screened && member.pre_employment_investigation.length > 2)) && data?.worked_for_fta ? (
                <div style={{
                    padding: 10,
                    margin: 10
                }}>
                    <FTADetails applicant={data} member={member} />
                </div>
            ) : null}
        </>
    )
}

export default ApplicantInformation