import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import { IProcessRecord, IProcessRecordDto } from '../../interfaces/DriversApplicationForEmployment/process.record';

export const processRecordApi = createApi({
    reducerPath: 'process_record',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/process-record`
    }),
    tagTypes: ['ProcessRecord'],
    endpoints: (builder) => ({
        createProcessRecord: builder.mutation<IProcessRecord, IProcessRecordDto>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'ProcessRecord', id: 'ProcessRecord' }]
        }),
        updateProcessRecord: builder.mutation<IProcessRecord, IProcessRecord>({
            query: (body) => ({
                url: `/${body.id}`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'ProcessRecord', id: 'ProcessRecord' }]
        }),
        deleteProcessRecord: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'ProcessRecord', id: 'ProcessRecord' }]
        }),
        getProcessRecord: builder.mutation<IProcessRecord, number | void>({
            query: (id) => ({
                url: '/process-record/',
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            })
            // invalidatesTags: [{ type: "ProcessRecord", id: "ProcessRecord" }],
        }),
        getProcessRecordByUser: builder.query<IProcessRecord, void>({
            query: () => ({
                url: '/get-process-record-by-user/',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ProcessRecord', id: 'ProcessRecord' }]
        }),
        getProcessRecordByUserId: builder.query<IProcessRecord, number | void>({
            query: (id) => ({
                url: '/get-process-record-by-user-id/',
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ProcessRecord', id: 'ProcessRecord' }]
        })
    })
});

export const {
    useCreateProcessRecordMutation,
    useUpdateProcessRecordMutation,
    useGetProcessRecordMutation,
    useGetProcessRecordByUserQuery,
    useGetProcessRecordByUserIdQuery,
    useDeleteProcessRecordMutation
} = processRecordApi;
