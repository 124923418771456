import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

interface ILinkRow {
    name: string;
    path: string;
    show?: boolean;
}

export default function LinkRow({ links }: { links: ILinkRow[] }) {
    const location = useLocation();

    return (
        <div className="d-flex overflow-auto h-55px">
            {links.filter((x) => x.show).map((link, index) => (
                <ul
                    key={index}
                    className="nav nav-stretch nav-line-tabs nav-line-tabs-3x border-transparent fs-5 fw-bolder flex-nowrap"
                >
                    <li className="nav-item">
                        <Link
                            className={
                                `nav-link text-active-primary me-10 ` + (location.pathname.includes(link.path) && 'active')
                            }
                            to={link.path}
                        >
                            {link.name}
                        </Link>
                    </li>
                </ul>
            ))}
        </div>
    );
}
