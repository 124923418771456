import React from 'react'
import { IMember } from '../../../models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material'
import {
    IProvidedEducationMaterialsDto
} from '../../../store/interfaces/DrugandAlcoholEmployeesCertifiedReceipt/provided-education-materials'
import {
    useCreateProvidedEducationMaterialsMutation,
    useUpdateProvidedEducationMaterialsMutation,
    useGetProvidedEducationMaterialsByUserIdQuery
} from '../../../store/reducers/DrugandAlcoholEmployeesCertifiedReceipt/provided-educational-materials'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'


const initialValues: Partial<IProvidedEducationMaterialsDto> = {
    designated_to_answer_questions: false,
    categories_of_drivers: false,
    safety_sensitive_functions: false,
    specific_information_concerning_driver_conduct: false,
    circumstances_under_which_driver_will_be_tested: false,
    test_procedures: false,
    requirement_that_drivers_submit_to_tests: false,
    explanation_of_consiquences: false,
    consiquenses_for_part_382_violations: false,
    consiquences_for_alcohol: false,
    information_on_effects_controlled_substances: false,
    other_information: '',
}


const validationSchema = Yup.object({
})


interface IProps {
    member?: IMember
    isDriverFile?: boolean
}


const ProvidedEducationalMaterials = ({ member, isDriverFile }: IProps) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data } = useGetProvidedEducationMaterialsByUserIdQuery(member?.user?.id!, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })

    const [createProvidedEducationMaterials, { isLoading: isCreating }] = useCreateProvidedEducationMaterialsMutation()
    const [updateProvidedEducationMaterials, { isLoading: isUpdating }] = useUpdateProvidedEducationMaterialsMutation()

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                updateProvidedEducationMaterials({
                    ...data,
                    ...values
                })
            } else {
                createProvidedEducationMaterials({
                    ...values,
                    // @ts-ignore
                    company: member?.company?.id,
                    // @ts-ignore
                    member: member?.id,
                })
            }
            resetForm()
        },
        validateOnBlur: true,
        validateOnChange: true,
    })

    React.useEffect(() => {
        if (data) {
            formik.setValues(data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                Provided Educational Materials
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify" }} variant="body1" gutterBottom component="p">
                This is to certify that I have been provided educational materials required by Sec.
                382.601 and employer policies with respect to meeting the Part 382 requirements.
                The materials I've been provided include detailed discussion of the following selected "items"
            </Typography>
            {/* <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify" }} variant="body1" gutterBottom component="p">
                Select All That Apply
            </Typography> */}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formik.values.designated_to_answer_questions}
                                sx={{
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }}
                                disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                            />
                        }
                        label="The designated person to answer questions about the material"
                        onChange={(e) => formik.setFieldValue('designated_to_answer_questions', !formik.values.designated_to_answer_questions)}
                        sx={{
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formik.values.categories_of_drivers}
                                sx={{
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }}
                                disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                            />
                        }
                        label="The categories of drivers subject to Part 382."
                        onChange={(e) => formik.setFieldValue('categories_of_drivers', !formik.values.categories_of_drivers)}
                        sx={{
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formik.values.safety_sensitive_functions}
                                sx={{
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }}
                                disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                            />
                        }
                        label="The safety-sensitive functions and periods of the workday for which compliance is required."
                        onChange={(e) => formik.setFieldValue('safety_sensitive_functions', !formik.values.safety_sensitive_functions)}
                        sx={{
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formik.values.specific_information_concerning_driver_conduct}
                                sx={{
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }}
                                disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                            />
                        }
                        label="Specific information concerning prohibited driver conduct"
                        onChange={(e) => formik.setFieldValue('specific_information_concerning_driver_conduct', !formik.values.specific_information_concerning_driver_conduct)}
                        sx={{
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formik.values.circumstances_under_which_driver_will_be_tested}
                                sx={{
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }}
                                disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                            />
                        }
                        label="Circumstances under which a driver will be tested"
                        onChange={(e) => formik.setFieldValue('circumstances_under_which_driver_will_be_tested', !formik.values.circumstances_under_which_driver_will_be_tested)}
                        sx={{
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formik.values.test_procedures}
                                sx={{
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }}
                                disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                            />
                        }
                        label="Test procedures, driver protection and integrity of the testing procedures, and safeguarding the validity of the test."
                        onChange={(e) => formik.setFieldValue('test_procedures', !formik.values.test_procedures)}
                        sx={{
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formik.values.requirement_that_drivers_submit_to_tests}
                                sx={{
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }}
                                disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                            />
                        }
                        label="The requirement that drivers submit to tests administered in accordance with Part 382"
                        onChange={(e) => formik.setFieldValue('requirement_that_drivers_submit_to_tests', !formik.values.requirement_that_drivers_submit_to_tests)}
                        sx={{
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formik.values.explanation_of_consiquences}
                                sx={{
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }}
                                disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                            />
                        }
                        label="An explanation of what will be considered a refusal to submit to a test and the consequences"
                        onChange={(e) => formik.setFieldValue('explanation_of_consiquences', !formik.values.explanation_of_consiquences)}
                        sx={{
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formik.values.consiquences_for_alcohol}
                                sx={{
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }}
                                disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                            />
                        }
                        label="The consequences for drivers found to have an alcohol concentration of 0.02 or greater but less than 0.04."
                        onChange={(e) => formik.setFieldValue('consiquences_for_alcohol', !formik.values.consiquences_for_alcohol)}
                        sx={{
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formik.values.information_on_effects_controlled_substances}
                                sx={{
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }}
                                disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                            />
                        }
                        label="Information on any of the following: The effects of alcohol and controlled substances
            use on an individual's health, work, or personal life. Signs and symptoms of a problem.
            Available methods of intervening when a problem is suspected (confrontation, referral, etc.)."
                        onChange={(e) => formik.setFieldValue('information_on_effects_controlled_substances', !formik.values.information_on_effects_controlled_substances)}
                        sx={{
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Other Information:
                    </Typography>
                    <TextField
                        fullWidth
                        id="other_information"
                        placeholder='Other Information'
                        multiline
                        rows={4}
                        size="small"
                        variant="outlined"
                        value={formik.values.other_information}
                        onChange={(e) => {
                            formik.setFieldValue('other_information', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.other_information && Boolean(formik.errors.other_information) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.other_information && Boolean(formik.errors.other_information) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.other_information && Boolean(formik.errors.other_information) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                {
                    (!data || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role))) && !isDriverFile ? <Grid item xs={12} sm={12}>
                        <Button fullWidth disabled={isCreating || isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                            Save
                        </Button>
                        {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                            <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                                Once you click save, you will not be able to edit this form.
                            </Typography>
                        )}
                    </Grid > : null}
            </Grid >
        </div >
    )
}

export default ProvidedEducationalMaterials