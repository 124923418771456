import { Visibility, VisibilityOff } from '@mui/icons-material';
import * as Yup from "yup"
import { useFormik } from 'formik';
import { Box, Button, Container, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Typography } from '@mui/material'
import React from 'react'
import { changePassword } from '../../modules/auth/core/_requests';
import { toast } from 'react-toastify';
import { PasswordMeterComponent } from '../../../compliancemasters/assets/ts/components';

const initialValues = {
    password: "",
    confirmPassword: ""
}

const validationSchema = Yup.object({
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters long'),

    confirmPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
});

const PasswordReset = () => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const urlParams = new URLSearchParams(window.location.search);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            const token = urlParams.get("token")
            if (token) {
                changePassword(values.password, token.split('?')[0]).then(() => {
                    toast.success("Password changed successfully")
                    setTimeout(() => {
                        window.location.assign("/auth/login")
                    }, 3000)
                }).catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong")
                    formik.setSubmitting(false)
                })
            }
        }
    })

    React.useEffect(() => {
        PasswordMeterComponent.bootstrap();
    }, []);

    return (
        <Container>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}>
                <Box component={Paper} sx={{
                    p: 4,
                    width: {
                        xs: '90%',
                        sm: '50%',
                        md: '40%',
                        lg: '30%',
                    }
                }}>
                    <Typography variant="h4" align="center">Password Reset</Typography>
                    <Grid container spacing={3} mt={3}>
                        <Grid item sm={12}>
                            <FormControl fullWidth size="small" variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                    {...formik.getFieldProps("password")}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item sm={12}>
                            <FormControl fullWidth size="small" variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Repeat Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Repeat Password"
                                    {...formik.getFieldProps("confirmPassword")}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item sm={12}>
                            <Button
                                fullWidth
                                size="small"
                                variant="contained"
                                disabled={!formik.isValid || formik.isSubmitting}
                                onClick={() => formik.handleSubmit()}
                            >
                                Reset Password
                            </Button>
                        </Grid>
                        <Grid item sm={12}>
                            <Button
                                fullWidth
                                size="small"
                                variant="text"
                                onClick={() => window.location.assign("/auth/login")}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}

export default PasswordReset