/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';
import Button from '../../../../../app/modules/buttons/Button';
import { KTSVG } from '../../../../helpers';


function formatBytes(bytes: number): string {
    const sizes = ['Bytes', 'Kb', 'Mb', 'Gb'];
    if (bytes === 0) return '0 Byte';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
}

interface IProps {
    value?: File;
    values?: File[];
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClear: () => void;
    classNames?: string;
}
export default function FileInput({ value, onChange, onClear, values, classNames }: IProps) {
    const inputFile = useRef<HTMLInputElement>(null);
    const [fileNames, setFileNames] = React.useState<string>();

    function handleUploadFile() {
        inputFile.current?.click();
    }

    React.useEffect(() => {
        if (value) {
            setFileNames(value.name);
        } else {
            setFileNames('');
        }
    }, [value]);

    React.useEffect(() => {
        if (values && values?.length > 0) {
            //  Add file names to the state separated by commas
            setFileNames(values.map((item) => item.name).join(', '));
        } else {
            setFileNames('');
        }
    }, [values]);

    return (
        <>
            {value ? (
                <div className="d-flex pb-5">
                    <div className="symbol symbol-50px me-5">
                        <span className="symbol-label bg-light">
                            <KTSVG
                                path="/media/icons/duotune/files/fil005.svg"
                                className="svg-icon-1 svg-icon-gray-500"
                            />
                        </span>
                    </div>
                    <div className="d-flex flex-column">
                        <a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bolder">
                            {fileNames ? fileNames : 'No file chosen'}
                        </a>
                        <span className="text-gray-400 fw-bold fs-7">{formatBytes(value?.size)}</span>
                    </div>
                    <div className="ms-auto">
                        <Button className={'btn btn-outline-primary'} onClick={onClear}>
                            ⅹ
                        </Button>
                    </div>
                </div>
            ) : null}
            {values ? (
                <div className="d-flex pb-5">
                    <div className="symbol symbol-50px me-5">
                        <span className="symbol-label bg-light">
                            <KTSVG
                                path="/media/icons/duotune/files/fil005.svg"
                                className="svg-icon-1 svg-icon-gray-500"
                            />
                        </span>
                    </div>
                    <div className="d-flex flex-column">
                        <a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bolder">
                            {fileNames ? fileNames : 'No files chosen'}
                        </a>
                        <span className="text-gray-400 fw-bold fs-7">{formatBytes(values.reduce((a, b) => b.size + a, 0))}</span>
                    </div>
                    <div className="ms-auto">
                        <Button className={'btn btn-outline-primary'} onClick={onClear}>
                            ⅹ
                        </Button>
                    </div>
                </div>
            ) : null}
            <div>
                <input
                    type="file"
                    name="avatar"
                    accept=".png, .jpg, .jpeg, .pdf"
                    style={{ display: 'none' }}
                    ref={inputFile}
                    onChange={onChange}
                    multiple={true}
                />
                <Button className={classNames} onClick={handleUploadFile}>Choose File</Button>
            </div>

            <div className="form-text">Allowed file types: png, jpg, jpeg. .pdf</div>
        </>
    );
}
