import React from 'react'
import { useParams } from 'react-router-dom';
import { IMember } from '../../../models';
import { getCompanyMember } from '../../../shared';
import { Box, Button, Container, Paper } from '@mui/material';
import FormHeaderCard from '../HeaderCard';
import DriverAuthorizationAndSignature from './DriverAuthorizationAndSignature';
import ActionButtons from '../ActionButtons';
import { useGetLimitedQueriesDachProgressMutation, useGetMemberLimitedQueriesDachProgressMutation } from '../../../store/reducers/LimitedQueriesDrugAndAlcoholClearinghouse/progress';
import { useThemeMode } from '../../partials';
import { useAuth } from '../../../app/modules/auth';
import { StripeProduct } from '../../../app/modules/models';

const DriverAuthorization = ({ subscription }: { subscription?: StripeProduct }) => {
    const { mode } = useThemeMode()
    const [getProgress, { data: progress }] = useGetLimitedQueriesDachProgressMutation()
    const [getMemberProgress, { data: memberProgress }] = useGetMemberLimitedQueriesDachProgressMutation()
    const [member, setMember] = React.useState<IMember>()
    const { id } = useParams()
    const [value, setValue] = React.useState(0);
    const { currentUser } = useAuth()

    React.useEffect(() => {
        if (id) {
            getCompanyMember(id).then((data) => {
                setMember(data)
            })
            getProgress(Number(id))
            getMemberProgress(Number(id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Container style={{ userSelect: 'none' }}>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <FormHeaderCard
                    headerTitle='Consent for Limited Queries Of The FMCSA Drug And Alcohol Clearinghouse'
                    status={progress && (progress?.progress > 0 && progress?.progress < 100) ? "In Progress" : progress && progress.progress > 99 ? "Completed" : "Not Started"}
                    sectionCount={4}
                    itemCount={29}
                    totalProgress={progress ? progress.progress : 0}
                    memberProgress={memberProgress ? memberProgress.progress : 0}
                    nextButton={{ title: "Safety and Performance History", path: "" }}
                    member={member}
                    setValue={setValue}
                    value={value}

                />
            </Box>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <DriverAuthorizationAndSignature subscription={subscription} member={member} />
            </Box>
            <ActionButtons
                member={member}
                nextButton={{
                    title: subscription?.name === "Drug and Alcohol Package" ? "" : (member?.pre_employment_screened || currentUser?.member?.pre_employment_screened) ? "Previous Pre-employment employee drug and alcohol test statement" : "Safety and Performance History",
                    path: subscription?.name === "Drug and Alcohol Package" ? "" : (member?.pre_employment_screened || currentUser?.member?.pre_employment_screened) ? "previous-pre-employment-employee-drug-and-alcohol-test-statement" : "previous-employee-safety-performance-history"
                }}
                prevButton={{
                    title: currentUser?.role === "Member" ? "driver-handbook-receipt" : subscription?.name === "Drug and Alcohol Package" ? "" : "Certificate of Road Test",
                    path: subscription?.name === "Drug and Alcohol Package" ? "" : "certificate-of-road-test"
                }}
            />
            <div style={{
                display: "flex",
                width: "47vw",
                justifyContent: "center",
                alignContent: "center",
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 10,
            }}>
                <Button variant='contained' fullWidth onClick={() => {
                    if (currentUser?.role === "Member") {
                        window.location.assign(`/dashboard/overview`)
                    } else if (member?.id) {
                        window.location.assign(`/member/${member?.id}/profile/driver-documents`)
                    }
                }}>
                    Back to Forms Overview
                </Button>
            </div>
        </Container>
    )
}

export default DriverAuthorization