import React from 'react'
import { IMember } from '../../../models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material'
import {
    ICouplingAndUncouplingDto
} from '../../../store/interfaces/RecordOfRoadTest/coupling-and-uncoupling'
import {
    useCreateCouplingAndUncouplingMutation,
    useUpdateCouplingAndUncouplingMutation,
    useGetCouplingAndUncouplingByUserIdQuery,
    useDeleteCouplingAndUncouplingMutation
} from '../../../store/reducers/RecordOfRoadTest/coupling-and-uncoupling'
import { InspectionTextChoices } from '../../../store/interfaces/RecordOfRoadTest/backing-and-parking'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'


const initialValues: Partial<ICouplingAndUncouplingDto> = {
    line_up_units: InspectionTextChoices.NA,
    connect_glad_hands_to_trailer_before_coupling: InspectionTextChoices.NA,
    connect_glad_hands_and_light_line_properly: InspectionTextChoices.NA,
    couples_without_difficulty: InspectionTextChoices.NA,
    raises_landing_gear_fully_after_coupling: InspectionTextChoices.NA,
    visually_checks_king_pin_assembly_to_be_certain_of_proper: InspectionTextChoices.NA,
    checks_coupling_by_applying_hand_valve: InspectionTextChoices.NA,
    assure_surface_will_support_pressure_before_uncoupling: InspectionTextChoices.NA,
    remarks: '',
}

const validationSchema = Yup.object({
    line_up_units: Yup.string().required('Required'),
    connect_glad_hands_to_trailer_before_coupling: Yup.string().required('Required'),
    connect_glad_hands_and_light_line_properly: Yup.string().required('Required'),
    couples_without_difficulty: Yup.string().required('Required'),
    raises_landing_gear_fully_after_coupling: Yup.string().required('Required'),
    visually_checks_king_pin_assembly_to_be_certain_of_proper: Yup.string().required('Required'),
    checks_coupling_by_applying_hand_valve: Yup.string().required('Required'),
    assure_surface_will_support_pressure_before_uncoupling: Yup.string().required('Required'),
})


const CouplingAndUncoupling = ({ member, isDriverFile }: { member?: IMember, isDriverFile?: boolean }) => {
    const { currentUser } = useAuth()
    const { mode } = useThemeMode()
    const { data } = useGetCouplingAndUncouplingByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    })
    const [createCouplingAndUncoupling, { isLoading: isCreating }] = useCreateCouplingAndUncouplingMutation()
    const [updateCouplingAndUncoupling, { isLoading: isUpdating }] = useUpdateCouplingAndUncouplingMutation()
    const [deleteCouplingAndUncoupling] = useDeleteCouplingAndUncouplingMutation()

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                updateCouplingAndUncoupling({
                    ...values,
                    id: data.id
                })
            } else {
                // @ts-ignore
                createCouplingAndUncoupling({
                    ...values,
                    // @ts-ignore
                    company: member?.company?.id,
                    // @ts-ignore
                    member: member?.id,
                })
            }
            resetForm()
        },
        validateOnBlur: true,
        validateOnChange: true,
    })

    React.useEffect(() => {
        if (data) {
            formik.setValues(data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const setAllAsSatisfactory = () => {
        formik.setValues({
            assure_surface_will_support_pressure_before_uncoupling: InspectionTextChoices.SATISFACTORY,
            checks_coupling_by_applying_hand_valve: InspectionTextChoices.SATISFACTORY,
            connect_glad_hands_and_light_line_properly: InspectionTextChoices.SATISFACTORY,
            connect_glad_hands_to_trailer_before_coupling: InspectionTextChoices.SATISFACTORY,
            couples_without_difficulty: InspectionTextChoices.SATISFACTORY,
            line_up_units: InspectionTextChoices.SATISFACTORY,
            raises_landing_gear_fully_after_coupling: InspectionTextChoices.SATISFACTORY,
            visually_checks_king_pin_assembly_to_be_certain_of_proper: InspectionTextChoices.SATISFACTORY,
        })
    }

    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                Coupling and Uncoupling (Trucks Only)
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify" }} variant="body1" gutterBottom component="p">
                For the items that apply, please mark the driver's performance accordingly.
                Explain unsatisfactory items under Remarks.
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Button size="small" fullWidth variant="contained" onClick={setAllAsSatisfactory}>
                        Set all As satisfactory
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Lines up units
                    </Typography>
                    <TextField
                        fullWidth
                        id="line_up_units"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.line_up_units}
                        onChange={(e) => {
                            formik.setFieldValue('line_up_units', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.line_up_units && Boolean(formik.errors.line_up_units) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.line_up_units && Boolean(formik.errors.line_up_units) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.line_up_units && Boolean(formik.errors.line_up_units) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Connects glad hands to trailer to apply trailer brakes before coupling
                    </Typography>
                    <TextField
                        fullWidth
                        id="connect_glad_hands_to_trailer_before_coupling"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.connect_glad_hands_to_trailer_before_coupling}
                        onChange={(e) => {
                            formik.setFieldValue('connect_glad_hands_to_trailer_before_coupling', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.connect_glad_hands_to_trailer_before_coupling && Boolean(formik.errors.connect_glad_hands_to_trailer_before_coupling) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.connect_glad_hands_to_trailer_before_coupling && Boolean(formik.errors.connect_glad_hands_to_trailer_before_coupling) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.connect_glad_hands_to_trailer_before_coupling && Boolean(formik.errors.connect_glad_hands_to_trailer_before_coupling) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Connects glad hands and light line properly
                    </Typography>
                    <TextField
                        fullWidth
                        id="connect_glad_hands_and_light_line_properly"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.connect_glad_hands_and_light_line_properly}
                        onChange={(e) => {
                            formik.setFieldValue('connect_glad_hands_and_light_line_properly', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.connect_glad_hands_and_light_line_properly && Boolean(formik.errors.connect_glad_hands_and_light_line_properly) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.connect_glad_hands_and_light_line_properly && Boolean(formik.errors.connect_glad_hands_and_light_line_properly) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.connect_glad_hands_and_light_line_properly && Boolean(formik.errors.connect_glad_hands_and_light_line_properly) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Couples without difficulty
                    </Typography>
                    <TextField
                        fullWidth
                        id="couples_without_difficulty"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.couples_without_difficulty}
                        onChange={(e) => {
                            formik.setFieldValue('couples_without_difficulty', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.couples_without_difficulty && Boolean(formik.errors.couples_without_difficulty) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.couples_without_difficulty && Boolean(formik.errors.couples_without_difficulty) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.couples_without_difficulty && Boolean(formik.errors.couples_without_difficulty) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Raises landing gear fully after coupling
                    </Typography>
                    <TextField
                        fullWidth
                        id="raises_landing_gear_fully_after_coupling"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.raises_landing_gear_fully_after_coupling}
                        onChange={(e) => {
                            formik.setFieldValue('raises_landing_gear_fully_after_coupling', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.raises_landing_gear_fully_after_coupling && Boolean(formik.errors.raises_landing_gear_fully_after_coupling) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.raises_landing_gear_fully_after_coupling && Boolean(formik.errors.raises_landing_gear_fully_after_coupling) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.raises_landing_gear_fully_after_coupling && Boolean(formik.errors.raises_landing_gear_fully_after_coupling) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Visually checks king pin assembly to be certain of proper
                    </Typography>
                    <TextField
                        fullWidth
                        id="visually_checks_king_pin_assembly_to_be_certain_of_proper"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.visually_checks_king_pin_assembly_to_be_certain_of_proper}
                        onChange={(e) => {
                            formik.setFieldValue('visually_checks_king_pin_assembly_to_be_certain_of_proper', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.visually_checks_king_pin_assembly_to_be_certain_of_proper && Boolean(formik.errors.visually_checks_king_pin_assembly_to_be_certain_of_proper) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.visually_checks_king_pin_assembly_to_be_certain_of_proper && Boolean(formik.errors.visually_checks_king_pin_assembly_to_be_certain_of_proper) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.visually_checks_king_pin_assembly_to_be_certain_of_proper && Boolean(formik.errors.visually_checks_king_pin_assembly_to_be_certain_of_proper) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Checks coupling by applying hand valve or tractor-protection valve (trailer air supply valve) and gently applying pressure by trying to pull away from trailer
                    </Typography>
                    <TextField
                        fullWidth
                        id="checks_coupling_by_applying_hand_valve"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.checks_coupling_by_applying_hand_valve}
                        onChange={(e) => {
                            formik.setFieldValue('checks_coupling_by_applying_hand_valve', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.checks_coupling_by_applying_hand_valve && Boolean(formik.errors.checks_coupling_by_applying_hand_valve) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.checks_coupling_by_applying_hand_valve && Boolean(formik.errors.checks_coupling_by_applying_hand_valve) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.checks_coupling_by_applying_hand_valve && Boolean(formik.errors.checks_coupling_by_applying_hand_valve) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Assure that surface will support trailer before uncoupling
                    </Typography>
                    <TextField
                        fullWidth
                        id="assure_surface_will_support_pressure_before_uncoupling"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.assure_surface_will_support_pressure_before_uncoupling}
                        onChange={(e) => {
                            formik.setFieldValue('assure_surface_will_support_pressure_before_uncoupling', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.assure_surface_will_support_pressure_before_uncoupling && Boolean(formik.errors.assure_surface_will_support_pressure_before_uncoupling) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.assure_surface_will_support_pressure_before_uncoupling && Boolean(formik.errors.assure_surface_will_support_pressure_before_uncoupling) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.assure_surface_will_support_pressure_before_uncoupling && Boolean(formik.errors.assure_surface_will_support_pressure_before_uncoupling) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Remarks
                    </Typography>
                    <TextField
                        fullWidth
                        id="remarks"
                        size="small"
                        variant="outlined"
                        multiline
                        rows={3}
                        value={formik.values.remarks}
                        onChange={(e) => {
                            formik.setFieldValue('remarks', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.remarks && Boolean(formik.errors.remarks) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.remarks && Boolean(formik.errors.remarks) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.remarks && Boolean(formik.errors.remarks) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                {(!data || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role))) && !isDriverFile ? <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={isCreating || isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                        Save
                    </Button>
                    {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                        <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                            Once you click save, you will not be able to edit this form.
                        </Typography>
                    )}
                </Grid > : null}
                {data && currentUser?.role === "SuperAdmin" && <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={!!member?.terminated_at} onClick={() => {
                        const res = window.confirm("Are you sure you want to delete this form?")
                        if (res && data) {
                            deleteCouplingAndUncoupling(data.id)
                        }
                    }} variant="contained" color="primary">
                        Delete Form
                    </Button>
                </Grid>}
            </Grid >
        </div >
    )
}

export default CouplingAndUncoupling