import MemberRequestTable from '../../../compliancemasters/partials/widgets/tables/MemberTable/MemberRequestTable';

export default function MemberRequestDashboard() {
    return (
        <>
            <div className="row gy-5 g-xl-8">
                <div className="col-xl-12">
                    <MemberRequestTable />
                </div>
            </div>
        </>
    );
}
