import {ICompany, IMember} from '../../../models'
import {IAbstract} from '../abstract'

export enum EducationLevel {
  GRADE_SCHOOL_1 = 'Grade School 1',
  GRADE_SCHOOL_2 = 'Grade School 2',
  GRADE_SCHOOL_3 = 'Grade School 3',
  GRADE_SCHOOL_4 = 'Grade School 4',
  GRADE_SCHOOL_5 = 'Grade School 5',
  GRADE_SCHOOL_6 = 'Grade School 6',
  GRADE_SCHOOL_7 = 'Grade School 7',
  GRADE_SCHOOL_8 = 'Grade School 8',
  HIGH_SCHOOL_1 = 'High School 1',
  HIGH_SCHOOL_2 = 'High School 2',
  HIGH_SCHOOL_3 = 'High School 3',
  HIGH_SCHOOL_4 = 'High School 4',
  COLLEGE_1 = 'College 1 Year',
  COLLEGE_2 = 'College 2 Years',
  COLLEGE_3 = 'College 3 Years',
  COLLEGE_4 = 'College 4 Years',
  OVER_4 = 'Over 4 Years of College',
}

export interface IDriverEducationDto {
  education_level: EducationLevel

  school_name: string

  school_location: string

  company: ICompany

  member: IMember
}

export interface IDriverEducation extends IDriverEducationDto, IAbstract {}

export interface IDriverSignatureDto {
  signature: string
  date: string
  member: IMember
  company: ICompany
}

export interface IDriverSignature extends IDriverSignatureDto, IAbstract {}
