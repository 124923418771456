import React from 'react'
import { IMember } from '../../../models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Grid, TextField, Typography } from '@mui/material'
import {
    IDriverInformationDto
} from '../../../store/interfaces/RecordOfRoadTest/driver-information'
import {
    useCreateDriverInformationMutation,
    useUpdateDriverInformationMutation,
    useGetDriverInformationByUserIdQuery,
    useDeleteDriverInformationMutation
} from '../../../store/reducers/RecordOfRoadTest/driver-information'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'
import { useGetAddressQuery } from '../../../store/reducers/DriversApplicationForEmployment/applicant-information'
import { changeStringLast4 } from '../../../shared'


const initialValues: Partial<IDriverInformationDto> = {
    driver_name: '',
    address: '',
    driver_license_number: '',
    state: '',
    equipment_driven: "",
    tested_from: '',
    tested_to: '',
    date_of_road_test: '',
}

const validationSchema = Yup.object({
    driver_name: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    driver_license_number: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    equipment_driven: Yup.string().required('Required'),
    tested_from: Yup.string().required('Required'),
    tested_to: Yup.string().required('Required'),
    date_of_road_test: Yup.string().required('Required'),
})



const DriverInformation = ({ member, isDriverFile }: { member?: IMember, isDriverFile?: boolean }) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data } = useGetDriverInformationByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    })


    const { data: addresses = [] } = useGetAddressQuery(Number(member?.user?.id), {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    })

    const [createDriverInformation, { isLoading: isCreating }] = useCreateDriverInformationMutation()
    const [updateDriverInformation, { isLoading: isUpdating }] = useUpdateDriverInformationMutation()
    const [deleteDriverInformation] = useDeleteDriverInformationMutation()

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                await updateDriverInformation({
                    ...values,
                    id: data.id
                })
            } else {
                await createDriverInformation({
                    ...values,
                    // @ts-ignore
                    member: member?.id,
                    // @ts-ignore
                    company: member?.company?.id,
                })
            }
            resetForm()
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate: (values) => {
            const errors: Partial<IDriverInformationDto> = {}
            if (values.tested_from && values.tested_to) {
                if (new Date(values.tested_from) > new Date(values.tested_to)) {
                    errors.tested_from = 'Tested from cannot be greater than tested to'
                }
            }
            if (!values.driver_name) {
                errors.driver_name = 'Required'
            }
            if (!values.address) {
                errors.address = 'Required'
            }
            if (!values.driver_license_number) {
                errors.driver_license_number = 'Required'
            }
            if (!values.state) {
                errors.state = 'Required'
            }
            if (!values.equipment_driven) {
                // @ts-ignore
                errors.equipment_driven = 'Required'
            }
            if (!values.date_of_road_test) {
                errors.date_of_road_test = 'Required'
            }
            return errors
        }
    })

    React.useEffect(() => {
        if (data) {
            formik.setValues({
                ...data,
                driver_license_number: data?.driver_license_number
            })
        } else if (member) {
            const currentAddress = addresses?.find((address) => address?.is_current)
            formik.setValues({
                ...initialValues,
                driver_name: `${member?.user?.first_name} ${member?.user?.last_name}`,
                driver_license_number: member?.drivers_license_number,
                state: member?.state,
                address: currentAddress ? `${currentAddress?.city}, ${currentAddress?.state}, ${currentAddress?.zip_code} ` : ""
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, member, addresses])

    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                Driver Information
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify" }} variant="body1" gutterBottom component="p">
                Section 391.31 - The individual giving this road test will rate the performance of the driver
                for each section of the exam. Employers can digitally complete this form through their account.
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Employee's Name
                    </Typography>
                    <TextField
                        fullWidth
                        id="driver_name"
                        placeholder="Employee's Name"
                        size="small"
                        variant="outlined"
                        value={formik.values.driver_name}
                        // onChange={(e) => {
                        //     formik.setFieldValue('driver_name', e.target.value)
                        // }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.driver_name && Boolean(formik.errors.driver_name) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.driver_name && Boolean(formik.errors.driver_name) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.driver_name && Boolean(formik.errors.driver_name) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Address
                    </Typography>
                    <TextField
                        fullWidth
                        id="address"
                        placeholder='(City, State, Zip)'
                        size="small"
                        variant="outlined"
                        value={formik.values.address}
                        onChange={(e) => {
                            formik.setFieldValue('address', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.address && Boolean(formik.errors.address) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.address && Boolean(formik.errors.address) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.address && Boolean(formik.errors.address) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Driver License Number
                    </Typography>
                    <TextField
                        fullWidth
                        id="driver_license_number"
                        placeholder='Driver License Number'
                        size="small"
                        variant="outlined"
                        value={["Member", "Owner"].includes(currentUser?.role!) ? changeStringLast4(String(formik?.values?.driver_license_number), "*")! : formik.values.driver_license_number}
                        onChange={(e) => {
                            formik.setFieldValue('driver_license_number', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.driver_license_number && Boolean(formik.errors.driver_license_number) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.driver_license_number && Boolean(formik.errors.driver_license_number) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.driver_license_number && Boolean(formik.errors.driver_license_number) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        State
                    </Typography>
                    <TextField
                        fullWidth
                        id="state"
                        placeholder='State'
                        size="small"
                        variant="outlined"
                        value={formik.values.state}
                        onChange={(e) => {
                            formik.setFieldValue('state', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.state && Boolean(formik.errors.state) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.state && Boolean(formik.errors.state) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.state && Boolean(formik.errors.state) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Equipment Driven
                    </Typography>
                    <TextField
                        fullWidth
                        id="equipment_driven"
                        size="small"
                        variant="outlined"
                        value={formik.values.equipment_driven}
                        onChange={(e) => {
                            formik.setFieldValue('equipment_driven', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.equipment_driven && Boolean(formik.errors.equipment_driven) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.equipment_driven && Boolean(formik.errors.equipment_driven) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.equipment_driven && Boolean(formik.errors.equipment_driven) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Tested From (Location)
                    </Typography>
                    <TextField
                        fullWidth
                        id="tested_from"
                        size="small"
                        variant="outlined"
                        value={formik.values.tested_from}
                        placeholder='Tested From'
                        onChange={(e) => {
                            formik.setFieldValue('tested_from', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.tested_from && Boolean(formik.errors.tested_from) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.tested_from && Boolean(formik.errors.tested_from) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.tested_from && Boolean(formik.errors.tested_from) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >

                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Tested To (Location)
                    </Typography>
                    <TextField
                        fullWidth
                        id="tested_to"
                        size="small"
                        variant="outlined"
                        value={formik.values.tested_to}
                        placeholder='Tested To'
                        onChange={(e) => {
                            formik.setFieldValue('tested_to', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.tested_to && Boolean(formik.errors.tested_to) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.tested_to && Boolean(formik.errors.tested_to) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.tested_to && Boolean(formik.errors.tested_to) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Date of Road Test
                    </Typography>
                    <TextField
                        fullWidth
                        id="date_of_road_test"
                        size="small"
                        variant="outlined"
                        value={formik.values.date_of_road_test}
                        type='date'
                        onChange={(e) => {
                            formik.setFieldValue('date_of_road_test', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date_of_road_test && Boolean(formik.errors.date_of_road_test) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date_of_road_test && Boolean(formik.errors.date_of_road_test) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date_of_road_test && Boolean(formik.errors.date_of_road_test) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                {(!data || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role))) && !isDriverFile ? <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={isCreating || isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                        Save
                    </Button>
                    {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                        <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                            Once you click save, you will not be able to edit this form.
                        </Typography>
                    )}
                </Grid > : null}
                {data && currentUser?.role === "SuperAdmin" && <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={!!member?.terminated_at} onClick={() => {
                        const res = window.confirm("Are you sure you want to delete this form?")
                        if (res && data) {
                            deleteDriverInformation(data.id)
                        }
                    }} variant="contained" color="primary">
                        Delete Form
                    </Button>
                </Grid>}
            </Grid >
        </div >
    )
}

export default DriverInformation