/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { IMember } from '../../../../../../models';
import { downloadFile, useNavigation } from '../../../../../../shared';
import { useGetAnnualReviewOfDrivingRecordProgressMutation } from '../../../../../../store/reducers/AnnualReviewOfDrivingRecord/progress';
import { useGetCertofComplianceProgressMutation } from '../../../../../../store/reducers/CertificateOfComplianceWithDriverLicenseRequirements/progress';
import { useGetDriverMedicalVerificationProgressMutation } from '../../../../../../store/reducers/DriverMedicalExaminerRegistryVerification/progress';
import { useGetDriverStatementDutyHoursProgressMutation } from '../../../../../../store/reducers/DriverStatementDutyHours/progress';
import { useGetDriverApplicationProgressMutation } from '../../../../../../store/reducers/DriversApplicationForEmployment/progress';
import { useGetDriversHandbookReceiptProgressMutation } from '../../../../../../store/reducers/DriversHandbookReceipt/progress';
import { useGetDachEmployeesCertifiedReceiptProgressMutation } from '../../../../../../store/reducers/DrugandAlcoholEmployeesCertifiedReceipt/progress';
import { useGetEmploymentHistoryProgressMutation } from '../../../../../../store/reducers/EmploymentHistory/progress';
import { useGetLimitedQueriesDachProgressMutation } from '../../../../../../store/reducers/LimitedQueriesDrugAndAlcoholClearinghouse/progress';
import { useGetPrevPreEmploymentDachTestStatementProgressMutation } from '../../../../../../store/reducers/PreviousPreEmploymentDrugAndAlcoholTestStatement/progress';
import { useGetRequestForCheckOfDrivingRecordsProgressMutation } from '../../../../../../store/reducers/RequestForCheckOfDrivingRecords/progress';
import { toAbsoluteUrl } from '../../../../../helpers';
// import { useAuth } from '../../../../../../app/modules/auth';
import { useCompany } from '../../../../../../context/CompanyContext';
import { useSelector } from '../../../../../../store';
import { StripeActions } from '../../../../../../store/reducers/StripeProducts/StripeActions';
import { IconButton } from '@mui/material';
import { MoreVertRounded } from '@mui/icons-material';

interface IProps {
    member: IMember;
    product_name?: string;
    onActionComplete?: () => void;
    setMember?: (val: IMember) => void;
    setMenuOpen?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function MemberRow({ member, product_name, onActionComplete, setMember, setMenuOpen }: IProps) {

    const { currentCompany } = useCompany();
    // const { deleteUser } = useAuth();
    const [getAnnualReviewProgress, { data: annualReviewProgress }] = useGetAnnualReviewOfDrivingRecordProgressMutation()
    const [getCertOfComplianceProgress, { data: certOfComplianceProgress }] = useGetCertofComplianceProgressMutation()
    const [getMedicalExaminerInfo, { data: medicalExaminerInfo }] = useGetDriverMedicalVerificationProgressMutation()
    const [getDriverApplication, { data: driverApplication }] = useGetDriverApplicationProgressMutation()
    const [getDriverHandbookReceipt, { data: driverHandbookReceipt }] = useGetDriversHandbookReceiptProgressMutation()
    const [getDriverStatementDutyHours, { data: driverStatementDutyHours }] = useGetDriverStatementDutyHoursProgressMutation()
    const [getDachCertifiedReceipt, { data: dachCertifiedReceipt }] = useGetDachEmployeesCertifiedReceiptProgressMutation()
    const [getEmploymentHistory, { data: employmentHistory }] = useGetEmploymentHistoryProgressMutation()
    const [getLimitedQueries, { data: limitedQueries }] = useGetLimitedQueriesDachProgressMutation()
    const [getPrevDachTestStatement, { data: prevDachTestStatement }] = useGetPrevPreEmploymentDachTestStatementProgressMutation()
    const [getRequestForCheck, { data: requestForCheck }] = useGetRequestForCheckOfDrivingRecordsProgressMutation()
    const { navigateToCompanyMemberProfile } = useNavigation();
    // const [loadingDelete, setLoadingDelete] = React.useState(false);
    const [profileImage, setProfileImage] = React.useState<string>();
    const [dateCompleted, setDateCompleted] = React.useState<string>("")
    const { products } = useSelector((state) => state.stripeReducer?.product);
    function goToProfile() {
        // @ts-ignore
        navigateToCompanyMemberProfile(member.id, member.user.member.company);
    }

    // function handleDelete(type: 'soft' | 'hard') {
    //     setLoadingDelete(true);
    //     deleteUser(member?.user.email, type).then(() => {
    //         setLoadingDelete(false);
    //         if (onActionComplete) {
    //             onActionComplete();
    //         }
    //     });
    // }

    React.useEffect(() => {
        getAnnualReviewProgress(Number(member.id))
        getCertOfComplianceProgress(Number(member.id))
        getMedicalExaminerInfo(Number(member.id))
        getDriverApplication(Number(member.id))
        getDriverHandbookReceipt(Number(member.id))
        getDriverStatementDutyHours(Number(member.id))
        getDachCertifiedReceipt(Number(member.id))
        getEmploymentHistory(Number(member.id))
        getLimitedQueries(Number(member.id))
        getPrevDachTestStatement(Number(member.id))
        getRequestForCheck(Number(member.id))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const progress = React.useMemo(() => {
        const progress = [
            annualReviewProgress?.progress,
            certOfComplianceProgress?.progress,
            medicalExaminerInfo?.progress,
            driverApplication?.progress,
            driverHandbookReceipt?.progress,
            driverStatementDutyHours?.progress,
            dachCertifiedReceipt?.progress,
            employmentHistory?.progress,
            limitedQueries?.progress,
            prevDachTestStatement?.progress,
            requestForCheck?.progress
        ]
        const totalProgress = progress.reduce((acc, curr) => (acc ?? 0) + (curr ?? 0), 0) ?? 0
        return Math.round(totalProgress / progress.length)
    }, [annualReviewProgress,
        certOfComplianceProgress,
        medicalExaminerInfo,
        driverApplication,
        driverHandbookReceipt,
        driverStatementDutyHours,
        dachCertifiedReceipt,
        employmentHistory,
        limitedQueries,
        prevDachTestStatement,
        requestForCheck])


    React.useEffect(() => {
        if (member?.user?.profile_url) {
            downloadFile(Number(member?.user?.profile_url)).then((res) => {
                setProfileImage(res.url)
            }).catch((err) => {
                console.log(err)
            })
        }
    }, [member?.user?.profile_url])

    React.useEffect(() => {
        StripeActions.getProducts()
    }, [])

    React.useEffect(() => {
        if (member?.date_screening_completed) {
            const utcDate = new Date(member?.date_screening_completed)
            const offsetMinutes = utcDate.getTimezoneOffset()
            utcDate.setMinutes(utcDate.getMinutes() + offsetMinutes)
            const adjustedYear = utcDate.getFullYear();
            const adjustedMonth = String(utcDate.getMonth() + 1).padStart(2, '0');
            const adjustedDay = String(utcDate.getDate()).padStart(2, '0');
            const formattedDate = `${adjustedMonth}-${adjustedDay}-${adjustedYear}`;
            setDateCompleted(formattedDate)
        } else {
            setDateCompleted("N/A")
        }
    }, [member])

    return (
        <tr>
            <td>
                <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                        {profileImage ? <img src={profileImage} alt="" /> : <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt="" />}
                    </div>
                    <div className="d-flex justify-content-start flex-column" onClick={goToProfile}>
                        <a href="#" className="text-dark fw-bold text-hover-primary fs-6 cursor-pointer">
                            {member.user.first_name} {member.user.last_name} ({member.user.email})
                        </a>
                    </div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                        {member?.driver_type} /
                        {member?.pre_employment_screened ? ' Yes' : ' No'}
                    </div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-center text-center flex-column">
                        {product_name !== "Deluxe Mini Package" ? dateCompleted : "N/A"}
                    </div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-center text-center flex-column">
                        {product_name !== "Deluxe Mini Package" && member?.date_screening_completed ? (
                            (() => {
                                const originalDate = new Date(member?.date_screening_completed);
                                const newDate = new Date(originalDate);
                                newDate.setMonth(originalDate.getMonth() + 3);
                                const utcDate = new Date(newDate)
                                const offsetMinutes = utcDate.getTimezoneOffset()
                                utcDate.setMinutes(utcDate.getMinutes() + offsetMinutes)
                                const adjustedYear = utcDate.getFullYear();
                                const adjustedMonth = String(utcDate.getMonth() + 1).padStart(2, '0');
                                const adjustedDay = String(utcDate.getDate()).padStart(2, '0');
                                return `${adjustedMonth}-${adjustedDay}-${adjustedYear}`;
                            })()
                        ) : "N/A"}
                    </div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                        {!member.terminated_at ? 'Active' : 'Terminated'}
                    </div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                        {!member?.pre_employment_screened ? products.find((prod) => prod.product_id === currentCompany?.drivers_pending_employment?.find((item) => Number(item.driver_id) === Number(member?.id))?.id)?.name : product_name}
                    </div>
                </div>
            </td>
            <td className="text-end">
                {product_name !== "Drug and Alcohol Package" ? <div className="d-flex flex-column w-100 me-2">
                    <div className="d-flex flex-stack mb-2">
                        <span className="text-muted me-2 fs-7 fw-semibold">{progress}%</span>
                    </div>
                    <div className="progress h-6px w-100">
                        <div
                            className="progress-bar bg-primary"
                            role="progressbar"
                            style={{ width: `${progress}%` }}
                        ></div>
                    </div>
                </div> : (
                    <div className="d-flex flex-stack mb-2">
                        <span className="text-muted me-2 fs-7 fw-semibold">N/A</span>
                    </div>
                )}
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                        <IconButton onClick={(e) => {
                            setMember?.(member)
                            setMenuOpen?.(e)
                        }}>
                            <MoreVertRounded />
                        </IconButton>
                    </div>
                </div>
            </td>
        </tr>
    );
}
