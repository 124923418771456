export default function TableLoader({ length, emptyText }: { length?: number; emptyText?: string }) {
    if (length! > 0) {
        return <></>;
    }

    if (length! === 0 && emptyText) {
        return (
            <div className="d-flex justify-content-center pb-10">
                <div className="text-muted fs-6 fw-bold">{emptyText}</div>
            </div>
        );
    }

    return (
        <div className="d-flex justify-content-center pb-10">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
}
