/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Registration } from './components/Registration';
import { ForgotPassword } from './components/ForgotPassword';
import { CreateCompany } from './components/CreateCompany';
import { Login } from './components/Login';
import { EmailEmployee } from './components/EmailEmployee';
import { ChangePassword } from './components/ChangePassword';
import TermsConditions from './components/TermsConditions';
import ContactModal from '../../../compliancemasters/layout/components/ContactModal';
import { SalesRepRegistration } from './components/SalesRepRegistration';
import DVIRLogin from './components/DVIRLogin';
import LoginOTP from './components/LoginOTP';

const AuthLayout = () => {
    const [open, setOpen] = React.useState(false)
    useEffect(() => {
        document.body.classList.add('bg-body');
        return () => {
            document.body.classList.remove('bg-body');
        };
    }, []);

    return (
        <div
            className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
            style={{
                // backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`
            }}
        >
            {/* begin::Content */}
            <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                {/* begin::Wrapper */}
                <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                    <Outlet />
                </div>
                {/* end::Wrapper */}
            </div>
            {/* end::Content */}
            {/* begin::Footer */}
            <div className="d-flex flex-center flex-column-auto p-10">
                <div className="d-flex align-items-center fw-bold fs-6">
                    <a href="#" onClick={() => setOpen(true)} className="text-muted text-hover-primary px-2">
                        Contact Us
                    </a>
                </div>
            </div>
            {/* end::Footer */}
            <ContactModal open={open} setOpen={setOpen} />
        </div>
    );
};

const AuthPage = () => (
    <Routes>
        <Route element={<AuthLayout />}>
            <Route path="login" element={<Login />} />
            <Route path="login-otp" element={<LoginOTP />} />
            <Route path="dvir-signin" element={<DVIRLogin />} />
            <Route path="registration" element={<Registration />} />
            <Route path="sales-rep/registration" element={<SalesRepRegistration />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="create-company" element={<CreateCompany />} />
            <Route path="email-employee" element={<EmailEmployee />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route index element={<Login />} />
        </Route>
        <Route path='terms' element={<TermsConditions />} />
    </Routes>
);

export { AuthPage };
