import React from 'react'
import "./terms_conditions.css"
import { Button } from 'react-bootstrap'


const SalesRepTermsAndConditions = ({ isModal }: { isModal?: boolean }) => {
    return (
        <div style={{
            margin: 10,
            padding: 30,
            width: "100vw",
        }}>
            <div style={{
                width: "70%",
                marginLeft: "auto",
                marginRight: "auto"
            }}>
                <div style={{
                    display: "flex",
                    marginBottom: 15
                }}>
                    {!isModal && (
                        <Button style={{
                            width: "40%",
                            marginLeft: "auto",
                            marginRight: "auto"
                        }}

                            onClick={() => window.history.back()}>
                            Back
                        </Button>
                    )}
                </div>
                <h1 style={{ textAlign: "center" }}>Terms and Conditions</h1>
                <p>
                    This INDEPENDENT SALES REPRESENTATIVE AGREEMENT (the “Agreement”), by and between Compliance Masters, LLC a North Carolina
                    limited liability company with offices in Charlotte, North Carolina (the “Company”) and, an individual, citizen (the “Representative”) below.
                </p>
                <p>
                    WHEREAS, the Company is engaged in the business of providing subscription-based
                    compliance services related to the transportation industry, as more fully described in Schedule A
                    attached hereto (the “Services”); and
                </p>
                <p>
                    WHEREAS, the Company desires to retain the services of the Representative to market
                    and solicit orders for the Services by the Company, and the Representative desires to perform such
                    Services; as more fully described in Schedule A attached hereto (the “Services”);
                </p>
                <p>
                    NOW, THEREFORE, for and in consideration of the premises, mutual covenants and
                    conditions contained herein, and other good and valuable consideration, the receipt and sufficiency
                    of which are hereby acknowledged, the parties hereto hereby agree as follows:
                </p>
                <ol>
                    <li>
                        <h2 style={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                        }}>
                            Engagement of the Representative.
                        </h2>
                        <ol style={{
                            listStyleType: "none"
                        }}>
                            <li style={{
                                margin: 10
                            }}>
                                1.1. The Company hereby engages the Representative as its non-exclusive
                                representative to market and solicit orders for the license of the Services in the Territory
                                (as defined in Section 1.3 below), subject to acceptance by the Company. The engagement
                                of the Representative shall be as an independent contractor and not as an employee, agent,
                                subsidiary or corporate affiliate of the Company and shall be for the Term as defined in
                                Section 1.2 below unless earlier terminated pursuant to the terms of this Agreement. The
                                Representative hereby accepts such engagement
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                1.2. Sales Representative shall have no authority to bind the Company to any contract
                                or agreement, nor shall Sales Representative hold themselves out as an agent or employee
                                of the Company. Sales Representative shall be solely responsible for the payment of all
                                applicable taxes and shall not be entitled to any employee benefits or entitlements.
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                1.3. The term of the engagement (the “Term”) shall be enduring unless either the
                                Company or the Representative gives the other party written notice of its intention to
                                terminate this Agreement under Section 5 below.
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                1.4. The Representative shall market and solicit orders for the Services to be provided
                                to prospective customers located in the United States of America (the “Territory”).
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                1.5. The Representative is authorized to use the copyrighted materials, trademarks, trade
                                names and service marks of the Company, and its subsidiaries and partnerships solely in
                                connection with the performance of Representative’s obligations hereunder in accordance
                                with the policies and procedures established by the Company from time to time provided
                                that nothing contained in this Agreement or otherwise shall effect or be deemed to effect
                                an assignment, license or other grant of any right by the Company of any of the Company’s
                                rights in or to such copyrighted materials, trademarks, trade names or service marks or any
                                other intellectual property of the Company.
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                1.6. During the term of this Agreement, the Representative shall not market, solicit the
                                license or sale of or have an interest, directly or indirectly, in any Person (as defined below)
                                that markets or solicits the sale of, any service that compete with the Services or otherwise
                                conflict with the best interests of the Company.
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                1.7. The Representative shall not have authority to accept orders on behalf of the
                                Company or to bind or commit the Company to deliver the Services or assume or perform
                                any other obligation in respect of any customer or prospective customer, and the
                                Representative shall not make any contrary representations. The Representative shall not
                                make any representation or warranty on behalf of the Company other than as authorized in
                                writing by the Company.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 style={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                        }}>
                            Prices and Terms of Order; Confirmation and Acceptance of Orders.
                        </h2>
                        <ol style={{
                            listStyleType: "none"
                        }}>
                            <li style={{
                                margin: 10
                            }}>
                                2.1. The Representative shall quote only the prices, delivery schedules and other terms
                                and conditions supplied by the Company and no deviations shall be made therefrom.
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                2.2. All orders solicited and taken by the Representative shall be submitted to the
                                Company and are subject to acceptance and confirmation in writing by a duly authorized
                                officer of the Company. Only the Company shall make decisions regarding a customer’s
                                credit and all matters relating to billing to customers. The Representative shall, on request,
                                assist the Company in obtaining credit information relating to customers or prospective
                                customers. All quotations for the Services made by the Representative to customers or
                                prospective customers and all orders delivered to the Representative must be made
                                expressly subject to the approval and confirmation of the Company and are not final until
                                an authorized officer gives such approval in writing
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                2.3. The Company reserves the right, in its sole discretion, to decline to accept any order
                                and to change or discontinue the marketing of any of the Services, without prior notice to
                                the Representative. In the event that the Company declines to accept any such order or
                                change or discontinue the marketing of any of the Services the Company shall not be liable
                                to the Representative for the payment of any commission or fees that would have been
                                payable hereunder had such order been accepted by the Company, except for any
                                commissions due from funds accepted by the Company from the account of Representative.
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                2.4. The Company may, in its sole discretion, cancel any order, either in whole or in
                                part, at any time after acceptance by the Company and the Company shall be relieved of
                                all of its obligations with respect to commissions and fees thereto, except for any
                                commissions already due at time of cancellation from payments already made by customer
                                which shall be paid to representative
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                2.5. The agent may not offer or recommend to the customer an alternative solution from
                                a competitor.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 style={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                        }}>
                            Commissions and Expenses.
                        </h2>
                        <ol style={{
                            listStyleType: "none"
                        }}>
                            <li style={{
                                margin: 10
                            }}>
                                3.1. The Company shall pay a commission to the Representative entered into by the
                                Company with customers obtained primarily through the efforts of the Representative, in
                                accordance with the fee schedule set forth in Schedule B attached hereto
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                3.2. Company will reimburse Representatives with amounts spent by mutual agreement
                                on Companies business 30 days from date of invoice delivered by email.
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                3.3. Sales Representative shall be paid on a monthly basis, at the end of the month, for
                                the number of drivers in their account. The website will track the number of drivers the
                                Sales Representative has and the cost of what packages the Company purchased.
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                3.4. Sales Representative shall work at their own pace and are only obligated to sell a
                                minimum of 25 drivers per year and up to 500 drivers per year. If Sales Representative fails
                                to sell a minimum of 25 drivers per year, their contract may be terminated by the Company.
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                3.5. Upon termination of this Agreement, Sales Representative shall lose their residual
                                income, provided, however, in the event of Sales Representative's retirement, Sales
                                Representative will receive 75% of monthly income as residual income. In the event of
                                Sales Representative's death, if Sales Representative has a spouse, 50% of monthly gross
                                will go to the spouse until the death of the spouse.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 style={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                        }}>
                            Furnishing Sales Materials to the Representative.
                        </h2>
                        <ol style={{
                            listStyleType: "none"
                        }}>
                            <li style={{
                                margin: 10
                            }}>
                                4.1. In connection with the Representative’s performance of his duties and obligations,
                                the Company may from time to time during the term of this Agreement furnish the
                                Representative with such sales catalogs, brochures, samples, equipment, software and other
                                sales materials (collectively the “Sales Materials”) as the Company deems necessary to
                                enable the Representative to solicit orders for the Services. All Sales Materials shall
                                remain the exclusive property of the Company. The Representative shall use such Sales
                                Materials solely for the purpose of soliciting orders for the Services within the Territory
                                and will take all action necessary and appropriate to ensure that no Sales Materials are lost,
                                stolen, destroyed or damaged. If any Sales Material is lost, stolen, destroyed or damaged,
                                the Representative shall be liable to the Company for the cost of replacement of such Sales
                                Materials.
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                4.2. Upon demand by and in accordance with the instructions of the Company, the
                                Representative will, at the Representative’s expense, return to the Company all Sales
                                Materials specified in such demand within 10 days after the date of such demand.
                                Materials.
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                4.3. Representative further agrees that, in the event that this Agreement is terminated
                                for any reason (with or without cause), the Representative shall immediately return, at the
                                Representative’s own expense, all such Sales Materials to the Company at its main office
                                or as otherwise directed by the Company.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 style={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                        }}>
                            Termination
                        </h2>
                        <p>
                            Notwithstanding anything to the contrary contained in this Agreement, either
                            the Representative or the Company may terminate this Agreement with thirty (30) days after
                            giving notice in writing to the other party of the terminating party’s intention to terminate this
                            Agreement.
                        </p>
                    </li>
                    <li>
                        <h2 style={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                        }}>
                            Indemnification
                        </h2>
                        <ol style={{
                            listStyleType: "none"
                        }}>
                            <li style={{
                                margin: 10
                            }}>
                                6.1. The Company shall defend, indemnify and hold harmless the Representative from
                                and against any loss, liability, claim or damage (including reasonable attorneys’ fees and
                                costs) relating to or arising from a breach of this Agreement by the Company.
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                6.2. The Representative shall defend, indemnify and hold harmless the Company, its
                                officers, directors, members, employees and agents from and against any loss, liability,
                                claim or damage (including reasonable attorneys’ fees and costs) relating to or arising from
                                (i) any claim or allegation in connection with the marketing of the Services by the
                                Representative and (ii) a breach of this Agreement by the Representative.
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                6.3. Sales Representative agrees to indemnify, defend and hold harmless the Company,
                                its officers, directors, employees and agents, from and against any and all claims, damages,
                                liabilities, costs and expenses (including reasonable attorneys' fees and costs) arising out
                                of or in connection with Sales Representative's acts or omissions in connection with the
                                performance of their obligations under this Agreement, including but not limited to any
                                breach of any representation, warranty or covenant of Sales Representative set forth in this
                                Agreement.
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                6.4. The foregoing indemnities are conditioned on (i) prompt written notice by the party
                                seeking indemnification; (ii) cooperation in the defense of the claim, demand or action;
                                and (iii) the obtaining of the prior written approval of the indemnifying party of any
                                settlement or offer of settlement.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 style={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                        }}>
                            Non-Disclosure.
                        </h2>
                        <ol style={{
                            listStyleType: "none"
                        }}>
                            <li style={{
                                margin: 10
                            }}>
                                The Representative covenants and agrees that he shall not, during the
                                Term of this Agreement, except in the performance of obligations hereunder, or at any time after
                                the termination of this Agreement, communicate or disclose to any Person (other than the
                                Company or its Affiliates and the Company’s agents, consultants, auditors or attorneys), or use
                                for his account, without the prior written consent of the Company, any business information,
                                observations, data, written material, records or documents relating to the business and affairs of
                                the Company or any of its Affiliates, including, without limitation, any trade secrets, customer
                                lists, information relating to sources of customers or prospects, financial, personnel and customer
                                information, and any confidential information concerning the business or affairs of any supplier,
                                creditor, lender, shareholder or customer of the Company or any of its Affiliates which was
                                obtained or acquired by the Representative during the Term of this Agreement. In addition, the
                                Representative shall not discuss the fees or other compensation received by the Representative,
                                or by any of the Company’s employees, salesmen or agents with any other person whether or not
                                they are employed by the Company (except for confidential discussions with the Representative’s
                                accounting and legal advisors); nor shall the Representative discuss sales achieved by the
                                Company or any profit and cost information with any other party except as authorized by the
                                President of the Company. The Representative further covenants and agrees that the
                                Representative shall retain all such knowledge and information concerning the foregoing in trust
                                for the sole benefit of the Company and its Affiliates and their successors and assigns.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 style={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                        }}>
                            Covenant to Report; Ownership of Trade Secrets.
                        </h2>
                        <ol style={{
                            listStyleType: "none"
                        }}>
                            <li style={{
                                margin: 10
                            }}>
                                8.1. The Representative shall promptly communicate and disclose to the Company all
                                observations made and data obtained by the Representative during the Term. All written
                                materials, records and documents made by the Representative or coming into his
                                possession during the Term concerning the business or affairs of the Company or any of
                                its Affiliates shall be the sole property of the Company and its Affiliates; and upon the
                                Termination of this Agreement or upon the earlier request of the Company during the Term,
                                the Representative shall promptly deliver the same to the Company (or its designee).
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 style={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                        }}>
                            Covenants of Sales Representative.
                        </h2>
                        <ol style={{
                            listStyleType: "none"
                        }}>
                            <li style={{
                                margin: 10
                            }}>
                                9.1. Sales Representative agrees with Company as follows, and Sales Representative
                                acknowledges that Company would not be entering into this Agreement if Sales
                                Representative had not made these covenants:
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                9.1.1. Sales Representative will not at any time, during or after the term of this
                                Agreement, directly or indirectly divulge or otherwise disclose to anyone other than an
                                employee of Company the names or addresses of customers or potential customers of
                                Company, any contents of files maintained by Company or by Sales Representative for
                                Company prices and pricing policies of Company, procedures and policies of Company or
                                other information which is confidential or proprietary to Company, unless Company gives
                                its prior written consent to such disclosure.
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                9.1.2. All books, records, notes, reports, copy, advertising, contracts, orders, drafts,
                                accounts, documents and other information or writings relating to Company or its
                                customers, employees, Sales Representatives, or agents, whether prepared by Sales
                                Representative or otherwise coming into the possession of Sales Representative, is and
                                shall remain the exclusive property of Company and shall be returned to Company upon
                                demand.
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                9.1.3. Sales Representative shall report all violations of Company's patents and/or
                                copyrights and other proprietary rights in its Product and other works immediately upon
                                discovery of such violations.
                            </li>
                            <li style={{
                                margin: 10
                            }}>
                                9.1.4. Sales Representative acknowledges that each of the above matters is important and
                                material to the business and success of Company and agrees that any breach of this section
                                is a material breach of this Agreement, from which Sales Representative may be enjoined.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 style={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                        }}>
                            Remedies.
                        </h2>
                        <ol style={{
                            listStyleType: "none"
                        }}>
                            <li style={{
                                margin: 10
                            }}>
                                The Representative acknowledges that the Company will have no adequate
                                remedy at law if the Representative violates any of the terms of this Agreement. In such event, the
                                Representative agrees that the Company will be entitled to obtain preliminary and permanent
                                injunctive relief or otherwise to specifically enforce any of the provisions of this Agreement, in
                                any court of competent jurisdiction, without prejudice to any other remedy available to it at the
                                Company’s expense; provided, however, that such remedy will be held and construed to be
                                cumulative and not exclusive of any rights or remedies, whether in law or equity, otherwise
                                available under the terms of this Agreement or under federal, state or local statutes, rules and
                                regulations.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 style={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                        }}>
                            Definitions.
                        </h2>
                        <ol style={{
                            listStyleType: "none"
                        }}>
                            <li style={{
                                margin: 10
                            }}>
                                Unless otherwise defined herein, the following terms shall have the meanings
                                ascribed to them: (a) “Affiliate” shall mean any Person that directly, or indirectly through one or
                                more intermediaries, controls or is controlled by or is under common control with another Person;
                                and (b) “Person” shall mean any individual, corporation, firm, association, partnership, other legal
                                entity or other form of business organization.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 style={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                        }}>
                            No Conflicts with Other Agreements.
                        </h2>
                        <ol style={{
                            listStyleType: "none"
                        }}>
                            <li style={{
                                margin: 10
                            }}>
                                The Representative represents and warrants that
                                his obligations under this Agreement do not breach, violate, conflict with or contravene any oral
                                or written understanding or agreement binding on the Representative, including, without
                                limitation, any covenant not to compete
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 style={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                        }}>
                            Assignment.
                        </h2>
                        <ol style={{
                            listStyleType: "none"
                        }}>
                            <li style={{
                                margin: 10
                            }}>
                                This Agreement and all of the provisions hereof shall be binding upon and
                                inure to the benefit of both parties hereto and their respective successors and assigns; provided,
                                however, that neither this Agreement nor Representative without the prior written consent of the
                                Company, nor any of the rights, interests or obligations hereunder may be assigned by the
                                Company without the prior written consent of the Representative. Specifically in the event that the
                                business of Company or intellectual property of Company is acquired then the new owners will
                                remain liable for commission payments as herein agreed and cancellation remains for cause.
                                Notwithstanding this protection the representation agreement may also be terminated in exchange
                                for a payment agreed as part of any sale of company or its intellectual property.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 style={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                        }}>
                            Changes; Alterations.
                        </h2>
                        <ol style={{
                            listStyleType: "none"
                        }}>
                            <li style={{
                                margin: 10
                            }}>
                                No change, alteration, modification or addition to this Agreement
                                shall be effective unless in writing and properly executed by the parties hereto.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 style={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                        }}>
                            Limitation of Liability.
                        </h2>
                        <ol style={{
                            listStyleType: "none"
                        }}>
                            <li style={{
                                margin: 10
                            }}>
                                In no event shall the Company be liable for any indirect, special,
                                incidental, consequential, or punitive damages, including but not limited to, loss of profits or
                                revenue, loss of data, or claims of third parties, arising out of or in connection with this Agreement,
                                even if the Company has been advised of the possibility of such damages.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 style={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                        }}>
                            Applicable Law.
                        </h2>
                        <ol style={{
                            listStyleType: "none"
                        }}>
                            <li style={{
                                margin: 10
                            }}>
                                This Agreement shall be governed by and construed solely and
                                exclusively in accordance with the laws of state of North Carolina, without regard to any statutory
                                or common-law provision pertaining to conflicts of laws principles.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 style={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                        }}>
                            Arbitration.
                        </h2>
                        <ol style={{
                            listStyleType: "none"
                        }}>
                            <li style={{
                                margin: 10
                            }}>
                                Any dispute arising out of or relating to this Agreement, or the breach thereof
                                shall be resolved by binding arbitration in accordance with the rules of the American Arbitration
                                Association. The arbitration shall be conducted in Charlotte, North Carolina and shall be heard by
                                a single arbitrator. The decision of the arbitrator shall be final and binding upon the parties and
                                judgment upon the award rendered by the arbitrator may be entered in any court having jurisdiction
                                thereof.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 style={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                        }}>
                            Notices.
                        </h2>
                        <ol style={{
                            listStyleType: "none"
                        }}>
                            <li style={{
                                margin: 10
                            }}>
                                All notices and other communications which are required under this Agreement
                                shall be in writing and shall be deemed to have been duly given (a) when delivered in person, (b)
                                two business days after being mailed with an internationally recognized courier service provided
                                that such mailing is sent by a tracked process and the documents are signed for by the recipient at
                                their last known business address.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 style={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                        }}>
                            Entire Agreement.
                        </h2>
                        <ol style={{
                            listStyleType: "none"
                        }}>
                            <li style={{
                                margin: 10
                            }}>
                                This Agreement, together with Schedule A and Schedule B attached
                                hereto, embodies the entire agreement and understanding between the parties hereto with respect
                                to the subject matter of this Agreement and supersedes all prior agreements, commitments,
                                arrangements; negotiations or understandings, whether oral or written, between the parties with
                                respect thereto. There are no agreements, covenants, undertakings, representations or warranties
                                with respect to the subject matter of this Agreement other than those expressly set forth or referred
                                to herein.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 style={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                        }}>
                            Severability.
                        </h2>
                        <ol style={{
                            listStyleType: "none"
                        }}>
                            <li style={{
                                margin: 10
                            }}>
                                If any provisions of the Agreement shall be declared to be invalid or
                                unenforceable, in whole or in part, such invalidity or unenforceability shall not affect the remaining
                                provisions hereof, which shall remain in full force and effect.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 style={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                        }}>
                            Headings.
                        </h2>
                        <ol style={{
                            listStyleType: "none"
                        }}>
                            <li style={{
                                margin: 10
                            }}>
                                The headings contained in this Agreement are for convenience of reference
                                only and shall not constitute a part hereof or define, limit or otherwise affect the meaning of any
                                of the terms or provisions hereof.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 style={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                        }}>
                            Counterparts
                        </h2>
                        <ol style={{
                            listStyleType: "none"
                        }}>
                            <li style={{
                                margin: 10
                            }}>
                                This Agreement may be executed in one or more counterparts, each of
                                which shall be deemed an original, but all of which taken together shall constitute one and the
                                same instrument.
                            </li>
                        </ol>
                    </li>
                </ol>
                <h1 style={{
                    textAlign: "center"
                }}>
                    Schedule A
                </h1>
                <h3>
                    DESCRIPTION OF SERVICES:
                </h3>
                <ol style={{
                    listStyleType: "none"
                }}>
                    <li style={{
                        margin: 10
                    }}>
                        A. Sales and Marketing of the Company’s Subscription Packages and the ancillary services
                        to commercial trucking companies.
                    </li>
                    <li style={{
                        margin: 10
                    }}>
                        B. To protect the interests of the Company to the best of Representatives ability, including
                        the safekeeping of trade secrets of the Company
                    </li>
                </ol>
                <h1 style={{
                    textAlign: "center"
                }}>
                    Schedule B
                </h1>
                <p>
                    Sales Representative shall receive compensation as residuals, calculated as a percentage of gross
                    sales received from customers obtained by Sales Representative in accordance with the following
                    schedule:
                </p>
                <ol style={{
                    listStyleType: "none"
                }}>
                    <li style={{
                        margin: 10
                    }}>
                        a. If the Customer has 1-99 drivers then the Sales Representative receives 40% of Gross
                        Sales to that Customer.
                    </li>
                    <li style={{
                        margin: 10
                    }}>
                        b. If the Customer has 100-199 Drivers, then the Sales Representative receives 41% of
                        Gross Sales
                    </li>
                    <li style={{
                        margin: 10
                    }}>
                        c. If the Customer has 200-299 Drivers, then the Sales Representative receives 42% of
                        Gross Sales.
                    </li>
                    <li style={{
                        margin: 10
                    }}>
                        d. If the Customer has 300-399 Drivers, then the Sales Representative receives 43% of
                        Gross Sales.
                    </li>
                    <li style={{
                        margin: 10
                    }}>
                        e. If the Customer has 400-499 Drivers, then the Sales Representative receives 44% of
                        Gross Sales.
                    </li>
                    <li style={{
                        margin: 10
                    }}>
                        f. If the Customer has 500-599 Drivers, then the Sales Representative receives 45% of
                        Gross Sales.
                    </li>
                    <li style={{
                        margin: 10
                    }}>
                        g. If the Customer has 600-699 Drivers, then the Sales Representative receives 46% of
                        Gross Sales.
                    </li>
                    <li style={{
                        margin: 10
                    }}>
                        h. If the Customer has 700-799 Drivers, then the Sales Representative receives 47% of
                        Gross Sales.
                    </li>
                    <li style={{
                        margin: 10
                    }}>
                        i. If the Customer has 800-899 Drivers, then the Sales Representative receives 48% of
                        Gross Sales.
                    </li>
                    <li style={{
                        margin: 10
                    }}>
                        j. If the Customer has 900-999 Drivers, then the Sales Representative receives 49% of
                        Gross Sales.
                    </li>
                    <li style={{
                        margin: 10
                    }}>
                        k. If the Customer has 1000 drivers and over, then the Sales Representative receives 50%
                        of Gross Sales
                    </li>
                </ol>
            </div>
        </div>
    )
}

export default SalesRepTermsAndConditions