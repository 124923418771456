import {ICompany, IMember} from '../../../models'
import {IAbstract} from '../abstract'

export enum AlcoholChoices {
  DIRECT_OBSERVATION_OF_THE_VIOLATION = 'Direct observation of the violation',
  REPORT_FROM_A_PREVIOUS_EMPLOYER = "Information received from driver's previous employer",
  ALCOHOL_USE_WHILE_ON_DUTY = 'Alcohol use while on duty',
  CONTROLLED_SUBSTANCES_USE = 'Controlled substances use',
  TRAFFIC_VIOLATIONS_FOR_DUI = 'Traffic violations for driving a CMV while under the influence of alcohol',
  N_A = 'N/A',
}

export enum ControlledSubstanceTextChoices {
  DIRECT_OBSERVATION_OF_THE_VIOLATION = 'Direct observation of the violation',
  REPORT_FROM_A_PREVIOUS_EMPLOYER = "Information received from driver's previous employer",
  CONTROLLED_SUBSTANCES_USE = 'Controlled substances use',
  DRIVERS_ADMISSON_OF_THE_VIOLATION = "Driver's admission of the violation",
  N_A = 'N/A',
}

export interface IViolationAndActualKnowledgeDto {
  alcohol_use_within_4_hours_of_duty: string
  alcohol_use_prior_to_post_accident_test: string
  alcohol_use_while_on_duty: string
  controlled_substances_use: string
  alcohol: AlcoholChoices
  controlled_substances: ControlledSubstanceTextChoices
  date_knowledge_obtained: string
  date_submitted_to_clearinghouse: string
  description_of_the_violation: string
  attached_documentation: string
  company: ICompany
  member: IMember
}

export interface IViolationAndActualKnowledge extends IViolationAndActualKnowledgeDto, IAbstract {}
