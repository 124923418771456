import React, { useState } from 'react';
import { useGetMaintenanceMemberDvirQuery } from '../../../store/reducers/PreventableMaintenance/dvir.report';
import { useAuth } from '../../modules/auth';
import { Box, Paper, TableBody, TableCell, TablePagination, TableRow } from '@mui/material';
import TableWrapper from '../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import TableContainerHeader from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableContainerHeaderTitle from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import TableContainer from '../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableHeader from '../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import DateComponent from './DateComponent';

function formatSignatures(mechanicSignature?: string, nextDriverSignature?: string): string {
    if (!mechanicSignature && !nextDriverSignature) {
        return 'Pending both signatures';
    }
    if (!mechanicSignature) {
        return 'Pending mechanic signature';
    }
    if (!nextDriverSignature) {
        return 'Pending next driver signature';
    }
    return 'Signed';
}

const MaintenanceMemberDashboard = () => {
    const { currentUser } = useAuth();
    const { data: reports = [] } = useGetMaintenanceMemberDvirQuery(currentUser?.maintenance_member?.id || 0, {
        skip: !currentUser?.maintenance_member?.id,
        refetchOnFocus: true
    });

    const [page, setPage] = useState<number>(0);

    const [limit, setLimit] = useState<number>(10);

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };

    return (
        <Paper component={Box} mt={4}>
            <TableWrapper>
                <TableContainerHeader>
                    <TableContainerHeaderTitle title="DVIR's assigned to you" />
                </TableContainerHeader>
                <TableContainer>
                    <TableHeader>
                        <TableHeaderColumn>File Name</TableHeaderColumn>
                        <TableHeaderColumn>Driver Name</TableHeaderColumn>
                        <TableHeaderColumn>Start Odometer Reading</TableHeaderColumn>
                        <TableHeaderColumn>End Odometer Reading</TableHeaderColumn>
                        <TableHeaderColumn>Mechanic / Next Driver Signature</TableHeaderColumn>
                        <TableHeaderColumn>Date of DVIR</TableHeaderColumn>
                    </TableHeader>
                    <TableBody>
                        {reports?.slice(page * limit, page * limit + limit)?.map((dvir, index) => (
                            <TableRow
                                key={dvir.id}
                                hover
                                sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                    window.location.assign(`/preventable-maintenance/report/${dvir?.id}`);
                                }}
                            >
                                <TableCell>DVIR {index + 1}</TableCell>
                                <TableCell>
                                    {dvir?.member?.user?.first_name ?? ''} {dvir?.member?.user?.last_name ?? ''}
                                </TableCell>
                                <TableCell>
                                    {Intl.NumberFormat('en-US', {
                                        compactDisplay: 'short'
                                    }).format(Number(dvir.start_odometer_reading))}{' '}
                                    Miles
                                </TableCell>
                                <TableCell>
                                    {Intl.NumberFormat('en-US', {
                                        compactDisplay: 'short'
                                    }).format(Number(dvir.end_odometer_reading))}{' '}
                                    Miles
                                </TableCell>
                                <TableCell>
                                    {formatSignatures(dvir.mechanic_signature, dvir.next_driver_signature)}
                                </TableCell>
                                <DateComponent date={dvir.date ?? new Date()} />
                            </TableRow>
                        ))}
                    </TableBody>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={reports.length ?? 0}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[10, 25, 30]}
                />
            </TableWrapper>
        </Paper>
    );
};

export default MaintenanceMemberDashboard;
