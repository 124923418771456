import { DashboardLink } from '../DashboardLink';

export function MenuInner() {
    return (
        <>
            <DashboardLink size={'75'} />
            {/* <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to="/dashboard" /> */}
        </>
    );
}
