import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers'
import {
  IDachEmployeecertifiedReceiptDto,
  IDachEmployeecertifiedReceipt,
} from '../../interfaces/DrugandAlcoholEmployeesCertifiedReceipt/dach-employee-certified-receipt'

export const dachEmployeeCertifiedReceiptApi = createApi({
  reducerPath: 'dach_employee_certified_receipt',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/drug-and-alcohol-employees-certified-receipt`,
  }),
  tagTypes: ['DachEmployeeCertifiedReceipt'],
  endpoints: (builder) => ({
    createDachEmployeeCertifiedReceipt: builder.mutation<
      IDachEmployeecertifiedReceipt,
      IDachEmployeecertifiedReceiptDto
    >({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {
          type: 'DachEmployeeCertifiedReceipt',
          id: 'DachEmployeeCertifiedReceipt',
        },
      ],
    }),
    updateDachEmployeeCertifiedReceipt: builder.mutation<
      IDachEmployeecertifiedReceipt,
      IDachEmployeecertifiedReceipt
    >({
      query: (body) => ({
        url: `/${body.id}`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {
          type: 'DachEmployeeCertifiedReceipt',
          id: 'DachEmployeeCertifiedReceipt',
        },
      ],
    }),
    getDachEmployeeCertifiedReceipt: builder.query<IDachEmployeecertifiedReceipt, number | void>({
      query: (id) => ({
        url: '/get-drug-and-alcohol-employees-certified-receipt',
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {
          type: 'DachEmployeeCertifiedReceipt',
          id: 'DachEmployeeCertifiedReceipt',
        },
      ],
    }),
    getDachEmployeeCertifiedReceiptByUser: builder.query<IDachEmployeecertifiedReceipt, void>({
      query: () => ({
        url: '/get-drug-and-alcohol-employees-certified-receipt-by-user',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {
          type: 'DachEmployeeCertifiedReceipt',
          id: 'DachEmployeeCertifiedReceipt',
        },
      ],
    }),
    getDachEmployeeCertifiedReceiptByUserId: builder.query<
      IDachEmployeecertifiedReceipt,
      number | void
    >({
      query: (id) => ({
        url: '/get-drug-and-alcohol-employees-certified-receipt-by-user-id',
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {
          type: 'DachEmployeeCertifiedReceipt',
          id: 'DachEmployeeCertifiedReceipt',
        },
      ],
    }),
    deleteDachEmployeeCertifiedReceipt: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {
          type: 'DachEmployeeCertifiedReceipt',
          id: 'DachEmployeeCertifiedReceipt',
        },
      ],
    }),
  }),
})

export const {
  useCreateDachEmployeeCertifiedReceiptMutation,
  useUpdateDachEmployeeCertifiedReceiptMutation,
  useGetDachEmployeeCertifiedReceiptQuery,
  useGetDachEmployeeCertifiedReceiptByUserQuery,
  useGetDachEmployeeCertifiedReceiptByUserIdQuery,
  useDeleteDachEmployeeCertifiedReceiptMutation,
} = dachEmployeeCertifiedReceiptApi
