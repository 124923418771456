import { maintenanceMemberApi } from './maintenance.member';
import { maintenanceVehicleApi } from './maintenance.vehicle';
import { serviceTypesApi } from './service.types';
import { dvirReportApi } from './dvir.report';
import { pmProgramApi } from './pm.program';
import { notesAndLinksApi } from './notes.links';
import { serviceOrdersApi } from './service.orders';
import { serviceAlertsApi } from './service.alerts';

export const MaintenanceMemberReducers = {
    [maintenanceMemberApi.reducerPath]: maintenanceMemberApi.reducer
};

export const MaintenanceVehicleReducers = {
    [maintenanceVehicleApi.reducerPath]: maintenanceVehicleApi.reducer
};

export const ServiceProgramReducers = {
    [serviceTypesApi.reducerPath]: serviceTypesApi.reducer
};

export const DvirReportReducers = {
    [dvirReportApi.reducerPath]: dvirReportApi.reducer
};

export const PmProgramReducers = {
    [pmProgramApi.reducerPath]: pmProgramApi.reducer
};

export const NotesAndLinksReducers = {
    [notesAndLinksApi.reducerPath]: notesAndLinksApi.reducer
};

export const ServiceOrdersReducers = {
    [serviceOrdersApi.reducerPath]: serviceOrdersApi.reducer
};

export const ServiceAlertsReducers = {
    [serviceAlertsApi.reducerPath]: serviceAlertsApi.reducer
};

export const MaintenanceMemberMiddleware = [maintenanceMemberApi.middleware];

export const MaintenanceVehicleMiddleware = [maintenanceVehicleApi.middleware];

export const ServiceProgramMiddleware = [serviceTypesApi.middleware];

export const DvirReportMiddleware = [dvirReportApi.middleware];

export const PmProgramMiddleware = [pmProgramApi.middleware];

export const NotesAndLinksMiddleware = [notesAndLinksApi.middleware];

export const ServiceOrdersMiddleware = [serviceOrdersApi.middleware];

export const ServiceAlertsMiddleware = [serviceAlertsApi.middleware];
