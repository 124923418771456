import {ICompany, IMember} from '../../../models'
import {IAbstract} from '../abstract'

export enum InformationTextChoices {
  EMAILED = 'Emailed',
  FAX = 'Fax',
  MAIL = 'Mail',
  RELAYED_BY_PHONE = 'Relayed by Phone',
}

export interface IProspectiveEmployerInformationDto {
  company_name: string
  attention: string
  street: string
  city_state_zip: string
  phone_number: string
  email: string
  this_form_was: InformationTextChoices
  date: string
  name_of_person_contacted: string
  applicant_signature: string
  company: ICompany
  member: IMember
}

export interface IProspectiveEmployerInformation
  extends IProspectiveEmployerInformationDto,
    IAbstract {}
