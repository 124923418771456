import React from 'react';
import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { sendDvirOtp, verifyDvirOtp, verifyMemberAccount } from '../../../../shared';
import { toast } from 'react-toastify';
import { useAuth } from '../core/Auth';
import { FormLabel } from 'react-bootstrap';

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required')
});

const initialValues = {
    email: '',
    loginType: 'Driver'
};

function maskEmail(email: string): string {
    return email.replace(/^(.{5})/, '*****');
}

const DVIRLogin = () => {
    const [emailSent, setEmailSent] = React.useState(false);
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [channel, setChannel] = React.useState('email');
    const [otpRoute, setOtpRoute] = React.useState('');
    const [value, setValue] = React.useState('');
    const [otp, setOtp] = React.useState('');
    const [otpSent, setOtpSent] = React.useState(false);
    const { saveAuth, setCurrentUser, getUser } = useAuth();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChannel((event.target as HTMLInputElement).value);
        if (event.target.value === 'email') {
            setValue('email');
            setChannel('email');
            setOtpRoute(email);
        }
        if (event.target.value === 'sms') {
            setValue('sms');
            setChannel('sms');
            setOtpRoute(phoneNumber);
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            const response = await verifyMemberAccount(values.email, values.loginType);
            if (response) {
                setEmailSent(true);
                setEmail(response.email);
                setPhoneNumber(response.phone_number);
            } else {
                toast.warn('The email is not associated with any account');
            }
        }
    });

    return (
        <div>
            <Typography variant="h5" textAlign="center" mb={4}>
                Driver Vehicle Inspection Report Confirmation
            </Typography>
            {!emailSent && (
                <>
                    <Typography>Please enter the email address used for your Compliance Masters account</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                id="email"
                                type="email"
                                {...formik.getFieldProps('email')}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                size="small"
                                placeholder="Email"
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography align="center">Select Login Account Type</Typography>
                            <FormControl>
                                <RadioGroup
                                    value={formik.values.loginType}
                                    onChange={(e) => formik.setFieldValue('loginType', e.target.value)}
                                    row
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel value="Company-Owner" control={<Radio />} label="Company Owner" />
                                    <FormControlLabel value="Driver" control={<Radio />} label="Driver" />
                                    <FormControlLabel
                                        value="Maintenance-Member"
                                        control={<Radio />}
                                        label="Maintenance Member"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Button
                                variant="contained"
                                fullWidth
                                size="small"
                                color="primary"
                                onClick={formik.submitForm}
                            >
                                Continue
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
            {emailSent && !otpSent && (
                <>
                    <Typography>
                        A one time password is required. Would you like to receive the OTP via email or SMS?
                    </Typography>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={value}
                            onChange={handleChange}
                        >
                            <FormControlLabel
                                value="email"
                                control={<Radio />}
                                label={`Send email to ${maskEmail(email)}`}
                            />
                            <FormControlLabel
                                value="sms"
                                control={<Radio />}
                                label={`Send text to ${maskEmail(phoneNumber)}`}
                            />
                        </RadioGroup>
                    </FormControl>
                    <Button
                        variant="contained"
                        fullWidth
                        size="small"
                        color="primary"
                        onClick={async () => {
                            const res = await sendDvirOtp(otpRoute, channel);
                            if (res) {
                                toast.success('OTP sent successfully');
                                setOtpSent(true);
                            } else {
                                toast.error('Failed to send OTP');
                            }
                        }}
                    >
                        Send OTP
                    </Button>
                </>
            )}
            {otpSent && (
                <>
                    <Typography>
                        Please enter the OTP sent to your {channel === 'email' ? 'Email' : 'Phone Number'}
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                id="otp"
                                type="text"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                size="small"
                                placeholder="OTP"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Button
                                variant="contained"
                                fullWidth
                                size="small"
                                color="primary"
                                onClick={async () => {
                                    const res = await verifyDvirOtp(otpRoute, otp, channel);
                                    if (res) {
                                        saveAuth({
                                            api_token: res.access,
                                            refreshToken: res.refresh
                                        });
                                        getUser().then((res) => {
                                            setCurrentUser({ ...res[0], otpConfirmed: true });
                                            if (res[0].role === 'MaintenanceMember') {
                                                window.location.assign('/preventable-maintenance/dashboard');
                                            } else {
                                                window.location.assign('/driver-inspection-report/dashboard');
                                            }
                                        });
                                    } else {
                                        toast.error('Failed to verify OTP');
                                    }
                                }}
                            >
                                Confirm OTP
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
};

export default DVIRLogin;
