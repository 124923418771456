import { ISalesRep } from '../shared/client/sales-rep';
import { IMaintenanceMember } from '../store/interfaces/PreventableMaintenance/maintenance.members';
import { IMember } from './IMember';

export enum DriverType {
    NEW_DRIVER = 'New Driver',
    EXISTING_DRIVER = 'Existing Driver'
}

export interface IUser {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    role: 'Owner' | 'Admin' | 'Member' | 'SuperAdmin' | 'MaintenanceMember';
    role_id: number;
    drivers_license_number: string;
    member?: IMember;
    maintenance_member?: IMaintenanceMember;
    profile_url: string;
    sales_rep?: ISalesRep;
}
