import {driverMedicalExaminerInformationAndOwnerSignatureApi} from './driver-medical-examiner-information-and-owner-signature'
import {driverMedicalExaminerRegistryVerificationProgress} from './progress'

export const DriverMedicalExaminerRegistryVerificationReducers = {
  [driverMedicalExaminerInformationAndOwnerSignatureApi.reducerPath]:
    driverMedicalExaminerInformationAndOwnerSignatureApi.reducer,
  [driverMedicalExaminerRegistryVerificationProgress.reducerPath]:
    driverMedicalExaminerRegistryVerificationProgress.reducer,
}

export const DriverMedicalExaminerRegistryVerificationMiddleware = [
  driverMedicalExaminerInformationAndOwnerSignatureApi.middleware,
  driverMedicalExaminerRegistryVerificationProgress.middleware,
]
