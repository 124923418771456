import React from 'react';
import Button from '../../../../app/modules/buttons/Button';
import { IMember } from '../../../../models';
import { TerminateModal } from './TerminateModal';

interface IProps {
    member: IMember;
    onSubmit?: () => void;
}
export default function TerminateButton({ member, onSubmit }: IProps) {
    const term = member.terminated_at ? 'Terminated' : 'Terminate';
    return (
        <>
            <TerminateModal member={member} onSubmit={onSubmit} />
            <Button className={'btn btn-sm btn-light mx-1'} dataBsToggle="modal" dataBsTarget="#terminate_modal" disabled={!!member?.terminated_at}>
                {term}
            </Button>
        </>
    );
}
