import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import styled from 'styled-components';
import { useAuth } from '../../modules/auth';
import { Menu, MenuItem } from '@mui/material';

export default function LandingPageHeader() {
    const { currentUser } = useAuth()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    }
    return (
        <Box sx={{ display: 'flex', }}>
            <CssBaseline />
            <AppBar component="nav" position='fixed' sx={{ bgcolor: "white", zIndex: 9999999999 }}>
                <Toolbar>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'none' } }}
                    >
                        {/* <Img
                            loading="lazy"
                            style={{
                                height: 90,
                                width: "auto"
                            }}
                            src='/media/logos/compliance-01.png'
                        /> */}
                    </Typography>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'block', } }}
                    >
                        {/* <Img
                            loading="lazy"
                            style={{
                                height: 90,
                                width: "auto"
                            }}
                            src='/media/logos/compliance-01.png'
                        /> */}
                    </Typography>
                    <Box sx={{ display: { sm: 'none', md: 'block', xs: "none" } }}>
                        <a href='#home' className='btn' style={{ color: 'black' }}>
                            Home
                        </a>
                        <a href='#driver_compliance' className='btn' style={{ color: 'black' }}>
                            Driver Compliance
                        </a>
                        <a href='#drug_alcohol_compliance' className='btn' style={{ color: 'black' }}>
                            Drug and Alcohol Compliance
                        </a>
                        <a href='#pricing' className='btn' style={{ color: 'black' }}>
                            Pricing
                        </a>
                        <>
                            {currentUser ? (
                                <a href="/dashboard" className="btn btn-success">
                                    Dashboard
                                </a>
                            ) : (
                                <a href="/auth/login" className="btn btn-success">
                                    Sign In / Sign Up
                                </a>
                            )}
                        </>
                    </Box>
                    <IconButton
                        color="success"
                        aria-label="open drawer"
                        edge="end"
                        size="large"
                        onClick={handleClick}
                        sx={{ mr: 2, display: { sm: 'block', md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        sx={{ zIndex: 9999999 }}
                    >
                        <MenuItem onClick={() => {
                            window.location.href = '#home'
                        }}>Home</MenuItem>
                        <MenuItem onClick={() => {
                            window.location.href = '#driver_compliance'
                        }}>Driver Compliance</MenuItem>
                        <MenuItem onClick={() => {
                            window.location.href = '#drug_alcohol_compliance'
                        }}>Drug and Alcohol Compliance</MenuItem>
                        <MenuItem onClick={() => {
                            window.location.href = '#pricing'
                        }}>Pricing</MenuItem>
                        <MenuItem>
                            <>
                                {currentUser ? (
                                    <a href="/dashboard" className="btn btn-success">
                                        Dashboard
                                    </a>
                                ) : (
                                    <a href="/auth/login" className="btn btn-success">
                                        Sign In / Sign Up
                                    </a>
                                )}
                            </>
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

// const Img = styled.img`
//   aspect-ratio: 1;
//   object-fit: contain;
//   object-position: center;
//   width: 150px;
//   overflow: hidden;
//   z-index: 1;
//   max-width: 100%;
// `;
