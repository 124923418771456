import { IMember } from '../../../../../models'
import React from 'react'
import TableContainerHeader from '../../../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader'
import TableContainerHeaderTitle from '../../../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle'
import TableHeader from '../../../../../compliancemasters/partials/widgets/tables/components/TableHeader'
import TableHeaderColumn from '../../../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn'
import TableWrapper from '../../../../../compliancemasters/partials/widgets/tables/components/TableWrapper'
import MedicalExaminerRegistryVerification from './MedicalRegistryVerification'
import TableContainer from '../../../../../compliancemasters/partials/widgets/tables/components/TableContainer'
import AnnualReviewOfDrivingRecords from './AnnualReviewOfRoadRecord'

const MemberForms = ({ member }: { member?: IMember }) => {

    return (
        <TableWrapper>
            <TableContainerHeader>
                <TableContainerHeaderTitle title="Non Current Forms" />
            </TableContainerHeader>
            <TableContainer>
                <TableHeader>
                    <TableHeaderColumn>Form</TableHeaderColumn>
                    <TableHeaderColumn>{""}</TableHeaderColumn>
                    <TableHeaderColumn>{""}</TableHeaderColumn>
                    <TableHeaderColumn>{""}</TableHeaderColumn>
                    <TableHeaderColumn end>Actions</TableHeaderColumn>
                </TableHeader>
                <tbody>
                    <MedicalExaminerRegistryVerification member={member} />
                    <AnnualReviewOfDrivingRecords id={Number(member?.id!)} />
                </tbody>
            </TableContainer>
        </TableWrapper>
    )
}

export default MemberForms