import { ICompany, IMember } from '../../models';
import client from '../Client';

export function getCompanies() {
    return client(`auth/api/company/`);
}

export function getCurrentCompany() {
    return client(`auth/api/company/current-company/`);
}

export function updateCompany(companyId: number, body: Partial<ICompany>) {
    return client(`auth/api/company/${companyId}/`, {
        body,
        method: 'PUT'
    });
}

export function getCompanyMembers(companyId: string) {
    return client(`auth/api/company/${companyId}/members`);
}

export function getCompanyMember(memberId: string) {
    return client(`auth/api/company/member/?memberId=${memberId}`);
}

export function updateCompanyMember(memberId: string, body: Partial<IMember>) {
    return client(`auth/api/company/update-member/`, {
        body: {
            ...body,
            memberId
        },
        method: 'PUT'
    });
}

export function getMemberCompanyForms(memberId: string) {
    return client(`auth/api/company/company-member-forms/?memberId=${memberId}`);
}

export function terminateMember(memberId: string) {
    return client(`auth/api/company/terminate-member/`, {
        body: {
            memberId
        }
    });
}

export function activateMember(memberId: string) {
    return client(`auth/api/company/activate-member/`, {
        body: {
            memberId
        }
    });
}

export const sendOptToPhoneNumber = (phoneNumber: string, channel: string) => {
    return client(`form-revamp/sms/send-otp/`, {
        body: {
            to: phoneNumber,
            channel
        }
    });
};

export const sendDvirOtp = (phoneNumber: string, channel: string) => {
    return client(`form-revamp/sms/send-dvir-otp/`, {
        body: {
            to: phoneNumber,
            channel
        }
    });
};

export const verifyMemberAccount = (email: string, loginType?: string) => {
    return client(`form-revamp/sms/verify-member-account/`, {
        body: {
            email,
            loginType: loginType ? loginType : undefined
        }
    });
};

export const verifyOtp = (phoneNumber: string, otp: string) => {
    return client(`form-revamp/sms/verify-otp/`, {
        body: {
            to: phoneNumber,
            otp
        }
    });
};

export const verifyDvirOtp = (phoneNumber: string, otp: string, channel: string) => {
    return client(`form-revamp/sms/verify-dvir-otp/`, {
        body: {
            to: phoneNumber,
            otp,
            channel
        }
    });
};

export const sendLoginOtp = (phoneNumber: string, channel: string) => {
    return client(`form-revamp/sms/send-login-otp/`, {
        body: {
            to: phoneNumber,
            channel
        }
    });
};
