import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import { IPSPDisclosureForm, IPSPDisclosureFormDto } from '../../interfaces/PSPDisclosure/PSPDisclosure';
import { IProgress } from '../../interfaces/abstract';

export const pspDisclosureApi = createApi({
    reducerPath: 'psp_disclosure',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/psp-disclosure-form`
    }),
    tagTypes: ['PSPDisclosure'],
    endpoints: (builder) => ({
        createPSPDisclosureForm: builder.mutation<IPSPDisclosureForm, IPSPDisclosureFormDto>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'PSPDisclosure', id: 'PSPDisclosure' }]
        }),
        updatePSPDisclosureForm: builder.mutation<IPSPDisclosureForm, IPSPDisclosureForm>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'PSPDisclosure', id: 'PSPDisclosure' }]
        }),
        deletePSPDisclosureForm: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'PSPDisclosure', id: 'PSPDisclosure' }]
        }),
        getPSPDisclosureForm: builder.query<IPSPDisclosureForm, number>({
            query: (id) => ({
                url: `/get-psp-disclosure-form/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'PSPDisclosure', id: 'PSPDisclosure' }]
        }),
        getPSPDisclosureFormByUser: builder.query<IPSPDisclosureForm, void>({
            query: () => ({
                url: `/get-psp-disclosure-form-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'PSPDisclosure', id: 'PSPDisclosure' }]
        }),
        getPSPDisclosureFormByUserId: builder.query<IPSPDisclosureForm, number>({
            query: (id) => ({
                url: `/get-psp-disclosure-form-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'PSPDisclosure', id: 'PSPDisclosure' }]
        }),
        getPSPDisclosureProgress: builder.query<IProgress, number>({
            query: (id) => ({
                url: `/get-total-progress/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'PSPDisclosure', id: 'PSPDisclosure' }]
        }),
        getMemberPSPDisclosureProgress: builder.query<IProgress, number>({
            query: (id) => ({
                url: `/get-total-progress/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'PSPDisclosure', id: 'PSPDisclosure' }]
        })
    })
});

export const {
    useCreatePSPDisclosureFormMutation,
    useUpdatePSPDisclosureFormMutation,
    useGetPSPDisclosureFormQuery,
    useGetPSPDisclosureFormByUserQuery,
    useGetPSPDisclosureFormByUserIdQuery,
    useGetPSPDisclosureProgressQuery,
    useGetMemberPSPDisclosureProgressQuery,
    useDeletePSPDisclosureFormMutation
} = pspDisclosureApi;
