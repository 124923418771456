import React, { useEffect } from 'react';
import { IMaintenanceVehicle } from '../../../../store/interfaces/PreventableMaintenance/maintenance.vehicles';
import { TransitionProps } from '@mui/material/transitions';
import { Button, Dialog, DialogContent, DialogTitle, Grid, Slide, TextField } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { INotesAndLinks, NotesAndLinksType } from '../../../../store/interfaces/PreventableMaintenance/notes.and.links';
import {
    useCreateNotesAndLinksMutation,
    useUpdateNotesAndLinksMutation
} from '../../../../store/reducers/PreventableMaintenance/notes.links';

interface IProps {
    open: boolean;
    setOpen: (val: boolean) => void;
    vehicle?: IMaintenanceVehicle;
    note?: INotesAndLinks;
    setNote?: (val?: INotesAndLinks) => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initialValues: Partial<INotesAndLinks> = {
    maintenance_vehicle: undefined,
    note: '',
    note_subject: '',
    type: NotesAndLinksType.NOTE
};

export const AddNoteModal: React.FunctionComponent<IProps> = ({ open, setOpen, vehicle, note, setNote }) => {
    const [createVehicleNote] = useCreateNotesAndLinksMutation();
    const [updateVehicleNote] = useUpdateNotesAndLinksMutation();
    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            note: Yup.string().required('Note is required'),
            note_subject: Yup.string().required('Note Subject is required')
        }),
        onSubmit: (values) => {
            if (note) {
                updateVehicleNote({
                    ...note,
                    ...values
                }).then(() => {
                    setOpen(false);
                    formik.resetForm();
                    setNote?.(undefined);
                });
            } else {
                createVehicleNote({
                    ...values,
                    maintenance_vehicle: vehicle
                }).then(() => {
                    setOpen(false);
                    formik.resetForm();
                });
            }
        }
    });

    useEffect(() => {
        if (note && open) {
            formik.setValues({
                note: note.note,
                note_subject: note.note_subject
            });
        }
        // eslint-disable-next-line
    }, [note, open]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
                setOpen(false);
                formik.resetForm();
                setNote?.(undefined);
            }}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle
                sx={{
                    textAlign: 'center',
                    backgroundColor: '#F5F5F5',
                    color: '#000000',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    padding: '20px 20px 20px 20px'
                }}
            >
                {note ? 'Update Note' : 'Add Note'}
            </DialogTitle>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2} p={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="note_subject"
                                name="note_subject"
                                label="Note Subject"
                                size="small"
                                value={formik.values.note_subject}
                                onChange={formik.handleChange}
                                error={formik.touched.note_subject && Boolean(formik.errors.note_subject)}
                                helperText={formik.touched.note_subject && formik.errors.note_subject}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="note"
                                name="note"
                                label="Note"
                                size="small"
                                multiline
                                rows={4}
                                value={formik.values.note}
                                onChange={formik.handleChange}
                                error={formik.touched.note && Boolean(formik.errors.note)}
                                helperText={formik.touched.note && formik.errors.note}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" size="small" fullWidth>
                                {note ? 'Update Note' : 'Add Note'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};
