import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, MenuItem, Slide, TextField } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { IMaintenanceVehicle, VehicleType } from "../../../store/interfaces/PreventableMaintenance/maintenance.vehicles";
import { useCompany } from "../../../context/CompanyContext";
import { useCreateMaintenanceVehicleMutation } from "../../../store/reducers/PreventableMaintenance/maintenance.vehicle";
import { useAuth } from "../../modules/auth";
import { useGetPmProgramsByCompanyQuery } from "../../../store/reducers/PreventableMaintenance/pm.program";

interface IProps {
    open: boolean;
    setOpen: (val: boolean) => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initialValues: Partial<IMaintenanceVehicle> = {
    vehicle_number: '',
    vehicle_make: '',
    vehicle_model: '',
    current_odometer_reading: 0,
    vehicle_type: VehicleType.TRUCK,
    pm_program: undefined,
}

const validationSchema = Yup.object().shape({
    vehicle_number: Yup.string().required('Vehicle Number is required'),
    vehicle_make: Yup.string().required('Vehicle Make is required'),
    vehicle_model: Yup.string().required('Vehicle Model is required'),
    current_odometer_reading: Yup.number().required('Odometer Reading is required'),
    vehicle_type: Yup.string().required('Vehicle Type is required'),
})

export const AddVehicleModal = ({ open, setOpen }: Readonly<IProps>) => {
    const { currentCompany } = useCompany()
    const { currentUser } = useAuth()
    const [createVehicle, { isLoading }] = useCreateMaintenanceVehicleMutation();
    const { data: pmPrograms = [] } = useGetPmProgramsByCompanyQuery(Number(currentCompany?.id) ?? currentUser?.company?.id, {
        skip: !open || (!currentCompany?.id && !currentUser?.company?.id),
    });
    const [addPmProgram, setAddPmProgram] = React.useState<boolean>(false);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            createVehicle({ ...values, company: Number(currentCompany?.id) ?? currentUser?.company?.id }).then(() => {
                setOpen(false);
                formik.resetForm();
            });
        }
    });

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpen(false)}
            maxWidth='sm'
            fullWidth
        >
            <DialogTitle sx={{
                textAlign: "center",
                backgroundColor: '#F5F5F5',
                color: '#000000',
                fontSize: '16px',
                fontWeight: 'bold',
                padding: '20px 20px 20px 20px',
            }}>
                Add Vehicle
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12} m={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <FormControlLabel
                            control={<Checkbox checked={addPmProgram} />}
                            label="Assign Pm Program"
                            onChange={() => setAddPmProgram(!addPmProgram)}
                        />
                    </Grid>
                    {addPmProgram && <Grid item xs={12} m={1}>
                        <TextField
                            fullWidth
                            label='Select PM Program'
                            value={formik.values.pm_program}
                            onChange={(e) => {
                                formik.setFieldValue('pm_program', e.target.value);
                            }}
                            error={formik.touched.pm_program && Boolean(formik.errors.pm_program)}
                            helperText={formik.touched.pm_program && formik.errors.pm_program}
                            size="small"
                            select
                        >
                            {pmPrograms.map((program) => (
                                <MenuItem key={program.id} value={program.id}>
                                    {program.program_name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>}
                    <Grid item xs={12} m={1}>
                        <TextField
                            fullWidth
                            label='Vehicle Number'
                            placeholder="Vehicle Number"
                            name='vehicle_number'
                            value={formik.values.vehicle_number}
                            onChange={formik.handleChange}
                            error={formik.touched.vehicle_number && Boolean(formik.errors.vehicle_number)}
                            helperText={formik.touched.vehicle_number && formik.errors.vehicle_number}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} m={1}>
                        <TextField
                            fullWidth
                            label='Vehicle Make'
                            placeholder="Vehicle Make"
                            name='vehicle_make'
                            value={formik.values.vehicle_make}
                            onChange={formik.handleChange}
                            error={formik.touched.vehicle_make && Boolean(formik.errors.vehicle_make)}
                            helperText={formik.touched.vehicle_make && formik.errors.vehicle_make}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} m={1}>
                        <TextField
                            fullWidth
                            label='Vehicle Model'
                            placeholder="Vehicle Model"
                            name='vehicle_model'
                            value={formik.values.vehicle_model}
                            onChange={formik.handleChange}
                            error={formik.touched.vehicle_model && Boolean(formik.errors.vehicle_model)}
                            helperText={formik.touched.vehicle_model && formik.errors.vehicle_model}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} m={1}>
                        <TextField
                            fullWidth
                            select
                            name='vehicle_type'
                            value={formik.values.vehicle_type}
                            onChange={formik.handleChange}
                            error={formik.touched.vehicle_type && Boolean(formik.errors.vehicle_type)}
                            helperText={formik.touched.vehicle_type && formik.errors.vehicle_type}
                            size="small"
                        >
                            {Object.values(VehicleType).map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} m={1}>
                        <TextField
                            fullWidth
                            label='Odometer Reading'
                            placeholder="Odometer Reading"
                            name='current_odometer_reading'
                            value={formik.values.current_odometer_reading}
                            onChange={formik.handleChange}
                            error={formik.touched.current_odometer_reading && Boolean(formik.errors.current_odometer_reading)}
                            helperText={formik.touched.current_odometer_reading && formik.errors.current_odometer_reading}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button disabled={isLoading || !formik.isValid} fullWidth variant='contained' color='primary' onClick={() => formik.handleSubmit()}>
                            Add Vehicle
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};