import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    IRequestBySignature,
    IRequestBySignatureDto
} from '../../interfaces/RequestForCheckOfDrivingRecords/request-by-signature';

export const requestBySignatureApi = createApi({
    reducerPath: 'request_by_signature',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/request-by-signature`
    }),
    tagTypes: ['RequestBySignature'],
    endpoints: (builder) => ({
        createRequestBySignature: builder.mutation<IRequestBySignature, IRequestBySignatureDto>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'RequestBySignature', id: 'RequestBySignature' }]
        }),
        updateRequestBySignature: builder.mutation<IRequestBySignature, IRequestBySignature>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'RequestBySignature', id: 'RequestBySignature' }]
        }),
        deleteRequestBySignature: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'RequestBySignature', id: 'RequestBySignature' }]
        }),
        getRequestBySignature: builder.query<IRequestBySignature, number | void>({
            query: (id) => ({
                url: `/get-request-by-signature/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'RequestBySignature', id: 'RequestBySignature' }]
        }),
        getRequestBySignatureByUser: builder.query<IRequestBySignature, void>({
            query: () => ({
                url: `/get-request-by-signature-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'RequestBySignature', id: 'RequestBySignature' }]
        }),
        getRequestbySignatureByUserId: builder.query<IRequestBySignature, number | void>({
            query: (id) => ({
                url: `/get-request-by-signature-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'RequestBySignature', id: 'RequestBySignature' }]
        })
    })
});

export const {
    useCreateRequestBySignatureMutation,
    useUpdateRequestBySignatureMutation,
    useGetRequestBySignatureQuery,
    useGetRequestBySignatureByUserQuery,
    useGetRequestbySignatureByUserIdQuery,
    useDeleteRequestBySignatureMutation
} = requestBySignatureApi;
