import React from 'react'
import ExpiringFilesTable from '../../../compliancemasters/partials/widgets/tables/MemberTable/ExpiringFilesTable'
import { useCompany } from '../../../context/CompanyContext'
import { getCompanySubscription } from '../../../shared'
import { StripeProduct } from '../../modules/models'
import { toast } from 'react-toastify'
import { PageTitle } from '../../../compliancemasters/layout/core'
import { useIntl } from 'react-intl'
import { CircularProgress } from '@mui/material'
import { useAuth } from '../../modules/auth'

const ExpiringFilesDashboard = () => {
    const { currentCompany } = useCompany()
    const { currentUser } = useAuth()
    const [companySub, setCompanySub] = React.useState<StripeProduct[]>([])
    const [loading, setLoading] = React.useState(true)
    const intl = useIntl();


    const getCompanySub = async () => {
        setLoading(true)
        const sub = await getCompanySubscription()
        if (sub) {
            const uniqueProducts = sub.filter(
                (function () {
                    const seenIds = new Set<string>();
                    return (product: StripeProduct) => {
                        if (!seenIds.has(product.name)) {
                            seenIds.add(product.name);
                            return true;
                        }
                        return false;
                    };
                })()
            )
            setCompanySub(uniqueProducts)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        getCompanySub()
    }, [])


    React.useEffect(() => {
        if (!loading && companySub.length !== currentCompany?.package?.length && currentUser?.role === "Owner") {
            // Redirect to Subscription and Payment Page
            toast.error("Please subscribe to all your selected packages to continue")
            setTimeout(() => {
                window.location.assign("/subscription/plan")
            }, 4000)
        }
    }, [loading, companySub, currentCompany, currentUser])
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <div className="row gy-5 g-xl-8">
                <div className="col-xl-12">
                    {currentUser?.role === "Owner" && <>
                        {loading ? (
                            <div className="row gy-5 g-xl-8">
                                <div className="col-xl-12">
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        height: "100vh"
                                    }}>
                                        <CircularProgress />
                                    </div>
                                </div>
                            </div>
                        ) : companySub.length === currentCompany?.package.length && !loading ? (
                            <ExpiringFilesTable />
                        ) : (
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                height: "100vh"
                            }}>
                                <div className="alert alert-danger text-center" role="alert">
                                    You have not subscribed to all your selected packages. Please subscribe to all your selected packages to continue.
                                </div>
                            </div>
                        )}
                    </>}
                    {currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role) && (
                        <>
                            {loading ? (
                                <div className="row gy-5 g-xl-8">
                                    <div className="col-xl-12">
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            height: "100vh"
                                        }}>
                                            <CircularProgress />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <ExpiringFilesTable />
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default ExpiringFilesDashboard