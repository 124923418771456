import { IServiceOrders } from '../../store/interfaces/PreventableMaintenance/service.orders';
import client from '../Client';

export const startUpload = (val: Partial<IServiceOrders>) => {
    return client('auth/api/preventable-maintenance/service-orders/', {
        body: val
    });
};

export const finishUpload = (fileId: number) => {
    return client('auth/api/preventable-maintenance/service-orders/', {
        body: {
            fileId
        }
    });
};

export const getTrainingResources = () => {
    return client('auth/api/preventable-maintenance/service-orders/');
};

export const downloadFile = (fileId: number) => {
    return client('auth/api/preventable-maintenance/service-orders/download', {
        body: {
            id: fileId
        }
    });
};
