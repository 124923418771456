/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {
    Box,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
import { IServiceTypes } from '../../../../store/interfaces/PreventableMaintenance/service.types';
import { IMaintenanceVehicle } from '../../../../store/interfaces/PreventableMaintenance/maintenance.vehicles';
import TableWrapper from '../../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import TableContainer from '../../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableHeader from '../../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import { KTSVG } from '../../../../compliancemasters/helpers';
import { AddServiceTypeModal } from './AddServiceTypeModal';
import { MoreVertRounded } from '@mui/icons-material';
import { VehicleListModal } from './VehicleListModal';
import { toast } from 'react-toastify';
import { DeleteServiceTypeModal } from './DeleteServiceTypeModal';

interface IProps {
    service_types?: IServiceTypes[];
    vehicle?: IMaintenanceVehicle;
    onCreate?: () => void;
}

const PMProgram: React.FunctionComponent<IProps> = ({ service_types, vehicle, onCreate }) => {
    const [open, setOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [page, setPage] = useState<number>(0);
    const [assignModalOpen, setAssignModalOpen] = useState(false);
    const [serviceType, setServiceType] = useState<IServiceTypes>();
    const [limit, setLimit] = useState<number>(10);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };

    const getServiceName = (count: number) => {
        const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const index = count % 26;
        const repeat = Math.floor(count / 26);
        return `${alphabet[index].repeat(repeat + 1)}`;
    };

    return (
        <Box component={Paper} elevation={2} boxShadow={3} sx={{ borderRadius: 2 }} p={2} mt={3}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'gray' }}>
                        Preventable Maintenance Program for Vehicle No. {vehicle?.vehicle_number}
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'green' }}>
                        Please note that Service Reminders rely on updated odometer readings provided by completing
                        Driver Vehicle Inspection Reports
                    </Typography>
                </Grid>
            </Grid>
            <TableWrapper>
                <TableContainer>
                    <TableHeader>
                        <TableHeaderColumn>Service Name</TableHeaderColumn>
                        <TableHeaderColumn>Frequency</TableHeaderColumn>
                        <TableHeaderColumn>Description</TableHeaderColumn>
                        <TableHeaderColumn>{''}</TableHeaderColumn>
                    </TableHeader>
                    <TableBody>
                        {service_types
                            ?.slice(page * limit, page * limit + limit)
                            ?.sort((a, b) => new Date(a.created_at!).getTime() - new Date(b.created_at!).getTime())
                            .map((service, index) => (
                                <TableRow key={service.id} sx={{ cursor: 'pointer' }} hover>
                                    <TableCell>{service.name}</TableCell>
                                    <TableCell>Occurs every {service.miles} miles</TableCell>
                                    <TableCell>{service.description}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={(event) => {
                                                setServiceType(service);
                                                handleClick(event);
                                            }}
                                        >
                                            <MoreVertRounded />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={service_types?.length ?? 0}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[10, 25, 30]}
                />
            </TableWrapper>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3}>
                    <div
                        className="btn btn-sm btn-light-primary d-inline-flex align-items-center"
                        onClick={() => setOpen(true)}
                    >
                        <KTSVG path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
                        Add Service ({getServiceName(service_types?.length ?? 0)},{' '}
                        {getServiceName(service_types?.length ? Number(service_types?.length!) + 1 : 1)},{' '}
                        {getServiceName(service_types?.length ? service_types?.length! + 2 : 2)},...)
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={3}></Grid>
                <Grid item xs={12} sm={12} md={3}></Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <div style={{ width: '100%', justifyContent: 'end', alignItems: 'flex-end' }}>
                        <a
                            style={{ float: 'right', textDecoration: 'underline' }}
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setAssignModalOpen(true);
                            }}
                        >
                            Assign this PM Program to other vehicles
                        </a>
                    </div>
                </Grid>
            </Grid>
            <AddServiceTypeModal
                serviceType={serviceType}
                onCreate={onCreate}
                service_type_count={service_types?.length}
                open={open}
                setOpen={setOpen}
                vehicle={vehicle}
                setServiceType={setServiceType}
            />
            <DeleteServiceTypeModal open={deleteModalOpen} setOpen={setDeleteModalOpen} serviceType={serviceType} />
            <VehicleListModal open={assignModalOpen} setOpen={setAssignModalOpen} pmProgram={vehicle?.pm_program} />
            <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleClose}>
                <MenuItem
                    onClick={() => {
                        setOpen(true);
                        toast.warn(
                            'If you have this vehicle’s PM Program assigned to other vehicles, remember that any changes you make to this Service will only be changed for this vehicle.',
                            { autoClose: 10000 }
                        );
                        handleClose();
                    }}
                >
                    Update Service Type
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setDeleteModalOpen(true);
                    }}
                >
                    Delete Service Type
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default PMProgram;
