import * as React from 'react';
import { IRandomDACHTesting, RandomDACHTestingStatus } from '../../../../../store/interfaces/RandomDach/random-dach';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Grid, InputLabel, MenuItem, TextField } from '@mui/material'; import { useUpdateRandomDACHTestingMutation } from '../../../../../store/reducers/RandomDach/random-dach';
;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const initialValues: Partial<IRandomDACHTesting> = {
    company: undefined,
    created_at: undefined,
    date_completed: undefined,
    id: undefined,
    is_active: undefined,
    members: undefined,
    quarter: undefined,
    status: undefined,
    updated_at: undefined,
    year: undefined,
}

const validationSchema = Yup.object({
    status: Yup.string().required('Status is required'),
})

export default function UpdateRandomTesting({
    open,
    setOpen,
    randomTesting,
    getDachTesting,
}: {
    open: boolean
    setOpen: (open: boolean) => void
    randomTesting?: IRandomDACHTesting
    getDachTesting: () => void
}) {
    const [updateRandomTestinng] = useUpdateRandomDACHTestingMutation()
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            // @ts-ignore
            updateRandomTestinng({
                ...randomTesting,
                ...values
            }).then(() => {
                toast.success('Random Testing Selection Updated Successfully')
                getDachTesting()
                setOpen(false)
            })
        },
    })
    const handleClose = () => {
        setOpen(false);
        formik.resetForm()
    };

    React.useEffect(() => {
        if (randomTesting && open) {
            formik.setValues(randomTesting)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, randomTesting])

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            maxWidth='sm'
            fullWidth
        >
            <DialogTitle sx={{
                backgroundColor: '#1e1e2d',
                color: 'white',
                textAlign: 'center'
            }}>
                Update Random Testing Selection Status
            </DialogTitle>
            <DialogContent sx={{
                p: 4
            }}>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12} sm={12}>
                        <InputLabel>
                            Update Status
                        </InputLabel>
                        <TextField
                            fullWidth
                            size='small'
                            id="status"
                            name="status"
                            value={formik.values.status}
                            onChange={(e) => formik.setFieldValue('status', e.target.value)}
                            select
                        >
                            {Object.values(RandomDACHTestingStatus).map((status) => (
                                <MenuItem key={status} value={status}>
                                    {status}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{
                                backgroundColor: '#1e1e2d',
                            }}
                            color="primary"
                            onClick={() => {
                                formik.handleSubmit()
                            }}
                        >
                            Update Company
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
