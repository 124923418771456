/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useLayout } from '../../core';
import { KTSVG, } from '../../../helpers';
import { AsideMenu } from './AsideMenu';
import { useAuth } from '../../../../app/modules/auth';

function AsideDefault() {
    const { config, classes } = useLayout();
    const { currentUser } = useAuth();
    const asideRef = useRef<HTMLDivElement | null>(null);
    const { aside } = config;
    const dontDisplay = ['Member', "SalesRep"].includes(currentUser?.role || '') || !aside.display;
    const [minimized, setMinimized] = useState(false);
    const minimize = () => {
        asideRef.current?.classList.add('animating');
        setTimeout(() => {
            asideRef.current?.classList.remove('animating');
        }, 300);
        setMinimized(!minimized);
    };

    useEffect(() => {
        if (["Member", "SalesRep"].includes(currentUser?.role!)) {
            document.body.classList.remove('aside-enabled');
        } else {
            document.body.classList.add('aside-enabled');
        }
    }, [currentUser]);

    if (!dontDisplay) {
        return (
            <div
                id="kt_aside"
                className={clsx('aside', classes.aside.join(' '))}
                data-kt-drawer="true"
                data-kt-drawer-name="aside"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                data-kt-drawer-direction="start"
                data-kt-drawer-toggle="#kt_aside_mobile_toggle"
                ref={asideRef}
            >
                {/* begin::Brand */}
                <div className="aside-logo flex-column-auto" id="kt_aside_logo">
                    {/* begin::Logo */}
                    {!minimized && (
                        <Link to="/dashboard">
                            <div className="text-white">Compliance Master</div>
                        </Link>
                    )}
                    {/* end::Logo */}

                    {/* begin::Aside toggler */}
                    {aside.minimize && (
                        <div
                            id="kt_aside_toggle"
                            className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
                            data-kt-toggle="true"
                            data-kt-toggle-state="active"
                            data-kt-toggle-target="body"
                            data-kt-toggle-name="aside-minimize"
                            onClick={minimize}
                        >
                            <KTSVG
                                path={'/media/icons/duotune/arrows/arr080.svg'}
                                className={'svg-icon-1 rotate-180'}
                            />
                        </div>
                    )}
                    {/* end::Aside toggler */}
                </div>
                {/* end::Brand */}

                {/* begin::Aside menu */}
                <div className="aside-menu flex-column-fluid">
                    <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
                </div>
                {/* end::Aside menu */}

                {/* begin::Footer */}
                {/* <div className='aside-footer flex-column-auto pt-5 pb-7 px-5' id='kt_aside_footer'></div> */}
            </div>
        );
    } else {
        return null;
    }
}

export { AsideDefault };
