
import Button from '../../../../app/modules/buttons/Button';
import { KTSVG } from '../../../../compliancemasters/helpers';
import { IMember } from '../../../../models';
import React from 'react';
import { useAuth } from '../../auth';
import { toast } from 'react-toastify';

interface IProps {
    member: IMember;
    onSubmit?: () => void;
}


const TerminationMessage = ({ member, term }: { term: string; member: IMember }) => (
    <div className="row">
        {term === 'Terminate' ? (
            <span className="fs-2x fw-bolder">
                Are you sure you want to end Pre Employment Screening for
                <span className="text-danger">
                    {` ${member.user.first_name} ${member.user.last_name}?  `}
                </span>
                This will end the investigation and remove the applicants profile from your dashboard.
            </span>
        ) : (
            <span className="fs-2x fw-bolder">
                Activating
                <span className={'text-success'}>
                    {` ${member.user.first_name} ${member.user.last_name}  `}
                </span> will add 1 driver to the package and change your subscription price.
                The driver’s profile will be active on the Company dashboard.
                Are you sure you want to activate
                ?
            </span>
        )}
    </div>
)

const TerminationComplete = () => (
    <div className="row">
        <span className="fs-2x fw-bolder">
            Pre Employment Screening has ended.
        </span>
    </div>
)


export function EndInvestigationModal({ member, onSubmit }: IProps) {
    const { deleteUser } = useAuth();
    const term = member.terminated_at ? 'Activate' : 'Terminate';
    const [terminationComplete, setTerminationComplete] = React.useState(false);

    function handleClick() {
        deleteUser(member?.user?.email, 'hard').then(() => {
            toast.success('User deleted successfully');
            setTerminationComplete(true);
            setTimeout(() => {
                window.location.assign("/dashboard")
            }, 3000);
        })
    }



    return (
        <div className="modal fade" id="end_investigation_modal" role="dialog" tabIndex={-1} aria-modal="true">
            <div className="modal-dialog modal-dialog-centered mw-650px">
                <div className="modal-content">
                    <div className="modal-header">
                        {/* begin::Modal title */}
                        <h2 className="fw-bolder">{term} Member</h2>
                        {/* end::Modal title */}

                        {/* begin::Close */}
                        <div
                            className="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            style={{ cursor: 'pointer' }}
                        >
                            <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
                        </div>
                        {/* end::Close */}
                    </div>

                    <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                        <div className="text-center">
                            <div className="mb-10">
                                {terminationComplete ? <TerminationComplete /> : <TerminationMessage member={member} term={term} />}
                                <div className="row justify-content-center pt-10">
                                    <div className="col">
                                        {!terminationComplete ? <>
                                            {term === "Terminate" ? (
                                                <>
                                                    <Button onClick={handleClick}>
                                                        <span className="indicator-label">Yes</span>
                                                    </Button>
                                                    <Button dataBsDismiss="modal" className="btn btn-secondary ms-4">
                                                        <span className="indicator-label">No</span>
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <Button dataBsDismiss="modal" onClick={handleClick}>
                                                        <span className="indicator-label">Yes</span>
                                                    </Button>
                                                    <Button dataBsDismiss="modal" className="btn btn-secondary ms-4">
                                                        <span className="indicator-label">No</span>
                                                    </Button>
                                                </>
                                            )}
                                        </> : (

                                            <Button dataBsDismiss="modal" onClick={() => {
                                                setTerminationComplete(false);
                                            }}>
                                                <span className="indicator-label">Close</span>
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
