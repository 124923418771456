import React from 'react'
import { IMember } from '../../../models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Grid, TextField, Typography } from '@mui/material'
import {
    IPreviousPreEmploymentDrugWitnessSignatureDto
} from '../../../store/interfaces/PreviousPreEmploymentDrugAndAlcoholTestStatement/PreviousPreEmploymentDrugWitnessSignature'
import {
    useCreatePreviousPreEmploymentDrugWitnessSignatureMutation,
    useUpdatePreviousPreEmploymentDrugWitnessSignatureMutation,
    useGetPreviousPreEmploymentDrugWitnessSignatureByUserIdQuery,
    useDeletePreviousPreEmploymentDrugWitnessSignatureMutation
} from '../../../store/reducers/PreviousPreEmploymentDrugAndAlcoholTestStatement/previous-pre-employment-drug-witness-singature'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'
import { useGetProcessRecordByUserIdQuery } from '../../../store/reducers/DriversApplicationForEmployment/process-record'
import { useGetCertificationByUserIdQuery } from '../../../store/reducers/CertificateOfComplianceWithDriverLicenseRequirements/certification'


const initialValues: Partial<IPreviousPreEmploymentDrugWitnessSignatureDto> = {
    witness_signature: "",
    date: '',
}


const validationSchema = Yup.object({
    witness_signature: Yup.string().required('Required'),
    date: Yup.string().required('Required'),
})


interface IProps {
    member?: IMember
}


const WitnessSignature = ({ member }: IProps) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data } = useGetPreviousPreEmploymentDrugWitnessSignatureByUserIdQuery(member?.user?.id!, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })

    const { data: processRecord } = useGetProcessRecordByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    })

    const { data: cert } = useGetCertificationByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })

    const [createPreviousPreEmploymentDrugWitnessSignature, { isLoading: isCreating }] = useCreatePreviousPreEmploymentDrugWitnessSignatureMutation()
    const [updatePreviousPreEmploymentDrugWitnessSignature, { isLoading: isUpdating }] = useUpdatePreviousPreEmploymentDrugWitnessSignatureMutation()
    const [deletePreviousPreEmploymentDrugWitnessSignature] = useDeletePreviousPreEmploymentDrugWitnessSignatureMutation()

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                updatePreviousPreEmploymentDrugWitnessSignature({
                    ...data,
                    ...values,
                })
            } else {
                createPreviousPreEmploymentDrugWitnessSignature({
                    ...values,
                    // @ts-ignore
                    company: member?.company?.id!,
                    // @ts-ignore
                    member: member?.id!,
                })
            }
            resetForm()
        },
        validateOnChange: true,
        validateOnBlur: true,
        validate(values) {
            const errors: Partial<IPreviousPreEmploymentDrugWitnessSignatureDto> = {}
            if (!values.witness_signature) {
                errors.witness_signature = 'Required'
            }
            if (!values.date) {
                errors.date = 'Required'
            }
            return errors
        },
    })

    React.useEffect(() => {
        if (data) {
            formik.setValues(data)
        } else if (processRecord) {
            formik.setFieldValue("date", processRecord?.date_employed)
        } else if (cert) {
            formik.setFieldValue("date", cert?.date_of_hire)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, processRecord, cert])

    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                Witness Signature
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify" }} variant="body1" gutterBottom component="p">
                I certify that the information provided on this document is true and correct.
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Witness By (Signature)
                    </Typography>
                    <TextField
                        fullWidth
                        id="witness_signature"
                        placeholder='Witness By (Signature)'
                        size="small"
                        variant="outlined"
                        value={formik.values.witness_signature}
                        onChange={(e) => {
                            formik.setFieldValue('witness_signature', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.witness_signature && Boolean(formik.errors.witness_signature) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.witness_signature && Boolean(formik.errors.witness_signature) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.witness_signature && Boolean(formik.errors.witness_signature) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                fontStyle: 'italic'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Date of Hire
                    </Typography>
                    <TextField
                        fullWidth
                        id="date"
                        type='date'
                        size="small"
                        variant="outlined"
                        value={formik.values.date}
                        onChange={(e) => {
                            formik.setFieldValue('date', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                {!data || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role)) ? <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={isCreating || isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                        Save
                    </Button>
                    {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                        <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                            Once you click save, you will not be able to edit this form.
                        </Typography>
                    )}
                </Grid > : null}
                {data && currentUser?.role === "SuperAdmin" && <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={!!member?.terminated_at} onClick={() => {
                        const res = window.confirm("Are you sure you want to delete this form?")
                        if (res && data) {
                            deletePreviousPreEmploymentDrugWitnessSignature(data.id)
                        }
                    }} variant="contained" color="primary">
                        Delete Form
                    </Button>
                </Grid>}
            </Grid >
        </div >
    )
}

export default WitnessSignature