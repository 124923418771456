import client from '../Client';

export enum TrainingResourcesFileType {
    LINK = 'LINK',
    FILE = 'FILE'
}
export interface ITrainingResourcesDto {
    id: number;
    title: string;
    description: string;
    file_name: string;
    file_type: string;
    original_file_name: string;
    original_name: string;
    training_type: TrainingResourcesFileType;
    link: string;
    file_changed?: boolean;
}

export const startUpload = (val: Partial<ITrainingResourcesDto>) => {
    return client(`auth/api/training-resources/upload/start/`, {
        body: val
    });
};

export const finishUpload = (fileId: number) => {
    return client(`auth/api/training-resources/upload/finish/`, {
        body: {
            fileId
        }
    });
};

export const getTrainingResources = () => {
    return client(`auth/api/training-resources/`);
};

export const deleteTrainingResource = (fileId: number) => {
    return client(`auth/api/training-resources/hard-delete/`, {
        body: {
            fileId
        }
    });
};

export const updateTrainingResource = (fileId: number, body: Partial<ITrainingResourcesDto>) => {
    return client(`auth/api/training-resources/${fileId}/`, {
        body,
        method: 'PUT'
    });
};

export const downloadFile = (fileId: number) => {
    return client(`auth/api/training-resources/download/`, {
        body: {
            id: fileId
        }
    });
};
