import TableContainerHeaderTitle from '../components/TableContainerHeaderTitle';
import TableContainerHeader from '../components/TableContainerHeader';
import TableWrapper from '../components/TableWrapper';
import TableContainer from '../components/TableContainer';
import TableHeader from '../components/TableHeader';
import TableHeaderColumn from '../components/TableHeaderColumn';
import FileRow from './components/FileRow';
import { IFile, IMember } from '../../../../../models';
import TableLoader from '../components/TableLoader';
import NewFileModalButton from '../../../modals/new-file/NewFileModalButton';
import { useAuth } from '../../../../../app/modules/auth';
import { useState } from 'react';
import DeleteFileModal from '../../../modals/delete-file/DeleteFileModal';

interface IProps {
    files: IFile[];
    memberId: string | number;
    onAddNewFile?: () => void;
    location?: string;
    member: IMember
    subscription_type?: string;
}
export default function FilesTable({ files, memberId, onAddNewFile, location, member, subscription_type }: IProps) {
    const [file, setFile] = useState<IFile>();
    const { currentUser } = useAuth()
    return (
        <>
            <TableWrapper>
                <TableContainerHeader>
                    <TableContainerHeaderTitle
                        successText={member?.pre_employment_screened && location && !["Non-Current", "Drug"].includes(location) && subscription_type !== "Drug and Alcohol Package" ? "For all drivers, upload a picture of their medical card, both sides of drivers license, and any pre-employment investigations you might have." : ""}
                        title={`${location === "Drug" ? `${location} File` : location} Uploads`}
                    />
                    {location !== "Non-Current" && <NewFileModalButton memberId={memberId} onAdd={onAddNewFile} location={location} subscription_type={subscription_type} />}
                </TableContainerHeader>
                <TableContainer>
                    <TableHeader>
                        <TableHeaderColumn>File</TableHeaderColumn>
                        <TableHeaderColumn>Desc.</TableHeaderColumn>
                        <TableHeaderColumn>Expiration</TableHeaderColumn>
                        <TableHeaderColumn>location</TableHeaderColumn>
                        {location === "Drug" && (<>
                            <TableHeaderColumn>Quarter</TableHeaderColumn>
                            <TableHeaderColumn>Year</TableHeaderColumn>
                        </>)}
                        <TableHeaderColumn end>View</TableHeaderColumn>
                        {currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role) && <TableHeaderColumn end>Delete</TableHeaderColumn>}
                        {currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role) && <TableHeaderColumn end>{""}</TableHeaderColumn>}
                    </TableHeader>

                    <tbody>
                        {files.map((file) => (
                            <FileRow location={location} setFile={setFile} key={file.id} file={file} showDelete={currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role)} />
                        ))}
                    </tbody>
                </TableContainer>
                <TableLoader length={files.length} emptyText={'No files yet.'} />
            </TableWrapper>
            <DeleteFileModal onDeleteFile={onAddNewFile!} fileId={file?.id} fileName={file?.title} userName={`${member?.user?.first_name} ${member?.user?.last_name}`} />
        </>
    );
}
