import { IPreEmploymentInvestigation } from '../../../../models'

interface IProps {
    investigation: Partial<IPreEmploymentInvestigation>
    price?: number
}

const PreEmploymentRow = ({ investigation, price }: IProps) => {



    return (
        <tr>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">{investigation?.type}</div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">{investigation?.is_current_investigation ? "In Progress" : investigation?.screened ? "Completed" : "Not Started"}</div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">{price ? "Yes" : "No"}</div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">{investigation?.date_started ? "Yes" : "No"}</div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">{investigation?.date_started ? Intl.DateTimeFormat('en-US', {
                        dateStyle: 'medium',
                    }).format(new Date(investigation?.date_started)) : "N/A"}</div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">{investigation.date_started ? investigation?.payment : "N/A"}</div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">{price ? Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    }).format(price) : "N/A"}</div>
                </div>
            </td>
        </tr>
    )
}

export default PreEmploymentRow