import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    IPreTripInspectionAndEmergencyEquipmentDto,
    IPreTripInspectionAndEmergencyEquipment
} from '../../interfaces/RecordOfRoadTest/pre-trip-inspection-and-emergency-equipment';

export const preTripInspectionAndEmergencyEquipmentApi = createApi({
    reducerPath: 'pre_trip_inspection_and_emergency_equipment',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/pre-trip-inspection-and-emergency-equipment`
    }),
    tagTypes: ['PreTripInspectionAndEmergencyEquipment'],
    endpoints: (builder) => ({
        createPreTripInspectionAndEmergencyEquipment: builder.mutation<
            IPreTripInspectionAndEmergencyEquipment,
            IPreTripInspectionAndEmergencyEquipmentDto
        >({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'PreTripInspectionAndEmergencyEquipment',
                    id: 'PreTripInspectionAndEmergencyEquipment'
                }
            ]
        }),
        updatePreTripInspectionAndEmergencyEquipment: builder.mutation<
            IPreTripInspectionAndEmergencyEquipment,
            IPreTripInspectionAndEmergencyEquipment
        >({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'PreTripInspectionAndEmergencyEquipment',
                    id: 'PreTripInspectionAndEmergencyEquipment'
                }
            ]
        }),
        deletePreTripInspectionAndEmergencyEquipment: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'PreTripInspectionAndEmergencyEquipment',
                    id: 'PreTripInspectionAndEmergencyEquipment'
                }
            ]
        }),
        getPreTripInspectionAndEmergencyEquipment: builder.query<
            IPreTripInspectionAndEmergencyEquipment,
            number | void
        >({
            query: (id) => ({
                url: `/get-pre-trip-inspection-and-emergency-equipment/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'PreTripInspectionAndEmergencyEquipment',
                    id: 'PreTripInspectionAndEmergencyEquipment'
                }
            ]
        }),
        getPreTripInspectionAndEmergencyEquipmentByUser: builder.query<IPreTripInspectionAndEmergencyEquipment, void>({
            query: () => ({
                url: `/get-pre-trip-inspection-and-emergency-equipment-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'PreTripInspectionAndEmergencyEquipment',
                    id: 'PreTripInspectionAndEmergencyEquipment'
                }
            ]
        }),
        getPreTripInspectionAndEmergencyEquipmentByUserId: builder.query<
            IPreTripInspectionAndEmergencyEquipment,
            number | void
        >({
            query: (id) => ({
                url: `/get-pre-trip-inspection-and-emergency-equipment-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'PreTripInspectionAndEmergencyEquipment',
                    id: 'PreTripInspectionAndEmergencyEquipment'
                }
            ]
        })
    })
});

export const {
    useCreatePreTripInspectionAndEmergencyEquipmentMutation,
    useUpdatePreTripInspectionAndEmergencyEquipmentMutation,
    useGetPreTripInspectionAndEmergencyEquipmentQuery,
    useGetPreTripInspectionAndEmergencyEquipmentByUserQuery,
    useGetPreTripInspectionAndEmergencyEquipmentByUserIdQuery,
    useDeletePreTripInspectionAndEmergencyEquipmentMutation
} = preTripInspectionAndEmergencyEquipmentApi;
