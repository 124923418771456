import { Suspense } from 'react'
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../compliancemasters/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../compliancemasters/layout/core'
import { MasterInit } from '../compliancemasters/layout/MasterInit'
import { CompanyProvider } from '../context/CompanyContext'
import { AuthInit } from './modules/auth'

const App = () => {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <I18nProvider>
                <LayoutProvider>
                    <AuthInit>
                        <CompanyProvider>
                            <CompanyProvider>
                                <Outlet />
                                <MasterInit />
                            </CompanyProvider>
                        </CompanyProvider>
                    </AuthInit>
                </LayoutProvider>
            </I18nProvider>
        </Suspense>
    )
}

export default withLDProvider({
    clientSideID: '659ef5fa6f94d4103794d24d',
    context: {
        "kind": "user",
        "key": "user",
        "name": "user",
    }
})(App);

// export {App}
