import { Route, Routes, Navigate, useParams } from 'react-router-dom';
import { MasterLayout } from '../../compliancemasters/layout/MasterLayout';
import { useAuth } from '../modules/auth';
import CompanyPrivateRoutes from './CompanyPrivateRoutes';
import MemberPrivateRoutes from './MemberPrivateRoutes';
import React, { lazy } from 'react';
import { SuspensedView } from './SuspendedView';
import AdminPrivateRoutes from './AdminPrivateRoutes';
import SuperAdminPrivateRoutes from './SuperAdminPrivateRoutes';
import NotificationPrivateRoutes from './NotificationPrivateRoutes';
import DriversApplicationForEmployment from '../../compliancemasters/reforms/DriversApplicationForEmployment';
import EmploymentHistory from '../../compliancemasters/reforms/EmploymentHistory';
import CertificateOfComplianceWithDriverLicenseRequirements from '../../compliancemasters/reforms/CertificateOfComplianceWithDriverLicenseRequirements';
import RequestForCheckOfDrivingRecords from '../../compliancemasters/reforms/RequestForCheckOfDrivingRecords';
import DriversStatementDutyHours from '../../compliancemasters/reforms/DriversStatementDutyHours';
import MedicalExaminerNationalRegistry from '../../compliancemasters/reforms/MedicalExaminerNationalRegistryVerification';
import DriversHandbookReceipt from '../../compliancemasters/reforms/DriversHandbookReceipt';
import RecordOfRoadTestComponent from '../../compliancemasters/reforms/RecordOfRoadTest';
import CertificateOfRoadTest from '../../compliancemasters/reforms/CertificateOfRoadTest';
import DriverAuthorization from '../../compliancemasters/reforms/LimitedQueriesDrugAndAlcoholClearinghouse';
import PreviousEmployeeSafetyPerformance from '../../compliancemasters/reforms/PreviousEmployeeSafetyPerformanceHistory';
import { PrevPreEmploymentDach } from '../../compliancemasters/reforms/PrePreEmploymentDach';
import DachCertifiedReceipt from '../../compliancemasters/reforms/DachCertifiedReceipt';
import DocsActualKnowledge from '../../compliancemasters/reforms/DocsActualKnowledge';
import AnnualReviewDrivingRecords from '../../compliancemasters/reforms/AnnualReviewDrivingRecords';
import DriversReleaseForm from '../../compliancemasters/reforms/DriverReleaseForm';
import DriverReleaseFormBackgroundChecks from '../../compliancemasters/reforms/DriverReleaseFormBackgroundChecks';
import PSPDisclosure from '../../compliancemasters/reforms/PSPDisclosure';
import WrittenPolicy from '../../compliancemasters/reforms/WrittenPolicy';
import NonCurrentAnnualReviewDrivingRecords from '../modules/profile/components/Forms/AnnualReviewofDrivingRecords';
import NonCurrentMedicalExaminerNationalRegistry from '../modules/profile/components/Forms/MedicalExaminerNationalRegistryVerification';
import { StripeProduct } from '../modules/models';
import { IMember } from '../../models';
import { getCompanyMember, getSubscriptionPlans } from '../../shared';
import SalesRepPrivateRoutes from './SalesRepPrivateRoutes';
import NonCurrentDriverAuthorization from '../modules/profile/components/Forms/DachClearingHouse/index';
import SelectVehicle from '../modules/auth/components/SelectVehicle';
import MaintenanceMemberPrivateRoutes from './MaintenanceMemberPrivateRoutes';
import { IPayloadProps } from '../../compliancemasters/layout/components/aside/AsideMenus/OwnerAsideMenu';
import { useFlags } from 'launchdarkly-react-client-sdk';

const DriverPdfView = lazy(() => import('../../app/modules/profile/components/DriverPDFView'));

const PrivateRoutes = () => {
    const { currentUser } = useAuth();
    const { '*': urlParam } = useParams();
    const [memberSubscription, setMemberSubscription] = React.useState<StripeProduct | undefined>(undefined);
    const [subscriptions, setSubscriptions] = React.useState<Array<StripeProduct>>([]);
    const [member, setMember] = React.useState<IMember>();
    const { pmProgramEnabled, pmProgramEnabledUrl } = useFlags<IPayloadProps>();

    const getPlanOptions = React.useCallback(async () => {
        const subs = await getSubscriptionPlans();
        setSubscriptions(subs.data);
    }, []);

    // React.useEffect(() => {
    //     const parts = urlParam?.split('/');
    //     if (
    //         currentUser?.role !== 'Member' &&
    //         parts?.length &&
    //         (parts.some((x) => x === 'profile') || parts.some((x) => x === 're-form'))
    //     ) {
    //         getCompanyMember(parts[1]).then((res) => {
    //             setMember(res);
    //         });
    //     } else if (currentUser?.role === 'Member') {
    //         getCompanyMember(String(currentUser?.member?.id)).then((res) => {
    //             setMember(res);
    //         });
    //     }
    // }, [currentUser, urlParam]);

    React.useEffect(() => {
        const fetchMember = (memberId: string) => {
            getCompanyMember(memberId).then((res) => {
                setMember(res);
            });
        };
        const parts = urlParam?.split('/');
        const isProfileOrReform = parts?.some((x) => x === 'profile' || x === 're-form');

        if (currentUser?.role === 'Member') {
            const memberId = String(currentUser?.member?.id);
            fetchMember(memberId);
        } else if (isProfileOrReform && parts?.length) {
            const memberId = parts[1];
            fetchMember(memberId);
        }
    }, [currentUser, urlParam]);

    React.useEffect(() => {
        getPlanOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPlanOptions]);

    React.useEffect(() => {
        if (member) {
            const subscription = subscriptions.find((subscription) => subscription.product_id === member?.product_id);
            setMemberSubscription(subscription);
        }
    }, [member, subscriptions]);

    return (
        <Routes>
            <Route
                path="select-vehicle"
                element={
                    <SuspensedView>
                        <SelectVehicle />
                    </SuspensedView>
                }
            />
            <Route element={<MasterLayout />}>
                <Route path="auth/*" element={<Navigate to="/dashboard" />} />
                {currentUser?.role === 'Admin' ? <Route path="*" element={<AdminPrivateRoutes />} /> : null}
                {currentUser?.role === 'SuperAdmin' ? <Route path="*" element={<SuperAdminPrivateRoutes />} /> : null}
                {currentUser?.role === 'Owner' ? <Route path="*" element={<CompanyPrivateRoutes />} /> : null}
                {currentUser?.role === 'Member' ? <Route path="*" element={<MemberPrivateRoutes />} /> : null}
                {currentUser?.role === 'SalesRep' ? <Route path="*" element={<SalesRepPrivateRoutes />} /> : null}
                {currentUser?.role === 'MaintenanceMember' &&
                pmProgramEnabled &&
                pmProgramEnabledUrl.urls.includes(process.env.REACT_APP_WEBSITE_URL!) ? (
                    <Route path="*" element={<MaintenanceMemberPrivateRoutes />} />
                ) : null}
                {/* <Route path='member/:id/' element={<ReFormRoutes />} /> */}
                <Route
                    path="driver-pdf/:id/*"
                    element={
                        <SuspensedView>
                            <DriverPdfView />
                        </SuspensedView>
                    }
                />
                <Route path="notifications/*" element={<NotificationPrivateRoutes />} />

                {/* Forms */}
                <Route
                    path="member/:id/re-form/driver-release-form-background-checks"
                    element={
                        <SuspensedView>
                            <DriverReleaseFormBackgroundChecks />
                        </SuspensedView>
                    }
                />
                <Route
                    path="member/:id/re-form/written-drug-and-alcohol-policy"
                    element={
                        <SuspensedView>
                            <WrittenPolicy />
                        </SuspensedView>
                    }
                />
                <Route
                    path="member/:id/re-form/disclosure-form-for-psp"
                    element={
                        <SuspensedView>
                            <PSPDisclosure />
                        </SuspensedView>
                    }
                />
                <Route
                    path="member/:id/re-form/driver-release-form"
                    element={
                        <SuspensedView>
                            <DriversReleaseForm />
                        </SuspensedView>
                    }
                />
                <Route
                    path="member/:id/re-form/driver-application-for-employment"
                    element={
                        <SuspensedView>
                            <DriversApplicationForEmployment />
                        </SuspensedView>
                    }
                />
                <Route
                    path="member/:id/re-form/employment-history"
                    element={
                        <SuspensedView>
                            <EmploymentHistory />
                        </SuspensedView>
                    }
                />
                {!member?.pre_employment_screened ||
                (member?.pre_employment_screened && member?.pre_employment_investigation.length > 0) ? (
                    <Route
                        path="/member/:id/re-form/previous-employee-safety-performance-history"
                        element={
                            <SuspensedView>
                                <PreviousEmployeeSafetyPerformance />
                            </SuspensedView>
                        }
                    />
                ) : null}
                <Route
                    path="member/:id/re-form/certificate-of-compliance-with-driver-license-requirements"
                    element={
                        <SuspensedView>
                            <CertificateOfComplianceWithDriverLicenseRequirements />
                        </SuspensedView>
                    }
                />
                <Route
                    path="member/:id/re-form/request-for-check-of-driving-record"
                    element={
                        <SuspensedView>
                            <RequestForCheckOfDrivingRecords />
                        </SuspensedView>
                    }
                />
                <Route
                    path="member/:id/re-form/driver-statement-of-on-duty-hours"
                    element={
                        <SuspensedView>
                            <DriversStatementDutyHours />
                        </SuspensedView>
                    }
                />
                {currentUser && currentUser.role !== 'Member' && (
                    <Route
                        path="/member/:id/re-form/medical-examiner-national-registry-verification"
                        element={
                            <SuspensedView>
                                <MedicalExaminerNationalRegistry />
                            </SuspensedView>
                        }
                    />
                )}
                {currentUser && currentUser.role !== 'Member' && (
                    <Route
                        path="/member/:id/non-current/medical-examiner-national-registry-verification"
                        element={
                            <SuspensedView>
                                <NonCurrentMedicalExaminerNationalRegistry />
                            </SuspensedView>
                        }
                    />
                )}
                <Route
                    path="/member/:id/re-form/driver-handbook-receipt"
                    element={
                        <SuspensedView>
                            <DriversHandbookReceipt />
                        </SuspensedView>
                    }
                />
                {currentUser && currentUser.role !== 'Member' && (
                    <Route
                        path="/member/:id/re-form/record-of-road-test"
                        element={
                            <SuspensedView>
                                <RecordOfRoadTestComponent />
                            </SuspensedView>
                        }
                    />
                )}
                {currentUser && currentUser.role !== 'Member' && (
                    <Route
                        path="/member/:id/re-form/certificate-of-road-test"
                        element={
                            <SuspensedView>
                                <CertificateOfRoadTest subscription={memberSubscription} />
                            </SuspensedView>
                        }
                    />
                )}
                <Route
                    path="/member/:id/re-form/consent-for-limited-queries-of-the-fmcsa-drug-and-alcohol-clearinghouse"
                    element={
                        <SuspensedView>
                            <DriverAuthorization subscription={memberSubscription} />
                        </SuspensedView>
                    }
                />
                <Route
                    path="/member/:id/drug-file/consent-for-limited-queries-of-the-fmcsa-drug-and-alcohol-clearinghouse"
                    element={
                        <SuspensedView>
                            <NonCurrentDriverAuthorization subscription={memberSubscription} />
                        </SuspensedView>
                    }
                />
                <Route
                    path="/member/:id/re-form/previous-pre-employment-employee-drug-and-alcohol-test-statement"
                    element={
                        <SuspensedView>
                            <PrevPreEmploymentDach />
                        </SuspensedView>
                    }
                />
                <Route
                    path="/member/:id/re-form/drug-and-alcohol-employees-certified-receipt"
                    element={
                        <SuspensedView>
                            <DachCertifiedReceipt />
                        </SuspensedView>
                    }
                />
                {currentUser && currentUser.role !== 'Member' && (
                    <Route
                        path="/member/:id/re-form/documentation-of-actual-knowledge-of-dot-d-and-a-violation"
                        element={
                            <SuspensedView>
                                <DocsActualKnowledge />
                            </SuspensedView>
                        }
                    />
                )}
                {currentUser && currentUser.role !== 'Member' && (
                    <Route
                        path="/member/:id/re-form/annual-review-of-driving-record"
                        element={
                            <SuspensedView>
                                <AnnualReviewDrivingRecords />
                            </SuspensedView>
                        }
                    />
                )}
                {currentUser && currentUser.role !== 'Member' && (
                    <Route
                        path="/member/:id/non-current/annual-review-of-driving-record"
                        element={
                            <SuspensedView>
                                <NonCurrentAnnualReviewDrivingRecords />
                            </SuspensedView>
                        }
                    />
                )}

                {/* Reroute to 404 */}
                <Route path="*" element={<Navigate to="/error/404" />} />
            </Route>
        </Routes>
    );
};

export { PrivateRoutes };
