import { useIntl } from 'react-intl';
import { PageTitle } from '../../../compliancemasters/layout/core';
import DashboardTabs from './DashboardTabs';
import { useCompany } from '../../../context/CompanyContext';

export default function AdminDashboard() {
    const { currentCompany } = useCompany()
    const intl = useIntl();
    console.log(currentCompany)

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <div className="row gy-5 g-xl-8">
                <div className="col-xl-12">
                    <DashboardTabs />
                </div>
            </div>
        </>
    );
}
