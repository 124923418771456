import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    IApplicantSignature,
    IApplicantSignatureDto
} from '../../interfaces/DriversApplicationForEmployment/applicant.signature';

export const applicantSignatureApi = createApi({
    reducerPath: 'applicant_signature',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/applicant-signature`
    }),
    tagTypes: ['ApplicantSignature'],
    endpoints: (builder) => ({
        createApplicantSignature: builder.mutation<IApplicantSignature, IApplicantSignatureDto>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'ApplicantSignature', id: 'ApplicantSignature' }]
        }),
        updateApplicantSignature: builder.mutation<IApplicantSignature, IApplicantSignature>({
            query: (body) => ({
                url: `/${body.id}`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'ApplicantSignature', id: 'ApplicantSignature' }]
        }),
        deleteApplicantSignature: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'ApplicantSignature', id: 'ApplicantSignature' }]
        }),
        getApplicantSignature: builder.mutation<IApplicantSignature, number | void>({
            query: (id) => ({
                url: '/applicant-signature/',
                method: 'POST',
                body: {
                    memberId: id
                }
            })
        }),
        getApplicantSignatureByUser: builder.query<IApplicantSignature, void>({
            query: () => ({
                url: '/applicant-signature-by-user/',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ApplicantSignature', id: 'ApplicantSignature' }]
        }),
        getApplicantSignatureByUserId: builder.query<IApplicantSignature, number | void>({
            query: (id) => ({
                url: '/applicant-signature-by-user-id/',
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ApplicantSignature', id: 'ApplicantSignature' }]
        })
    })
});

export const {
    useCreateApplicantSignatureMutation,
    useUpdateApplicantSignatureMutation,
    useGetApplicantSignatureMutation,
    useGetApplicantSignatureByUserQuery,
    useGetApplicantSignatureByUserIdQuery,
    useDeleteApplicantSignatureMutation
} = applicantSignatureApi;
