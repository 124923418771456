import React from 'react'
import { IMember } from '../../../models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Grid, TextField, Typography } from '@mui/material'
import {
    IDachCertifiedRecipientDriversSignatureDto
} from '../../../store/interfaces/DrugandAlcoholEmployeesCertifiedReceipt/dach-certified-recipient-drivers-signature'
import {
    useCreateDachCertifiedRecipientDriversSignatureMutation,
    useUpdateDachCertifiedRecipientDriversSignatureMutation,
    useGetDachCertifiedRecipientDriversSignatureByUserIdQuery,
    useDeleteDachCertifiedRecipientDriversSignatureMutation
} from '../../../store/reducers/DrugandAlcoholEmployeesCertifiedReceipt/dach-certified-receipt-drivers-signature'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'
import { useGetProcessRecordByUserIdQuery } from '../../../store/reducers/DriversApplicationForEmployment/process-record'
import { useGetCertificationByUserIdQuery } from '../../../store/reducers/CertificateOfComplianceWithDriverLicenseRequirements/certification'


const initialValues: Partial<IDachCertifiedRecipientDriversSignatureDto> = {
    employee_signature: '',
    date: '',
}

const validationSchema = Yup.object({
    employee_signature: Yup.string().required('Required'),
    date: Yup.string().required('Required'),
})

interface IProps {
    member?: IMember
    isDriverFile?: boolean
}


const DriversSignature = ({ member, isDriverFile }: IProps) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data } = useGetDachCertifiedRecipientDriversSignatureByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })

    const { data: processRecord } = useGetProcessRecordByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    })

    const { data: cert } = useGetCertificationByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })

    const [createDachCertifiedRecipientDriversSignature, { isLoading: isCreating }] = useCreateDachCertifiedRecipientDriversSignatureMutation()
    const [updateDachCertifiedRecipientDriversSignature, { isLoading: isUpdating }] = useUpdateDachCertifiedRecipientDriversSignatureMutation()
    const [deleteDachReceipt, { isLoading: isDeleting }] = useDeleteDachCertifiedRecipientDriversSignatureMutation()

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                updateDachCertifiedRecipientDriversSignature({
                    ...data,
                    ...values
                })
            } else {
                createDachCertifiedRecipientDriversSignature({
                    ...values,
                    // @ts-ignore
                    company: member?.company?.id,
                    // @ts-ignore
                    member: member?.id,
                })
            }
            resetForm()
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate(values) {
            const errors: Partial<IDachCertifiedRecipientDriversSignatureDto> = {}
            if (!values.employee_signature) errors.employee_signature = 'Required'
            if (!values.date) errors.date = 'Required'
            return errors
        },
    })

    React.useEffect(() => {
        if (data) formik.setValues(data)
        if (processRecord) {
            formik.setFieldValue("date", processRecord?.date_employed)
        } else if (cert) {
            formik.setFieldValue("date", cert?.date_of_hire)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, processRecord, cert])


    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                Drivers Signature
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Employee Signature
                    </Typography>
                    <TextField
                        fullWidth
                        id="employee_signature"
                        placeholder='Employee Signature'
                        size="small"
                        variant="outlined"
                        value={formik.values.employee_signature}
                        onChange={(e) => {
                            formik.setFieldValue('employee_signature', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.employee_signature && Boolean(formik.errors.employee_signature) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.employee_signature && Boolean(formik.errors.employee_signature) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.employee_signature && Boolean(formik.errors.employee_signature) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                fontStyle: 'italic'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Date of Hire
                    </Typography>
                    <TextField
                        fullWidth
                        id="date"
                        type='date'
                        size="small"
                        variant="outlined"
                        value={formik.values.date}
                        onChange={(e) => {
                            formik.setFieldValue('date', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                {(!data || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role))) && !isDriverFile ? <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={isCreating || isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                        Save
                    </Button>
                    {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                        <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                            Once you click save, you will not be able to edit this form.
                        </Typography>
                    )}
                </Grid > : null}
                {(currentUser?.role === "Admin" || currentUser?.role === "SuperAdmin") && data && !isDriverFile ? (
                    <Grid item xs={12} sm={12}>
                        <Button fullWidth disabled={isDeleting || (!!member?.terminated_at)} onClick={() => deleteDachReceipt(data?.id)} variant="contained" color="secondary">
                            Delete
                        </Button>
                    </Grid>
                ) : null}
            </Grid >
        </div >
    )
}

export default DriversSignature