import * as React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Typography } from '@mui/material';

export default function DriverComplianceTabs() {
    const [value, setValue] = React.useState("1");

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', mt: 5, mb: 15, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} centered variant="fullWidth">
                        <Tab label="Digital Driver Compliance" value="1" />
                        <Tab label="Expiration Date Tracking and Annual MVR’s and DACH Queries" value="2" />
                        <Tab label="Continuous Live MVR Monitoring" value="3" />
                    </TabList>
                </Box>
                <div style={{ height: 15 }} />
                <TabPanel value="1">
                    <img
                        loading="lazy"
                        src="/media/landing-page-images/driver-compliance-tabs/driver-compliance-1.png"
                        alt="dashboardx"
                        style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: 10,
                            marginBottom: 30
                        }}
                    />
                    <img
                        loading="lazy"
                        src="/media/landing-page-images/driver-compliance-tabs/driver-compliance-2.png"
                        alt="dashboardx"
                        style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: 10
                        }}
                    />
                </TabPanel>
                <TabPanel value="2">
                    <img
                        loading="lazy"
                        src="/media/landing-page-images/driver-compliance-tabs/annual-mvr.png"
                        alt="dashboardx"
                        style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: 10
                        }}
                    />
                    <Typography variant="h5" align='center' style={{ marginTop: 20, marginBottom: 20 }}>
                        Owners and Drivers will receive 90, 60 and 30 day notifications before a drivers license or medical card expires.
                    </Typography>
                    <Typography variant="h5" align='center' style={{ marginTop: 20, marginBottom: 20 }}>
                        Compliance Masters Uploads an Annual MVR and Annual DACH query for each driver at no additional cost.
                    </Typography>
                </TabPanel>
                <TabPanel value="3">
                    <img
                        loading="lazy"
                        src="/media/landing-page-images/driver-compliance-tabs/live-monitoring.png"
                        alt="dashboardx"
                        style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: 10
                        }}
                    />
                </TabPanel>
            </TabContext>
        </Box>
    );
}
