import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import * as Yup from 'yup';
import { TransitionProps } from '@mui/material/transitions';
import { ITrainingResourcesDto, TrainingResourcesFileType, finishUpload, startUpload, updateTrainingResource } from '../../../shared/client/training-resources';
import { Grid, InputLabel, MenuItem, TextField } from '@mui/material';
import { useFormik } from 'formik';
import FileInput from '../../../compliancemasters/partials/modals/new-file/components/FileInput';
import Button from '../../modules/buttons/Button';
import axios from 'axios';
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onAdd?: () => void;
    resource?: ITrainingResourcesDto;
}

const initialValues: Partial<ITrainingResourcesDto> = {
    title: '',
    description: '',
    file_name: '',
    file_type: '',
    original_file_name: '',
    original_name: '',
    training_type: TrainingResourcesFileType.FILE,
    link: '',
    file_changed: false
}

const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    training_type: Yup.string().required('Training Type is required'),
})

export default function NewFileModal({ open, setOpen, onAdd, resource }: Readonly<IProps>) {
    const [file, setFile] = React.useState<File>()
    const [loading, setLoading] = React.useState(false)
    const [uploaded, setUploaded] = React.useState(false)
    const handleClose = () => {
        setOpen(false);
        formik.resetForm()
    };



    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            if (!resource) {

                setLoading(true)
                startUpload({
                    file_name: file ? (file! as File).name : '',
                    file_type: file ? (file! as File).type : '',
                    description: values.description!,
                    title: values.title!,
                    training_type: values.training_type,
                    link: values.link
                }).then((res) => {
                    if (values.training_type === TrainingResourcesFileType.FILE) {
                        const { url, id } = res

                        let options = {
                            headers: {
                                'Content-Type': (file! as File).type
                            }
                        };

                        let instance = axios.create();
                        instance.put(url, file, options).then((res) => {
                            finishUpload(id).then(() => {
                                setUploaded(true);
                                formik.resetForm();
                                formik.validateForm();
                                onAdd?.();
                            }).catch((error: any) => {
                                console.log(error)
                            })
                        }).catch((err) => {
                            setLoading(false)
                            console.log(err);
                        }).finally(() => {
                            setLoading(false)
                        })
                    } else {
                        setLoading(false)
                        setUploaded(true)
                    }
                }).catch((error) => {
                    console.log(error)
                    setLoading(false)
                }).finally(() => {
                    handleClose()
                })
            } else {
                setLoading(true)
                updateTrainingResource(resource.id, values).then((res) => {
                    const { url, id } = res
                    let options = {
                        headers: {
                            'Content-Type': (file! as File).type
                        }
                    };
                    let instance = axios.create();
                    instance.put(url, file, options).then((res) => {
                        finishUpload(id).then(() => {
                            setUploaded(true);
                            formik.resetForm();
                            formik.validateForm();
                            onAdd?.();
                        }).catch((error: any) => {
                            console.log(error)
                        })
                    }).catch((err) => {
                        setLoading(false)
                        console.log(err);
                    }).finally(() => {
                        setLoading(false)
                    })

                })
            }
        }
    })

    React.useEffect(() => {
        if (!open) {
            formik.resetForm()
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [open])

    React.useEffect(() => {
        if (uploaded) {
            toast.success('Resource Added')
            handleClose()
        }
        // eslint-disable-next-line
    }, [uploaded])

    React.useEffect(() => {
        if (resource) {
            formik.setValues({
                title: resource.title,
                description: resource.description,
                training_type: resource.training_type,
                link: resource.link,
                file_name: resource.file_name,
                file_type: resource.file_type,
                original_file_name: resource.original_file_name,
                original_name: resource.original_name,
            })
        }
        // eslint-disable-next-line
    }, [resource])

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle style={{ textAlign: 'center' }}>Add Training Resource</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} p={1}>
                    <Grid item sm={12} lg={12}>
                        <TextField
                            fullWidth
                            label="Title"
                            name='title'
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            variant="outlined"
                            size="small"
                        />
                    </Grid>
                    <Grid item sm={12} lg={12}>
                        <TextField
                            fullWidth
                            label="Description"
                            name='description'
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            variant="outlined"
                            multiline
                            rows={3}
                            size="small"
                        />
                    </Grid>
                    <Grid item sm={12} lg={12}>
                        <InputLabel>
                            Training Type
                        </InputLabel>
                        <TextField
                            fullWidth
                            name='training_type'
                            value={formik.values.training_type}
                            onChange={formik.handleChange}
                            variant="outlined"
                            select
                            size="small"
                        >
                            {Object.values(TrainingResourcesFileType).map((item) => (
                                <MenuItem value={item} key={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {formik.values.training_type === TrainingResourcesFileType.FILE ? <Grid item sm={12} lg={12}>
                        <FileInput
                            onChange={(e) => {
                                setFile(e.target.files?.[0])
                                if (resource) {
                                    formik.setFieldValue('file_changed', true)
                                }
                            }}
                            onClear={() => setFile(undefined)}
                            value={file}
                        />
                    </Grid> : null}
                    {formik.values.training_type === TrainingResourcesFileType.LINK ? <Grid item sm={12} lg={12}>
                        <TextField
                            fullWidth
                            label="Link"
                            name='link'
                            value={formik.values.link}
                            onChange={formik.handleChange}
                            variant="outlined"
                            size="small"
                        />
                    </Grid> : null}
                    <Grid item sm={12} lg={12} sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button loading={loading} onClick={formik.handleSubmit}>Add Resource</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
