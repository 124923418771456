import React from 'react'
import { IMember } from '../../../models'
import { Button, Grid, TextField, Typography } from '@mui/material'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { IReleaseFormApplicantSignatureDto } from '../../../store/interfaces/ReleaseFormBackgroundCheck/ReleaseFormBackgroundChecks'
import {
    useCreateReleaseFormApplicantSignatureMutation,
    useUpdateReleaseFormApplicantSignatureMutation,
    useGetReleaseFormApplicantSignatureByUserIdQuery,
    useDeleteReleaseFormApplicantSignatureMutation
} from '../../../store/reducers/ReleaseFormBackgroundCheck/release-form-signature'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'


const initialValues: Partial<IReleaseFormApplicantSignatureDto> = {
    applicant_name: '',
    applicant_signature: '',
    date: ''
}

const validationSchema = Yup.object({
    applicant_name: Yup.string().required('Applicant Name is required'),
    applicant_signature: Yup.string().required('Applicant Signature is required'),
    date: Yup.string().required('Date is required'),
})


const StateSpecificNotices = ({ member, isDriverFile }: { member?: IMember, isDriverFile?: boolean }) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data } = useGetReleaseFormApplicantSignatureByUserIdQuery(Number(member?.user?.id), {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
        refetchOnFocus: true,
    })

    const [createReleaseFormApplicantSignature, { isLoading: isCreating }] = useCreateReleaseFormApplicantSignatureMutation()
    const [updateReleaseFormApplicantSignature, { isLoading: isUpdating }] = useUpdateReleaseFormApplicantSignatureMutation()
    const [deleteReleaseFormApplicantSignature] = useDeleteReleaseFormApplicantSignatureMutation()


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                updateReleaseFormApplicantSignature({
                    ...data,
                    ...values
                }).then(() => {
                    resetForm()
                })
            } else {
                // @ts-ignore
                createReleaseFormApplicantSignature({
                    ...values,
                    member: Number(member?.id),
                    company: Number(member?.company?.id),
                }).then(() => {
                    resetForm()
                })
            }
        },
        validateOnBlur: true,
        validateOnChange: true,
    })

    React.useEffect(() => {
        if (data) {
            formik.setValues({
                applicant_name: data.applicant_name,
                applicant_signature: data.applicant_signature,
                date: data.date,
            })
        } else if (member) {
            const date_today = new Date()
            const year_today = date_today.getFullYear()
            const month_today = String(date_today.getMonth() + 1).padStart(2, '0')
            const day_today = String(date_today.getDate()).padStart(2, '0')
            const formattedDateToday = `${year_today}-${month_today}-${day_today}`
            formik.setValues({
                applicant_name: member?.user?.first_name + ' ' + member?.user?.last_name,
            })
            formik.setFieldValue('date', formattedDateToday)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, member])

    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ textAlign: "center" }} variant="h5" gutterBottom component="h5">
                STATE SPECIFIC NOTICES AND DISCLOSURES BACKGROUND INVESTIGATION
            </Typography>
            <Typography sx={{ textAlign: "center", color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                Pursuant to state law, the following disclosures are provided to state residents or when the
                employment is located within one of these states.
            </Typography>
            <Typography sx={{ textAlign: "justify", mt: 3, color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                NEW JERSEY: If you are a New Jersey resident or applying for employment at a location within
                the State of New Jersey, signing below you acknowledges receipt of the New Jersey Fair Credit
                Reporting Act provisions.
            </Typography>
            <Typography sx={{ textAlign: "justify", mt: 3, color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                NEW YORK: If you are a New York resident or applying for employment at a location within the
                State of New York, you have the right to inspect and receive a copy of any investigative
                consumer report requested by the Company by contacting Amerisearch Background Alliance
                Phone: [800- 569-6133].
            </Typography>
            <Typography sx={{ textAlign: "justify", mt: 3, color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                NEW YORK: If you are a New York resident or applying for employment at a location within the
                State of New York, by signing below you acknowledge receipt of a copy of Article 23-A of the
                New York Correction Law.
            </Typography>
            <Typography sx={{ textAlign: "justify", mt: 3, color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                RHODE ISLAND: If you are a Rhode Island resident or applying for employment at a location
                within the State of Rhode Island, the Company may request a credit report from a consumer
                reporting agency in connection with your application.
            </Typography>
            <Typography sx={{ textAlign: "justify", mt: 3, color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                VERMONT: If you are a Vermont resident or applying for employment at a location within the
                State of Vermont, by signing below you acknowledge receipt of the NOTICE – BACKGROUND
                INVESTIGATION AND USE OF CREDIT INFORMATION.
            </Typography>
            <Typography sx={{ textAlign: "justify", mt: 3, color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                WASHINGTON STATE: If you are a Washington resident or applying for employment at a
                location within the State of Washington, you have the right to request from Amerisearch
                Background Alliance a written summary of your rights and remedies under the Washington Fair
                Credit Reporting Act.
            </Typography>
            <Typography sx={{ textAlign: "justify", mt: 3, color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                MASSACHUSETTS, MINNESOTA, and OKLAHOMA: If you are resident of Massachusetts,
                Minnesota or Oklahoma or applying for employment in one of these states, please contact
                Amerisearch Background Alliance @ 800-569-6133, if you would like to receive a copy of your
                consumer report, free of charge, if one is obtained by the Company.
            </Typography>
            <Typography sx={{ textAlign: "justify", mt: 3, color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                GEORGIA Statewide -GCIC Purpose Code E – Employment Used for non-criminal justice
                purposes. CJAs may perform a name-based background check and provide the Georgia CHRI
                results to the authorized recipient designated on the consent form. GCIC suggests that the
                agency stamp the Georgia CHRI results “Employment Copy” before providing to the authorized
                recipient. Purpose code E provides Georgia CHRI to include 1st offender under sentence. It
                does not provide 1st offender completed, record restrictions, time expired restrictions, or juvenile
                records. Consent of the individual is required. An ARN is not required to complete the inquiry.
            </Typography>
            <Grid container spacing={3} p={1} sx={{
                alignItems: "center",
            }}>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Signature
                    </Typography>
                    <TextField
                        fullWidth
                        id="signature"
                        placeholder='Signature'
                        size="small"
                        variant="outlined"
                        value={formik.values.applicant_signature}
                        className={formik.touched.applicant_signature && Boolean(formik.errors.applicant_signature) ? "green" : "red"}
                        error={formik.touched.applicant_signature && Boolean(formik.errors.applicant_signature)}
                        helperText={formik.touched.applicant_signature && formik.errors.applicant_signature}
                        onChange={e => formik.setFieldValue('applicant_signature', e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.applicant_signature && Boolean(formik.errors.applicant_signature) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.applicant_signature && Boolean(formik.errors.applicant_signature) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.applicant_signature && Boolean(formik.errors.applicant_signature) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                fontStyle: "italic"
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Print Name
                    </Typography>
                    <TextField
                        fullWidth
                        id="print_name"
                        placeholder='Print Name'
                        size="small"
                        variant="outlined"
                        value={formik.values.applicant_name}
                        className={formik.touched.applicant_name && Boolean(formik.errors.applicant_name) ? "green" : "red"}
                        error={formik.touched.applicant_name && Boolean(formik.errors.applicant_name)}
                        helperText={formik.touched.applicant_name && formik.errors.applicant_name}
                        onChange={e => formik.setFieldValue('applicant_name', e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.applicant_name && Boolean(formik.errors.applicant_name) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.applicant_name && Boolean(formik.errors.applicant_name) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.applicant_name && Boolean(formik.errors.applicant_name) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Date
                    </Typography>
                    <TextField
                        fullWidth
                        id="date"
                        type='Date'
                        size="small"
                        variant="outlined"
                        value={formik.values.date}
                        className={formik.touched.date && Boolean(formik.errors.date) ? "green" : "red"}
                        error={formik.touched.date && Boolean(formik.errors.date)}
                        helperText={formik.touched.date && formik.errors.date}
                        onChange={e => formik.setFieldValue('date', e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                {(!data || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role))) && !isDriverFile ? <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={isCreating || isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                        Save
                    </Button>
                    {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                        <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                            Once you click save, you will not be able to edit this form.
                        </Typography>
                    )}
                </Grid> : null}
                {data && currentUser?.role === "SuperAdmin" && <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={!!member?.terminated_at} onClick={() => {
                        const res = window.confirm("Are you sure you want to delete this form?")
                        if (res && data) {
                            deleteReleaseFormApplicantSignature(data.id)
                        }
                    }} variant="contained" color="primary">
                        Delete Form
                    </Button>
                </Grid>}
            </Grid>
        </div>
    )
}

export default StateSpecificNotices