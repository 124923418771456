import * as React from 'react';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { ISalesRep, ISalesRepDto, updateSalesRep } from '../../../shared/client/sales-rep';
import { toast } from 'react-toastify';
import { Box, Chip, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Paper, TextField } from '@mui/material';
import { AddCircleRounded } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function capitalizeFirstLetter(str: string): string {
    if (str.length === 0) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
}



const initialValues: ISalesRepDto = {
    phone_number: '',
    zip_code: '',
    state: '',
    user: 0,
    city: '',
    street_address: '',
    is_active: true,
    assigned_states: [],
    is_compliance_master_account: false,
}

const validationSchema = Yup.object({
    zip_code: Yup.string().required('Required'),
    phone_number: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    user: Yup.number().required('Required'),
    city: Yup.string().required('Required'),
    street_address: Yup.string().required('Required'),
    assigned_states: Yup.array().required('Required'),
})

export default function UpdateSalesRep({
    open,
    setOpen,
    salesRep,
    getSalesReps,
}: {
    open: boolean
    setOpen: (open: boolean) => void
    salesRep?: ISalesRep
    getSalesReps: () => void
}) {
    const [assignedState, setAssignedState] = React.useState<string>("")
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (salesRep) {
                await updateSalesRep(salesRep.id, {
                    ...values,
                    user: salesRep.user.id,
                }).then((res) => {
                    toast.success('Sales Rep updated successfully')
                    setOpen(false)
                    getSalesReps()
                    resetForm()
                }).catch((err) => {
                    toast.error(err.message)
                })
            }
            getSalesReps()
            setOpen(false)
        },
    })

    const handleClose = () => {
        setOpen(false);
        formik.resetForm()
    };

    React.useEffect(() => {
        if (salesRep && open) {
            formik.setValues({
                phone_number: salesRep.phone_number,
                zip_code: salesRep.zip_code,
                state: salesRep.state,
                user: salesRep.user.id,
                city: salesRep.city,
                street_address: salesRep.street_address,
                is_active: salesRep.is_active,
                assigned_states: salesRep.assigned_states ? salesRep.assigned_states : [],
                is_compliance_master_account: salesRep.is_compliance_master_account,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, salesRep])

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            maxWidth='sm'
            fullWidth
        >
            <DialogTitle sx={{
                backgroundColor: '#1e1e2d',
                color: 'white',
                textAlign: 'center'
            }}>
                {salesRep ? `Update Sales Rep profile for ${capitalizeFirstLetter(salesRep?.user?.first_name)} ${capitalizeFirstLetter(salesRep.user?.last_name)}` : 'Add New Sales Rep'}
            </DialogTitle>
            <DialogContent sx={{
                p: 4
            }}>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12} sm={12}>
                        <InputLabel>
                            {formik.values.is_active ? 'Active' : 'Inactive'}
                        </InputLabel>
                        <TextField
                            fullWidth
                            size='small'
                            id="is_active"
                            name="is_active"
                            value={formik.values.is_active ? 'Yes' : 'No'}
                            onChange={(e) => formik.setFieldValue('is_active', e.target.value === 'Yes' ? true : false)}
                            select
                        >
                            <MenuItem value={"Yes"}>
                                Yes
                            </MenuItem>
                            <MenuItem value={"No"}>
                                No
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel>
                            Is Compliance Master Account
                        </InputLabel>
                        <TextField
                            fullWidth
                            size='small'
                            id="is_compliance_master_account"
                            name="is_compliance_master_account"
                            value={formik.values.is_compliance_master_account ? 'Yes' : 'No'}
                            onChange={(e) => formik.setFieldValue('is_compliance_master_account', e.target.value === 'Yes' ? true : false)}
                            select
                        >
                            <MenuItem value={"Yes"}>
                                Yes
                            </MenuItem>
                            <MenuItem value={"No"}>
                                No
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel>
                            Phone Number
                        </InputLabel>
                        <TextField
                            fullWidth
                            size='small'
                            id="phone_number"
                            name="phone_number"
                            value={formik.values.phone_number}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel>
                            Street Address
                        </InputLabel>
                        <TextField
                            fullWidth
                            size='small'
                            id="street_address"
                            name="street_address"
                            value={formik.values.street_address}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel>
                            City
                        </InputLabel>
                        <TextField
                            fullWidth
                            size='small'
                            id="city"
                            name="city"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel>
                            State
                        </InputLabel>
                        <TextField
                            fullWidth
                            size='small'
                            id="state"
                            name="state"
                            value={formik.values.state}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel>
                            Zip Code
                        </InputLabel>
                        <TextField
                            fullWidth
                            size='small'
                            id="zip_code"
                            name="zip_code"
                            value={formik.values.zip_code}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel htmlFor="outlined-adornment-assigned-states">
                            Assigned States
                        </InputLabel>
                        <FormControl variant="outlined" fullWidth sx={{ p: 1 }}>
                            <OutlinedInput
                                id="outlined-adornment-assigned-states"
                                value={assignedState}
                                onChange={(e) => setAssignedState(e.target.value)}
                                size='small'
                                fullWidth
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => {
                                                formik.setFieldValue('assigned_states', [...formik.values.assigned_states, assignedState])
                                                setAssignedState("")
                                            }}
                                            edge="end"
                                        >
                                            <AddCircleRounded />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    {formik.values.assigned_states.length > 0 ? (
                        <Grid item sm={12}>
                            <Box component={Paper} sx={{
                                padding: 3,
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                // justifyContent: 'space-between',
                            }}>
                                {formik.values.assigned_states.map((state, index) => (
                                    <Chip sx={{ m: 1 }} label={state} variant="outlined"
                                        onDelete={() => {
                                            formik.setFieldValue('assigned_states', formik.values.assigned_states.filter(x => x !== state))
                                        }}
                                        key={state}
                                    />
                                ))}
                            </Box>
                        </Grid>
                    ) : null}
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{
                                backgroundColor: '#1e1e2d',
                            }}
                            color="primary"
                            onClick={() => {
                                formik.handleSubmit()
                            }}
                        >
                            {salesRep ? 'Update Sales Rep' : 'Add Sales Rep'}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
