import * as React from "react";
import styled from "styled-components";
import Button from "../modules/buttons/Button";
import QuoteModal from "./quote-modal";
import { FormControl, Grid, InputAdornment, InputLabel, OutlinedInput, Typography, Box, Paper } from "@mui/material";
import { CheckRounded, ContactMailRounded, ContentPasteSearchRounded, DevicesRounded, HelpOutlineRounded, SensorOccupiedRounded, SettingsEthernetRounded } from "@mui/icons-material";
import DriverComplianceTabs from "./DriverComplianceTabs";
import LandingPageHeader from "./Headers/Header";
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
// @ts-ignore
import FOG from 'vanta/src/vanta.fog'

// interface IProps {
//     flags: {
//         UpdateSubscriptions: boolean
//     }
// }


const LandingPage = ({ flags }: any) => {
    React.useEffect(() => {
        FOG({
            el: "#landing-page",
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 6800.00,
            minWidth: 200.00,
            highlightColor: 0xfceeae,
            midtoneColor: 0x73dc8d,
            lowlightColor: 0xa1afe3,
            baseColor: 0xfafafa,
            blurFactor: 0.58,
            zoom: 1.10
        })
    }, [])

    const [email, setEmail] = React.useState("");
    return (
        <Container id="landing-page">
            <Div2
                style={{
                    width: "100%",
                    margin: 15,
                }}>
                <LandingPageHeader />
                <Grid
                    container
                    spacing={2}
                    style={{
                        marginTop: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 99999
                    }}>
                    <Grid item xs={12} sm={12} md={6}>
                        <div
                            id="home"
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{ flexGrow: 1, }}
                            >
                                <Img
                                    loading="lazy"
                                    style={{
                                        height: 250,
                                        width: "auto"
                                    }}
                                    src='/media/logos/compliance-01.png'
                                />
                            </Typography>
                            <Typography variant="h3" sx={{ fontWeight: "bold", }}>
                                <div>
                                    <img
                                        loading="lazy"
                                        src="/media/landing-page-images/artifacts/artifact-1.png"
                                        alt="dashboardx"
                                        style={{
                                            width: 60,
                                            height: "auto",
                                            position: "absolute",
                                            marginBottom: 30,
                                        }}
                                    />
                                </div>
                                <span style={{ color: "black", font: "400 40px Gilroy-Bold, sans-serif" }}>Welcome to <Div15>Compliance</Div15> </span>
                            </Typography>
                            <Div15>
                                <Typography style={{ font: "400 40px Gilroy-Bold, sans-serif" }}>
                                    Masters
                                </Typography>
                            </Div15>
                            <Div16>
                                <Typography align="center" variant="h5">
                                    The most efficient, user-friendly and affordable digital management
                                    service specialized in mastering your compliance!
                                </Typography>
                            </Div16>
                            <FormControl size="medium" variant="filled" sx={{ mt: 10 }}>
                                <InputLabel htmlFor="outlined-adornment-password">Email Address</InputLabel>
                                <OutlinedInput
                                    sx={{ borderRadius: 40, }}
                                    id="outlined-adornment-password"
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Button
                                                className={'btn btn-md btn-success mx-1'}
                                                dataBsToggle='modal'
                                                dataBsTarget='#modal_quote_form'
                                            >
                                                Request Quote
                                            </Button>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <div>
                            <img
                                style={{ width: "100%", height: "auto", marginBottom: 30 }}
                                loading="lazy"
                                alt="Compliance Masters"
                                src="/media/landing-page-images/laptop.png"
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 40 }}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "65%",
                            height: 100,
                            flexDirection: "row",
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignItems: "center"
                        }}>
                            <CheckRounded sx={{ fontSize: 50, mr: 2, color: "gray" }} />
                            <Typography variant="h4" sx={{ fontWeight: "bold", textAlign: "center" }}>
                                Efficient
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "65%",
                            height: 100,
                            flexDirection: "row",
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignItems: "center"
                        }}>
                            <CheckRounded sx={{ fontSize: 50, mr: 2, color: "gray" }} />
                            <Typography variant="h4" sx={{ fontWeight: "bold", textAlign: "center" }}>
                                User Friendly
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "65%",
                            height: 100,
                            flexDirection: "row",
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignItems: "center"
                        }}>
                            <CheckRounded sx={{ fontSize: 50, mr: 2, color: "gray" }} />
                            <Typography variant="h4" sx={{ fontWeight: "bold", textAlign: "center" }}>
                                Affordable
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 150 }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box
                            component={Paper}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                width: "70%",
                                alignItems: "center",
                                marginLeft: "auto",
                                marginRight: "auto",
                                borderRadius: 5,
                            }}>
                            <video controls loop style={{ width: "100%", height: "auto" }} src="https://compliance-masters-videos.s3.us-east-2.amazonaws.com/Welcome-to-Compliance-Masters.mp4" />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 150 }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            alignItems: "center"
                        }}>
                            <div id="driver_compliance">
                                <Typography variant="h3" sx={{ fontWeight: "bold", textAlign: "center" }}>
                                    Driver Compliance
                                </Typography>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="h5" sx={{ textAlign: "center" }}>
                            Invite your drivers to complete their driver files online using our digital
                            forms designed to provide you with the most user-friendly and time-efficient experience
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <DriverComplianceTabs />
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 150, width: "100%", marginLeft: "auto", marginRight: "auto" }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="h3" sx={{ fontWeight: "bold", textAlign: "center" }}>
                            Pre-Employment Investigations
                        </Typography>
                        <Typography variant="h6" sx={{ textAlign: "center" }}>
                            Compliance Masters orders all pre-employment investigations for you and uploads the results to the drivers profile. We will reimburse you for all pre-employment investigation costs on the drivers three month anniversary of employment
                        </Typography>
                        <Typography variant="h4" sx={{ textAlign: "center" }}>
                            Hiring qualified drivers has never been this easy!
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 120 }}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "65%",
                            height: 100,
                            flexDirection: "column",
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignItems: "center"
                        }}>
                            <HelpOutlineRounded sx={{ fontSize: 50, color: "green", mt: 2, mb: 2 }} />
                            <Typography variant="h6" sx={{ textAlign: "center" }}>
                                Pre-Employment DACH Query
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "65%",
                            height: 100,
                            flexDirection: "column",
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignItems: "center"
                        }}>
                            <DevicesRounded sx={{ fontSize: 50, color: "green", mt: 2, mb: 2 }} />
                            <Typography variant="h6" sx={{ textAlign: "center" }}>
                                Pre-employment Motor Vehicle Record
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "65%",
                            height: 100,
                            flexDirection: "column",
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignItems: "center"
                        }}>
                            <ContentPasteSearchRounded sx={{ fontSize: 50, color: "green", mt: 2, mb: 2 }} />
                            <Typography variant="h6" sx={{ textAlign: "center" }}>
                                Pre-employment Drug Test
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "65%",
                            height: 100,
                            flexDirection: "column",
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignItems: "center"
                        }}>
                            <SensorOccupiedRounded sx={{ fontSize: 50, color: "green", mt: 2, mb: 2 }} />
                            <Typography variant="h6" sx={{ textAlign: "center" }}>
                                Pre-employment Criminal Background Check
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 120 }}>
                    <Grid item xs={12} sm={6} md={3}></Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "65%",
                            height: 100,
                            flexDirection: "column",
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignItems: "center"
                        }}>
                            <SettingsEthernetRounded sx={{ fontSize: 50, color: "green", mt: 2, mb: 2 }} />
                            <Typography variant="h6" sx={{ textAlign: "center" }}>
                                Pre-employment Screening Program (PSP)
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "65%",
                            height: 100,
                            flexDirection: "column",
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignItems: "center"
                        }}>
                            <ContactMailRounded sx={{ fontSize: 50, color: "green", mt: 2, mb: 2 }} />
                            <Typography variant="h6" sx={{ textAlign: "center" }}>
                                Previous Employer Accident Records
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}></Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item md={12}>
                        <Box style={{
                            display: "flex",
                            marginTop: 50,
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <img
                                loading="lazy"
                                src="/media/landing-page-images/laptop-image-2.png"
                                alt="dashboardx"
                                style={{
                                    width: "80%",
                                    height: "auto"
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 150, width: "100%", marginLeft: "auto", marginRight: "auto" }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <div id="drug_alcohol_compliance">
                            <Typography variant="h3" sx={{ fontWeight: "bold", textAlign: "center" }}>
                                Drug and Alcohol Compliance
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={2}
                    sx={{
                        marginTop: 5,
                        width: {
                            xs: "100%",
                            sm: "100%",
                            md: "70%",
                            lg: "65%",
                        },
                        marginLeft: "auto", marginRight: "auto"
                    }}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box sx={{
                            display: "flex",
                            width: "100%",
                            height: 460,
                            flexDirection: "column",
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignItems: "center",
                            padding: 5,
                            borderRadius: 5
                        }} component={Paper} elevation={3} boxShadow={3}>
                            <Typography variant="h5" sx={{ textAlign: "center", fontWeight: "bold" }}>
                                Random Testing
                            </Typography>
                            <Typography variant="h6" sx={{ textAlign: "center" }}>
                                Have all your employees placed into your own pool or our random drug testing consortium - We test 52% drug and 12%
                                alcohol at no additional cost to you.
                            </Typography>
                            <Typography variant="h6" sx={{ textAlign: "center" }}>
                                Your company will be notified of an employee’s quarterly random selection.
                                The necessary testing information will be sent via email.
                                Company will be notified of results and results are automatically uploaded to the employee’s drug and alcohol file.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box sx={{
                            display: "flex",
                            width: "100%",
                            height: 460,
                            flexDirection: "column",
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignItems: "center",
                            padding: 5,
                            borderRadius: 5
                        }} component={Paper} elevation={3} boxShadow={3}>
                            <Typography variant="h5" sx={{ textAlign: "center", fontWeight: "bold" }}>
                                Pre-Employment, Post-Accident,
                                Reasonable Suspicion,
                                Return-to-Duty, and Follow up Testing
                            </Typography>
                            <Typography variant="h6" sx={{ textAlign: "center" }}>
                                When hiring a new employee a pre-employment investigation will be done, including a pre-employment drug test.
                                With just a few clicks motor carriers can order post-accident,
                                reasonable suspicion, or return-to-duty tests when needed.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box sx={{
                            display: "flex",
                            width: "100%",
                            minHeight: 290,
                            flexDirection: "column",
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignItems: "center",
                            borderRadius: 5,
                            p: 1
                        }} component={Paper} elevation={3} boxShadow={3}>
                            <img
                                loading="lazy"
                                src="/media/landing-page-images/dach-3.png"
                                alt="dashboardx"
                                style={{
                                    width: "100%",
                                    height: "auto"
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box sx={{
                            display: "flex",
                            width: "100%",
                            minHeight: 290,
                            flexDirection: "column",
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignItems: "center",
                            borderRadius: 5,
                            p: 1
                        }} component={Paper} elevation={3} boxShadow={3}>
                            <img
                                loading="lazy"
                                src="/media/landing-page-images/dach-2.png"
                                alt="dashboardx"
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    borderRadius: 5,
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 150, width: "100%", marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Grid item xs={12} md={12} lg={12}>
                        <div id="pricing">
                            <Typography variant="h3" sx={{ fontWeight: "bold", textAlign: "center" }}>
                                Clear Pricing Makes It Easy
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <img
                            loading="lazy"
                            src="/media/landing-page-images/pricing/group1.png"
                            alt="dashboardx"
                            style={{
                                width: "100%",
                                height: "auto",
                                marginLeft: "auto",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <img
                            loading="lazy"
                            src="/media/landing-page-images/pricing/group2.png"
                            alt="dashboardx"
                            style={{
                                width: "100%",
                                height: "auto"
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <img
                            loading="lazy"
                            src="/media/landing-page-images/pricing/group3.png"
                            alt="dashboardx"
                            style={{
                                width: "100%",
                                height: "auto"
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <img
                            loading="lazy"
                            src="/media/landing-page-images/pricing/group4.png"
                            alt="dashboardx"
                            style={{
                                width: "100%",
                                height: "auto"
                            }}
                        />
                    </Grid>
                </Grid>
                {/* <Grid container spacing={2} style={{ marginTop: 150, width: "100%", marginLeft: "auto", marginRight: "auto" }}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box sx={{
                            display: "flex",
                            width: "100%",
                            height: 400,
                            flexDirection: "column",
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignItems: "center",
                            padding: 5,
                            borderRadius: 5
                        }} component={Paper} elevation={3} boxShadow={3}>
                            <Typography variant="h4" sx={{ textAlign: "center" }}>
                                Live MVR Add-on
                            </Typography>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "100%",
                            }}>
                                <Typography variant="h4" sx={{ textAlign: "center" }}>
                                    <span className="text-success">
                                        $5 {" "}
                                    </span>
                                    <span className="text-muted">
                                        /month per driver
                                    </span>
                                </Typography>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box sx={{
                            display: "flex",
                            width: "100%",
                            height: 400,
                            flexDirection: "column",
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignItems: "center",
                            padding: 5,
                            borderRadius: 5
                        }} component={Paper} elevation={3} boxShadow={3}>
                            <Typography variant="h4" sx={{ textAlign: "center" }}>
                                D&A Written Policy
                            </Typography>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "100%",
                            }}>
                                <Typography variant="h4" sx={{ textAlign: "center" }}>
                                    <span className="text-success">
                                        $500 {" "}
                                    </span>
                                </Typography>
                            </div>
                        </Box>
                    </Grid>
                </Grid> */}
                <Grid container spacing={2} style={{ marginTop: 50, width: "95%", marginLeft: "auto", marginRight: "auto" }}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="h6" sx={{ fontWeight: "bold", color: "gray", textAlign: "center" }}>
                            Contact Us
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="h6" sx={{ fontWeight: "bold", color: "gray", textAlign: "center" }}>
                            Phone Number: (478)221-7900
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="h6" sx={{ fontWeight: "bold", color: "gray", textAlign: "center" }}>
                            Email: <a href="mailto:support@mycompliancemaster.com">
                                support@mycompliancemaster.com
                            </a>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 20, width: "95%", marginLeft: "auto", marginRight: "auto" }}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="h6" sx={{ fontWeight: "bold", color: "gray", textAlign: "center" }}>
                            Copyright ©  Compliance Masters Ltd. All rights reserved.
                        </Typography>
                    </Grid>
                </Grid>
            </Div2>
            <QuoteModal setEmail={setEmail} email={email} />
        </Container>
    )
}

export default withLDConsumer()(LandingPage);

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    `;


const Div2 = styled.div`
  align-self: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  padding: 0 57px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Div15 = styled.div`
  color: #222;
  background: linear-gradient(90deg, #039044 16.78%, #b4d525 79.21%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font: 400 40px Gilroy-Bold, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div16 = styled.div`
  color: #73738d;
  margin-top: 52px;
  font: 400 16px/24px Gilroy-Medium, -apple-system, Roboto, Helvetica,
    sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 150px;
  overflow: hidden;
  z-index: 1;
  max-width: 100%;
`;
