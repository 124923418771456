import React from 'react'
import { useParams } from 'react-router-dom';
import { IMember } from '../../../models';
import {
    getCompanyMember,
    // getSubscriptionPlans
} from '../../../shared';
import { Box, Button, Container, Paper } from '@mui/material';
// import { StripeProduct } from '../../../app/modules/models';
import FormHeaderCard from '../HeaderCard';
import DrugAlcoholWrittenPolicy from './DrugAlcoholWrittenPolicy';
import WrittenPolicySignature from './WrittenPolicySignature';
import { useGetDrugAlcoholWrittenPolicyProgressQuery, useGetMemberDrugAlcoholWrittenPolicyProgressQuery } from '../../../store/reducers/DrugAlcoholWrittenPolicy/drug-alcohol-written-policy';
import { useThemeMode } from '../../partials';
import ActionButtons from '../ActionButtons';
import { useAuth } from '../../../app/modules/auth';

const WrittenPolicy = () => {
    const { mode } = useThemeMode()
    const [member, setMember] = React.useState<IMember>()
    const { id } = useParams()
    const { currentUser } = useAuth()
    const { data: progress } = useGetDrugAlcoholWrittenPolicyProgressQuery(Number(member?.id), {
        skip: !member?.id,
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
        refetchOnFocus: true,
    })
    const { data: memberProgress } = useGetMemberDrugAlcoholWrittenPolicyProgressQuery(Number(member?.id), {
        skip: !member?.id,
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
        refetchOnFocus: true,
    })
    const [value, setValue] = React.useState(0);
    React.useEffect(() => {
        if (id) {
            getCompanyMember(id).then((data) => {
                setMember(data)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container style={{ userSelect: 'none' }}>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <FormHeaderCard
                    headerTitle='Drug and Alcohol Written Policy'
                    status={progress && (progress?.progress > 0 && progress?.progress < 100) ? "In Progress" : progress && progress.progress > 99 ? "Completed" : "Not Started"}
                    sectionCount={1}
                    itemCount={1}
                    totalProgress={progress ? progress.progress : 0}
                    memberProgress={memberProgress ? memberProgress.progress : 0}
                    nextButton={{ title: "Certificate of Road Test", path: "" }}
                    member={member}
                    setValue={setValue}
                    value={value}
                />
            </Box>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: "47vw",
                        md: "70vw",
                        sm: "80vw",
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
                component={Paper}
            >
                <DrugAlcoholWrittenPolicy />
            </Box>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: "47vw",
                        md: "70vw",
                        sm: "80vw",
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
                component={Paper}
            >
                <WrittenPolicySignature member={member} />
            </Box>
            <ActionButtons
                member={member}
                nextButton={{ title: "Drivers Application for Employment", path: "driver-application-for-employment" }}
                prevButton={{
                    path: !member?.pre_employment_screened || (member?.pre_employment_screened && member?.pre_employment_investigation.length > 0) ? "disclosure-form-for-psp" : "driver-release-form-background-checks",
                    title: !member?.pre_employment_screened || (member?.pre_employment_screened && member?.pre_employment_investigation.length > 0) ? "Disclosure Form for PSP" : "Driver Release Form Background Checks"
                }}
            />
            <div style={{
                display: "flex",
                width: "47vw",
                justifyContent: "center",
                marginLeft: 'auto',
                marginRight: 'auto',
            }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (currentUser?.role === "Member") {
                            window.location.assign(`/dashboard/overview`)
                        } else {

                            if (member?.id) {
                                window.location.assign(`/member/${member?.id}/profile/driver-documents`)
                            }
                        }
                    }}
                    fullWidth
                >Back to Forms Overview</Button>
            </div>
        </Container>
    )
}

export default WrittenPolicy