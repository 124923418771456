/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { Logout, AuthPage, useAuth } from '../modules/auth';
import App from '../App';
import TermsConditions from '../modules/auth/components/TermsConditions';
import { Theme, ThemeProvider, createTheme } from '@mui/material/styles';
import { useThemeMode } from '../../compliancemasters/partials';
import SalesRepTermsAndConditions from '../modules/auth/components/SalesRepTermsAndConditions';
import PasswordReset from '../pages/password-reset/PasswordReset';
import CompleteDriverFile from '../pages/complete-driver-file/CompleteDriverFile';
import CompleteDriverFileDrugAlcohol from '../pages/complete-driver-file/CompleteDriverFileDrugAlcohol';
import LandingPage from '../pages/LandingPage';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;


const AppRoutes: FC = () => {
    const { currentUser } = useAuth();
    const { mode, updateMode, updateMenuMode } = useThemeMode()
    const [theme, setTheme] = React.useState<Theme>({} as Theme);

    const createNewTheme = React.useCallback(() => {
        if (mode === "light") {
            return createTheme({
                components: {
                    MuiInputBase: { // Targeting the InputBase component
                        styleOverrides: {
                            root: {
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                },
                                "& .MuiInputBase-input": {
                                    WebkitTextFillColor: "black",
                                }
                            },
                        },
                    },
                    MuiTypography: {
                        styleOverrides: {
                            root: {
                                color: "black",
                            }
                        }
                    }
                },
            });
        } else {
            return createTheme({
                components: {
                    MuiInputBase: { // Targeting the InputBase component
                        styleOverrides: {
                            root: {
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "white",
                                },
                                "& .MuiInputBase-input": {
                                    WebkitTextFillColor: "white",
                                }
                            },
                        },
                    },
                    MuiTypography: {
                        styleOverrides: {
                            root: {
                                color: "white",
                            }
                        }
                    }
                },
            });
        }
    }, [mode])

    React.useEffect(() => {
        setTheme(createNewTheme())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode])

    React.useEffect(() => {
        updateMode("light")
        updateMenuMode("light")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (window.location.pathname.length > 0 && window.location.search === ("?ref=")) {
            window.localStorage.setItem("compliance_master_refer_path", window.location.pathname)
        }
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter basename={PUBLIC_URL}>
                <Routes>
                    <Route element={<App />}>
                        <Route path="error/*" element={<ErrorsPage />} />
                        <Route path="logout" element={<Logout />} />
                        <Route path="set-new-password" element={<PasswordReset />} />
                        <Route path="/" element={<LandingPage />} />
                        <Route path='terms' element={currentUser && currentUser?.role === "SalesRep" ? <SalesRepTermsAndConditions /> : <TermsConditions />} />
                        <Route
                            element={<CompleteDriverFile />}
                            path="/complete-driver-file"
                        />
                        <Route
                            element={<CompleteDriverFileDrugAlcohol />}
                            path="/complete-driver-file-drug-alcohol"
                        />
                        {currentUser ? (
                            <>
                                <Route path="/*" element={<PrivateRoutes />} />
                                <Route path="auth/*" element={<AuthPage />} />
                            </>
                        ) : (
                            <>
                                <Route path="auth/*" element={<AuthPage />} />
                                <Route path="*" element={<Navigate to="/auth" />} />
                            </>
                        )}
                    </Route>
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export { AppRoutes };
