import React, { useEffect } from 'react';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { IDVIReport, Trailer } from '../../../store/interfaces/PreventableMaintenance/dvir.report';
import {
    useCreateTrailerMutation,
    useGetTrailerByReportQuery,
    useUpdateTrailerMutation
} from '../../../store/reducers/PreventableMaintenance/dvir.report';

const initialValues: Partial<Trailer> = {
    report: undefined,
    brake_connections: false,
    brakes: false,
    coupling_devices: false,
    coupling_kingpin: false,
    doors: false,
    hitch: false,
    landing_gear: false,
    lights: false,
    reflectors_reflective_tape: false,
    roof: false,
    suspension_system: false,
    tarpaulin: false,
    tires: false,
    wheels_and_rims: false,
    others: ''
};

interface IProps {
    dviReport?: IDVIReport;
    createReport?: boolean;
    disabled?: boolean;
    conditionSatisfactory?: boolean;
    setDefectChecked?: (value: boolean) => void;
}

const TrailerChecklist: React.FunctionComponent<IProps> = ({
    dviReport,
    createReport,
    disabled,
    conditionSatisfactory,
    setDefectChecked
}) => {
    const [createTrailerReport, { data, isSuccess }] = useCreateTrailerMutation();
    const [updateTrailerReport, { data: updated, isSuccess: updateSuccess }] = useUpdateTrailerMutation();
    const { data: trailer } = useGetTrailerByReportQuery(dviReport?.id ?? 0, {
        refetchOnFocus: true,
        skip: !dviReport
    });
    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({}),
        onSubmit: async (values) => {
            if (trailer) {
                await updateTrailerReport({ ...values, id: trailer.id, report: dviReport });
            } else {
                createTrailerReport({ ...values, report: dviReport });
            }
        }
    });

    useEffect(() => {
        if (isSuccess && data) {
            formik.setValues(data);
        }
        // eslint-disable-next-line
    }, [data, isSuccess]);

    useEffect(() => {
        if (dviReport && createReport) {
            formik.handleSubmit();
        }
        // eslint-disable-next-line
    }, [dviReport]);

    useEffect(() => {
        if (trailer) {
            formik.setValues(trailer);
        }
        if (updated && updateSuccess) {
            formik.setValues(updated);
        }
        // eslint-disable-next-line
    }, [trailer, updated]);

    useEffect(() => {
        const values = Object.values(formik.values);
        if (values.includes(true)) {
            setDefectChecked && setDefectChecked(true);
        } else {
            setDefectChecked && setDefectChecked(false);
        }
        // eslint-disable-next-line
    }, [formik.values]);

    return (
        <>
            {!conditionSatisfactory && (
                <>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <Typography variant="h6" sx={{ textDecoration: 'underline' }}>
                            Trailer / Vehicle No.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() =>
                                        formik.setFieldValue('brake_connections', !formik.values.brake_connections)
                                    }
                                    checked={formik.values.brake_connections}
                                />
                            }
                            label="Brake Connections"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={<Checkbox onClick={() => formik.setFieldValue('brakes', !formik.values.brakes)} />}
                            label="Brakes"
                            disabled={disabled}
                            checked={formik.values.brakes}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() =>
                                        formik.setFieldValue('coupling_devices', !formik.values.coupling_devices)
                                    }
                                />
                            }
                            label="Coupling Devices"
                            disabled={disabled}
                            checked={formik.values.coupling_devices}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() =>
                                        formik.setFieldValue('coupling_kingpin', !formik.values.coupling_kingpin)
                                    }
                                />
                            }
                            label="Coupling (King) Pin"
                            disabled={disabled}
                            checked={formik.values.coupling_kingpin}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={<Checkbox onClick={() => formik.setFieldValue('doors', !formik.values.doors)} />}
                            label="Doors"
                            disabled={disabled}
                            checked={formik.values.doors}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={<Checkbox onClick={() => formik.setFieldValue('hitch', !formik.values.hitch)} />}
                            label="Hitch"
                            disabled={disabled}
                            checked={formik.values.hitch}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => formik.setFieldValue('landing_gear', !formik.values.landing_gear)}
                                />
                            }
                            label="Landing Gear"
                            disabled={disabled}
                            checked={formik.values.landing_gear}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={<Checkbox onClick={() => formik.setFieldValue('lights', !formik.values.lights)} />}
                            label="Lights All"
                            disabled={disabled}
                            checked={formik.values.lights}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() =>
                                        formik.setFieldValue(
                                            'reflectors_reflective_tape',
                                            !formik.values.reflectors_reflective_tape
                                        )
                                    }
                                />
                            }
                            label="Reflectors / Reflective Tape"
                            disabled={disabled}
                            checked={formik.values.reflectors_reflective_tape}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={<Checkbox onClick={() => formik.setFieldValue('roof', !formik.values.roof)} />}
                            label="Roof"
                            disabled={disabled}
                            checked={formik.values.roof}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() =>
                                        formik.setFieldValue('suspension_system', !formik.values.suspension_system)
                                    }
                                />
                            }
                            label="Suspenion System"
                            disabled={disabled}
                            checked={formik.values.suspension_system}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox onClick={() => formik.setFieldValue('tarpaulin', !formik.values.tarpaulin)} />
                            }
                            label="Tarpaulin"
                            disabled={disabled}
                            checked={formik.values.tarpaulin}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={<Checkbox onClick={() => formik.setFieldValue('tires', !formik.values.tires)} />}
                            label="Tires"
                            disabled={disabled}
                            checked={formik.values.tires}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() =>
                                        formik.setFieldValue('wheelsAndRims', !formik.values.wheels_and_rims)
                                    }
                                />
                            }
                            label="Wheels and Rims"
                            disabled={disabled}
                            checked={formik.values.wheels_and_rims}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={<Checkbox onClick={() => formik.setFieldValue('others', !formik.values.others)} />}
                            label="Other"
                            disabled={disabled}
                            checked={Boolean(formik.values.others)}
                        />
                    </Grid>
                </>
            )}
        </>
    );
};

export default TrailerChecklist;
