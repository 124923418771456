import React from 'react';
import TableWrapper from '../../../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import TableContainerHeader from '../../../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableContainerHeaderTitle from '../../../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import TableContainer from '../../../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableHeader from '../../../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import { useAuth } from '../../../../modules/auth';
import { useGetDriverReportsQuery } from '../../../../../store/reducers/PreventableMaintenance/dvir.report';
import { Button, Grid, TableBody, TableCell, TableRow } from '@mui/material';
import SelectVehicleModal from './SelectVehicleModal';
import DateComponent from '../../../preventableMaintenance/DateComponent';

// function formatDate(dateString: string): string {
//     const date = new Date(dateString);

//     const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
//     const day = String(date.getDate()).padStart(2, '0');
//     const year = date.getFullYear();

//     return `${month}-${day}-${year}`;
// }

const DvirDashboard = () => {
    const { currentUser } = useAuth();
    const [selectVehicleOpen, setSelectVehicleOpen] = React.useState(false);
    const { data: reports = [] } = useGetDriverReportsQuery(currentUser?.member?.id, {
        skip: !currentUser?.member?.id
    });

    return (
        <>
            <TableWrapper>
                <TableContainerHeader>
                    <Grid container spacing={2} p={2}>
                        <Grid item xs={12} sm={6} md={7.5} lg={8.5}>
                            <TableContainerHeaderTitle title="Your Driver Inspection Report Dashboard" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4.5} lg={3.5}>
                            <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                onClick={() => {
                                    setSelectVehicleOpen(true);
                                }}
                            >
                                Complete Driver-Vehicle Inspection Report
                            </Button>
                        </Grid>
                    </Grid>
                </TableContainerHeader>
                <TableContainer>
                    <TableHeader>
                        <TableHeaderColumn>Is Current</TableHeaderColumn>
                        <TableHeaderColumn>Vehicle No.</TableHeaderColumn>
                        <TableHeaderColumn>Date</TableHeaderColumn>
                        <TableHeaderColumn>Start Odometer</TableHeaderColumn>
                        <TableHeaderColumn>End Odometer</TableHeaderColumn>
                        <TableHeaderColumn>Satisfactory</TableHeaderColumn>
                        <TableHeaderColumn>Defects Corrected</TableHeaderColumn>
                        <TableHeaderColumn>Driver Signature</TableHeaderColumn>
                        <TableHeaderColumn>Mechanic Signature</TableHeaderColumn>
                        <TableHeaderColumn>Next DVR Signature</TableHeaderColumn>
                    </TableHeader>
                    <TableBody>
                        {reports.length > 0
                            ? reports.map((report) => (
                                  <TableRow
                                      hover
                                      sx={{ cursor: 'pointer' }}
                                      onClick={() => {
                                          window.location.assign(`/driver-inspection-report/${report?.id}`);
                                      }}
                                      key={report?.id}
                                  >
                                      <TableCell align="center">{report.is_current ? 'Yes' : 'No'}</TableCell>
                                      <TableCell align="center">{report?.vehicle?.vehicle_number}</TableCell>
                                      <DateComponent date={report?.date ?? new Date()} />
                                      <TableCell align="center">{report?.start_odometer_reading}</TableCell>
                                      <TableCell align="center">
                                          <span
                                              style={{
                                                  color: !report?.end_odometer_reading ? 'red' : ''
                                              }}
                                          >
                                              {report?.end_odometer_reading || 'Not Entered'}
                                          </span>
                                      </TableCell>
                                      <TableCell align="center">
                                          {report?.condition_satisfactory ? 'Yes' : 'No'}
                                      </TableCell>
                                      <TableCell align="center">
                                          {!report?.condition_satisfactory
                                              ? report?.defects_corrected
                                                  ? 'Yes'
                                                  : 'No'
                                              : 'N/A'}
                                      </TableCell>
                                      <TableCell align="center">{report?.driver_signature}</TableCell>
                                      <TableCell align="center">
                                          <span
                                              style={{
                                                  color:
                                                      !report?.condition_satisfactory && !report?.mechanic_signature
                                                          ? 'red'
                                                          : ''
                                              }}
                                          >
                                              {!report?.condition_satisfactory
                                                  ? report?.mechanic_signature || 'Not Entered'
                                                  : 'N/A'}
                                          </span>
                                      </TableCell>
                                      <TableCell align="center">
                                          <span
                                              style={{
                                                  color:
                                                      !report?.condition_satisfactory && !report?.next_driver_signature
                                                          ? 'red'
                                                          : ''
                                              }}
                                          >
                                              {!report?.condition_satisfactory
                                                  ? report?.next_driver_signature || 'Not Entered'
                                                  : 'N/A'}
                                          </span>
                                      </TableCell>
                                  </TableRow>
                              ))
                            : null}
                    </TableBody>
                </TableContainer>
            </TableWrapper>
            <SelectVehicleModal open={selectVehicleOpen} setOpen={setSelectVehicleOpen} />
        </>
    );
};

export default DvirDashboard;
