import React, { useEffect, useState } from 'react';
import TableWrapper from '../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import TableContainerHeader from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableContainerHeaderTitle from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import { KTSVG } from '../../../compliancemasters/helpers';
import TableContainer from '../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableHeader from '../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import { TableBody, TableCell, TablePagination, TableRow } from '@mui/material';
import { AddProductModal } from './AddProductModal';
import { StripeActions } from '../../../store/reducers/StripeProducts/StripeActions';
import { useSelector } from '../../../store';

const ProductsTable = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [page, setPage] = React.useState<number>(0);
    const [limit, setLimit] = React.useState<number>(5);
    const { products } = useSelector((state) => state.stripeReducer.product);
    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };

    useEffect(() => {
        if (!open) {
            StripeActions.getCustomProducts();
        }
    }, [open]);

    return (
        <>
            <TableWrapper>
                <TableContainerHeader>
                    <TableContainerHeaderTitle title="Stripe Products" />
                    <div
                        className="btn btn-sm btn-light-primary d-inline-flex align-items-center"
                        onClick={() => setOpen(true)}
                    >
                        <KTSVG path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
                        Add Product
                    </div>
                </TableContainerHeader>
                <TableContainer>
                    <TableHeader>
                        <TableHeaderColumn>Product Name</TableHeaderColumn>
                        <TableHeaderColumn>Product Parent</TableHeaderColumn>
                        <TableHeaderColumn>Price</TableHeaderColumn>
                        <TableHeaderColumn>Description</TableHeaderColumn>
                        <TableHeaderColumn>Stripe ID</TableHeaderColumn>
                    </TableHeader>
                    <TableBody>
                        {products
                            .filter((item) => item.metadata?.custom_product)
                            .map((product) => (
                                <TableRow key={product.product_id}>
                                    <TableCell>{product.name}</TableCell>
                                    <TableCell>{product?.metadata?.parent_product_name}</TableCell>
                                    <TableCell>{product.unit_amount}</TableCell>
                                    <TableCell>{product.description}</TableCell>
                                    <TableCell>{product.product_id}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={products.length}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[10, 25, 30]}
                />
            </TableWrapper>
            <AddProductModal open={open} setOpen={setOpen} />
        </>
    );
};

export default ProductsTable;
