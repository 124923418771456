import React from 'react';
import { useParams } from 'react-router-dom';
import LinkRow from './LinkRow';
import { StripeProduct } from '../../../models';
import { IMember } from '../../../../../models';
import { getCompanyMember } from '../../../../../shared';

interface ILinkRow {
    name: string;
    path: string;
    show?: boolean;
}


const links: (memberSubscription?: StripeProduct, memberId?: string, screened?: boolean) => ILinkRow[] = (memberSubscription, memberId, screened) => {
    if (memberSubscription?.name.toLocaleLowerCase() === "Master Package".toLowerCase() || memberSubscription?.name.toLocaleLowerCase() === "Deluxe Package".toLowerCase() || memberSubscription?.name.toLocaleLowerCase() === "Drug and Alcohol Package".toLowerCase()) {

        return (memberSubscription?.name.toLocaleLowerCase() === "Master Package".toLowerCase() || memberSubscription?.name.toLocaleLowerCase() === "Deluxe Package".toLowerCase()) && screened ? [
            {
                name: 'Driver File',
                path: `/member/${memberId}/profile/driver-documents`,
                show: true
            },
            {
                name: 'Drug File',
                path: `/member/${memberId}/profile/drug-documents`,
                show: true
            },
            {
                name: 'Non-Current File',
                path: `/member/${memberId}/profile/non-current-documents`,
                show: true
            },
            {
                name: 'Manager Notes',
                path: `/member/${memberId}/profile/manager-notes`,
                show: screened
            },
        ] : memberSubscription?.name.toLocaleLowerCase() === "Drug and Alcohol Package".toLowerCase() ? [
            {
                name: 'Drug File',
                path: `/member/${memberId}/profile/drug-documents`,
                show: true
            },
            {
                name: 'Non-Current File',
                path: `/member/${memberId}/profile/non-current-documents`,
                show: true
            },
            {
                name: 'Manager Notes',
                path: `/member/${memberId}/profile/manager-notes`,
                show: screened
            },
        ] : [
            {
                name: 'Driver File',
                path: `/member/${memberId}/profile/driver-documents`,
                show: true
            },
            {
                name: 'Manager Notes',
                path: `/member/${memberId}/profile/manager-notes`,
                show: screened
            },
        ]
    } else if (memberSubscription?.name.toLocaleLowerCase() === "Driver File Package".toLowerCase()) {
        return [
            {
                name: 'Driver File',
                path: `/member/${memberId}/profile/driver-documents`,
                show: true
            },
            {
                name: 'Non-Current File',
                path: `/member/${memberId}/profile/non-current-documents`,
                show: true
            },
            {
                name: 'Manager Notes',
                path: `/member/${memberId}/profile/manager-notes`,
                show: screened
            },
        ]
    } else {
        return screened ? [
            {
                name: 'Driver File',
                path: `/member/${memberId}/profile/driver-documents`,
                show: true
            },
            {
                name: 'Drug File',
                path: `/member/${memberId}/profile/drug-documents`,
                show: true
            },
            {
                name: 'Non-Current File',
                path: `/member/${memberId}/profile/non-current-documents`,
                show: true
            },
            {
                name: 'Manager Notes',
                path: `/member/${memberId}/profile/manager-notes`,
                show: screened
            },
        ] : [
            {
                name: 'Driver File',
                path: `/member/${memberId}/profile/driver-documents`,
                show: true
            },
            {
                name: 'Manager Notes',
                path: `/member/${memberId}/profile/manager-notes`,
                show: screened
            },
        ]
    }
}

interface IProps {
    memberSubscription?: StripeProduct | undefined;
}

export default function CompanyMemberPageLinkRow({ memberSubscription }: IProps) {
    const [member, setMember] = React.useState<IMember>()
    const { memberId } = useParams();

    React.useEffect(() => {
        getCompanyMember(memberId!).then((res) => {
            setMember(res)
        }).catch((err) => {
            console.log(err)
            window.location.assign("/dashboard")
        })
    }, [memberId])

    return <LinkRow links={links(memberSubscription, memberId, member?.pre_employment_screened)} />;
}
