import React, { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import DvirDashboard from '../pages/driver-inspection-report/member/dashboard/DvirDashboard'
import { SuspensedView } from './SuspendedView'

const InspectionReport = lazy(() => import('../pages/driver-inspection-report/DriverInspectionReport'))
const UpdateInspectionReport = lazy(() => import('../pages/driver-inspection-report/UpdateDriverInspectionReport'))

const DvirRoutes = () => {
    return (
        <Routes>
            {<Route path="dashboard/*" element={
                <SuspensedView>
                    <DvirDashboard />
                </SuspensedView>
            } />}
            <Route path="/vehicle/:id/*" element={
                <SuspensedView>
                    <InspectionReport />
                </SuspensedView>
            } />
            <Route path="/:id/*" element={
                <SuspensedView>
                    <UpdateInspectionReport />
                </SuspensedView>
            } />
            <Route path="*" element={<Navigate to="/error/404" />} />
        </Routes>
    )
}

export default DvirRoutes