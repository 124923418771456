import React, { useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { changePassword, } from '../core/_requests';
import { useNavigation } from '../../../../shared';

const urlParams = new URLSearchParams(window.location.href);
const token = (urlParams.values().next().value = !null ? urlParams.values().next().value : '');
const initialValues = {
    password: '',
    changepassword: '',
    token: token
};
const forgotPasswordSchema = Yup.object().shape({
    password: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Password is required'),
    changepassword: Yup.string()
        .required('Password confirmation is required')
        .when('password', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match")
        })
});

export function ChangePassword() {
    const [loading, setLoading] = useState(false);
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
    const { navigateToLogin } = useNavigation();

    const formik = useFormik({
        initialValues,
        validationSchema: forgotPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true);
            setHasErrors(undefined);
            setTimeout(() => {
                changePassword(values.password, initialValues.token!)
                    .then(({ data: { result } }) => {
                        setHasErrors(false);
                        setLoading(false);
                        navigateToLogin();
                    })
                    .catch(() => {
                        setHasErrors(true);
                        setLoading(false);
                        setSubmitting(false);
                        setStatus('The login detail is incorrect');
                    });
            }, 1000);
        }
    });

    return (
        <>
            <form
                className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
                noValidate
                id="kt_login_password_reset_form"
                onSubmit={formik.handleSubmit}
            >
                <div className="text-center mb-10">
                    {/* begin::Title */}
                    <h1 className="text-dark mb-3">Password Reset</h1>
                    {/* end::Title */}

                    {/* begin::Link */}
                    <div className="text-gray-400 fw-bold fs-4">Please enter a new password.</div>
                    {/* end::Link */}
                </div>

                {/* begin::Title */}
                {hasErrors === true && (
                    <div className="mb-lg-15 alert alert-danger">
                        <div className="alert-text font-weight-bold">
                            Sorry, looks like there are some errors detected, please try again.
                        </div>
                    </div>
                )}

                {hasErrors === false && (
                    <div className="mb-10 bg-light-info p-8 rounded">
                        <div className="text-info">Password Successfully Changed!</div>
                    </div>
                )}
                {/* end::Title */}

                {/* begin::Form group */}
                <div className="mb-10 fv-row" data-kt-password-meter="true">
                    <div className="mb-1">
                        <label className="form-label fw-bolder text-dark fs-6">Password</label>
                        <div className="position-relative mb-3">
                            <input
                                type="password"
                                placeholder="Password"
                                autoComplete="off"
                                {...formik.getFieldProps('password')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    {
                                        'is-invalid': formik.touched.password && formik.errors.password
                                    },
                                    {
                                        'is-valid': formik.touched.password && !formik.errors.password
                                    }
                                )}
                            />
                            {formik.touched.password && formik.errors.password && (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        <span role="alert">{formik.errors.password}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="text-muted">Use 8 or more characters with a mix of letters, numbers & symbols.</div>
                </div>
                <div className="fv-row mb-5">
                    <label className="form-label fw-bolder text-dark fs-6">Confirm Password</label>
                    <input
                        type="password"
                        placeholder="Password confirmation"
                        autoComplete="off"
                        {...formik.getFieldProps('changepassword')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                                'is-invalid': formik.touched.changepassword && formik.errors.changepassword
                            },
                            {
                                'is-valid': formik.touched.changepassword && !formik.errors.changepassword
                            }
                        )}
                    />
                    {formik.touched.changepassword && formik.errors.changepassword && (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                <span role="alert">{formik.errors.changepassword}</span>
                            </div>
                        </div>
                    )}
                </div>
                {/* end::Form group */}

                {/* begin::Form group */}
                <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                    <button
                        type="submit"
                        id="kt_password_reset_submit"
                        className="btn btn-lg btn-primary fw-bolder me-4"
                    >
                        <span className="indicator-label">Submit</span>
                        {loading && (
                            <span className="indicator-progress">
                                Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        )}
                    </button>
                    <Link to="/auth/login">
                        <button
                            type="button"
                            id="kt_login_password_reset_form_cancel_button"
                            className="btn btn-lg btn-light-primary fw-bolder"
                            disabled={formik.isSubmitting || !formik.isValid}
                        >
                            Cancel
                        </button>
                    </Link>{' '}
                </div>
                {/* end::Form group */}
            </form>
        </>
    );
}
