import TableContainerHeaderTitle from '../components/TableContainerHeaderTitle';
import TableContainerHeader from '../components/TableContainerHeader';
import TableWrapper from '../components/TableWrapper';
import TableContainer from '../components/TableContainer';
import TableHeader from '../components/TableHeader';
import TableHeaderColumn from '../components/TableHeaderColumn';
import { useAuth } from '../../../../../app/modules/auth';
import { IUser } from '../../../../../models';
import { useEffect, useState } from 'react';
import UserRow from './components/UserRow';
import { IconButton, Tooltip } from '@mui/material';
import { GroupAddRounded, } from '@mui/icons-material';


/*
Billy, I went ahead and refactored the getAllUsers function to be searchUsers. This way, we can use it to get all users, or get a specific user by email.
*/

/*
Both Admins and SuperAdmins can use this component to view all users in the system. You can see this by going to AdminDashboard and SuperAdminDashboard.
Both are using the same component.

However, SuperAdmins and Admins shouldn't be able to do exactly the same things

SuperAdmins should be able to:
- Promote a user to Admin
- Delete any user

Both should be able to:
- View a member's profile
- Delete a member

So Admins shouldn't be able to:
- Create a new Admin
- Delete any user that isn't a member
*/

export default function AdminUserTable() {
    const { searchUsers, currentUser } = useAuth();
    const [users, setUsers] = useState<IUser[]>();

    useEffect(() => {
        handleSearch('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearch = (email: string) => {
        searchUsers(email).then((res: IUser[]) => {
            setUsers(res)
        });
    };

    return (
        <div>
            <TableWrapper>
                <TableContainerHeader>
                    <TableContainerHeaderTitle title="All Users" />
                    <div className="input-group input-group-solid input-group-sm w-250px">
                        <input
                            type="text"
                            className="form-control ps-4"
                            placeholder="Search"
                            name={`email_${Math.random().toString(36).substring(2, 9)}`}
                            onChange={(e) => handleSearch(e.target.value)}
                            autoComplete='off'
                        />
                        {currentUser && currentUser.role === "SuperAdmin" ? (
                            <Tooltip title="Add Admin" placement='top'>
                                <IconButton onClick={() => {
                                    window.location.assign('/admin/add')
                                }}>
                                    <GroupAddRounded
                                        sx={{
                                            color: '#fff',
                                            ml: '15px',
                                        }}
                                        fontSize='large'
                                    />
                                </IconButton>
                            </Tooltip>
                        ) : null}
                    </div>
                </TableContainerHeader>
                {/* begin::Body */}
                <TableContainer>
                    <TableHeader>
                        <TableHeaderColumn>Names</TableHeaderColumn>
                        <TableHeaderColumn>User email</TableHeaderColumn>
                        <TableHeaderColumn>Role</TableHeaderColumn>
                    </TableHeader>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                        {users?.map((user) => {
                            return <UserRow user={user} key={user.id} onActionComplete={() => handleSearch('')} />;
                        })}
                    </tbody>
                </TableContainer>
                {/* begin::Body */}
            </TableWrapper>
        </div>
    );
}
