import React from 'react';
import VehicleProfile from './VehicleProfile';
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom';
import PMProgram from './PMProgram';
import { useGetMaintenanceVehicleQuery } from '../../../../store/reducers/PreventableMaintenance/maintenance.vehicle';
import DriverVehicleInspectionReport from './DriverVehicleInspectionReport';
import { useGetServiceTypesByProgramIdQuery } from '../../../../store/reducers/PreventableMaintenance/service.types';
import ServiceOrders from './ServiceOrders';
import NotesAndLinks from './NotesAndLinks';
import { VehicleType } from '../../../../store/interfaces/PreventableMaintenance/maintenance.vehicles';

const MaintenanceVehicleDetails = () => {
    const { id } = useParams<{ id: string }>();

    const { data: vehicle, refetch } = useGetMaintenanceVehicleQuery(Number(id), {
        refetchOnFocus: true,
        skip: !id
    });

    const { data: service_types = [] } = useGetServiceTypesByProgramIdQuery(Number(vehicle?.pm_program?.id ?? 0), {
        refetchOnFocus: true,
        skip: !vehicle?.pm_program?.id
    });

    const onCreate = () => {
        refetch();
    };

    return (
        <Routes>
            <Route
                element={
                    <>
                        <VehicleProfile vehicle={vehicle} />
                        <Outlet />
                    </>
                }
            >
                <Route
                    path="pm-program"
                    element={<PMProgram onCreate={onCreate} service_types={service_types} vehicle={vehicle} />}
                />
                <Route
                    path="driver-vehicle-inspection-report"
                    element={
                        <DriverVehicleInspectionReport
                            dvirList={
                                vehicle?.vehicle_type === VehicleType.TRAILER
                                    ? vehicle?.dvir_report_trailer
                                    : vehicle?.dvir_reports ?? []
                            }
                        />
                    }
                />
                <Route path="service-orders" element={<ServiceOrders vehicle={vehicle} />} />
                <Route
                    path="notes-and-links"
                    element={<NotesAndLinks vehicle={vehicle} notesAndLinks={vehicle?.notes_and_links} />}
                />
                <Route index element={<Navigate to="pm-program" />} />
                <Route path="*" element={<h1>Not Found</h1>} />
            </Route>
        </Routes>
    );
};

export default MaintenanceVehicleDetails;
