import { Link } from 'react-router-dom';
import { INotification } from '../../../../models/INotification';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import Notification from '../../../layout/components/header/components/NotificationHeader/Notification';

export default function HeaderNotificationsMenu({ notifications }: { notifications: INotification[] }) {
    return (
        <div className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px" data-kt-menu="true">
            <div
                className="d-flex flex-column bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')` }}
            >
                <h3 className="text-white fw-bold px-9 mt-10 mb-6">Notifications</h3>
            </div>

            <div>
                <div className="scroll-y mh-325px my-5 px-8">
                    {notifications.map((notification, index) => (
                        <Notification key={index} notification={notification} />
                    ))}
                </div>

                <div className="py-3 text-center border-top">
                    <Link to="/notifications" className="btn btn-color-gray-600 btn-active-color-primary">
                        View All <KTSVG path="/media/icons/duotune/arrows/arr064.svg" className="svg-icon-5" />
                    </Link>
                </div>
            </div>
        </div>
    );
}

export { HeaderNotificationsMenu };
