/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { PasswordMeterComponent } from '../../../../compliancemasters/assets/ts/components';
import { useAuth } from '../core/Auth';
import { DriverType, IMember } from '../../../../models';
import { getCompanyMembers, getSubscriptionPlans } from '../../../../shared';
import { useCompany } from '../../../../context/CompanyContext';
import { StripeProduct } from '../../models';
import { useParams } from 'react-router-dom';

const getPrice = (option: StripeProduct) => {
    if (option.tiers) {
        return option.tiers[0].unit_amount / 100;
    } else {
        return option.unit_amount / 100;
    }
};

const initialValues = {
    email: '',
    company: '',
    driver_type: DriverType.NEW_DRIVER,
    product_id: ''
};

const registrationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    driver_type: Yup.string().required('Driver Type is required'),
    product_id: Yup.string().required('Product is required')
});

export function EmailEmployee() {
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isError, setIsError] = useState(false);
    const [packagesLoading, setPackagesLoading] = useState(false);
    const { emailUserForSignUp } = useAuth();
    const [subscriptions, setSubscriptions] = useState<StripeProduct[]>([]);
    const { currentCompany } = useCompany();
    const { id } = useParams<{ id: string }>();

    const setSubscription = (subscriptions: StripeProduct[], setSubscriptions: any) => {
        setSubscriptions(subscriptions);
    };

    const setPackageLoading = (isLoading: boolean, setPackagesLoading: any) => {
        setPackagesLoading(isLoading);
    };

    const filterSubscriptionPlans = (
        driverType: string,
        currentCompany: any,
        subscriptionPlans: StripeProduct[],
        members: IMember[]
    ) => {
        if (driverType === DriverType.NEW_DRIVER) {
            return subscriptionPlans;
        } else if (driverType === DriverType.EXISTING_DRIVER) {
            const optionList: StripeProduct[] = [];
            for (const pkg of currentCompany?.package || []) {
                const option = subscriptionPlans.find((x) => x.product_id === pkg.id);
                if (option) {
                    if (optionList.find((x) => x.product_id === option.product_id)) {
                        continue;
                    }
                    const memberItems = members.filter((x) => x.product_id === option.product_id);
                    if (memberItems.length >= pkg.numberOfDrivers) {
                        continue;
                    }
                    optionList.push(option);
                }
            }
            return optionList;
        } else {
            return [];
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            function errorHandler(error: any) {
                setIsError(true);
                setLoading(false);
            }
            function completedRequest() {
                setSubmitting(false);
                setLoading(false);
                setSubmitted(true);
                resetForm();
            }
            setSubmitted(false);
            setLoading(true);
            emailUserForSignUp(values.email, currentCompany?.name!, values.driver_type, values.product_id, id)
                .then((res) => {
                    completedRequest();
                })
                .catch((error) => errorHandler(error));
        }
    });

    useEffect(() => {
        PasswordMeterComponent.bootstrap();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSubs = React.useCallback(async () => {
        if (formik.values.driver_type) {
            setPackagesLoading(true);
            if (currentCompany) {
                const members: IMember[] = await getCompanyMembers(currentCompany?.id);
                getSubscriptionPlans().then((res: { data: StripeProduct[] }) => {
                    const filteredSubscriptions = filterSubscriptionPlans(
                        formik.values.driver_type,
                        currentCompany,
                        res.data,
                        members
                    );
                    setSubscription(filteredSubscriptions, setSubscriptions);
                    setPackageLoading(false, setPackagesLoading);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCompany, formik.values.driver_type]);

    // React.useEffect(() => {
    //     if (currentCompany?.package) {
    //         let addNewDriver = true;

    //         for (const sub of currentCompany.package) {
    //             const members = currentCompany.members.filter(x => x.product_id === sub.id);
    //             if (members.length < sub.numberOfDrivers) {
    //                 addNewDriver = false;
    //                 setDriverTypes([DriverType.EXISTING_DRIVER]);
    //                 break; // Break out of the loop as we found a package needing existing drivers
    //             }
    //         }

    //         if (addNewDriver) {
    //             // Set to NEW_DRIVER only if all packages have enough existing drivers
    //             setDriverTypes([DriverType.NEW_DRIVER]);
    //         }
    //     }
    // }, [currentCompany]);

    React.useEffect(() => {
        getSubs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.driver_type, getSubs]);

    React.useEffect(() => {
        if (submitted) {
            setTimeout(() => {
                setSubmitted(false);
            }, 3000);
        }
    }, [submitted]);

    return (
        <form
            className="form w-100 p-5 fv-plugins-bootstrap5 fv-plugins-framework"
            noValidate
            id="kt_login_signup_form"
            onSubmit={formik.handleSubmit}
        >
            {formik.status && (
                <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">{formik.status}</div>
                </div>
            )}
            <div className="mb-10">
                <label className="form-label">Please enter email of employee you'd like to add </label>
                <input
                    type="text"
                    className="form-control form-control-solid"
                    {...formik.getFieldProps('email')}
                    placeholder="Employee Email"
                />
            </div>
            {/* <div className="mb-10">
                <label className="form-label">Please select type of employee you'd like to add </label>
                <select
                    className="form-control form-control-solid"
                    {...formik.getFieldProps('driver_type')}
                >
                    <option value="">Select Driver Type</option>
                    {driverTypes.map((dvr, index) => {
                        return (
                            <option key={index} value={dvr}>
                                {dvr === DriverType.NEW_DRIVER ? 'New Driver' : 'Existing Driver'}
                            </option>
                        )
                    })}
                </select>
            </div> */}
            {packagesLoading && (
                <div className="mb-10">
                    <div className="text-center">Loading Packages...</div>
                </div>
            )}
            {!packagesLoading && (
                <div className="mb-10">
                    <label className="form-label">Please select Package for which you are adding this driver</label>
                    <select className="form-control form-control-solid" {...formik.getFieldProps('product_id')}>
                        <option value="">Select Package</option>
                        {subscriptions.map((plan, index) => (
                            <option key={index} value={plan.product_id}>
                                {plan.name} - ${getPrice(plan)}
                            </option>
                        ))}
                    </select>
                </div>
            )}
            <div className="text-center">
                <button
                    type="submit"
                    id="kt_sign_up_submit"
                    className="btn btn-lg btn-primary w-100 mb-5"
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className="indicator-label">Send Email</span>}
                    {loading && (
                        <span className="indicator-progress" style={{ display: 'block' }}>
                            Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    )}
                </button>
            </div>
            {submitted && !isError && (
                <div>
                    <div className="alert alert-success d-flex align-items-center p-5 mb-10">
                        <span className="svg-icon svg-icon-2hx svg-icon-success me-3">-</span>
                        <div className="d-flex flex-column">
                            <h5 className="mb-1">Registration Email Sent!</h5>
                        </div>
                    </div>
                </div>
            )}
            {isError && !submitted && (
                <div>
                    <div className="alert alert-warning d-flex align-items-center p-5 mb-10">
                        <span className="svg-icon svg-icon-2hx svg-icon-warning me-3">-</span>
                        <div className="d-flex flex-column">
                            <h5 className="mb-1">Error Sending Email!</h5>
                        </div>
                    </div>
                </div>
            )}
        </form>
    );
}
