import {releaseFormAuthorizationApi} from './release-form-authorization'
import {releaseFormBackgroundCheckApi} from './release-form-background-check'
import {releaseFormApplicantSignatureApi} from './release-form-signature'

export const ReleaseFormBackgroundCheckReducers = {
  [releaseFormAuthorizationApi.reducerPath]: releaseFormAuthorizationApi.reducer,
  [releaseFormBackgroundCheckApi.reducerPath]: releaseFormBackgroundCheckApi.reducer,
  [releaseFormApplicantSignatureApi.reducerPath]: releaseFormApplicantSignatureApi.reducer,
}

export const ReleaseFormBackgroundCheckMiddleware = [
  releaseFormAuthorizationApi.middleware,
  releaseFormBackgroundCheckApi.middleware,
  releaseFormApplicantSignatureApi.middleware,
]
