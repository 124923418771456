import React, { useState } from 'react';
import { KTSVG } from '../../../helpers';
import { IUser } from '../../../../models';
import Button from '../../../../app/modules/buttons/Button';

interface IProps {
    user?: IUser;
    handleDelete: (x: 'soft' | 'hard') => void;
    loadingDelete: boolean;
}

export default function DeleteUserModalButton({ user, handleDelete, loadingDelete }: IProps) {
    const [callout, setCallout] = useState(deleteCallout());

    function hardDeleteCallout() {
        return (
            <>
                <div className="modal-body">
                    <h1 className="text-danger">
                        This is HARD delete. This will permanently delete the user from the system.
                    </h1>
                    <h2>
                        This should not be used regularly. In 99% of cases, you should use the soft delete option.
                        Please consult the engineering team before proceeding.
                    </h2>
                </div>
                {deleteFooter('hard')}
            </>
        );
    }

    function softDeleteCallout() {
        return (
            <>
                <div className="modal-body">
                    <h2 className="text-danger">
                        This is SOFT delete. This will mark the user as deleted in the system, but will not permanently
                        delete them.
                    </h2>
                    <p>
                        This should be used in 99% of cases. In the rare case that you need to permanently delete a
                        user, please consult the engineering team before proceeding.
                    </p>
                </div>
                {deleteFooter('soft')}
            </>
        );
    }

    function deleteCallout() {
        return (
            <>
                <div className="modal-body">
                    <div className="text-center">
                        <h3>Are you sure you want to delete this user?</h3>

                        <div className="py-6">
                            <h3>
                                Name: {user?.first_name} {user?.last_name}
                            </h3>
                            <h3>Email: {user?.email}</h3>
                            <h3>Role: {user?.role}</h3>
                            {user?.role === 'Owner' && (
                                <h3 className="text-danger">
                                    Deleting this user will delete all associated employees.
                                </h3>
                            )}
                        </div>

                        <p className="text-muted">This action cannot be undone.</p>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="d-flex justify-content-end">
                        <div className="row gx-2">
                            <div className="col-auto">
                                <Button className={'btn btn-bg-light btn-active-color-primary'} dataBsDismiss={'modal'}>
                                    Cancel
                                </Button>
                            </div>
                            <div className="col-auto">
                                <Button
                                    className={
                                        'btn btn-outline btn-outline-solid btn-outline-danger btn-active-light-danger'
                                    }
                                    onClick={() => setCallout(hardDeleteCallout())}
                                >
                                    <i className="fa-solid fa-thumbs-down text-danger"></i>
                                    Hard Delete
                                </Button>
                            </div>
                            <div className="col-auto">
                                <Button
                                    className={'btn btn-danger'}
                                    loading={loadingDelete}
                                    onClick={() => setCallout(softDeleteCallout())}
                                >
                                    <i className="fa-solid fa-thumbs-up text-white"></i>
                                    Soft Delete
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    function deleteFooter(deleteType: 'soft' | 'hard') {
        return (
            <div className="modal-footer">
                <div className="d-flex justify-content-end">
                    <div className="row gx-2">
                        <div className="col-auto">
                            <Button className={'btn btn-bg-light btn-active-color-primary'} dataBsDismiss={'modal'}>
                                Cancel
                            </Button>
                        </div>
                        <div className="col-auto">
                            <Button
                                className={'btn btn-danger'}
                                onClick={() => {
                                    handleDelete(deleteType);
                                }}
                                dataBsDismiss={'modal'}
                            >
                                Confirm Delete
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function Modal() {
        return (
            <div className="modal fade" tabIndex={-1} id={`delete_user_modal_${user?.id}`}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title">Delete User</h1>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-2x" />
                            </div>
                        </div>
                        {callout}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            {Modal()}
            <Button
                className={'btn btn-bg-danger btn-active-color-danger btn-sm'}
                loading={loadingDelete}
                dataBsTarget={`#delete_user_modal_${user?.id}`}
                dataBsToggle={'modal'}
                onClick={() => setCallout(deleteCallout())}
            >
                Delete Driver
            </Button>
        </>
    );
}
