import React from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../compliancemasters/helpers'
import { IMember } from '../../../../models'
import { useAuth } from '../../auth'
import { getCompanyMember } from '../../../../shared'
import { useGetMemberRecordOfRoadTestProgressMutation, useGetRecordOfRoadTestProgressMutation } from '../../../../store/reducers/RecordOfRoadTest/progress'
import { useGetPerformanceRatingAndOwnerSignatureByUserIdQuery } from '../../../../store/reducers/RecordOfRoadTest/performance-rating-owner-signature'
import { StatusTag } from '../../../../compliancemasters/partials'


const RecordOfRoadTest = ({ id }: { id: number }) => {
    const [progress, setProgress] = React.useState<number>(0)
    const [getProgress, { data }] = useGetRecordOfRoadTestProgressMutation()
    const { data: rating } = useGetPerformanceRatingAndOwnerSignatureByUserIdQuery(id, {
        skip: !id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    })
    const [getMemberProgress, { data: pg }] = useGetMemberRecordOfRoadTestProgressMutation()
    const [member, setMember] = React.useState<IMember>()
    const { currentUser } = useAuth()

    React.useEffect(() => {
        getProgress(id)
        getMemberProgress(id)
        getCompanyMember(String(id)).then(res => {
            setMember(res)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    React.useEffect(() => {
        if (currentUser && ["Admin", "Owner", "SuperAdmin"].includes(currentUser?.role) && data) {
            setProgress(data?.progress)
        } else if (pg) {
            setProgress(pg?.progress)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, pg])

    return (
        <tr>
            <td className='mw-125px'>
                <span className='text-dark fw-bold text-hover-primary mb-1 fs-6 cursor-pointer' onClick={() => window.location.assign(`/member/${id}/re-form/record-of-road-test`)}>
                    Record of Road Test
                </span>
            </td>
            <td>
                <div className='d-flex flex-column w-75 me-2'>
                    {progress > 98 && <StatusTag status={progress && (progress > 0 && progress < 100) ? "In Progress" : progress && progress > 99 ? "Completed" : "Not Started"} />}
                </div>
            </td>
            <td>
            </td>
            <td className='mw-125px'>
                {(member?.pre_employment_screened || currentUser?.member) && (rating && rating?.signature_of_examiner) && currentUser?.role !== "Member" ? (
                    <span className='text-success fw-bold cursor-pointer text-hover-primary mb-1 fs-6'>
                        Owner Signature Required
                    </span>
                ) : null}
            </td>
            <td className='text-end'>
                <Link className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" to={`/member/${id}/re-form/record-of-road-test`}>
                    <KTSVG path="/media/icons/duotune/arrows/arr064.svg" className="svg-icon-2" />
                </Link>
            </td>
        </tr>
    )
}

export default RecordOfRoadTest