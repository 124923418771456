import React from 'react'
import { IMember } from '../../../models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material'
import {
    IPrevPreEmploymentDrugAndAlcoholTestStatementDto
} from '../../../store/interfaces/PreviousPreEmploymentDrugAndAlcoholTestStatement/PreviousPreEmploymentEmployeeDrugAndAlcoholTestStatement'
import {
    useCreatePrevPreEmploymentDrugAndAlcoholTestStatementMutation,
    useGetPrevPreEmploymentDrugAndAlcoholTestStatementByUserIdQuery,
    useUpdatePrevPreEmploymentDrugAndAlcoholTestStatementMutation,
    useDeletePrevPreEmploymentDrugAndAlcoholTestStatementMutation
} from '../../../store/reducers/PreviousPreEmploymentDrugAndAlcoholTestStatement/previous-pre-employment-test-statement'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'
import { changeStringLast4 } from '../../../shared'


const initialValues: Partial<IPrevPreEmploymentDrugAndAlcoholTestStatementDto> = {
    prospective_employee_name: '',
    drivers_license_number: '',
    tested_positive_or_refused_to_test: false,
    can_provide_proof_of_return_to_duty: false,
}

const validationSchema = Yup.object({
    prospective_employee_name: Yup.string().required('Required'),
    drivers_license_number: Yup.string().required('Required'),
})


interface IProps {
    member?: IMember
    isDriverFile?: boolean
}


const PrevDachStatement = ({ member, isDriverFile }: IProps) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data } = useGetPrevPreEmploymentDrugAndAlcoholTestStatementByUserIdQuery(member?.user?.id!, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })

    const [createPrevPreEmploymentDrugAndAlcoholTestStatement, { isLoading: isCreating }] = useCreatePrevPreEmploymentDrugAndAlcoholTestStatementMutation()
    const [updatePrevPreEmploymentDrugAndAlcoholTestStatement, { isLoading: isUpdating }] = useUpdatePrevPreEmploymentDrugAndAlcoholTestStatementMutation()
    const [deletePrevPreEmploymentDrugAndAlcoholTestStatement] = useDeletePrevPreEmploymentDrugAndAlcoholTestStatementMutation()

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                updatePrevPreEmploymentDrugAndAlcoholTestStatement({
                    ...data,
                    ...values,
                }).then(() => {
                    resetForm()
                })
            } else {
                createPrevPreEmploymentDrugAndAlcoholTestStatement({
                    ...values,
                    // @ts-ignore
                    company: member?.company?.id,
                    // @ts-ignore
                    member: member?.id,
                }).then(() => {
                    resetForm()
                })
            }
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate(values) {
            const errors: Partial<IPrevPreEmploymentDrugAndAlcoholTestStatementDto> = {}
            if (!values.prospective_employee_name) {
                errors.prospective_employee_name = 'Required'
            }
            if (!values.drivers_license_number) {
                errors.drivers_license_number = 'Required'
            }
            return errors
        },
    })

    React.useEffect(() => {
        if (data) {
            formik.setValues({
                ...data,
                drivers_license_number: ["Member", "Owner"].includes(currentUser?.role!) ? changeStringLast4(data?.drivers_license_number, "*")! : data?.drivers_license_number,
            })
        } else {
            formik.setValues({
                ...initialValues,
                prospective_employee_name: `${member?.user?.first_name} ${member?.user?.last_name}`,
                drivers_license_number: member?.drivers_license_number,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, member])

    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                Previous Pre-Employment Employee Drug And Alcohol Test Statement
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify" }} variant="body1" gutterBottom component="p">
                Section 40.25(j): Employers must ask the employee whether he/she has tested positive,
                or refused to test, on any pre-employment drug or alcohol test administered by an employer
                that the employee applied for, but did not obtain, safety-sensitive transportation work
                covered by DOT agency drug and alcohol testing rules during the past two years.
                If the employee admits that he/she had a positive test or a refusal to test,
                you must not use the employee to perform safety-sensitive functions until and
                unless the employee documents successful completion of the return-to-duty process.
                (See Sec. 40.25(b)(5) and (e))
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Prospective Employee Name
                    </Typography>
                    <TextField
                        fullWidth
                        id="prospective_employee_name"
                        placeholder='Prospective Employee Name'
                        size="small"
                        variant="outlined"
                        value={formik.values.prospective_employee_name}
                        // onChange={(e) => {
                        //     formik.setFieldValue('prospective_employee_name', e.target.value)
                        // }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.prospective_employee_name && Boolean(formik.errors.prospective_employee_name) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.prospective_employee_name && Boolean(formik.errors.prospective_employee_name) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.prospective_employee_name && Boolean(formik.errors.prospective_employee_name) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Driver's License Number
                    </Typography>
                    <TextField
                        fullWidth
                        id="drivers_license_number"
                        placeholder='Drivers License Number'
                        size="small"
                        variant="outlined"
                        value={formik.values.drivers_license_number}
                        onChange={(e) => {
                            formik.setFieldValue('drivers_license_number', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_license_number && Boolean(formik.errors.drivers_license_number) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_license_number && Boolean(formik.errors.drivers_license_number) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_license_number && Boolean(formik.errors.drivers_license_number) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify" }} variant="body1" gutterBottom component="p">
                        The prospective employee is required by Sec. 40.25(j) to respond to the following questions
                    </Typography>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        1. Have you tested positive, or refused to test, on any pre-employment drug
                        or alcohol test administered by an employer to which you applied for,
                        but did not obtain, safety-sensitive transportation work covered by DOT
                        agency drug and alcohol testing rules during the past 2 years?
                    </Typography>
                    <TextField
                        fullWidth
                        id="tested_positive_or_refused_to_test"
                        select
                        size="small"
                        variant="outlined"
                        value={formik.values.tested_positive_or_refused_to_test ? "Yes" : "No"}
                        onChange={(e) => {
                            formik.setFieldValue('tested_positive_or_refused_to_test', e.target.value === "Yes" ? true : false)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.tested_positive_or_refused_to_test && Boolean(formik.errors.tested_positive_or_refused_to_test) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.tested_positive_or_refused_to_test && Boolean(formik.errors.tested_positive_or_refused_to_test) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.tested_positive_or_refused_to_test && Boolean(formik.errors.tested_positive_or_refused_to_test) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        2. If you answered yes, can you provide/obtain proof that you've successfully completed the DOT return-to-duty requirements?
                    </Typography>
                    <TextField
                        fullWidth
                        id="can_provide_proof_of_return_to_duty"
                        select
                        size="small"
                        variant="outlined"
                        value={formik.values.can_provide_proof_of_return_to_duty ? "Yes" : "No"}
                        onChange={(e) => {
                            formik.setFieldValue('can_provide_proof_of_return_to_duty', e.target.value === "Yes" ? true : false)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.can_provide_proof_of_return_to_duty && Boolean(formik.errors.can_provide_proof_of_return_to_duty) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.can_provide_proof_of_return_to_duty && Boolean(formik.errors.can_provide_proof_of_return_to_duty) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.can_provide_proof_of_return_to_duty && Boolean(formik.errors.can_provide_proof_of_return_to_duty) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </TextField>
                </Grid >
                {(!data || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role))) && !isDriverFile ? <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={isCreating || isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                        Save
                    </Button>
                    {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                        <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                            Once you click save, you will not be able to edit this form.
                        </Typography>
                    )}
                </Grid > : null}
                {data && currentUser?.role === "SuperAdmin" && <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={!!member?.terminated_at} onClick={() => {
                        const res = window.confirm("Are you sure you want to delete this form?")
                        if (res && data) {
                            deletePrevPreEmploymentDrugAndAlcoholTestStatement(data.id)
                        }
                    }} variant="contained" color="primary">
                        Delete Form
                    </Button>
                </Grid>}
            </Grid >
        </div >
    )
}

export default PrevDachStatement