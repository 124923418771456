import clsx from 'clsx'
import React from 'react'

const PostAccidentRequest = ({ postAccidentFormik }: {
    postAccidentFormik: any
}) => {
    return (
        <div>
            <div className="fv-row mb-7">
                <label className="form-label fw-bolder text-dark fs-6">
                    Was there a fatality?
                </label>
                <select
                    className={clsx('form-select form-select-solid',
                        {
                            'is-invalid': postAccidentFormik.touched.fatality && postAccidentFormik.errors.fatality
                        }
                    )}
                    {...postAccidentFormik.getFieldProps('fatality')}
                >
                    <option value={""}>Select</option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                </select>
            </div>
            {postAccidentFormik.values.fatality === 'No' && <div className="fv-row mb-7">
                <label className="form-label fw-bolder text-dark fs-6">
                    Was the driver ticketed?
                </label>
                <select
                    className={clsx('form-select form-select-solid',
                        {
                            'is-invalid': postAccidentFormik.touched.ticketed && postAccidentFormik.errors.ticketed
                        }
                    )}
                    {...postAccidentFormik.getFieldProps('ticketed')}
                >
                    <option value={""}>Select</option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                </select>
            </div>}
            {postAccidentFormik.values.ticketed === 'Yes' && <div className="fv-row mb-7">
                <label className="form-label fw-bolder text-dark fs-6">
                    Was a tow-away involved in the accident?
                </label>
                <select
                    className={clsx('form-select form-select-solid',
                        {
                            'is-invalid': postAccidentFormik.touched.towAway && postAccidentFormik.errors.towAway
                        }
                    )}
                    {...postAccidentFormik.getFieldProps('towAway')}
                >
                    <option value={""}>Select</option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                </select>
            </div>}
            {postAccidentFormik.values.towAway === 'No' && <div className="fv-row mb-7">
                <label className="form-label fw-bolder text-dark fs-6">
                    Was anyone injured?
                </label>
                <select
                    className={clsx('form-select form-select-solid',
                        {
                            'is-invalid': postAccidentFormik.touched.injured && postAccidentFormik.errors.injured
                        }
                    )}
                    {...postAccidentFormik.getFieldProps('injured')}
                >
                    <option value={""}>Select</option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                </select>
                <p style={{ marginTop: 10, marginBottom: 3, textAlign: 'center', color: 'red', fontSize: 13 }}>
                    It only counts as an injury if the injured was taken directly to the hospital after accident
                </p>
            </div>}
        </div>
    )
}

export default PostAccidentRequest