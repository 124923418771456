import React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { Button, Dialog, DialogContent, DialogTitle, Grid, InputLabel, Slide, TextField } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useThemeMode } from '../../../compliancemasters/partials';
import { IMember } from '../../../models';
import { finishUpload, getCompanyMember, getFileCategories, startUpload } from '../../../shared';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export interface ILiveMVRDto {
    company: number
    member: number
    file?: File
    date: Date
}

interface IProps {
    open: boolean
    setOpen: (open: boolean) => void
    onSubmitted?: () => void
    subscription_type?: string;
}

interface FileCategory {
    id: number;
    title: string;
}



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const initialValues: ILiveMVRDto = {
    company: 0,
    member: 0,
    file: undefined,
    date: new Date()
}

const validationSchema = Yup.object({
    company: Yup.number().required('Company is required'),
    member: Yup.number().required('Member is required'),
    file: Yup.mixed().required('File is required'),
    date: Yup.date().required('Date is required')
})



const AddFileMoodal = ({ open, setOpen, onSubmitted, subscription_type }: IProps) => {
    const { memberId } = useParams<{ memberId: string }>()
    const [category, setCategory] = React.useState<FileCategory>()
    const [uploaded, setUploaded] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const { mode } = useThemeMode()
    const handleClose = () => {
        setOpen(false);
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            setIsSubmitting(true);
            if (isSubmitting) return;
            const title = "Live MVR Monitoring"
            const file = values.file as File
            const expiration = undefined
            const location = "Driver File"

            startUpload({
                fileName: (file! as File).name,
                fileType: (file! as File).type,
                title,
                expiration,
                categoryId: category?.id,
                memberId: values.member,
                location,
                liveMvr: true,
                date: values.date
            }).then((res) => {
                const { url, id } = res;

                let options = {
                    headers: {
                        'Content-Type': (file! as File).type
                    }
                };

                let instance = axios.create();
                instance.put(url, values.file, options).then((res) => {
                    finishUpload(id).then(() => {
                        setUploaded(true);
                        formik.resetForm();
                        formik.validateForm();
                    });
                });
            })
                .finally(() => {
                    setSubmitting(false);
                    setIsSubmitting(false);
                    onSubmitted && onSubmitted();
                });


        },
    })

    React.useEffect(() => {
        if (memberId) {
            getCompanyMember(memberId!).then((res: IMember) => {
                formik.setFieldValue('member', res.id)
                formik.setFieldValue('company', res.company.id)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberId])

    React.useEffect(() => {
        getFileCategories({ category_type: "Driver File", memberId, subscription: subscription_type }).then((res: FileCategory[]) => {
            setCategory(res.find(item => item.title.includes("Annual Motor Vehicle Record")))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const SuccessComponent = () => {
        return (
            <div className="text-center">
                <h2 className="mb-5">File Uploaded Successfully</h2>
                <Button variant="contained" onClick={() => {
                    setUploaded(false)
                    handleClose()
                }}>
                    Close
                </Button>
            </div>
        )
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            sx={{ p: 1 }}
        >
            <DialogTitle sx={{
                textAlign: 'center',
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
            }}>Add a notification</DialogTitle>
            <DialogContent
                sx={{
                    backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                }}
            >
                {uploaded ? (<SuccessComponent />) : (
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <InputLabel sx={{
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }}>
                                File
                            </InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                type="file"
                                // value={formik.values.file?.name}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (e.target.files) {
                                        formik.setFieldValue('file', e.target.files[0])
                                    }
                                }}
                                error={formik.touched.file && Boolean(formik.errors.file)}
                                name='file'
                                id='file'
                                InputProps={{
                                    sx: {
                                        color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: formik.touched.file && Boolean(formik.errors.file) ? "red" : "green",
                                        },
                                        '&:hover fieldset': {
                                            borderColor: formik.touched.file && Boolean(formik.errors.file) ? "red" : "green",
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: formik.touched.file && Boolean(formik.errors.file) ? "red" : "green",
                                        },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <InputLabel sx={{
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }}>
                                Date
                            </InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                type="date"
                                value={formik.values.date}
                                onChange={formik.handleChange}
                                error={formik.touched.date && Boolean(formik.errors.date)}
                                name='date'
                                id='date'
                                InputProps={{
                                    sx: {
                                        color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                        },
                                        '&:hover fieldset': {
                                            borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                        },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={() => formik.handleSubmit()}
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? "Uploading..." : "Upload"}
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
        </Dialog>
    )
}

export default AddFileMoodal