import React, { FC, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import SalesRepDetails from '../pages/sales-reps/SalesRepDetails'
import { WithChildren } from '../../compliancemasters/helpers'
import { getCSSVariableValue } from '../../compliancemasters/assets/ts/_utils'
import TopBarProgress from 'react-topbar-progress-indicator'
import { useAuth } from '../modules/auth'
import ComplianceMasterSalesRepDetails from '../pages/sales-reps/ComlianceMasterSalesRepDetail'

const SalesRepPrivateRoutes = () => {
    const { currentUser } = useAuth()
    return (
        <Routes>
            <Route path="dashboard" element={
                <SuspensedView>
                    {currentUser?.sales_rep?.is_compliance_master_account ? <ComplianceMasterSalesRepDetails /> : <SalesRepDetails />}
                </SuspensedView>
            } />
        </Routes>
    )
}

export default SalesRepPrivateRoutes


const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue('--kt-primary');
    TopBarProgress.config({
        barColors: {
            '0': baseColor
        },
        barThickness: 1,
        shadowBlur: 5
    });
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};
