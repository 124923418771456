import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    IPlacingVehiclesInMotionAndUseOfControls,
    IPlacingVehiclesInMotionAndUseOfControlsDto
} from '../../interfaces/RecordOfRoadTest/placing-vehicles-in-motion-and-use-of-controls';

export const placingVehiclesInMotionAndUseOfControlsApi = createApi({
    reducerPath: 'placing_vehicles_in_motion_and_use_of_controls',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/placing-vehicles-in-motion-and-use-of-controls`
    }),
    tagTypes: ['PlacingVehiclesInMotionAndUseOfControls'],
    endpoints: (builder) => ({
        createPlacingVehiclesInMotionAndUseOfControls: builder.mutation<
            IPlacingVehiclesInMotionAndUseOfControls,
            IPlacingVehiclesInMotionAndUseOfControlsDto
        >({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'PlacingVehiclesInMotionAndUseOfControls',
                    id: 'PlacingVehiclesInMotionAndUseOfControls'
                }
            ]
        }),
        updatePlacingVehiclesInMotionAndUseOfControls: builder.mutation<
            IPlacingVehiclesInMotionAndUseOfControls,
            IPlacingVehiclesInMotionAndUseOfControls
        >({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'PlacingVehiclesInMotionAndUseOfControls',
                    id: 'PlacingVehiclesInMotionAndUseOfControls'
                }
            ]
        }),
        deletePlacingVehiclesInMotionAndUseOfControls: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'PlacingVehiclesInMotionAndUseOfControls',
                    id: 'PlacingVehiclesInMotionAndUseOfControls'
                }
            ]
        }),
        getPlacingVehiclesInMotionAndUseOfControls: builder.query<
            IPlacingVehiclesInMotionAndUseOfControls,
            number | void
        >({
            query: (id) => ({
                url: `/get-placing-vehicles-in-motion-and-use-of-controls/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'PlacingVehiclesInMotionAndUseOfControls',
                    id: 'PlacingVehiclesInMotionAndUseOfControls'
                }
            ]
        }),
        getPlacingVehiclesInMotionAndUseOfControlsByUser: builder.query<IPlacingVehiclesInMotionAndUseOfControls, void>(
            {
                query: () => ({
                    url: `/get-placing-vehicles-in-motion-and-use-of-controls-by-user/`,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                    }
                }),
                providesTags: [
                    {
                        type: 'PlacingVehiclesInMotionAndUseOfControls',
                        id: 'PlacingVehiclesInMotionAndUseOfControls'
                    }
                ]
            }
        ),
        getPlacingVehiclesInMotionAndUseOfControlsByUserId: builder.query<
            IPlacingVehiclesInMotionAndUseOfControls,
            number | void
        >({
            query: (id) => ({
                url: `/get-placing-vehicles-in-motion-and-use-of-controls-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'PlacingVehiclesInMotionAndUseOfControls',
                    id: 'PlacingVehiclesInMotionAndUseOfControls'
                }
            ]
        })
    })
});

export const {
    useCreatePlacingVehiclesInMotionAndUseOfControlsMutation,
    useUpdatePlacingVehiclesInMotionAndUseOfControlsMutation,
    useGetPlacingVehiclesInMotionAndUseOfControlsQuery,
    useGetPlacingVehiclesInMotionAndUseOfControlsByUserQuery,
    useGetPlacingVehiclesInMotionAndUseOfControlsByUserIdQuery,
    useDeletePlacingVehiclesInMotionAndUseOfControlsMutation
} = placingVehiclesInMotionAndUseOfControlsApi;
