/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { useAuth } from '../core/Auth';
import useNavigation from '../../../../shared/useNavigation/useNavigation';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IPayloadProps } from '../../../../compliancemasters/layout/components/aside/AsideMenus/OwnerAsideMenu';

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    password: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Password is required')
});

const initialValues = {
    email: '',
    password: ''
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
    const [loading, setLoading] = useState(false);
    const { pmProgramEnabled, pmProgramEnabledUrl } = useFlags<IPayloadProps>();
    const { saveAuth, setCurrentUser, getUser, login, currentUser } = useAuth();
    const { navigateToDashboard } = useNavigation();

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            /*
       on submit attempt to log the user in by getting their api refresh token
       Using that, get their user info, and set the current user to that user
       finally, send them to the dashboard
      */

            function errorHandler() {
                saveAuth(undefined);
                setStatus('The login detail is incorrect');
                setSubmitting(false);
                setLoading(false);
            }

            setLoading(true);

            login(values.email, values.password)
                .then((res) => {
                    //save the tokens to local storage
                    saveAuth({
                        api_token: res.access,
                        refreshToken: res.refresh
                    });
                    //get the users info
                    getUser()
                        .then((res) => {
                            //set the user when we get the info
                            setCurrentUser({
                                ...res[0],
                                otpConfirmed: process.env.REACT_APP_PUBLIC_ENV === 'prod' ? false : true
                            });
                            setLoading(false);
                            const referPath = window.localStorage.getItem('compliance_master_refer_path');
                            if (referPath) {
                                window.localStorage.removeItem('compliance_master_refer_path');
                                window.location.assign(referPath);
                            }
                        })
                        .catch(() => {
                            errorHandler();
                        });
                })
                .catch((error) => {
                    console.error(error);
                    errorHandler();
                });
        }
    });

    useEffect(() => {
        if (currentUser && !currentUser?.otpConfirmed) {
            window.location.assign('/auth/login-otp');
        }
        if (currentUser && currentUser?.otpConfirmed) {
            navigateToDashboard();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    return (
        <form className="form w-100" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
            {/* begin::Heading */}
            <div className="text-center mb-10">
                <h1 className="text-dark mb-3">Sign In to Compliance Masters</h1>
                <div className="text-gray-400 fw-bold fs-4">
                    New Here?{' '}
                    <Link to="/auth/sales-rep/registration" className="link-primary fw-bolder">
                        Create a Sales Rep Account
                    </Link>
                </div>
                {pmProgramEnabled && pmProgramEnabledUrl.urls.includes(process.env.REACT_APP_WEBSITE_URL!) ? (
                    <div className="text-gray-400 fw-bold fs-4">
                        Current Driver?{' '}
                        <Link to="/auth/dvir-signin" className="link-primary fw-bolder">
                            Complete Driver-Vehicle Inspection Report
                        </Link>
                    </div>
                ) : null}
            </div>
            {/* begin::Heading */}

            {formik.status ? (
                <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">{formik.status}</div>
                </div>
            ) : (
                <></>
            )}

            {/* begin::Form group */}
            <div className="fv-row mb-10">
                <label className="form-label fs-6 fw-bolder text-dark">Email</label>
                <input
                    placeholder="Email"
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                            'is-invalid': formik.touched.email && formik.errors.email
                        },
                        {
                            'is-valid': formik.touched.email && !formik.errors.email
                        }
                    )}
                    type="email"
                    name="email"
                    autoComplete="off"
                />
                {formik.touched.email && formik.errors.email && (
                    <div className="fv-plugins-message-container">
                        <span role="alert">{formik.errors.email}</span>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className="fv-row mb-10">
                <div className="d-flex justify-content-between mt-n5">
                    <div className="d-flex flex-stack mb-2">
                        {/* begin::Label */}
                        <label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
                        {/* end::Label */}
                        {/* begin::Link */}
                        <Link
                            to="/auth/forgot-password"
                            className="link-primary fs-6 fw-bolder"
                            style={{ marginLeft: '5px' }}
                        >
                            Forgot Password ?
                        </Link>
                        {/* end::Link */}
                    </div>
                </div>
                <input
                    type="password"
                    autoComplete="off"
                    placeholder="Password"
                    {...formik.getFieldProps('password')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                            'is-invalid': formik.touched.password && formik.errors.password
                        },
                        {
                            'is-valid': formik.touched.password && !formik.errors.password
                        }
                    )}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                            <span role="alert">{formik.errors.password}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className="text-center">
                <button
                    type="submit"
                    id="kt_sign_in_submit"
                    className="btn btn-lg btn-primary w-100 mb-5"
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className="indicator-label">Continue</span>}
                    {loading && (
                        <span className="indicator-progress" style={{ display: 'block' }}>
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    )}
                </button>

                {/* <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div>

        <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Continue with Google
        </a>
        <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
            className='h-20px me-3'
          />
          Continue with Facebook
        </a>

        <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='h-20px me-3'
          />
          Continue with Apple
        </a> */}
            </div>
            {/* end::Action */}
        </form>
    );
}
