import React from 'react'
import { useGetCompanyDrugAlcoholWrittenPolicyQuery } from '../../../store/reducers/DrugAlcoholWrittenPolicy/drug-alcohol-written-policy'
import TableWrapper from '../../../compliancemasters/partials/widgets/tables/components/TableWrapper'
import TableContainerHeader from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader'
import TableContainerHeaderTitle from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle'
import TableContainer from '../../../compliancemasters/partials/widgets/tables/components/TableContainer'
import TableHeader from '../../../compliancemasters/partials/widgets/tables/components/TableHeader'
import TableHeaderColumn from '../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn'

const MemberTable = () => {
    const { data = [] } = useGetCompanyDrugAlcoholWrittenPolicyQuery(undefined, {
        refetchOnFocus: true,
        refetchOnReconnect: true,
    })

    return (
        <TableWrapper>
            <TableContainerHeader>
                <TableContainerHeaderTitle title="Member Signatures" />
            </TableContainerHeader>
            <TableContainer>
                <TableHeader>
                    <TableHeaderColumn>Employee Name</TableHeaderColumn>
                    <TableHeaderColumn>Signature</TableHeaderColumn>
                    <TableHeaderColumn>Date</TableHeaderColumn>
                </TableHeader>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.id}>
                            <td>
                                <div className="d-flex align-items-center">
                                    <div className="d-flex justify-content-start flex-column">
                                        {item?.member?.user?.first_name} {item?.member?.user?.last_name}
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex align-items-center">
                                    <div className="d-flex justify-content-start flex-column font-style-italic">
                                        {item?.signature}
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex align-items-center">
                                    <div className="d-flex justify-content-start flex-column font-style-italic">
                                        {item?.date && Intl.DateTimeFormat('en-US', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit'
                                        }).format(new Date(item?.date))}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </TableContainer>
        </TableWrapper>
    )
}

export default MemberTable