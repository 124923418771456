import React from 'react'

interface IProps {
    children: string
    size?: string
    end?: boolean
    center?: boolean
}
export default function TableHeaderColumn({ end, children, center }: IProps) {
    return <th className={end ? 'text-end' : center ? "text-center" : undefined}>{children}</th>
}
