import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import { INotesAndLinks } from '../../interfaces/PreventableMaintenance/notes.and.links';

export const notesAndLinksApi = createApi({
    reducerPath: 'pm_notes_and_links',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/auth/api/preventable-maintenance/notes-and-links`
    }),
    tagTypes: ['NotesAndLinks'],
    endpoints: (builder) => ({
        createNotesAndLinks: builder.mutation<INotesAndLinks, Partial<INotesAndLinks>>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body: {
                    ...body,
                    maintenance_vehicle: body?.maintenance_vehicle?.id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'NotesAndLinks', id: 'NotesAndLinks' }]
        }),
        updateNotesAndLinks: builder.mutation<INotesAndLinks, Partial<INotesAndLinks>>({
            query: (body) => ({
                url: `/${body?.id}/`,
                method: 'PUT',
                body: {
                    ...body,
                    maintenance_vehicle: body?.maintenance_vehicle?.id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'NotesAndLinks', id: 'NotesAndLinks' }]
        }),
        deleteNotesAndLinks: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'NotesAndLinks', id: 'NotesAndLinks' }]
        }),
        getNotesAndLinks: builder.query<INotesAndLinks, number | void>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'NotesAndLinks', id: 'NotesAndLinks' }]
        }),
        getNotesAndLinksByVehicle: builder.query<INotesAndLinks[], number | void>({
            query: (vehicleId) => ({
                url: `/get-by-vehicle?vehicle_id=${vehicleId}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'NotesAndLinks', id: 'NotesAndLinks' }]
        })
    })
});

export const {
    useCreateNotesAndLinksMutation,
    useUpdateNotesAndLinksMutation,
    useDeleteNotesAndLinksMutation,
    useGetNotesAndLinksQuery,
    useGetNotesAndLinksByVehicleQuery
} = notesAndLinksApi;
