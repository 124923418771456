import React from 'react'
import { useParams } from 'react-router-dom';
import { Box, Container, Paper } from '@mui/material';
import { useThemeMode } from '../../../../../../compliancemasters/partials';
import { StripeProduct } from '../../../../models';
import { useGetLimitedQueriesDachProgressMutation, useGetMemberLimitedQueriesDachProgressMutation } from '../../../../../../store/reducers/LimitedQueriesDrugAndAlcoholClearinghouse/progress';
import { IMember } from '../../../../../../models';
import { getCompanyMember } from '../../../../../../shared';
import FormHeaderCard from '../../../../../../compliancemasters/reforms/HeaderCard';
import DriverAuthorizationAndSignature from './DriverAuthorization';

const NonCurrentDriverAuthorization = ({ subscription }: { subscription?: StripeProduct }) => {
    const { mode } = useThemeMode()
    const [getProgress, { data: progress }] = useGetLimitedQueriesDachProgressMutation()
    const [getMemberProgress, { data: memberProgress }] = useGetMemberLimitedQueriesDachProgressMutation()
    const [member, setMember] = React.useState<IMember>()
    const { id } = useParams()
    const [value, setValue] = React.useState(0);

    React.useEffect(() => {
        if (id) {
            getCompanyMember(id).then((data) => {
                setMember(data)
            })
            getProgress(Number(id))
            getMemberProgress(Number(id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Container>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <FormHeaderCard
                    headerTitle='Consent for Limited Queries Of The FMCSA Drug And Alcohol Clearinghouse'
                    status={progress && (progress?.progress > 0 && progress?.progress < 100) ? "In Progress" : progress && progress.progress > 99 ? "Completed" : "Not Started"}
                    sectionCount={4}
                    itemCount={29}
                    totalProgress={progress ? progress.progress : 0}
                    memberProgress={memberProgress ? memberProgress.progress : 0}
                    nextButton={{ title: "Safety and Performance History", path: "" }}
                    member={member}
                    setValue={setValue}
                    value={value}
                    drugFile={true}

                />
            </Box>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <DriverAuthorizationAndSignature subscription={subscription} member={member} />
            </Box>
        </Container>
    )
}

export default NonCurrentDriverAuthorization