import React from 'react';

interface IProps {
    children: React.ReactNode;
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
    loading?: boolean;
    loadingText?: string;
    dataBsToggle?: string;
    dataBsTarget?: string;
    dataBsDismiss?: string;
}
export default function Button({
    children,
    className = 'btn btn-primary',
    onClick,
    disabled,
    loading,
    loadingText = '',
    dataBsToggle,
    dataBsTarget,
    dataBsDismiss
}: IProps) {
    return (
        <div
            className={`${className} ${disabled || loading ? 'disabled' : ''}`}
            onClick={onClick}
            data-bs-toggle={dataBsToggle}
            data-bs-target={dataBsTarget}
            data-bs-dismiss={dataBsDismiss}
        >
            {!loading && <span className="indicator-label">{children}</span>}
            {loading && (
                <span className="indicator-progress" style={{ display: 'block' }}>
                    {loadingText}{' '}
                    <span
                        className={`spinner-border spinner-border-sm align-middle ${loadingText ? 'ms-2' : ''}`}
                    ></span>
                </span>
            )}
        </div>
    );
}
