/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../../compliancemasters/helpers';
import RequestButton from '../../../../compliancemasters/partials/modals/request/RequestButton';
import TerminateButton from '../../../../compliancemasters/partials/modals/terminate/TerminateButton';
import { IMember } from '../../../../models';
import { StripeProduct } from '../../models';
import { useAuth } from '../../auth';
import { downloadFile, finishUpload, getCompanyMember, startUpload } from '../../../../shared';
import { useGetDriverCertificateForOtherCompensatedWorkByUserIdQuery } from '../../../../store/reducers/DriverStatementDutyHours/driver-certificate-for-other-compensated-work';
import { useGetProcessRecordByUserIdQuery } from '../../../../store/reducers/DriversApplicationForEmployment/process-record';
import { useGetCertificationByUserIdQuery } from '../../../../store/reducers/CertificateOfComplianceWithDriverLicenseRequirements/certification';
import { Button, Checkbox, Grid, Tooltip } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useGetDriverAuthorizationAndSignatureByUserIdQuery } from '../../../../store/reducers/LimitedQueriesDrugAndAlcoholClearinghouse/driver-authorization-and-signature';
// import { useLocation } from 'react-router-dom';
import ConfirmRequestButton from '../../../../compliancemasters/partials/modals/request/ConfirmRequestButton';

interface IProps {
    member?: IMember;
    LinkRow?: React.FC<{ memberSubscription?: StripeProduct }>;
    onRefresh?: () => void;
    memberSubscription?: StripeProduct;
}

export default function MemberProfileHeader({ member, LinkRow, onRefresh, memberSubscription }: IProps) {
    const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
    const [uploaded, setUploaded] = React.useState(false);
    // const location = useLocation()
    const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0];
        if (selectedFile) {
            setSelectedFile(selectedFile);
        }
    };
    const [profileImage, setProfileImage] = React.useState<string>();

    const handleButtonClick = () => {
        const fileInput = document.getElementById('fileInput') as HTMLInputElement;
        fileInput.click();
    };

    const [memberObject, setMemberObject] = React.useState<IMember>();
    const [dateOfHire, setDateOfHire] = React.useState<string>();

    const { data } = useGetDriverCertificateForOtherCompensatedWorkByUserIdQuery(member?.user?.id, {
        skip: !member?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });

    const { data: processRecord } = useGetProcessRecordByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    const { data: cert } = useGetCertificationByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });

    const { data: consent } = useGetDriverAuthorizationAndSignatureByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });

    const { currentUser, toggleFormsDone } = useAuth();

    React.useEffect(() => {
        if (currentUser?.role === 'Member') {
            getCompanyMember(String(currentUser?.member?.id)).then((member) => {
                setMemberObject(member);
                downloadFile(Number(member?.user?.profile_url))
                    .then((res) => {
                        setProfileImage(res.url);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });
        } else if (member) {
            setMemberObject(member);
            downloadFile(Number(member?.user?.profile_url))
                .then((res) => {
                    setProfileImage(res.url);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [member, currentUser, uploaded]);

    React.useEffect(() => {
        if (memberSubscription?.name === 'Drug and Alcohol Package' && consent) {
            const utcDate = new Date(consent?.date);
            const offsetMinutes = utcDate.getTimezoneOffset();
            utcDate.setMinutes(utcDate.getMinutes() + offsetMinutes);
            const adjustedYear = utcDate.getFullYear();
            const adjustedMonth = String(utcDate.getMonth() + 1).padStart(2, '0');
            const adjustedDay = String(utcDate.getDate()).padStart(2, '0');
            const formattedDate = `${adjustedMonth}-${adjustedDay}-${adjustedYear}`;
            setDateOfHire(formattedDate);
        }
        if (processRecord) {
            const utcDate = new Date(processRecord?.date_employed);
            const offsetMinutes = utcDate.getTimezoneOffset();
            utcDate.setMinutes(utcDate.getMinutes() + offsetMinutes);
            const adjustedYear = utcDate.getFullYear();
            const adjustedMonth = String(utcDate.getMonth() + 1).padStart(2, '0');
            const adjustedDay = String(utcDate.getDate()).padStart(2, '0');
            const formattedDate = `${adjustedMonth}-${adjustedDay}-${adjustedYear}`;
            setDateOfHire(formattedDate);
        } else if (cert) {
            const utcDate = new Date(cert?.date_of_hire);
            const offsetMinutes = utcDate.getTimezoneOffset();
            utcDate.setMinutes(utcDate.getMinutes() + offsetMinutes);
            const adjustedYear = utcDate.getFullYear();
            const adjustedMonth = String(utcDate.getMonth() + 1).padStart(2, '0');
            const adjustedDay = String(utcDate.getDate()).padStart(2, '0');
            const formattedDate = `${adjustedMonth}-${adjustedDay}-${adjustedYear}`;
            setDateOfHire(formattedDate);
        }
    }, [cert, processRecord, memberSubscription, consent]);

    const handleUploadProfile = React.useCallback(() => {
        if (selectedFile && member) {
            startUpload({
                fileName: selectedFile.name,
                fileType: selectedFile.type,
                title: 'Profile Image',
                expiration: undefined,
                categoryId: 23,
                memberId: member?.id,
                location: 'Driver File',
                userId: member?.user?.id
            }).then((res) => {
                const { url, id } = res;

                let options = {
                    headers: {
                        'Content-Type': selectedFile.type
                    }
                };

                let instance = axios.create();
                instance
                    .put(url, selectedFile, options)
                    .then((res) => {
                        finishUpload(id).then(() => {
                            setUploaded(true);
                            setProfileImage(undefined);
                            if (currentUser?.role === 'Member') {
                                getCompanyMember(String(currentUser?.member?.id)).then((member: IMember) => {
                                    downloadFile(Number(member?.user?.profile_url))
                                        .then((res) => {
                                            setProfileImage(res.url);
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                        });
                                });
                                toast.success('Profile Image Uploaded Successfully');
                            } else {
                                getCompanyMember(String(member?.id)).then((member: IMember) => {
                                    downloadFile(Number(member?.user?.profile_url))
                                        .then((res) => {
                                            setProfileImage(res.url);
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                        });
                                });
                                toast.success('Profile Image Uploaded Successfully');
                            }
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });
        }
    }, [selectedFile, member, currentUser]);

    React.useEffect(() => {
        if (selectedFile) {
            handleUploadProfile();
        }
    }, [selectedFile, handleUploadProfile]);

    return (
        <>
            {currentUser && ['SuperAdmin', 'Admin'].includes(currentUser?.role) && (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Button
                            onClick={() => {
                                window.location.assign(`/company/${member?.company.id}`);
                            }}
                            variant="contained"
                            color="primary"
                            sx={{ mb: 5 }}
                        >
                            Go Back
                        </Button>
                    </Grid>
                </Grid>
            )}
            <div className="card mb-5 mb-xl-10">
                <div className="card-body pt-9 pb-0">
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                        <Tooltip title="Click to change profile picture">
                            <div className="me-7 mb-4 cursor-pointer" onClick={handleButtonClick}>
                                <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                    {profileImage ? (
                                        <img src={profileImage} alt="Metornic" />
                                    ) : (
                                        <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt="Metornic" />
                                    )}
                                    <input
                                        id="fileInput"
                                        type="file"
                                        className="position-absolute top-0 start-100 translate-middle"
                                        style={{
                                            display: 'none'
                                        }}
                                        onChange={handleFileInputChange}
                                    />
                                </div>
                            </div>
                        </Tooltip>

                        <div className="flex-grow-1">
                            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center mb-2">
                                        <a href="#" className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                                            {memberObject?.user.first_name} {memberObject?.user.last_name}
                                        </a>
                                    </div>
                                    <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                        <a
                                            href="#"
                                            className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                                        >
                                            <i className="bi bi-building me-1"></i>
                                            {memberObject?.company.name}
                                        </a>
                                        <a
                                            href="#"
                                            className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                                        >
                                            <i className="bi bi-telephone-fill me-1"></i>
                                            {memberObject?.phone_number ? memberObject?.phone_number : 'Phone Number'}
                                        </a>
                                        <a
                                            href="#"
                                            className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
                                        >
                                            <KTSVG
                                                path="/media/icons/duotune/communication/com011.svg"
                                                className="svg-icon-4 me-1"
                                            />
                                            {memberObject?.user.email}
                                        </a>
                                        <a
                                            href="#"
                                            className="d-flex align-items-center text-gray-400 text-hover-primary mb-2 ms-2"
                                        >
                                            <KTSVG
                                                path="/media/icons/duotune/communication/com013.svg"
                                                className="svg-icon-4 me-1"
                                            />
                                            Status: {memberObject?.terminated_at ? 'Terminated' : 'Active'}
                                        </a>
                                        {data?.currently_employed_elsewhere || data?.intend_to_work_elsewhere ? (
                                            <a
                                                href="#"
                                                className="d-flex align-items-center text-gray-400 text-hover-primary mb-2 ms-2"
                                            >
                                                <KTSVG
                                                    path="/media/icons/duotune/communication/com014.svg"
                                                    className="svg-icon-4 me-1"
                                                />
                                                Multi-Employed Driver
                                            </a>
                                        ) : null}
                                        {dateOfHire ? (
                                            <a
                                                href="#"
                                                className="d-flex align-items-center text-gray-400 text-hover-primary mb-2 ms-2"
                                            >
                                                <KTSVG
                                                    path="/media/icons/duotune/communication/com014.svg"
                                                    className="svg-icon-4 me-1"
                                                />
                                                Date of Hire: {dateOfHire}
                                            </a>
                                        ) : null}
                                        {currentUser &&
                                            memberObject &&
                                            ['Admin', 'SuperAdmin'].includes(currentUser?.role) && (
                                                <div className="d-flex align-items-center text-gray-400 text-hover-primary mb-2 ms-2">
                                                    <Checkbox
                                                        checked={memberObject?.forms_done}
                                                        onClick={() => {
                                                            toggleFormsDone(
                                                                Number(memberObject.id),
                                                                !memberObject.forms_done!
                                                            ).then((res) => {
                                                                setMemberObject(res);
                                                            });
                                                        }}
                                                    />
                                                    Toggle Forms Complete
                                                </div>
                                            )}
                                    </div>
                                </div>

                                {member?.pre_employment_screened ? (
                                    <div className="d-flex my-4 mx-2">
                                        {/*

                                    Your Request button could go here next to the terminate button

                                    I know I said it should open up a modal, so take inspiration from the TerminateButton.tsx file
                                    and feel free to copy as much as you need from it.

                                    */}
                                        {memberObject && (
                                            <RequestButton
                                                memberSubscription={memberSubscription}
                                                member={memberObject}
                                            />
                                        )}
                                        {memberObject && <TerminateButton member={memberObject} onSubmit={onRefresh} />}
                                        {memberObject &&
                                            currentUser &&
                                            ['Owner', 'Admin', 'SuperAdmin'].includes(currentUser?.role) && (
                                                <ConfirmRequestButton
                                                    memberSubscription={memberSubscription}
                                                    member={memberObject}
                                                    onSubmit={onRefresh}
                                                />
                                            )}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    {LinkRow ? <LinkRow memberSubscription={memberSubscription} /> : null}
                </div>
            </div>
        </>
    );
}
