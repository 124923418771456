import React from 'react'
import { IMember } from '../../../models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material'
import {
    IBackingAndParkingDto
} from '../../../store/interfaces/RecordOfRoadTest/backing-and-parking'
import {
    useCreateBackingAndParkingMutation,
    useGetBackingAndParkingByUserIdQuery,
    useUpdateBackingAndParkingMutation,
    useDeleteBackingAndParkingMutation
} from '../../../store/reducers/RecordOfRoadTest/backing-and-parking'
import { InspectionTextChoices } from '../../../store/interfaces/RecordOfRoadTest/backing-and-parking'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'


const initialValues: Partial<IBackingAndParkingDto> = {
    gets_out_and_checks_before_backing: InspectionTextChoices.NA,
    looks_back_as_well_as_uses_mirrors: InspectionTextChoices.NA,
    gets_out_and_rechecks_conditions_on_long_back: InspectionTextChoices.NA,
    avoids_backing_from_blind_side: InspectionTextChoices.NA,
    signals_when_backing: InspectionTextChoices.NA,
    controls_speed_and_direction_properly_while_backing: InspectionTextChoices.NA,
    does_not_hit_nearby_vehicles_or_stationary_objects: InspectionTextChoices.NA,
    parks_proper_distance_from_curb: InspectionTextChoices.NA,
    sets_parking_brake_puts_in_gear_chocks_wheel_shuts_off_motor: InspectionTextChoices.NA,
    checks_traffic_conditions_and_signals: InspectionTextChoices.NA,
    parks_in_legal_and_safe_location: InspectionTextChoices.NA,
    parks_off_pavement: InspectionTextChoices.NA,
    avoids_parking_on_soft_shoulders: InspectionTextChoices.NA,
    uses_emergency_warning_signals_when_required: InspectionTextChoices.NA,
    secures_unit_properly: InspectionTextChoices.NA,
    remarks: '',
}

const validationSchema = Yup.object({
    gets_out_and_checks_before_backing: Yup.string().required('Required'),
    looks_back_as_well_as_uses_mirrors: Yup.string().required('Required'),
    gets_out_and_rechecks_conditions_on_long_back: Yup.string().required('Required'),
    avoids_backing_from_blind_side: Yup.string().required('Required'),
    signals_when_backing: Yup.string().required('Required'),
    controls_speed_and_direction_properly_while_backing: Yup.string().required('Required'),
    does_not_hit_nearby_vehicles_or_stationary_objects: Yup.string().required('Required'),
    parks_proper_distance_from_curb: Yup.string().required('Required'),
    sets_parking_brake_puts_in_gear_chocks_wheel_shuts_off_motor: Yup.string().required('Required'),
    checks_traffic_conditions_and_signals: Yup.string().required('Required'),
    parks_in_legal_and_safe_location: Yup.string().required('Required'),
    parks_off_pavement: Yup.string().required('Required'),
    avoids_parking_on_soft_shoulders: Yup.string().required('Required'),
    uses_emergency_warning_signals_when_required: Yup.string().required('Required'),
    secures_unit_properly: Yup.string().required('Required'),
})


export const BackingAndParking: React.FC<{ member?: IMember, isDriverFile?: boolean }> = ({ member, isDriverFile }) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data, } = useGetBackingAndParkingByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })

    const [createBackingAndParking, { isLoading: isCreating }] = useCreateBackingAndParkingMutation()
    const [updateBackingAndParking, { isLoading: isUpdating }] = useUpdateBackingAndParkingMutation()
    const [deleteBackingAndParking] = useDeleteBackingAndParkingMutation()

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                await updateBackingAndParking({
                    ...values,
                    id: data.id,
                })
            } else {
                await createBackingAndParking({
                    ...values,
                    // @ts-ignore
                    member: member.id,
                    // @ts-ignore
                    company: member.company.id,
                })
            }
            resetForm()
        },
        validateOnChange: true,
        validateOnBlur: true,
    })

    React.useEffect(() => {
        if (data) {
            formik.setValues(data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const setAllAsSatisfactory = () => {
        formik.setValues({
            avoids_backing_from_blind_side: InspectionTextChoices.SATISFACTORY,
            avoids_parking_on_soft_shoulders: InspectionTextChoices.SATISFACTORY,
            checks_traffic_conditions_and_signals: InspectionTextChoices.SATISFACTORY,
            controls_speed_and_direction_properly_while_backing: InspectionTextChoices.SATISFACTORY,
            does_not_hit_nearby_vehicles_or_stationary_objects: InspectionTextChoices.SATISFACTORY,
            gets_out_and_checks_before_backing: InspectionTextChoices.SATISFACTORY,
            gets_out_and_rechecks_conditions_on_long_back: InspectionTextChoices.SATISFACTORY,
            looks_back_as_well_as_uses_mirrors: InspectionTextChoices.SATISFACTORY,
            parks_in_legal_and_safe_location: InspectionTextChoices.SATISFACTORY,
            parks_off_pavement: InspectionTextChoices.SATISFACTORY,
            parks_proper_distance_from_curb: InspectionTextChoices.SATISFACTORY,
            secures_unit_properly: InspectionTextChoices.SATISFACTORY,
            sets_parking_brake_puts_in_gear_chocks_wheel_shuts_off_motor: InspectionTextChoices.SATISFACTORY,
            signals_when_backing: InspectionTextChoices.SATISFACTORY,
            uses_emergency_warning_signals_when_required: InspectionTextChoices.SATISFACTORY,
        })
    }

    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                Backing and Parking
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify" }} variant="body1" gutterBottom component="p">
                For the items that apply, please mark the driver's performance accordingly.
                Explain unsatisfactory items under Remarks.
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Button size="small" fullWidth variant="contained" onClick={setAllAsSatisfactory}>
                        Set all As satisfactory
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        A. Backing
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Gets out and checks before backing
                    </Typography>
                    <TextField
                        fullWidth
                        id="gets_out_and_checks_before_backing"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.gets_out_and_checks_before_backing}
                        onChange={(e) => {
                            formik.setFieldValue('gets_out_and_checks_before_backing', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.gets_out_and_checks_before_backing && Boolean(formik.errors.gets_out_and_checks_before_backing) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.gets_out_and_checks_before_backing && Boolean(formik.errors.gets_out_and_checks_before_backing) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.gets_out_and_checks_before_backing && Boolean(formik.errors.gets_out_and_checks_before_backing) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Looks back as well as uses mirror
                    </Typography>
                    <TextField
                        fullWidth
                        id="looks_back_as_well_as_uses_mirrors"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.looks_back_as_well_as_uses_mirrors}
                        onChange={(e) => {
                            formik.setFieldValue('looks_back_as_well_as_uses_mirrors', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.looks_back_as_well_as_uses_mirrors && Boolean(formik.errors.looks_back_as_well_as_uses_mirrors) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.looks_back_as_well_as_uses_mirrors && Boolean(formik.errors.looks_back_as_well_as_uses_mirrors) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.looks_back_as_well_as_uses_mirrors && Boolean(formik.errors.looks_back_as_well_as_uses_mirrors) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Gets out and rechecks conditions on long back
                    </Typography>
                    <TextField
                        fullWidth
                        id="gets_out_and_rechecks_conditions_on_long_back"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.gets_out_and_rechecks_conditions_on_long_back}
                        onChange={(e) => {
                            formik.setFieldValue('gets_out_and_rechecks_conditions_on_long_back', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.gets_out_and_rechecks_conditions_on_long_back && Boolean(formik.errors.gets_out_and_rechecks_conditions_on_long_back) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.gets_out_and_rechecks_conditions_on_long_back && Boolean(formik.errors.gets_out_and_rechecks_conditions_on_long_back) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.gets_out_and_rechecks_conditions_on_long_back && Boolean(formik.errors.gets_out_and_rechecks_conditions_on_long_back) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Avoids backing from blind side
                    </Typography>
                    <TextField
                        fullWidth
                        id="avoids_backing_from_blind_side"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.avoids_backing_from_blind_side}
                        onChange={(e) => {
                            formik.setFieldValue('avoids_backing_from_blind_side', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.avoids_backing_from_blind_side && Boolean(formik.errors.avoids_backing_from_blind_side) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.avoids_backing_from_blind_side && Boolean(formik.errors.avoids_backing_from_blind_side) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.avoids_backing_from_blind_side && Boolean(formik.errors.avoids_backing_from_blind_side) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Signals when backing
                    </Typography>
                    <TextField
                        fullWidth
                        id="signals_when_backing"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.signals_when_backing}
                        onChange={(e) => {
                            formik.setFieldValue('signals_when_backing', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.signals_when_backing && Boolean(formik.errors.signals_when_backing) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.signals_when_backing && Boolean(formik.errors.signals_when_backing) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.signals_when_backing && Boolean(formik.errors.signals_when_backing) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Controls speed and direction properly while backing
                    </Typography>
                    <TextField
                        fullWidth
                        id="controls_speed_and_direction_properly_while_backing"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.controls_speed_and_direction_properly_while_backing}
                        onChange={(e) => {
                            formik.setFieldValue('controls_speed_and_direction_properly_while_backing', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.controls_speed_and_direction_properly_while_backing && Boolean(formik.errors.controls_speed_and_direction_properly_while_backing) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.controls_speed_and_direction_properly_while_backing && Boolean(formik.errors.controls_speed_and_direction_properly_while_backing) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.controls_speed_and_direction_properly_while_backing && Boolean(formik.errors.controls_speed_and_direction_properly_while_backing) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        B. Parking (City)
                    </Typography>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Does not hit nearby vehicles or stationary objects
                    </Typography>
                    <TextField
                        fullWidth
                        id="does_not_hit_nearby_vehicles_or_stationary_objects"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.does_not_hit_nearby_vehicles_or_stationary_objects}
                        onChange={(e) => {
                            formik.setFieldValue('does_not_hit_nearby_vehicles_or_stationary_objects', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.does_not_hit_nearby_vehicles_or_stationary_objects && Boolean(formik.errors.does_not_hit_nearby_vehicles_or_stationary_objects) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.does_not_hit_nearby_vehicles_or_stationary_objects && Boolean(formik.errors.does_not_hit_nearby_vehicles_or_stationary_objects) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.does_not_hit_nearby_vehicles_or_stationary_objects && Boolean(formik.errors.does_not_hit_nearby_vehicles_or_stationary_objects) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Parks proper distance from curb
                    </Typography>
                    <TextField
                        fullWidth
                        id="parks_proper_distance_from_curb"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.parks_proper_distance_from_curb}
                        onChange={(e) => {
                            formik.setFieldValue('parks_proper_distance_from_curb', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.parks_proper_distance_from_curb && Boolean(formik.errors.parks_proper_distance_from_curb) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.parks_proper_distance_from_curb && Boolean(formik.errors.parks_proper_distance_from_curb) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.parks_proper_distance_from_curb && Boolean(formik.errors.parks_proper_distance_from_curb) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Sets parking brake, puts in gear, chocks wheel, shuts off motor
                    </Typography>
                    <TextField
                        fullWidth
                        id="sets_parking_brake_puts_in_gear_chocks_wheel_shuts_off_motor"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.sets_parking_brake_puts_in_gear_chocks_wheel_shuts_off_motor}
                        onChange={(e) => {
                            formik.setFieldValue('sets_parking_brake_puts_in_gear_chocks_wheel_shuts_off_motor', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.sets_parking_brake_puts_in_gear_chocks_wheel_shuts_off_motor && Boolean(formik.errors.sets_parking_brake_puts_in_gear_chocks_wheel_shuts_off_motor) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.sets_parking_brake_puts_in_gear_chocks_wheel_shuts_off_motor && Boolean(formik.errors.sets_parking_brake_puts_in_gear_chocks_wheel_shuts_off_motor) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.sets_parking_brake_puts_in_gear_chocks_wheel_shuts_off_motor && Boolean(formik.errors.sets_parking_brake_puts_in_gear_chocks_wheel_shuts_off_motor) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Checks traffic conditions and signals when pulling out from parked position
                    </Typography>
                    <TextField
                        fullWidth
                        id="checks_traffic_conditions_and_signals"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.checks_traffic_conditions_and_signals}
                        onChange={(e) => {
                            formik.setFieldValue('checks_traffic_conditions_and_signals', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.checks_traffic_conditions_and_signals && Boolean(formik.errors.checks_traffic_conditions_and_signals) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.checks_traffic_conditions_and_signals && Boolean(formik.errors.checks_traffic_conditions_and_signals) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.checks_traffic_conditions_and_signals && Boolean(formik.errors.checks_traffic_conditions_and_signals) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Parks in legal and safe location
                    </Typography>
                    <TextField
                        fullWidth
                        id="parks_in_legal_and_safe_location"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.parks_in_legal_and_safe_location}
                        onChange={(e) => {
                            formik.setFieldValue('parks_in_legal_and_safe_location', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.parks_in_legal_and_safe_location && Boolean(formik.errors.parks_in_legal_and_safe_location) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.parks_in_legal_and_safe_location && Boolean(formik.errors.parks_in_legal_and_safe_location) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.parks_in_legal_and_safe_location && Boolean(formik.errors.parks_in_legal_and_safe_location) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        C. Parking (Road)
                    </Typography>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Parks off pavement
                    </Typography>
                    <TextField
                        fullWidth
                        id="parks_off_pavement"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.parks_off_pavement}
                        onChange={(e) => {
                            formik.setFieldValue('parks_off_pavement', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.parks_off_pavement && Boolean(formik.errors.parks_off_pavement) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.parks_off_pavement && Boolean(formik.errors.parks_off_pavement) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.parks_off_pavement && Boolean(formik.errors.parks_off_pavement) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Avoids parking on soft shoulder
                    </Typography>
                    <TextField
                        fullWidth
                        id="avoids_parking_on_soft_shoulders"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.avoids_parking_on_soft_shoulders}
                        onChange={(e) => {
                            formik.setFieldValue('avoids_parking_on_soft_shoulders', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.avoids_parking_on_soft_shoulders && Boolean(formik.errors.avoids_parking_on_soft_shoulders) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.avoids_parking_on_soft_shoulders && Boolean(formik.errors.avoids_parking_on_soft_shoulders) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.avoids_parking_on_soft_shoulders && Boolean(formik.errors.avoids_parking_on_soft_shoulders) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Uses emergency warning signals when required
                    </Typography>
                    <TextField
                        fullWidth
                        id="uses_emergency_warning_signals_when_required"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.uses_emergency_warning_signals_when_required}
                        onChange={(e) => {
                            formik.setFieldValue('uses_emergency_warning_signals_when_required', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.uses_emergency_warning_signals_when_required && Boolean(formik.errors.uses_emergency_warning_signals_when_required) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.uses_emergency_warning_signals_when_required && Boolean(formik.errors.uses_emergency_warning_signals_when_required) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.uses_emergency_warning_signals_when_required && Boolean(formik.errors.uses_emergency_warning_signals_when_required) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Secures unit properly
                    </Typography>
                    <TextField
                        fullWidth
                        id="secures_unit_properly"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.secures_unit_properly}
                        onChange={(e) => {
                            formik.setFieldValue('secures_unit_properly', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.secures_unit_properly && Boolean(formik.errors.secures_unit_properly) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.secures_unit_properly && Boolean(formik.errors.secures_unit_properly) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.secures_unit_properly && Boolean(formik.errors.secures_unit_properly) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Remarks
                    </Typography>
                    <TextField
                        fullWidth
                        id="remarks"
                        size="small"
                        placeholder='Remarks'
                        multiline
                        rows={4}
                        variant="outlined"
                        value={formik.values.remarks}
                        onChange={(e) => {
                            formik.setFieldValue('remarks', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.remarks && Boolean(formik.errors.remarks) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.remarks && Boolean(formik.errors.remarks) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.remarks && Boolean(formik.errors.remarks) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                {(!data || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role))) && !isDriverFile ? <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={isCreating || isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                        Save
                    </Button>
                    {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                        <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                            Once you click save, you will not be able to edit this form.
                        </Typography>
                    )}
                </Grid > : null}
                {data && currentUser?.role === "SuperAdmin" && <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={!!member?.terminated_at} onClick={() => {
                        const res = window.confirm("Are you sure you want to delete this form?")
                        if (res && data) {
                            deleteBackingAndParking(data.id)
                        }
                    }} variant="contained" color="primary">
                        Delete Form
                    </Button>
                </Grid>}
            </Grid >

        </div >
    )
}