import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import { Grid, Slide } from '@mui/material';
import { IRole, createRole, updateRole } from '../../../shared/client/roles';
import { toast } from 'react-toastify';


interface IProps {
    setOpen: (open: boolean) => void
    open: boolean;
    role?: IRole
    setRole: (role: IRole | undefined) => void
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddRoleModal({ open, setOpen, role, setRole }: IProps) {
    const [name, setName] = React.useState<string>('')

    const handleClose = () => {
        setOpen(false);
        setRole(undefined)
        setName('')
    };

    React.useEffect(() => {
        if (role) {
            setName(role.name)
        }
    }, [role])

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth='sm'
        >
            <DialogTitle sx={{
                '& .MuiTypography-root': {
                    fontSize: '1.5rem',
                    fontWeight: 'bold'
                },
                textAlign: 'center',
                color: "gray"
            }}>
                {role ? `Edit ${role.name}` : 'Add New Role'}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Role Name"
                            size='small'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                                if (!name) return toast.error('Please enter a role name')
                                if (role) {
                                    // edit role
                                    updateRole(role.id, name).then(() => {
                                        toast.success('Role updated successfully')
                                        handleClose()
                                    }).catch(() => {
                                        toast.error('Error updating role')
                                    })
                                } else {
                                    // add role
                                    createRole(name).then(() => {
                                        toast.success('Role added successfully')
                                        handleClose()
                                    }).catch(() => {
                                        toast.error('Error adding role')
                                    })
                                }
                            }}
                        >
                            {role ? 'Edit' : 'Add'}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
