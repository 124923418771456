/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { createCheckoutSessionPreEmployment, getCompanyMember, getFilesByMemberId, getPreEmploymentProducts, getSubscriptionPlans } from '../../../../shared';
import { useParams } from 'react-router-dom';
import { IFile, IMember, IPreEmploymentInvestigation, IPreEmploymentInvestigationDto, InvestigationType, PaymentStatus } from '../../../../models';
import FilesTable from '../../../../compliancemasters/partials/widgets/tables/FilesTable/FilesTable';
import DrugFileMemberTable from './Forms/DrugFileMemberTable';
import { useAuth } from '../../auth';
import { toast } from 'react-toastify';
import { StripeProduct } from '../../models';
import { useMutation, useQueryClient } from 'react-query';
import { createPreEmploymentInvestigation, updatePreEmploymentInvestigation } from '../../../../shared/client/pre-employment';
import { ButtonToolbar } from 'react-bootstrap';
import Button from '../../buttons/Button';
import { Button as MUIButton } from '@mui/material';
import { useGetPerformanceHistoryCompleteByUserIdQuery } from '../../../../store/reducers/PreviousEmployeeSafetyPerformanceHistory/driver-identification';
import { useGetPreviousEmployeeSafetyPerformanceHistoryProgressMutation } from '../../../../store/reducers/PreviousEmployeeSafetyPerformanceHistory/progress';
import PreEmploymentInvestigationTable from './PreEmploymentInvestigationTable';

const payableInvestigations = Object.values(InvestigationType).filter(investigation => investigation !== InvestigationType.PRE_EMPLOYMENT_FORMS);


export const getNextInvestigation = (
    investigations?: IPreEmploymentInvestigation[],
    memberSub?: { name: string }
): InvestigationType | undefined => {
    const currentInvestigation = investigations?.find(investigation => investigation.is_current_investigation);

    if (!currentInvestigation) {
        return undefined;
    }
    let investigationOrder: InvestigationType[] = [];

    investigationOrder = [
        InvestigationType.PRE_EMPLOYMENT_FORMS,
        InvestigationType.PRE_EMPLOYMENT_FULL_DACH_QUERY,
        InvestigationType.PRE_EMPLOYMENT_DRUG_TEST
    ];

    const currentIndex = investigationOrder.indexOf(currentInvestigation.type);

    if (currentIndex === -1) {
        return undefined;
    }

    const nextIndex = currentIndex + 1;
    return investigationOrder[nextIndex] || undefined;
};



const getCurrentInvestigation = (member: IMember) => member?.pre_employment_investigation?.find(item => item.is_current_investigation);


interface IEndInvestigationProps {
    options: StripeProduct[];
    member?: IMember;
    disabled?: boolean;
    subscription?: StripeProduct
    disableContinue: boolean;
}

const CloseInvestigationCard = ({ member, disabled, subscription, options, disableContinue }: IEndInvestigationProps) => {

    const [selectedPlan, setSelectedPlan] = useState<StripeProduct>()

    const [showButtons, setShowButtons] = React.useState(false)
    const [shouldShowEndButtons, setShowEndButtons] = React.useState(false)

    const queryClient = useQueryClient()

    const preEmploymentMutation = useMutation(updatePreEmploymentInvestigation, {
        onSuccess: (data) => {
            queryClient.invalidateQueries('pre-employment-investigations')
            // Refresh the page
            window.location.assign(`/dashboard`)
        }
    })


    const nextInvestigation = getNextInvestigation(member?.pre_employment_investigation, subscription!)

    const shouldShowInDriverFile = React.useCallback(() => {
        if (subscription?.name === "Drug and Alcohol Package" && member?.pre_employment_investigation && member?.pre_employment_investigation?.length > 2) {
            setShowButtons(true)
        } else {
            setShowButtons(false)
        }
        // eslint-disable-next-line
    }, [subscription, member, nextInvestigation])


    const shouldShowContinueButtons = (nextInvestigation && [/* InvestigationType.PRE_EMPLOYMENT_CRIMINAL_BACKGROUND_CHECK, */ InvestigationType.PRE_EMPLOYMENT_SCREENING_PROGRAM].includes(nextInvestigation)) || member?.pre_employment_investigation.some(item => !item.screened)

    const setShouldShowEndButtons = React.useCallback(() => {
        if (subscription?.name === "Drug and Alcohol Package" && member?.pre_employment_investigation && member?.pre_employment_investigation?.length > 1 && !member?.pre_employment_investigation.some(item => !item.screened)) {
            setShowEndButtons(true)
        } else {
            setShowEndButtons(false)
        }
        // eslint-disable-next-line
    }, [subscription, member?.pre_employment_investigation, nextInvestigation])

    const checkout = async (id: number) => {
        if (selectedPlan) {
            let url = await createCheckoutSessionPreEmployment(
                selectedPlan.price_id,
                selectedPlan.type,
                selectedPlan.tiers_mode,
                id
            )
            window.open(url?.url, '_blank')
        }
    }

    useEffect(() => {
        if (member && options?.length && subscription) {
            const nextInvest = getNextInvestigation(member!.pre_employment_investigation, subscription)
            if (nextInvest) {
                const nextInvestigation = options?.find(option => option.name.toLowerCase().includes(nextInvest.toLowerCase()))
                setSelectedPlan(nextInvestigation)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [member, options])

    React.useEffect(() => {
        shouldShowInDriverFile()
        setShouldShowEndButtons()
    }, [shouldShowInDriverFile, setShouldShowEndButtons])


    return (
        <>
            {showButtons ? (
                <div className="row g-5 g-xxl-8">
                    <div className="col-xl-12">
                        <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            {shouldShowContinueButtons ?
                                (
                                    <div className="card-body d-flex flex-column p-9">
                                        <div className="d-flex flex-stack flex-grow-1">
                                            <h5 className="fw-bolder my-2">
                                                All required pre-employment investigations have been completed.
                                                Would you like to continue the investigation and complete the optional items,
                                                end the investigation and hire the applicant, or end the investigation and do not hire the applicant?
                                            </h5>
                                        </div>
                                        <div className="d-flex flex-stack flex-grow-1">
                                            <ButtonToolbar>
                                                <Button className='my-3 mx-3 btn btn-success' disabled={disabled} onClick={async () => {
                                                    if (nextInvestigation && [InvestigationType.PRE_EMPLOYMENT_SCREENING_PROGRAM/* , InvestigationType.PRE_EMPLOYMENT_CRIMINAL_BACKGROUND_CHECK */].includes(nextInvestigation) && !disableContinue) {
                                                        const payload: IPreEmploymentInvestigationDto = {
                                                            investigation_type: nextInvestigation,
                                                            is_current_investigation: true,
                                                            member: Number(member?.id!),
                                                            requires_payment: payableInvestigations.includes(nextInvestigation),
                                                            payment: payableInvestigations.includes(nextInvestigation) ? PaymentStatus.PENDING : PaymentStatus.NONE,
                                                        }
                                                        const investigation = await createPreEmploymentInvestigation(payload);
                                                        if (investigation && investigation.requires_payment) {
                                                            checkout(investigation.id)
                                                        }
                                                    } else if (disableContinue) {
                                                        toast.warn("The investigation can’t be continued until Compliance Masters has completed the Previous Employee Safety Performance History investigation. You will be notified when this has been completed and available to review")
                                                    }
                                                }}>
                                                    Continue Investigation to Optional
                                                </Button>
                                                <Button className='my-3 mx-3 btn btn-primary' onClick={() => {
                                                    preEmploymentMutation.mutate({
                                                        // @ts-ignore
                                                        member: Number(member?.id),
                                                        complete: true,
                                                        id: member?.pre_employment_investigation?.find(investigation => investigation.is_current_investigation)?.id,
                                                        screened: true,
                                                    })
                                                }}>
                                                    End Investigation and Hire
                                                </Button>
                                                <Button
                                                    className='my-3 mx-3 btn btn-danger'
                                                    dataBsToggle="modal"
                                                    dataBsTarget="#end_investigation_modal"
                                                >
                                                    End Investigation
                                                </Button>
                                            </ButtonToolbar>
                                        </div>
                                    </div>
                                ) : shouldShowEndButtons ? (
                                    <div className="card-body d-flex flex-column p-9">
                                        <div className="d-flex flex-stack flex-grow-1">
                                            <h5 className="fw-bolder my-2">
                                                All necessary and optional pre-employment investigations have been completed.
                                                Would you like to hire the driver or end the investigation?
                                            </h5>
                                        </div>
                                        <div className="d-flex flex-stack flex-grow-1">
                                            <ButtonToolbar>
                                                <Button
                                                    className='my-3 mx-3 btn btn-success'
                                                    disabled={disabled}
                                                    onClick={() => {
                                                        preEmploymentMutation.mutate({
                                                            // @ts-ignore
                                                            member: Number(member?.id),
                                                            complete: true,
                                                            id: member?.pre_employment_investigation?.find(investigation => investigation.is_current_investigation)?.id,
                                                            screened: true,
                                                        })
                                                    }}
                                                >
                                                    End investigation and Hire
                                                </Button>
                                                <Button
                                                    className='my-3 mx-3 btn btn-danger'
                                                    dataBsToggle="modal"
                                                    dataBsTarget="#end_investigation_modal"
                                                >
                                                    End Investigation and don’t Hire
                                                </Button>
                                            </ButtonToolbar>
                                        </div>
                                    </div>
                                ) : null}
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    )
}

interface IInvestigationsProps {
    subscriptions: StripeProduct[]
    options: StripeProduct[]
    selectedPlan: StripeProduct | undefined
    setSelectedPlan: React.Dispatch<React.SetStateAction<StripeProduct | undefined>>
    member: IMember | undefined;
    disabled: boolean | undefined;
    subscription: StripeProduct | undefined;
    disableContinue: boolean;
}

const InvestigationCard = ({ member, disabled, subscription, options, selectedPlan, setSelectedPlan, subscriptions, disableContinue }: IInvestigationsProps) => {


    const checkout = async (id: number) => {
        if (selectedPlan) {
            let url = await createCheckoutSessionPreEmployment(
                selectedPlan.price_id,
                selectedPlan.type,
                selectedPlan.tiers_mode,
                id
            )
            window.open(url?.url, '_blank')
        }
    }




    useEffect(() => {
        if (member && options?.length) {
            const nextInvest = getNextInvestigation(member?.pre_employment_investigation, subscription)
            if (nextInvest) {
                const nextInvestigation = options?.find(option => option.name.toLowerCase().includes(nextInvest.toLowerCase()))
                setSelectedPlan(nextInvestigation)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options])
    return (
        <>
            {member?.pre_employment_investigation && subscription && getNextInvestigation(member?.pre_employment_investigation, subscription) && [
                InvestigationType.PRE_EMPLOYMENT_FULL_DACH_QUERY,
                subscriptions.find(item => item.product_id === member?.product_id)?.name !== "Drug and Alcohol Package" && InvestigationType.PRE_EMPLOYMENT_MVR,
                InvestigationType.PRE_EMPLOYMENT_DRUG_TEST
            ].includes(getNextInvestigation(member?.pre_employment_investigation, subscription)!) ? <div className="row g-5 g-xxl-8">
                <div className="col-xl-12">
                    <div className="card card-xxl-stretch mb-5 mb-xl-8">
                        <div className="card-body d-flex flex-column p-9">
                            {<div className="d-flex flex-stack flex-grow-1">
                                <h5 className="fw-bolder my-2">
                                    Would you like to continue or end this pre-employment investigation?
                                </h5>
                                <ButtonToolbar >
                                    <Button
                                        className='my-3 mx-3 btn btn-success' disabled={disabled} onClick={async () => {
                                            const nextInvestigation = getNextInvestigation(member.pre_employment_investigation, subscription);
                                            if (nextInvestigation && !disableContinue) {
                                                const payload: IPreEmploymentInvestigationDto = {
                                                    investigation_type: nextInvestigation,
                                                    is_current_investigation: true,
                                                    member: Number(member.id),
                                                    requires_payment: payableInvestigations.includes(nextInvestigation),
                                                    payment: payableInvestigations.includes(nextInvestigation) ? PaymentStatus.PENDING : PaymentStatus.NONE
                                                }
                                                const investigation = await createPreEmploymentInvestigation(payload)
                                                if (investigation?.requires_payment) {
                                                    checkout(investigation.id)
                                                }
                                            } else if (disableContinue) {
                                                toast.warn("The investigation can’t be continued until Compliance Masters has completed the Previous Employee Safety Performance History investigation. You will be notified when this has been completed and available to review")
                                            }
                                        }}>
                                        Continue Investigation
                                    </Button>
                                    <Button className='my-3 mx-3 btn btn-danger' dataBsToggle="modal" dataBsTarget="#end_investigation_modal">
                                        End Investigation
                                    </Button>
                                </ButtonToolbar>
                            </div>}
                        </div>
                    </div>
                </div>
            </div> : null}
        </>
    )
}


interface IProp {
    memberSubscription?: StripeProduct
}

export function DrugDocuments({ memberSubscription }: Readonly<IProp>) {
    const [member, setMember] = useState<IMember | undefined>();

    const { memberId } = useParams();
    const { currentUser, setFormsDone } = useAuth()
    const [options, setOptions] = useState<Array<StripeProduct>>()
    const [subscriptionss, setSubscriptionss] = useState<Array<StripeProduct>>([])
    const [selectedPlan, setSelectedPlan] = useState<StripeProduct>()
    const [disableComplete, setDisableComplete] = React.useState<boolean>(false)
    const [files, setFiles] = useState<IFile[]>([]);

    useLayoutEffect(() => {
        if (memberId) {
            getCompanyMember(memberId).then((mem) => {
                setMember(mem);
            });
            getFiles();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    function getFiles() {
        getFilesByMemberId(memberId!, 'Drug').then((files) => {
            setFiles(files);
        });
    }


    const { data } = useGetPerformanceHistoryCompleteByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    })

    const [getProgress, { data: progress }] = useGetPreviousEmployeeSafetyPerformanceHistoryProgressMutation()

    const getPlanOptions = React.useCallback(async () => {
        if (memberSubscription) {
            const res = await getPreEmploymentProducts(memberSubscription.name)
            const subs = await getSubscriptionPlans()
            setOptions(res.data)
            setSubscriptionss(subs.data)
        }
    }, [memberSubscription])



    React.useEffect(() => {
        if (((progress && progress?.progress > 97) || data?.performance_history_complete) && ['Master Package', 'Deluxe Package', 'Deluxe Mini Package', 'Driver File Package'].includes(memberSubscription?.name!)) {
            setDisableComplete(false)
        } else if (((progress && progress?.progress < 97) || !data?.performance_history_complete) && ['Master Package', 'Deluxe Package', 'Deluxe Mini Package', 'Driver File Package'].includes(memberSubscription?.name!)) {
            setDisableComplete(true)
        } else if (['Drug and Alcohol Package'].includes(memberSubscription?.name!)) {
            setDisableComplete(false)
        }
    }, [progress, data, memberSubscription])

    useEffect(() => {
        if (member) {
            getProgress(Number(member?.id))
        }
        // eslint-disable-next-line
    }, [member])

    useEffect(() => {
        getPlanOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPlanOptions]);



    //  Create a useEffecct that will refresh the page when the tab is focused
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                if (memberId) {
                    getCompanyMember(memberId).then((mem) => {
                        setMember(mem);
                    });
                    getFiles();
                }
                getPlanOptions()
            }
        }
        document.addEventListener('visibilitychange', handleVisibilityChange)
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return (
        <>
            <div className="d-flex flex-wrap flex-stack mb-6">
                <h3 className="fw-bolder my-2">Drug File</h3>
            </div>
            {memberSubscription && !member?.pre_employment_screened && ["Drug and Alcohol Package"].includes(memberSubscription?.name) &&
                <>
                    {!member?.terminated_at && !member?.pre_employment_screened && currentUser?.role && ["Owner", "Admin", "SuperAdmin"].includes(currentUser?.role) ? (
                        <PreEmploymentInvestigationTable
                            investigations={member?.pre_employment_investigation ?? []}
                            member={member!}
                            subscriptions={subscriptionss}
                            memberSubscription={memberSubscription}
                        />
                    ) : null}
                    {!member?.terminated_at && !member?.pre_employment_screened && currentUser?.role === 'Owner' ? (
                        <InvestigationCard
                            disabled={member && getCurrentInvestigation(member)?.screened ? false : member && getCurrentInvestigation(member)?.type === InvestigationType.PRE_EMPLOYMENT_FORMS ? false : true}
                            member={member!}
                            subscription={memberSubscription}
                            options={options!}
                            selectedPlan={selectedPlan}
                            setSelectedPlan={setSelectedPlan}
                            subscriptions={subscriptionss}
                            disableContinue={disableComplete}
                        />
                    ) : null}
                    {!member?.terminated_at && !member?.pre_employment_screened && currentUser?.role === 'Owner' ? (
                        <CloseInvestigationCard
                            disabled={member && getCurrentInvestigation(member)?.screened ? false : true}
                            member={member!}
                            subscription={memberSubscription}
                            options={options!}
                            disableContinue={disableComplete}
                        />
                    ) : null}
                </>
            }
            {member ? <DrugFileMemberTable memberSubscription={memberSubscription?.name} member={member} /> : null}
            <div className="row g-5 g-xxl-8">
                <div className="col-xl-12">
                    {member ? <FilesTable subscription_type={memberSubscription?.name} member={member} files={files} memberId={memberId!} onAddNewFile={getFiles} location="Drug" /> : null}
                </div>
            </div>
            {currentUser && currentUser?.role === "Member" && memberSubscription?.name === "Drug and Alcohol Package" ? (
                <div className="row g-5 g-xxl-8">
                    <div className="col-xl-12">
                        <MUIButton className="btn btn-primary" size="small" onClick={() => {
                            setFormsDone(currentUser?.member?.id!).then(() => {
                                if (currentUser?.member?.pre_employment_screened && ["Drug and Alcohol Package"].includes(memberSubscription?.name!)) {
                                    toast.success('Forms Completed')
                                    setTimeout(() => {
                                        window.location.assign(`/complete-driver-file-drug-alcohol?company=${member?.company?.name}`)
                                    }, 3000)
                                }
                            })
                        }} fullWidth>
                            Complete Driver File
                        </MUIButton>
                    </div>
                </div>
            ) : null}
        </>
    );
}
