import React from 'react'
import { IMember } from '../../../../models'
import { Box, Checkbox, FormControlLabel, Paper, Tooltip } from '@mui/material'
import { updateCompanyMember } from '../../../../shared'
import { toast } from 'react-toastify'
import { useAuth } from '../../auth'

const PreEmploymentCompleteCheckbox = ({ member }: { member: IMember }) => {
    const { currentUser } = useAuth()
    return (
        <Box component={Paper} p={2} sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            borderRadius: "10px",
            color: "white",
            fontWeight: "bold"
        }}>
            <Tooltip title="Click to Mark the investigations complete" placement="top">
                <FormControlLabel
                    control={
                        <Checkbox
                            sx={{ color: member?.pre_employment_investigation_complete ? "green" : "red" }}
                            checked={member?.pre_employment_investigation_complete} />}
                    label="Pre-Employment Investigations Completed?"
                    disabled={!["Admin", "SuperAdmin", "Owner"].includes(currentUser?.role!)}
                    onClick={() => {
                        updateCompanyMember(member.id, { pre_employment_investigation_complete: !member?.pre_employment_investigation_complete }).then(() => {
                            toast.success("Pre-Employment Investigations Status Updated")
                            setTimeout(() => {
                                window.location.reload()
                            }, 3000)
                        })
                    }}
                />
            </Tooltip>
        </Box>
    )
}

export default PreEmploymentCompleteCheckbox