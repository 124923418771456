import React from 'react'
import { IMember } from '../../../models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Box, Button, CircularProgress, Container, Divider, Grid, MenuItem, Paper, TextField, Typography } from '@mui/material'
import {
    IAnnualReviewOfDrivingRecord,
    IAnnualReviewOfDrivingRecordDto,
    Options
} from '../../../store/interfaces/AnnualReviewOfDrivingRecord/annual-review-of_driving-record'
import {
    useCreateAnnualReviewOfDrivingRecordMutation,
    useGetAnnaulReviewOfDrivingRecordByUserIdQuery,
} from '../../../store/reducers/AnnualReviewOfDrivingRecord/annual-review-of-driving-record'
import FormHeaderCard from '../HeaderCard'
import { useParams } from 'react-router-dom'
import { client, getCompanyMember } from '../../../shared'
import ActionButtons from '../ActionButtons'
import { useGetAnnualReviewOfDrivingRecordProgressMutation, useGetMemberAnnualReviewOfDrivingRecordProgressMutation } from '../../../store/reducers/AnnualReviewOfDrivingRecord/progress'
import { useThemeMode } from '../../partials'


const initialValues: Partial<IAnnualReviewOfDrivingRecordDto> = {
    name_of_driver: '',
    he_she: Options.MEETS_MINIMUM_REQUIREMENTS,
    action_taken_with_driver: '',
    reviewed_by_signature: '',
    date_of_mvr_review: '',
    print: '',
    title: '',
    motor_carrier_name: '',
    motor_carrier_address: '',
    city: '',
    state_zip: '',
}

const validationSchema = Yup.object({
    name_of_driver: Yup.string().required('Required'),
    he_she: Yup.string().required('Required'),
    action_taken_with_driver: Yup.string().required('Required'),
    reviewed_by_signature: Yup.string().required('Required'),
    date_of_mvr_review: Yup.string().required('Required'),
    title: Yup.string().required('Required'),
    motor_carrier_name: Yup.string().required('Required'),
    motor_carrier_address: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state_zip: Yup.string().required('Required'),
})

const AnnualReviewDrivingRecordItem = ({ annualReview }: { annualReview: IAnnualReviewOfDrivingRecord }) => {
    const { mode } = useThemeMode()
    const formik = useFormik({
        initialValues: annualReview,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
        },
        validateOnBlur: true,
        validateOnChange: true
    })
    let fileStatus = ""
    if (annualReview.is_original && annualReview.is_current) {
        fileStatus = "Original File (Current File)"
    } else if (annualReview.is_original) {
        fileStatus = "Original File"
    } else if (annualReview.is_current) {
        fileStatus = "Current File"
    }

    return (
        <div style={{ userSelect: 'none' }}>

            <Grid item xs={12} sm={12} mt={2}>
                <Typography sx={{ textAlign: "center" }} variant="h4" gutterBottom component="h4">
                    Annual Review of Driving Record
                </Typography>
            </Grid >
            <Grid item xs={12} sm={12} mt={2}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h6" gutterBottom component="h6">
                    File Status:   {fileStatus}
                </Typography>
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    I have hereby reviewed the driving record of (name of driver)
                </Typography>
                <TextField
                    fullWidth
                    id="name_of_driver"
                    placeholder='Name of Driver'
                    size="small"
                    variant="outlined"
                    value={formik.values.name_of_driver}

                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: formik.touched.name_of_driver && Boolean(formik.errors.name_of_driver) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                borderColor: formik.touched.name_of_driver && Boolean(formik.errors.name_of_driver) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: formik.touched.name_of_driver && Boolean(formik.errors.name_of_driver) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                        }
                    }}
                    disabled
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    In accordance with Section 391.25 and find that he/she (select one)
                </Typography>
                <TextField
                    fullWidth
                    id="he_she"
                    select
                    size="small"
                    variant="outlined"
                    value={formik.values.he_she}
                    disabled
                    onChange={(e) => {
                        formik.setFieldValue('he_she', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: formik.touched.he_she && Boolean(formik.errors.he_she) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                borderColor: formik.touched.he_she && Boolean(formik.errors.he_she) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: formik.touched.he_she && Boolean(formik.errors.he_she) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                        }
                    }}
                >
                    {Object.values(Options).map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Action taken with driver
                </Typography>
                <TextField
                    fullWidth
                    id="action_taken_with_driver"
                    placeholder='Action taken with driver'
                    multiline
                    disabled
                    rows={4}
                    size="small"
                    variant="outlined"
                    value={formik.values.action_taken_with_driver}
                    onChange={(e) => {
                        formik.setFieldValue('action_taken_with_driver', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: formik.touched.action_taken_with_driver && Boolean(formik.errors.action_taken_with_driver) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                borderColor: formik.touched.action_taken_with_driver && Boolean(formik.errors.action_taken_with_driver) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: formik.touched.action_taken_with_driver && Boolean(formik.errors.action_taken_with_driver) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Reviewed by Signature
                </Typography>
                <TextField
                    fullWidth
                    id="reviewed_by_signature"
                    placeholder='Reviewed by Signature'
                    size="small"
                    variant="outlined"
                    disabled
                    value={formik.values.reviewed_by_signature}
                    onChange={(e) => {
                        formik.setFieldValue('reviewed_by_signature', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: formik.touched.reviewed_by_signature && Boolean(formik.errors.reviewed_by_signature) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                borderColor: formik.touched.reviewed_by_signature && Boolean(formik.errors.reviewed_by_signature) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: formik.touched.reviewed_by_signature && Boolean(formik.errors.reviewed_by_signature) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            fontStyle: 'italic'
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            fontStyle: 'italic'

                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Date of MVR review
                </Typography>
                <TextField
                    fullWidth
                    id="date_of_mvr_review"
                    type='date'
                    size="small"
                    variant="outlined"
                    disabled
                    value={formik.values.date_of_mvr_review}
                    onChange={(e) => {
                        formik.setFieldValue('date_of_mvr_review', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: formik.touched.date_of_mvr_review && Boolean(formik.errors.date_of_mvr_review) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                borderColor: formik.touched.date_of_mvr_review && Boolean(formik.errors.date_of_mvr_review) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: formik.touched.date_of_mvr_review && Boolean(formik.errors.date_of_mvr_review) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Title
                </Typography>
                <TextField
                    fullWidth
                    id="title"
                    placeholder='Title'
                    size="small"
                    variant="outlined"
                    disabled
                    value={formik.values.title}
                    onChange={(e) => {
                        formik.setFieldValue('title', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: formik.touched.title && Boolean(formik.errors.title) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                borderColor: formik.touched.title && Boolean(formik.errors.title) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: formik.touched.title && Boolean(formik.errors.title) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Company Name
                </Typography>
                <TextField
                    fullWidth
                    id="motor_carrier_name"
                    placeholder='Company Name'
                    size="small"
                    variant="outlined"
                    value={formik.values.motor_carrier_name}
                    disabled
                    onChange={(e) => {
                        formik.setFieldValue('motor_carrier_name', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: formik.touched.motor_carrier_name && Boolean(formik.errors.motor_carrier_name) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                borderColor: formik.touched.motor_carrier_name && Boolean(formik.errors.motor_carrier_name) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: formik.touched.motor_carrier_name && Boolean(formik.errors.motor_carrier_name) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Company Address
                </Typography>
                <TextField
                    fullWidth
                    id="motor_carrier_address"
                    placeholder='Company Address'
                    size="small"
                    disabled
                    variant="outlined"
                    value={formik.values.motor_carrier_address}
                    onChange={(e) => {
                        formik.setFieldValue('motor_carrier_address', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: formik.touched.motor_carrier_address && Boolean(formik.errors.motor_carrier_address) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                borderColor: formik.touched.motor_carrier_address && Boolean(formik.errors.motor_carrier_address) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: formik.touched.motor_carrier_address && Boolean(formik.errors.motor_carrier_address) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    City
                </Typography>
                <TextField
                    fullWidth
                    id="city"
                    placeholder='City'
                    size="small"
                    variant="outlined"
                    value={formik.values.city}
                    disabled
                    onChange={(e) => {
                        formik.setFieldValue('city', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: formik.touched.city && Boolean(formik.errors.city) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                borderColor: formik.touched.city && Boolean(formik.errors.city) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: formik.touched.city && Boolean(formik.errors.city) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    State / ZIP
                </Typography>
                <TextField
                    fullWidth
                    id="state_zip"
                    placeholder='State / ZIP'
                    size="small"
                    variant="outlined"
                    value={formik.values.state_zip}
                    disabled
                    onChange={(e) => {
                        formik.setFieldValue('state_zip', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: formik.touched.state_zip && Boolean(formik.errors.state_zip) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                borderColor: formik.touched.state_zip && Boolean(formik.errors.state_zip) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: formik.touched.state_zip && Boolean(formik.errors.state_zip) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Divider sx={{ bordercolor: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', mt: 2, mb: 2 }} />
            </Grid>
        </div>
    )
}

const AnnualReviewDrivingRecords = () => {
    const [getProgress, { data: progress }] = useGetAnnualReviewOfDrivingRecordProgressMutation()
    const [getMemberProgress, { data: memberProgress }] = useGetMemberAnnualReviewOfDrivingRecordProgressMutation()
    const [showForm, setShowForm] = React.useState(false)
    const [annualReviews, setAnnualReviews] = React.useState<IAnnualReviewOfDrivingRecord[]>([])
    const [member, setMember] = React.useState<IMember>()
    const { id } = useParams()
    const [value, setValue] = React.useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isLoading, setIsLoading] = React.useState(false)
    const { mode } = useThemeMode()
    const [createAnnualReviewOfDrivingRecord, { isLoading: isCreating }] = useCreateAnnualReviewOfDrivingRecordMutation()

    const getRemider = async () => {
        try {
            const res = await client("form-revamp/annual-review-of-driving-record/get-reminder/", {
                body: {
                    company_id: member?.company?.id,
                    member_id: member?.id,
                }
            })

            if (res) {
                setShowForm(true)
            }

        } catch (error) {
            setShowForm(false)
        }
    }



    const { data = [], } = useGetAnnaulReviewOfDrivingRecordByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })

    React.useEffect(() => {

        if (id) {
            getCompanyMember(id).then(async (data: IMember) => {
                setMember(data)
            })
            getProgress(Number(id))
            getMemberProgress(Number(id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (member) {
            getRemider()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [member, data,])



    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            createAnnualReviewOfDrivingRecord({
                ...values,
                // @ts-ignore
                company: member?.company?.id,
                // @ts-ignore
                member: member?.id,
            }).then(async () => {
                resetForm()
                setShowForm(false)
                await getRemider()
                getProgress(Number(id))
                getMemberProgress(Number(id))
            })
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate(values) {
            const errors: Partial<IAnnualReviewOfDrivingRecordDto> = {}
            if (!values.name_of_driver) {
                errors.name_of_driver = 'Required'
            }
            if (!values.action_taken_with_driver) {
                errors.action_taken_with_driver = 'Required'
            }
            if (!values.reviewed_by_signature) {
                errors.reviewed_by_signature = 'Required'
            }
            if (!values.date_of_mvr_review) {
                errors.date_of_mvr_review = 'Required'
            }
            if (!values.title) {
                errors.title = 'Required'
            }
            if (!values.motor_carrier_name) {
                errors.motor_carrier_name = 'Required'
            }
            if (!values.motor_carrier_address) {
                errors.motor_carrier_address = 'Required'
            }
            if (!values.city) {
                errors.city = 'Required'
            }
            if (!values.state_zip) {
                errors.state_zip = 'Required'
            }
            return errors
        },
    })

    React.useEffect(() => {
        if (member) {
            formik.setFieldValue('name_of_driver', member ? `${member?.user?.first_name} ${member?.user?.last_name}` : '')
            formik.setFieldValue("motor_carrier_name", member?.company?.name ? member?.company?.name : "")
            formik.setFieldValue("motor_carrier_address", member?.company?.address1 ? member?.company?.address1 : "")
            formik.setFieldValue("city", member?.company?.city ? member?.company?.city : "")
            formik.setFieldValue("state_zip", `${member?.company?.country ? member?.company?.country : member?.company?.state ? member?.company?.state : ""}, ${member?.company?.zip_code ? member?.company?.zip_code : ""}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, member])


    React.useEffect(() => {
        if (data.length && member) {
            if (member?.pre_employment_screened && member?.pre_employment_investigation.length > 0) {
                setAnnualReviews(data.filter((item) => item.is_original || item.is_current))
            } else if (member?.pre_employment_screened && member?.pre_employment_investigation.length === 0) {
                setAnnualReviews(data.filter((item) => item.is_current))
            }
        }
    }, [data, member])



    return (
        <Container style={{ userSelect: 'none' }}>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
            }} component={Paper}>
                <FormHeaderCard
                    headerTitle='Annual Review of Driving Record'
                    status={progress && (progress?.progress > 0 && progress?.progress < 100) ? "In Progress" : progress && progress.progress > 99 ? "Completed" : "Not Started"}
                    sectionCount={4}
                    itemCount={29}
                    totalProgress={progress ? progress.progress : 0}
                    memberProgress={memberProgress ? memberProgress.progress : 0}
                    nextButton={{ title: "", path: "" }}
                    member={member}
                    setValue={setValue}
                    value={value}
                />
            </Box>
            {annualReviews && annualReviews.length > 0 ? annualReviews.map((item) => (
                <Box
                    sx={{
                        pl: 3,
                        pr: 3,
                        pt: 3,
                        m: 3,
                        borderRadius: 2,
                        width: {
                            lg: "47vw",
                            md: "70vw",
                            sm: "80vw",
                        },
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)'
                    }}
                    key={item.id}
                    component={Paper}>
                    <div
                        style={{
                            padding: 10,
                            margin: 10
                        }}>
                        <AnnualReviewDrivingRecordItem annualReview={item} />
                    </div>
                </Box>
            )) : null}
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    width: {
                        lg: "47vw",
                        md: "70vw",
                        sm: "80vw",
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)'
                }}
                component={Paper}>
                <div
                    style={{
                        padding: 10,
                        margin: 10
                    }}>
                    <Grid container spacing={2}>
                        {showForm && !isLoading ? (
                            <>
                                <Grid item xs={12} sm={12}>
                                    {member?.pre_employment_screened && member?.pre_employment_investigation.length > 0 && data.length < 1 ? (
                                        <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                            This annual review is documentation that you reviewed the Pre-employment MVR that was pulled during the driver’s pre-employment investigation. This annual review will remain in the driver file.
                                        </Typography>
                                    ) : null}
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                        I have hereby reviewed the driving record of (name of driver)
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        id="name_of_driver"
                                        placeholder='Name of Driver'
                                        size="small"
                                        variant="outlined"
                                        value={formik.values.name_of_driver}
                                        disabled={!!member?.terminated_at}
                                        onChange={(e) => {
                                            // formik.setFieldValue('name_of_driver', e.target.value)
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.name_of_driver && Boolean(formik.errors.name_of_driver) ? "red" : "green",
                                                },
                                                '&:hover fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.name_of_driver && Boolean(formik.errors.name_of_driver) ? "red" : "green",
                                                },
                                                '&.Mui-focused fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.name_of_driver && Boolean(formik.errors.name_of_driver) ? "red" : "green",
                                                },
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                            }
                                        }}
                                        InputProps={{
                                            style: {
                                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                        In accordance with Section 391.25 and find that he/she (select one)
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        id="he_she"
                                        select
                                        size="small"
                                        variant="outlined"
                                        value={formik.values.he_she}
                                        disabled={!!member?.terminated_at}
                                        onChange={(e) => {
                                            formik.setFieldValue('he_she', e.target.value)
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.he_she && Boolean(formik.errors.he_she) ? "red" : "green",
                                                },
                                                '&:hover fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.he_she && Boolean(formik.errors.he_she) ? "red" : "green",
                                                },
                                                '&.Mui-focused fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.he_she && Boolean(formik.errors.he_she) ? "red" : "green",
                                                },
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                            }
                                        }}
                                        InputProps={{
                                            style: {
                                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                                            }
                                        }}
                                    >
                                        {Object.values(Options).map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                        Action taken with driver
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        id="action_taken_with_driver"
                                        placeholder='Action taken with driver'
                                        multiline
                                        rows={4}
                                        size="small"
                                        variant="outlined"
                                        value={formik.values.action_taken_with_driver}
                                        disabled={!!member?.terminated_at}
                                        onChange={(e) => {
                                            formik.setFieldValue('action_taken_with_driver', e.target.value)
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.action_taken_with_driver && Boolean(formik.errors.action_taken_with_driver) ? "red" : "green",
                                                },
                                                '&:hover fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.action_taken_with_driver && Boolean(formik.errors.action_taken_with_driver) ? "red" : "green",
                                                },
                                                '&.Mui-focused fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.action_taken_with_driver && Boolean(formik.errors.action_taken_with_driver) ? "red" : "green",
                                                },
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                            }
                                        }}
                                        InputProps={{
                                            style: {
                                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                        Reviewed by Signature
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        id="reviewed_by_signature"
                                        placeholder='Reviewed by Signature'
                                        size="small"
                                        variant="outlined"
                                        value={formik.values.reviewed_by_signature}
                                        disabled={!!member?.terminated_at}
                                        onChange={(e) => {
                                            formik.setFieldValue('reviewed_by_signature', e.target.value)
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.reviewed_by_signature && Boolean(formik.errors.reviewed_by_signature) ? "red" : "green",
                                                },
                                                '&:hover fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.reviewed_by_signature && Boolean(formik.errors.reviewed_by_signature) ? "red" : "green",
                                                },
                                                '&.Mui-focused fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.reviewed_by_signature && Boolean(formik.errors.reviewed_by_signature) ? "red" : "green",
                                                },
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                                fontStyle: 'italic'
                                            }
                                        }}
                                        InputProps={{
                                            style: {
                                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                                fontStyle: 'italic'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                        Date of MVR review
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        id="date_of_mvr_review"
                                        type='date'
                                        size="small"
                                        variant="outlined"
                                        value={formik.values.date_of_mvr_review}
                                        disabled={!!member?.terminated_at}
                                        onChange={(e) => {
                                            formik.setFieldValue('date_of_mvr_review', e.target.value)
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.date_of_mvr_review && Boolean(formik.errors.date_of_mvr_review) ? "red" : "green",
                                                },
                                                '&:hover fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.date_of_mvr_review && Boolean(formik.errors.date_of_mvr_review) ? "red" : "green",
                                                },
                                                '&.Mui-focused fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.date_of_mvr_review && Boolean(formik.errors.date_of_mvr_review) ? "red" : "green",
                                                },
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                            }
                                        }}
                                        InputProps={{
                                            style: {
                                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                        Title
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        id="title"
                                        placeholder='Title'
                                        size="small"
                                        variant="outlined"
                                        value={formik.values.title}
                                        disabled={!!member?.terminated_at}
                                        onChange={(e) => {
                                            formik.setFieldValue('title', e.target.value)
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.title && Boolean(formik.errors.title) ? "red" : "green",
                                                },
                                                '&:hover fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.title && Boolean(formik.errors.title) ? "red" : "green",
                                                },
                                                '&.Mui-focused fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.title && Boolean(formik.errors.title) ? "red" : "green",
                                                },
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                            }
                                        }}
                                        InputProps={{
                                            style: {
                                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                        Company Name
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        id="motor_carrier_name"
                                        placeholder='Company Name'
                                        size="small"
                                        variant="outlined"
                                        value={formik.values.motor_carrier_name}
                                        disabled={!!member?.terminated_at}
                                        onChange={(e) => {
                                            formik.setFieldValue('motor_carrier_name', e.target.value)
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.motor_carrier_name && Boolean(formik.errors.motor_carrier_name) ? "red" : "green",
                                                },
                                                '&:hover fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.motor_carrier_name && Boolean(formik.errors.motor_carrier_name) ? "red" : "green",
                                                },
                                                '&.Mui-focused fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.motor_carrier_name && Boolean(formik.errors.motor_carrier_name) ? "red" : "green",
                                                },
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                            }
                                        }}
                                        InputProps={{
                                            style: {
                                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                        Company Address
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        id="motor_carrier_address"
                                        placeholder='Company Address'
                                        size="small"
                                        variant="outlined"
                                        value={formik.values.motor_carrier_address}
                                        disabled={!!member?.terminated_at}
                                        onChange={(e) => {
                                            formik.setFieldValue('motor_carrier_address', e.target.value)
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.motor_carrier_address && Boolean(formik.errors.motor_carrier_address) ? "red" : "green",
                                                },
                                                '&:hover fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.motor_carrier_address && Boolean(formik.errors.motor_carrier_address) ? "red" : "green",
                                                },
                                                '&.Mui-focused fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.motor_carrier_address && Boolean(formik.errors.motor_carrier_address) ? "red" : "green",
                                                },
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                            }
                                        }}
                                        InputProps={{
                                            style: {
                                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                        City
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        id="city"
                                        placeholder='City'
                                        size="small"
                                        variant="outlined"
                                        value={formik.values.city}
                                        disabled={!!member?.terminated_at}
                                        onChange={(e) => {
                                            formik.setFieldValue('city', e.target.value)
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.city && Boolean(formik.errors.city) ? "red" : "green",
                                                },
                                                '&:hover fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.city && Boolean(formik.errors.city) ? "red" : "green",
                                                },
                                                '&.Mui-focused fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.city && Boolean(formik.errors.city) ? "red" : "green",
                                                },
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                            }
                                        }}
                                        InputProps={{
                                            style: {
                                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                        State / ZIP
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        id="state_zip"
                                        placeholder='State / ZIP'
                                        size="small"
                                        variant="outlined"
                                        value={formik.values.state_zip}
                                        disabled={!!member?.terminated_at}
                                        onChange={(e) => {
                                            formik.setFieldValue('state_zip', e.target.value)
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.state_zip && Boolean(formik.errors.state_zip) ? "red" : "green",
                                                },
                                                '&:hover fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.state_zip && Boolean(formik.errors.state_zip) ? "red" : "green",
                                                },
                                                '&.Mui-focused fieldset': {
                                                    // Check Validation
                                                    borderColor: formik.touched.state_zip && Boolean(formik.errors.state_zip) ? "red" : "green",
                                                },
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                            }
                                        }}
                                        InputProps={{
                                            style: {
                                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',

                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Button fullWidth disabled={isCreating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                                        Save
                                    </Button>
                                </Grid>
                            </>
                        ) : null}
                    </Grid>
                </div>
                {!showForm && !isLoading && data && data.length === 0 ? (
                    <div style={{
                        height: "40vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}>
                        <Typography sx={{ textAlign: "center" }} variant="h5" gutterBottom component="h5">
                            Annual Review of Driving Records are not available at this time.
                        </Typography>
                        <Typography sx={{ textAlign: "center" }} variant="h6" gutterBottom component="h6">
                            When Compliance Masters uploads a driving record, you will be notified when the annual review is available to complete.
                        </Typography>
                    </div>
                ) : null}
                {isLoading ? (
                    <div style={{
                        height: "40vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                    }}>
                        <CircularProgress />
                    </div>
                ) : null}
            </Box>
            <ActionButtons
                member={member}
                prevButton={{
                    title: "Actual Knowledge of DOT D&A Violation",
                    path: "documentation-of-actual-knowledge-of-dot-d-and-a-violation"
                }}
            />
            <div style={{
                width: "47vw",
                marginLeft: "auto",
                marginRight: "auto"
            }}>
                <Button variant="contained" fullWidth={true} sx={{ mt: 3 }} color="primary" onClick={() => {
                    window.location.assign(`/member/${member?.id}/profile/driver-documents`)
                    //  Log Drivers Out
                }}>
                    Done
                </Button>
            </div>
        </Container>
    )
}

export default AnnualReviewDrivingRecords