import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Button, Checkbox, DialogContent, FormControlLabel, Grid } from '@mui/material';
import TermsConditions from './TermsConditions';
import { useThemeMode } from '../../../../compliancemasters/partials';
import { ICompany } from '../../../../models';
import { updateCompany } from '../../../../shared';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    company?: ICompany
}


export const TermsModal = ({ open, setOpen, company }: IProps) => {
    const { mode } = useThemeMode()
    const [checked, setChecked] = React.useState(false)
    React.useEffect(() => {
        if (company && open) {
            setChecked(company.accepted_terms_and_conditions)
        }
    }, [company, open])
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            TransitionComponent={Transition}
            fullScreen
        >
            <AppBar
                sx={{
                    position: 'relative',
                    backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                }}
            >
                {checked && <Toolbar>
                    <IconButton
                        sx={{
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            fontSize: '2.5rem'
                        }}
                        size="large"
                        edge="start"
                        color="inherit"
                        onClick={() => {
                            window.location.reload()
                        }}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>}
            </AppBar>
            <DialogContent sx={{
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
            }}>
                <TermsConditions isModal />
                <Grid container justifyContent="center" sx={{ marginTop: '2rem' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked} />}
                        onClick={async () => {
                            const res = await updateCompany(Number(company?.id), { accepted_terms_and_conditions: !checked })
                            setChecked(res.accepted_terms_and_conditions)
                        }}
                        label="Accept Terms and Conditions"
                    />
                </Grid>
                {checked && <Grid container justifyContent="center" sx={{ marginTop: '2rem' }}>
                    <Button
                        variant="contained"
                        size='small'
                        onClick={() => {
                            window.location.reload()
                        }}
                    >
                        Continue to Dashboard
                    </Button>
                </Grid>}
            </DialogContent>
        </Dialog>
    )
}