import {certificationApi} from './certification'
import {certofComplianceProgress} from './progress'

export const CertificationReducers = {
  [certificationApi.reducerPath]: certificationApi.reducer,
  [certofComplianceProgress.reducerPath]: certofComplianceProgress.reducer,
}

export const CertificationMiddleware = [
  certificationApi.middleware,
  certofComplianceProgress.middleware,
]
