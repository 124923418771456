import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useMutation, } from 'react-query';
import { KTSVG } from '../../compliancemasters/helpers';
import { IQuoteRequest } from '../../models/IQuote';
import { requestQuote } from '../../shared/client/quotes';


const initialValues: IQuoteRequest = {
    companyName: '',
    address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: ''
    },
    phoneNumber: '',
    contactName: '',
    email: '',
    numberOfDrivers: 0
}

const quoteRequestSchema = Yup.object().shape({
    companyName: Yup.string().required('Company Name is required'),
    address: Yup.object().shape({
        addressLine1: Yup.string().required('Address Line is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        zip: Yup.string().required('Zip is required')
    }),
    phoneNumber: Yup.string().required('Phone Number is required'),
    contactName: Yup.string().required('Contact Name is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    numberOfDrivers: Yup.number().min(1).required('Number of Drivers is required')
})

const QuoteModal = ({ email, setEmail }: { email: string, setEmail: (val: string) => void }) => {
    const [requestSent, setRequestSent] = React.useState(false)
    const [loading, setLoading] = React.useState(false);
    const quoteRequestMutation = useMutation(requestQuote, {
        onSuccess: (data) => {
            formik.resetForm()
            setRequestSent(true)
            setEmail("")
        }
    })

    const formik = useFormik({
        initialValues,
        validationSchema: quoteRequestSchema,
        onSubmit: async (values) => {
            setLoading(true);
            quoteRequestMutation.mutate(values);
            setLoading(false);
        }
    });

    React.useEffect(() => {
        const showForms = setTimeout(() => {
            setRequestSent(false)
        }, 5000)

        return () => clearTimeout(showForms)
    }, [requestSent])

    React.useEffect(() => {
        formik.setFieldValue('email', email)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email])


    return (
        <div className="modal fade" id="modal_quote_form" role="dialog" style={{ zIndex: 9999999 }} tabIndex={-1} aria-modal="true">
            <div className="modal-dialog modal-dialog-centered mw-650px">
                <div className="modal-content">
                    <div className="modal-header">
                        {/* begin::modal title */}
                        <h2 className="fw-bolder">Request a Quote</h2>
                        {/* end::modal title */}

                        {/* begin::Close */}
                        <div
                            className="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            style={{ cursor: 'pointer' }}
                        >
                            <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
                        </div>
                        {/* end::Close */}
                    </div>
                    <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                        {!requestSent && <form onSubmit={formik.handleSubmit} className="form row" id="kt_modal_quote_form">
                            <div className="fv-row mb-7">
                                <label className="form-label fw-bolder text-dark fs-6">Company Name</label>
                                <input
                                    placeholder="Company Name"
                                    {...formik.getFieldProps('companyName')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {
                                            'is-invalid': formik.touched.companyName && formik.errors.companyName
                                        },
                                        {
                                            'is-valid': formik.touched.companyName && !formik.errors.companyName
                                        }
                                    )}
                                    type="text"
                                    name="companyName"
                                    autoComplete="off"
                                />
                                {formik.touched.companyName && formik.errors.companyName && (
                                    <div className="fv-plugins-message-container">
                                        <span role="alert">{formik.errors.companyName}</span>
                                    </div>
                                )}
                            </div>
                            <div className="fv-row mb-7">
                                <label className="form-label fw-bolder text-dark fs-6">Address Line 1</label>
                                <input
                                    placeholder="Address Line 1"
                                    {...formik.getFieldProps('address.addressLine1')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {
                                            'is-invalid': formik.touched.address?.addressLine1 && formik.errors.address?.addressLine1
                                        },
                                        {
                                            'is-valid': formik.touched.address?.addressLine1 && !formik.errors.address?.addressLine1
                                        }
                                    )}
                                    type="text"
                                    name="address.addressLine1"
                                    autoComplete="off"
                                />
                                {formik.touched.address?.addressLine1 && formik.errors.address?.addressLine1 && (
                                    <div className="fv-plugins-message-container">
                                        <span role="alert">{formik.errors.address?.addressLine1}</span>
                                    </div>
                                )}
                            </div>
                            <div className="fv-row mb-7 col-md-6 col-lg-6">
                                <label className="form-label fw-bolder text-dark fs-6">City</label>
                                <input
                                    placeholder="City"
                                    {...formik.getFieldProps('address.city')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {
                                            'is-invalid': formik.touched.address?.city && formik.errors.address?.city
                                        },
                                        {
                                            'is-valid': formik.touched.address?.city && !formik.errors.address?.city
                                        }
                                    )}
                                    type="text"
                                    name="address.city"
                                    autoComplete="off"
                                />
                                {formik.touched.address?.city && formik.errors.address?.city && (
                                    <div className="fv-plugins-message-container">
                                        <span role="alert">{formik.errors.address?.city}</span>
                                    </div>
                                )}
                            </div>
                            <div className="fv-row mb-7 col-md-6 col-lg-6">
                                <label className="form-label fw-bolder text-dark fs-6">State</label>
                                <input
                                    placeholder="State"
                                    {...formik.getFieldProps('address.state')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {
                                            'is-invalid': formik.touched.address?.state && formik.errors.address?.state
                                        },
                                        {
                                            'is-valid': formik.touched.address?.state && !formik.errors.address?.state
                                        }
                                    )}
                                    type="text"
                                    name="address.state"
                                    autoComplete="off"
                                />
                                {formik.touched.address?.state && formik.errors.address?.state && (
                                    <div className="fv-plugins-message-container">
                                        <span role="alert">{formik.errors.address?.state}</span>
                                    </div>
                                )}
                            </div>
                            <div className="fv-row mb-7 col-md-6 col-lg-6">
                                <label className="form-label fw-bolder text-dark fs-6">Zip Code</label>
                                <input
                                    placeholder="Zip Code"
                                    {...formik.getFieldProps('address.zip')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {
                                            'is-invalid': formik.touched.address?.zip && formik.errors.address?.zip
                                        },
                                        {
                                            'is-valid': formik.touched.address?.zip && !formik.errors.address?.zip
                                        }
                                    )}
                                    type="text"
                                    name="address.zip"
                                    autoComplete="off"
                                />
                                {formik.touched.address?.zip && formik.errors.address?.zip && (
                                    <div className="fv-plugins-message-container">
                                        <span role="alert">{formik.errors.address?.zip}</span>
                                    </div>
                                )}
                            </div>
                            <div className="fv-row mb-7 col-md-6 col-lg-6">
                                <label className="form-label fw-bolder text-dark fs-6">Phone Number</label>
                                <input
                                    placeholder="Phone Number"
                                    {...formik.getFieldProps('phoneNumber')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {
                                            'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber
                                        },
                                        {
                                            'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber
                                        }
                                    )}
                                    type="text"
                                    name="phoneNumber"
                                    autoComplete="off"
                                />
                                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                                    <div className="fv-plugins-message-container">
                                        <span role="alert">{formik.errors.phoneNumber}</span>
                                    </div>
                                )}
                            </div>
                            <div className="fv-row mb-7 col-md-6 col-lg-6">
                                <label className="form-label fw-bolder text-dark fs-6">Contact Name</label>
                                <input
                                    placeholder="Contact Name"
                                    {...formik.getFieldProps('contactName')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {
                                            'is-invalid': formik.touched.contactName && formik.errors.contactName
                                        },
                                        {
                                            'is-valid': formik.touched.contactName && !formik.errors.contactName
                                        }
                                    )}
                                    type="text"
                                    name="contactName"
                                    autoComplete="off"
                                />
                                {formik.touched.contactName && formik.errors.contactName && (
                                    <div className="fv-plugins-message-container">
                                        <span role="alert">{formik.errors.contactName}</span>
                                    </div>
                                )}
                            </div>
                            <div className="fv-row mb-7 col-md-6 col-lg-6">
                                <label className="form-label fw-bolder text-dark fs-6">Email</label>
                                <input
                                    placeholder="Email"
                                    {...formik.getFieldProps('email')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {
                                            'is-invalid': formik.touched.email && formik.errors.email
                                        },
                                        {
                                            'is-valid': formik.touched.email && !formik.errors.email
                                        }
                                    )}
                                    type="email"
                                    name="email"
                                    autoComplete="off"
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className="fv-plugins-message-container">
                                        <span role="alert">{formik.errors.email}</span>
                                    </div>
                                )}
                            </div>
                            <div className="fv-row mb-7 col-md-12 col-lg-12">
                                <label className="form-label fw-bolder text-dark fs-6">Number of Drivers</label>
                                <input
                                    placeholder="Number of Drivers"
                                    {...formik.getFieldProps('numberOfDrivers')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {
                                            'is-invalid': formik.touched.numberOfDrivers && formik.errors.numberOfDrivers
                                        },
                                        {
                                            'is-valid': formik.touched.numberOfDrivers && !formik.errors.numberOfDrivers
                                        }
                                    )}
                                    type="text"
                                    name="numberOfDrivers"
                                    autoComplete="off"
                                />
                                {formik.touched.numberOfDrivers && formik.errors.numberOfDrivers && (
                                    <div className="fv-plugins-message-container">
                                        <span role="alert">{formik.errors.numberOfDrivers}</span>
                                    </div>
                                )}
                            </div>
                            <div className="text-center">
                                <button
                                    type="submit"
                                    id="kt_modal_quote_form"
                                    className="btn btn-success btn-md w-100 mb-5"
                                    disabled={loading}
                                    // data-bs-dismiss="modal"
                                    onClick={(e) => {
                                        console.log(formik.values);
                                    }}
                                >
                                    <span className="indicator-label">Submit</span>
                                </button>
                            </div>
                        </form>}
                        {requestSent && (
                            <h3 className='text-center'>
                                Your information has been submitted
                            </h3>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuoteModal;
