import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers'
import {
  IAffidavit,
  IAffidavitDto,
  IWitnessAndOwnerSignature,
  IWitnessAndOwnerSignatureDto,
} from '../../interfaces/DocumentationofActualKnowledgeofDOTDAndAViolation/witness-and-owner-signature'

export const witnessAndOwnerSignatureApi = createApi({
  reducerPath: 'witness_and_owner_signature',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/witness-and-owner-signature`,
  }),
  tagTypes: ['WitnessAndOwnerSignature'],
  endpoints: (builder) => ({
    createWitnessAndOwnerSignature: builder.mutation<
      IWitnessAndOwnerSignature,
      IWitnessAndOwnerSignatureDto
    >({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'WitnessAndOwnerSignature', id: 'WitnessAndOwnerSignature'}],
    }),
    updateWitnessAndOwnerSignature: builder.mutation<
      IWitnessAndOwnerSignature,
      IWitnessAndOwnerSignature
    >({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'WitnessAndOwnerSignature', id: 'WitnessAndOwnerSignature'}],
    }),
    getWitnessAndOwnerSignature: builder.query<IWitnessAndOwnerSignature[], number | void>({
      query: (id) => ({
        url: '/get-witness-and-owner-signature/',
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'WitnessAndOwnerSignature', id: 'WitnessAndOwnerSignature'}],
    }),
    getWitnessAndOwnerSignatureByUser: builder.query<IWitnessAndOwnerSignature[], void>({
      query: () => ({
        url: '/get-witness-and-owner-signature-by-user/',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'WitnessAndOwnerSignature', id: 'WitnessAndOwnerSignature'}],
    }),
    getWitnessAndOwnerSignatureByUserId: builder.query<IWitnessAndOwnerSignature[], number>({
      query: (id) => ({
        url: '/get-witness-and-owner-signature-by-user-id/',
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'WitnessAndOwnerSignature', id: 'WitnessAndOwnerSignature'}],
    }),
    deleteWitnessAndOwnerSignature: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'WitnessAndOwnerSignature', id: 'WitnessAndOwnerSignature'}],
    }),
  }),
})

export const affidavitApi = createApi({
  reducerPath: 'affidavit',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/affidavit`,
  }),
  tagTypes: ['Affidavit'],
  endpoints: (builder) => ({
    createAffidavit: builder.mutation<IAffidavit, IAffidavitDto>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'Affidavit', id: 'Affidavit'}],
    }),
    updateAffidavit: builder.mutation<IAffidavit, IAffidavit>({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'Affidavit', id: 'Affidavit'}],
    }),
    getAffidavit: builder.query<IAffidavit, number | void>({
      query: (id) => ({
        url: '/get-affidavit/',
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'Affidavit', id: 'Affidavit'}],
    }),
    getAffidavitByUser: builder.query<IAffidavit, void>({
      query: () => ({
        url: '/get-affidavit-by-user/',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'Affidavit', id: 'Affidavit'}],
    }),
    getAffidavitByUserId: builder.query<IAffidavit, number>({
      query: (id) => ({
        url: '/get-affidavit-by-user-id/',
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'Affidavit', id: 'Affidavit'}],
    }),
  }),
})

export const {
  useCreateWitnessAndOwnerSignatureMutation,
  useUpdateWitnessAndOwnerSignatureMutation,
  useGetWitnessAndOwnerSignatureQuery,
  useGetWitnessAndOwnerSignatureByUserQuery,
  useGetWitnessAndOwnerSignatureByUserIdQuery,
  useDeleteWitnessAndOwnerSignatureMutation,
} = witnessAndOwnerSignatureApi
export const {
  useCreateAffidavitMutation,
  useUpdateAffidavitMutation,
  useGetAffidavitQuery,
  useGetAffidavitByUserQuery,
  useGetAffidavitByUserIdQuery,
} = affidavitApi
