import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    ICouplingAndUncoupling,
    ICouplingAndUncouplingDto
} from '../../interfaces/RecordOfRoadTest/coupling-and-uncoupling';

export const couplingAndUncouplingApi = createApi({
    reducerPath: 'coupling_and_uncoupling',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/coupling-and-uncoupling`
    }),
    tagTypes: ['CouplingAndUncoupling'],
    endpoints: (builder) => ({
        createCouplingAndUncoupling: builder.mutation<ICouplingAndUncoupling, ICouplingAndUncouplingDto>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'CouplingAndUncoupling', id: 'CouplingAndUncoupling' }]
        }),
        updateCouplingAndUncoupling: builder.mutation<ICouplingAndUncoupling, ICouplingAndUncoupling>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'CouplingAndUncoupling', id: 'CouplingAndUncoupling' }]
        }),
        deleteCouplingAndUncoupling: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'CouplingAndUncoupling', id: 'CouplingAndUncoupling' }]
        }),
        getCouplingAndUncoupling: builder.query<ICouplingAndUncoupling, number | void>({
            query: (id) => ({
                url: `/get-coupling-and-uncoupling/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'CouplingAndUncoupling', id: 'CouplingAndUncoupling' }]
        }),
        getCouplingAndUncouplingByUser: builder.query<ICouplingAndUncoupling, void>({
            query: () => ({
                url: `/get-coupling-and-uncoupling-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'CouplingAndUncoupling', id: 'CouplingAndUncoupling' }]
        }),
        getCouplingAndUncouplingByUserId: builder.query<ICouplingAndUncoupling, number | void>({
            query: (id) => ({
                url: `/get-coupling-and-uncoupling-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'CouplingAndUncoupling', id: 'CouplingAndUncoupling' }]
        })
    })
});

export const {
    useCreateCouplingAndUncouplingMutation,
    useUpdateCouplingAndUncouplingMutation,
    useGetCouplingAndUncouplingQuery,
    useGetCouplingAndUncouplingByUserQuery,
    useGetCouplingAndUncouplingByUserIdQuery,
    useDeleteCouplingAndUncouplingMutation
} = couplingAndUncouplingApi;
