import React from 'react'
import { useParams } from 'react-router-dom';
import { IMember } from '../../../models';
import { getCompanyMember } from '../../../shared';
import { Box, Button, Container, Paper } from '@mui/material';
import FormHeaderCard from '../HeaderCard';
import DriverInformation from './DriverInformation';
import PreTripInspectionAndEmergencyEquipemnt from './PreTripInspectionAndEmergencyEquipemnt';
import CouplingAndUncoupling from './CouplingAndUncoupling';
import PlacingVehicleInMotion from './PlacingVehicleInMotion';
import { BackingAndParking } from './BackingAndParking';
import { SlowingAndStopping } from './SlowingAndStopping';
import { OperatingInTrafficPassingAndTurning } from './OperatingInTrafficPassingAndTurning';
import { Miscellaneous } from './Miscellaneous';
import { PerformanceRatingAndOwnerSignature } from './PerformanceRatingAndOwnerSignature';
import ActionButtons from '../ActionButtons';
import { useGetMemberRecordOfRoadTestProgressMutation, useGetRecordOfRoadTestProgressMutation } from '../../../store/reducers/RecordOfRoadTest/progress';
import { useThemeMode } from '../../partials';
import { useAuth } from '../../../app/modules/auth';

const RecordOfRoadTestComponent = () => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const [getProgress, { data: progress }] = useGetRecordOfRoadTestProgressMutation()
    const [getMembberProgress, { data: memberProgress }] = useGetMemberRecordOfRoadTestProgressMutation()
    const [member, setMember] = React.useState<IMember>()
    const { id } = useParams()
    const [value, setValue] = React.useState(0);


    React.useEffect(() => {
        if (id) {
            getCompanyMember(id).then((data) => {
                setMember(data)
            })
            getProgress(Number(id))
            getMembberProgress(Number(id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container style={{ userSelect: 'none' }}>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <FormHeaderCard
                    headerTitle='Record of Road Test'
                    status={progress && (progress?.progress > 0 && progress?.progress < 100) ? "In Progress" : progress && progress.progress > 99 ? "Completed" : "Not Started"}
                    sectionCount={4}
                    itemCount={29}
                    totalProgress={progress ? progress.progress : 0}
                    memberProgress={memberProgress ? memberProgress.progress : 0}
                    nextButton={{ title: "Certificate of Road Test", path: "" }}
                    member={member}
                    setValue={setValue}
                    value={value}
                />
            </Box>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <DriverInformation member={member} />
            </Box>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <PreTripInspectionAndEmergencyEquipemnt member={member} />
            </Box>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <CouplingAndUncoupling member={member} />
            </Box>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <PlacingVehicleInMotion member={member} />
            </Box>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <BackingAndParking member={member} />
            </Box>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <SlowingAndStopping member={member} />
            </Box>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <OperatingInTrafficPassingAndTurning member={member} />
            </Box>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <Miscellaneous member={member} />
            </Box>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <PerformanceRatingAndOwnerSignature member={member} />
            </Box>
            <ActionButtons
                member={member}
                nextButton={{ title: "Certificate of Road Test", path: "certificate-of-road-test" }}
                prevButton={{
                    title: "Drivers Handbook Receipt",
                    path: "driver-handbook-receipt"
                }}
            />
            <div style={{
                display: "flex",
                width: "47vw",
                justifyContent: "center",
                marginLeft: 'auto',
                marginRight: 'auto',
            }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (currentUser?.role === "Member") {
                            window.location.assign(`/dashboard/overview`)
                        } else {

                            if (member?.id) {
                                window.location.assign(`/member/${member?.id}/profile/driver-documents`)
                            }
                        }
                    }}
                    fullWidth
                >Back to Forms Overview</Button>
            </div>
        </Container>
    )
}

export default RecordOfRoadTestComponent