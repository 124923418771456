import * as authHelper from '../app/modules/auth/core/AuthHelpers'

interface Request {
  body?: {[key: string]: any}
  method?: string
  headers?: {[key: string]: any}
  token?: string
}

function addTrailingSlash(endpoint: string) {
  if (endpoint.includes('?')) {
    return endpoint
  } else if (endpoint.charAt(endpoint.length - 1) !== '/') {
    return `${endpoint}${'/'}`
  }
  return endpoint
}

function makeRequest(
  endpoint: string,
  {body, token, ...customConfig}: Request = {},
  stringify: boolean,
  method?: string
) {
  const finalToken = token ?? authHelper.getAuth()?.api_token ?? null

  const headers: {[key: string]: any} = !stringify ? {} : {'Content-Type': 'application/json'}

  if (finalToken) {
    headers.Authorization = `Bearer ${finalToken}`
  }

  const config: {[key: string]: any} = {
    method: method ? method : body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }

  if (body && stringify) {
    config.body = JSON.stringify(body)
  } else {
    config.body = body
  }
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/${endpoint}`, config)
}

async function client(
  endpoint: string,
  {body, ...customConfig}: Request = {},
  stringify: boolean = true,
  method?: string
) {
  let updatedEndpoint = addTrailingSlash(endpoint)
  const response = await makeRequest(updatedEndpoint, {body, ...customConfig}, stringify, method)
  const text = await response.text()
  try {
    const data = text ? JSON.parse(text) : null

    if (response.ok) {
      return data
    } else if (response.status === 401) {
      // logout();
    } else {
      return Promise.reject(data)
    }
  } catch (error) {
    throw error
  }
}

export default client
