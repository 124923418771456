import React from 'react'
import { IMember } from '../../../../models';
import { StripeProduct } from '../../models';
import TableWrapper from '../../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import TableContainerHeader from '../../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableContainerHeaderTitle from '../../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import TableContainer from '../../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableHeader from '../../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import DriverReleaseFormDrivingRecords from './DriverReleaseForm';
import DriverReleaseFormBackgroundChecks from './ReleaseFormBackgroundChecks';
import DisclosureFormPSP from './DisclosureFormPSP';

function shouldShowDisclosure(member?: IMember) {
    return !member?.pre_employment_screened //|| (member?.pre_employment_investigation.length > 0 && member?.pre_employment_screened);
}

const ReleaseForms = ({ member, subscription }: { member?: IMember; subscription?: StripeProduct }) => {

    return (
        <TableWrapper>
            <TableContainerHeader>
                <TableContainerHeaderTitle title="Release Forms" />
            </TableContainerHeader>
            <TableContainer>
                <TableHeader>
                    <TableHeaderColumn>Form</TableHeaderColumn>
                    <TableHeaderColumn>Progress</TableHeaderColumn>
                    <TableHeaderColumn>Status</TableHeaderColumn>
                    <TableHeaderColumn>{""}</TableHeaderColumn>
                    <TableHeaderColumn>Actions</TableHeaderColumn>
                </TableHeader>
                <tbody>

                    <DriverReleaseFormDrivingRecords id={Number(member?.id)} />
                    <DriverReleaseFormBackgroundChecks id={Number(member?.id)} />
                    {shouldShowDisclosure(member) ? <DisclosureFormPSP id={Number(member?.id)} /> : null}
                </tbody>
            </TableContainer>
        </TableWrapper>
    )
}

export default ReleaseForms