import { useEffect, useState } from 'react';
import { getCompanyMember } from '../../../../shared';
import { IMember } from '../../../../models/IMember';
import MemberProfileHeader from './MemberProfileHeader';
import CompanyMemberPageLinkRow from './components/CompanyMemberPageLinkRow';
import { useParams } from 'react-router-dom';
import { StripeProduct } from '../../models';

interface IProps {
    memberSubscription?: StripeProduct
}

export default function CompanyMemberProfileHeader({ memberSubscription }: IProps) {
    const { memberId } = useParams();
    const [member, setMember] = useState<IMember>();

    useEffect(() => {
        if (memberId) {
            handleGetMember();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleGetMember() {
        return getCompanyMember(memberId!).then((member) => {
            setMember(member);
        });
    }

    return (
        <>
            <MemberProfileHeader
                member={member}
                LinkRow={CompanyMemberPageLinkRow}
                onRefresh={handleGetMember}
                memberSubscription={memberSubscription}
            />
        </>
    )
}
