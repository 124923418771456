import React from 'react'
import { IMember } from '../../../models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material'
import { IDriverEducationDto, EducationLevel, } from '../../../store/interfaces/EmploymentHistory/driver-education'
import {
    useCreateDriverEducationMutation,
    useUpdateDriverEducationMutation,
    useGetDriverEducationByUserIdQuery,
    useDeleteDriversEducationMutation
} from '../../../store/reducers/EmploymentHistory/drivers-education'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'

const initialValues: Partial<IDriverEducationDto> = {
    education_level: EducationLevel.COLLEGE_1,
    school_name: '',
    school_location: '',
}

const validationSchema = Yup.object({
    education_level: Yup.string().required('Required'),
    school_name: Yup.string().required('Required'),
    school_location: Yup.string().required('Required'),
})


const Education = ({ member, isDriverFile }: { member?: IMember, isDriverFile?: boolean }) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data } = useGetDriverEducationByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })
    const [createDriverEducation, { isLoading: isCreating }] = useCreateDriverEducationMutation()
    const [updateDriverEducation, { isLoading: isUpdating }] = useUpdateDriverEducationMutation()
    const [deleteDriverEducation] = useDeleteDriversEducationMutation()

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                await updateDriverEducation({
                    // @ts-ignore
                    ...values,
                    // @ts-ignore
                    id: data.id,
                })
            } else {
                await createDriverEducation({
                    ...values,
                    // @ts-ignore
                    member: member?.id,
                    // @ts-ignore
                    company: member?.company?.id
                })
            }
            resetForm()
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate: (values) => {
            const errors: Partial<IDriverEducationDto> = {}
            if (!values.education_level) {
                // @ts-ignore
                errors.education_level = 'Required'
            }
            if (!values.school_name) {
                errors.school_name = 'Required'
            }
            if (!values.school_location) {
                errors.school_location = 'Required'
            }
            return errors
        }
    })

    React.useEffect(() => {
        if (data) {
            formik.setValues({
                education_level: data.education_level,
                school_name: data.school_name,
                school_location: data.school_location,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                Education
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Select your highest level of education
                    </Typography>
                    <TextField
                        fullWidth
                        id="education_level"
                        size="small"
                        variant="outlined"
                        value={formik.values.education_level}
                        onChange={(e) => {
                            formik.setFieldValue('education_level', e.target.value)
                        }}
                        select
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.education_level && Boolean(formik.errors.education_level) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.education_level && Boolean(formik.errors.education_level) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.education_level && Boolean(formik.errors.education_level) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(EducationLevel).map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Name of last school attended
                    </Typography>
                    <TextField
                        fullWidth
                        id="school_name"
                        size="small"
                        variant="outlined"
                        value={formik.values.school_name}
                        onChange={(e) => {
                            formik.setFieldValue('school_name', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.school_name && Boolean(formik.errors.school_name) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.school_name && Boolean(formik.errors.school_name) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.school_name && Boolean(formik.errors.school_name) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        City / State of last school attended
                    </Typography>
                    <TextField
                        fullWidth
                        id="school_location"
                        placeholder='City / State'
                        size="small"
                        variant="outlined"
                        value={formik.values.school_location}
                        onChange={(e) => {
                            formik.setFieldValue('school_location', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.school_location && Boolean(formik.errors.school_location) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.school_location && Boolean(formik.errors.school_location) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.school_location && Boolean(formik.errors.school_location) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                {(!data || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role))) && !isDriverFile ? <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={isCreating || isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                        Save
                    </Button>
                    {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                        <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                            Once you click save, you will not be able to edit this form.
                        </Typography>
                    )}
                </Grid > : null}
                {data && currentUser?.role === "SuperAdmin" && <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={!!member?.terminated_at} onClick={() => {
                        const res = window.confirm("Are you sure you want to delete this form?")
                        if (res && data) {
                            deleteDriverEducation(data.id)
                        }
                    }} variant="contained" color="primary">
                        Delete Form
                    </Button>
                </Grid>}
            </Grid >
        </div >
    )
}

export default Education
