import clsx from 'clsx';
import React, { FC } from 'react';
import {
    toAbsoluteUrl
} from '../../../helpers';
import { HeaderUserMenu, } from '../../../partials';
import NotificationHeader from './components/NotificationHeader/NotificationHeader';
import { useCompany } from '../../../../context/CompanyContext';
import { useAuth } from '../../../../app/modules/auth';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
    toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';

const Topbar: FC = () => {
    const { currentCompany } = useCompany()
    const { currentUser } = useAuth()
    return (
        <div className="d-flex align-items-stretch flex-shrink-0">
            {currentCompany && currentUser && ["Owner", "Member"].includes(currentUser?.role) && (
                <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                    {currentCompany.name}
                </div>
            )}
            <NotificationHeader />

            {/* begin::Theme mode */}
            {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
                <ThemeModeSwitcher
                    toggleBtnClass={clsx('btn-active-light-primary btn-custom', toolbarButtonHeightClass)}
                />
            </div> */}
            {/* end::Theme mode */}

            {/* begin::User */}
            <div
                className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
                id="kt_header_user_menu_toggle"
            >
                {/* begin::Toggle */}
                <div
                    className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
                    data-kt-menu-trigger="click"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                    data-kt-menu-flip="bottom"
                >
                    <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt="metronic" />
                </div>
                <HeaderUserMenu />
            </div>
        </div>
    );
};

export { Topbar };
