import { Container, Typography } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../modules/auth'

const CompleteDriverFile = () => {
    const { logout } = useAuth()
    //  Get Company Name from URL
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const companyName = searchParams.get('company');

    React.useEffect(() => {
        logout()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "90vh",
                flexDirection: "column"
            }}>
                <img src="/media/logos/compliance-01.png" alt="Compliance Masters Logo" style={{
                    width: 200,
                    height: 200
                }} />
                <Typography sx={{ mr: 5 }} variant="h4" gutterBottom component="h4" align="center">
                    Thank you for completing your driver file with Compliance Masters for {companyName}.
                    Your work here is done and you won’t need to log back into your account again.
                    Remember, your safety is always first! We wish you the best.
                </Typography>
            </div>
        </Container>
    )
}

export default CompleteDriverFile