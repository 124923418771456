import {driverAuthorizationAndSignatureApi} from './driver-authorization-and-signature'
import {limitedQueriesDachProgress} from './progress'

export const LimitedQueriesDrugAndAlcoholClearinghouseReducers = {
  [driverAuthorizationAndSignatureApi.reducerPath]: driverAuthorizationAndSignatureApi.reducer,
  [limitedQueriesDachProgress.reducerPath]: limitedQueriesDachProgress.reducer,
}

export const LimitedQueriesDrugAndAlcoholClearinghouseMiddleware = [
  driverAuthorizationAndSignatureApi.middleware,
  limitedQueriesDachProgress.middleware,
]
