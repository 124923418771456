import React from 'react'
import { useParams } from 'react-router-dom';
import { IMember } from '../../../models';
import { getCompanyMember } from '../../../shared';
import { Box, Button, Container, Paper } from '@mui/material';
import FormHeaderCard from '../HeaderCard';
import ProvidedEducationalMaterials from './ProvidedEducationalMaterials';
import DriversSignature from './DriversSignature';
import { AuthorizedEmployerRepresentativeSignature } from './AuthorizedEmployerRepresentativeSignature';
import ActionButtons from '../ActionButtons';
import { useGetDachEmployeesCertifiedReceiptProgressMutation, useGetMemberDachEmployeesCertifiedReceiptProgressMutation } from '../../../store/reducers/DrugandAlcoholEmployeesCertifiedReceipt/progress';
import { useAuth } from '../../../app/modules/auth';
import { useThemeMode } from '../../partials';

const DachCertifiedReceipt = () => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const [getProgress, { data: progress }] = useGetDachEmployeesCertifiedReceiptProgressMutation()
    const [getMemberProgress, { data: memberProgress }] = useGetMemberDachEmployeesCertifiedReceiptProgressMutation()
    const [member, setMember] = React.useState<IMember>()
    const { id } = useParams()
    const [value, setValue] = React.useState(0);


    React.useEffect(() => {
        if (id) {
            getCompanyMember(id).then((data) => {
                setMember(data)
            })
            getProgress(Number(id))
            getMemberProgress(Number(id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Container style={{ userSelect: 'none' }}>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <FormHeaderCard
                    headerTitle='Drug and Alcohol Employees Certified Receipt'
                    status={progress && (progress?.progress > 0 && progress?.progress < 100) ? "In Progress" : progress && progress.progress > 99 ? "Completed" : "Not Started"}
                    sectionCount={4}
                    itemCount={29}
                    totalProgress={progress ? progress.progress : 0}
                    memberProgress={memberProgress ? memberProgress.progress : 0}
                    nextButton={{
                        title: currentUser && currentUser.role === "Member" ? "" : "",
                        path: currentUser && currentUser.role === "Member" ? "" : ""
                    }}
                    member={member}
                    setValue={setValue}
                    value={value}
                />
            </Box>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <ProvidedEducationalMaterials member={member} />
            </Box>
            <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <DriversSignature member={member} />
            </Box>
            {currentUser && currentUser.role !== "Member" && <Box sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === "dark" ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: "47vw",
                    md: "70vw",
                    sm: "80vw",
                },
                marginLeft: 'auto',
                marginRight: 'auto',
            }} component={Paper}>
                <AuthorizedEmployerRepresentativeSignature member={member} />
            </Box>}
            <ActionButtons
                member={member}
                nextButton={{
                    title: currentUser && currentUser.role === "Member" ? "" : "Actual Knowledge of DOT D&A Violation",
                    path: currentUser && currentUser.role === "Member" ? "" : "documentation-of-actual-knowledge-of-dot-d-and-a-violation"
                }}
                prevButton={{ title: "Previous Pre-employment employee drug and alcohol test statement", path: "previous-pre-employment-employee-drug-and-alcohol-test-statement" }}
            />
            {currentUser?.role === "Member" ? (
                <div style={{
                    display: "flex",
                    width: "47vw",
                    justifyContent: "space-between",
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 10
                }}>
                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        size="small"
                        sx={{
                            width: {
                                lg: "47vw",
                                md: "70vw",
                                sm: "80vw",
                            },
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            m: 1
                        }}
                        onClick={() => {
                            if (member?.id) {
                                window.location.assign(`/dashboard/overview`)
                            }
                        }}>
                        Back to forms overview
                    </Button>
                </div>
            ) : null}
        </Container>
    )
}

export default DachCertifiedReceipt