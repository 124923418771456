import { KTSVG } from '../../../../../helpers';
import { IFile } from '../../../../../../models';
import { downloadFile, sendReminder } from '../../../../../../shared';
import Button from '../../../../../../app/modules/buttons/Button';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

interface IProps {
    file: IFile;
    expanded?: boolean;
    showDelete?: boolean;
    showReminder?: boolean;
    setFile: (file: IFile) => void;
    location?: string;
}

const Row = ({ file, props: { setFile, showDelete }, showReminder, }: { file: IFile; props: { showDelete?: boolean; setFile: (val: IFile) => void; }; showReminder?: boolean; }) => {
    const { category, expires_at, location, id, description, quarter, year } = file
    function getDate(date?: Date) {
        if (!date) return '';

        const utcDate = new Date(date)

        const offsetMinutes = utcDate.getTimezoneOffset();
        utcDate.setMinutes(utcDate.getMinutes() + offsetMinutes);

        // Format the date to "YYYY-MM-DD"
        const adjustedYear = utcDate.getFullYear();
        const adjustedMonth = String(utcDate.getMonth() + 1).padStart(2, '0');
        const adjustedDay = String(utcDate.getDate()).padStart(2, '0');
        const expiration = `${adjustedMonth}-${adjustedDay}-${adjustedYear}`;

        // if date is in past
        // if (utcDate < new Date()) {
        //     return 'Expired';
        // }

        return expiration;
    }

    function getYear(date?: string) {
        if (!date) return
        const yearValue = new Date(date).getFullYear()
        return yearValue
    }

    function handleDownloadFile() {
        downloadFile(id).then((res) => {
            window.open(res.url, '_blank');
        });
    }

    return (
        <tr>
            <td>
                <span className="text-dark fw-bold text-hover-primary mb-1 fs-6 cursor-pointer"
                    onClick={handleDownloadFile}
                >
                    {category}
                </span>
            </td>
            <td>
                <span className="text-dark fw-bold mb-1 fs-6">{description ? description : "N/A"}</span>
            </td>
            <td>
                <span className="text-dark fw-bold mb-1 fs-6">{expires_at ? getDate(expires_at) : "N/A"}</span>
            </td>
            <td>
                <span className="text-dark fw-bold mb-1 fs-6">{location ? `${location} file` : ''}</span>
            </td>
            {location === "Drug" && (<>
                <td>
                    <span className="text-dark fw-bold mb-1 fs-6">{quarter ? `${quarter}` : ''}</span>
                </td>
                <td>
                    <span className="text-dark fw-bold mb-1 fs-6">{year ? `${getYear(year)}` : 'N/A'}</span>
                </td>
            </>)}
            <td className="text-end">
                <div
                    className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                    onClick={handleDownloadFile}
                >
                    <KTSVG path="/media/icons/duotune/arrows/arr004.svg" className="svg-icon-2" />
                </div>
            </td>
            <td className="text-end">
                {showDelete ? (
                    <Button
                        className={'btn btn-icon btn-active-color-danger'}
                        dataBsTarget={`#delete_file_modal_${file.id}`}
                        dataBsToggle={'modal'}
                        onClick={() => setFile(file)}
                    >
                        <KTSVG path="/media/icons/duotune/general/gen027.svg" className="svg-icon-3" />
                    </Button>
                ) : null}
            </td>
            <td className="text-end">
                {showReminder && location === 'Non-Current' ? (
                    <Button
                        className={'btn btn-sm btn-icon btn-active-color-danger'}
                        onClick={() => {
                            // Remind Company
                            sendReminder(file).then(() => {
                                toast.success('Reminder sent')
                            }).catch(err => {
                                console.error(err)
                                toast.error('Failed to send reminder')
                            })
                        }}
                    >
                        Send Reminder
                    </Button>
                ) : null}
            </td>
        </tr>
    );
};

export default function FileRow({ file, showDelete, setFile }: IProps) {
    const [showReminder, setShowReminder] = useState(false)
    useEffect(() => {
        // Check if the file is expired then set the reminder to true
        if (file.expires_at && new Date(file.expires_at) < new Date() && ["Medical Card", "Drivers License (front)"].includes(file.category!)) {
            setShowReminder(true)
        }
    }, [file])

    return <Row file={file} props={{ setFile, showDelete }} showReminder={showReminder} />
}
