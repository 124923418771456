import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {StripeProduct} from '../../../app/modules/models'
import {ICompanyProduct} from '../../../app/modules/subscription'

export interface StripeState {
  subscription: {
    subscriptions: ICompanyProduct[]
    subscriptionsLoading: boolean
  }
  companyAddons: {
    companyAddon: ICompanyProduct[]
    companyAddonLoading: boolean
  }
  product: {
    products: StripeProduct[]
    productsLoading: boolean
  }
  oneTimeProduct: {
    oneTimeProducts: StripeProduct[]
    oneTimeProductsLoading: boolean
  }
  preEmploymentProducts: {
    preEmploymentProducts: StripeProduct[]
    preEmploymentProductsLoading: boolean
  }
  memberRequestProducts: {
    memberRequestProducts: StripeProduct[]
    memberRequestProductsLoading: boolean
  }
}

const initialState: StripeState = {
  subscription: {
    subscriptions: [],
    subscriptionsLoading: true,
  },
  product: {
    products: [],
    productsLoading: true,
  },
  oneTimeProduct: {
    oneTimeProducts: [],
    oneTimeProductsLoading: true,
  },
  companyAddons: {
    companyAddon: [],
    companyAddonLoading: true,
  },
  preEmploymentProducts: {
    preEmploymentProducts: [],
    preEmploymentProductsLoading: true,
  },
  memberRequestProducts: {
    memberRequestProducts: [],
    memberRequestProductsLoading: true,
  },
}

export const stripeSlice = createSlice({
  name: 'stripe_reducer',
  initialState,
  reducers: {
    setSubscriptions: (state, action: PayloadAction<ICompanyProduct[]>) => {
      state.subscription.subscriptions = action.payload
      state.subscription.subscriptionsLoading = false
    },
    setSubscriptionsLoading: (state, action: PayloadAction<boolean>) => {
      state.subscription.subscriptionsLoading = action.payload
    },
    setProducts: (state, action: PayloadAction<StripeProduct[]>) => {
      state.product.products = action.payload
      state.product.productsLoading = false
    },
    setProductsLoading: (state, action: PayloadAction<boolean>) => {
      state.product.productsLoading = action.payload
    },
    setOneTimeProducts: (state, action: PayloadAction<StripeProduct[]>) => {
      state.oneTimeProduct.oneTimeProducts = action.payload
      state.oneTimeProduct.oneTimeProductsLoading = false
    },
    setOneTimeProductsLoading: (state, action: PayloadAction<boolean>) => {
      state.oneTimeProduct.oneTimeProductsLoading = action.payload
    },
    setCompanyAddons: (state, action: PayloadAction<ICompanyProduct[]>) => {
      state.companyAddons.companyAddon = action.payload
      state.companyAddons.companyAddonLoading = false
    },
    setCompanyAddonsLoading: (state, action: PayloadAction<boolean>) => {
      state.companyAddons.companyAddonLoading = action.payload
    },
    setPreEmploymentProducts: (state, action: PayloadAction<StripeProduct[]>) => {
      state.preEmploymentProducts.preEmploymentProducts = action.payload
      state.preEmploymentProducts.preEmploymentProductsLoading = false
    },
    setPreEmploymentProductsLoading: (state, action: PayloadAction<boolean>) => {
      state.preEmploymentProducts.preEmploymentProductsLoading = action.payload
    },
    setMemberRequestProducts: (state, action: PayloadAction<StripeProduct[]>) => {
      state.memberRequestProducts.memberRequestProducts = action.payload
      state.memberRequestProducts.memberRequestProductsLoading = false
    },
    setMemberRequestProductsLoading: (state, action: PayloadAction<boolean>) => {
      state.memberRequestProducts.memberRequestProductsLoading = action.payload
    },
  },
})

export const {
  setSubscriptions,
  setSubscriptionsLoading,
  setProducts,
  setProductsLoading,
  setOneTimeProducts,
  setOneTimeProductsLoading,
  setCompanyAddons,
  setCompanyAddonsLoading,
  setPreEmploymentProducts,
  setPreEmploymentProductsLoading,
  setMemberRequestProducts,
  setMemberRequestProductsLoading,
} = stripeSlice.actions
