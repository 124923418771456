import { IMember } from '../../../../../models'
import React from 'react'
import TableContainerHeader from '../../../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader'
import TableContainerHeaderTitle from '../../../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle'
import TableHeader from '../../../../../compliancemasters/partials/widgets/tables/components/TableHeader'
import TableHeaderColumn from '../../../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn'
import TableWrapper from '../../../../../compliancemasters/partials/widgets/tables/components/TableWrapper'
import TableContainer from '../../../../../compliancemasters/partials/widgets/tables/components/TableContainer'
import DachClearingHouseQueries from './DachClearingHouse'
import { useAuth } from '../../../auth'
import DocsOfActualKnowledge from '../../NewFormsTable/DocsOfActualKnowledge'

const DrugFileMemberTable = ({ member, memberSubscription }: { member?: IMember; memberSubscription?: string }) => {
    const { currentUser } = useAuth()
    return (
        <TableWrapper>
            <TableContainerHeader>
                <TableContainerHeaderTitle title="Forms Overview" />
            </TableContainerHeader>
            <TableContainer>
                <TableHeader>
                    <TableHeaderColumn>Form</TableHeaderColumn>
                    <TableHeaderColumn>{""}</TableHeaderColumn>
                    <TableHeaderColumn>{""}</TableHeaderColumn>
                    <TableHeaderColumn>{""}</TableHeaderColumn>
                    <TableHeaderColumn end>Actions</TableHeaderColumn>
                </TableHeader>
                <tbody>
                    {memberSubscription === 'Drug and Alcohol Package' && <DachClearingHouseQueries id={Number(member?.id!)} />}
                    {currentUser && currentUser.role !== "Member" && <DocsOfActualKnowledge id={Number(member?.id)} />}
                </tbody>
            </TableContainer>
        </TableWrapper>
    )
}

export default DrugFileMemberTable