import React from 'react'
import { useFormik } from 'formik'
import { IMember } from '../../../models'
import { IAttemptsMadeDto } from '../../../store/interfaces/PreviousEmployeeSafetyPerformanceHistory/attempts-made'
import { useThemeMode } from '../../partials'
import { useCreateAttemptMadeMutation, useGetAttemptMadeByUserIdQuery, useUpdateAttemptMadeMutation, useDeleteAttemptMadeMutation } from '../../../store/reducers/PreviousEmployeeSafetyPerformanceHistory/attempts-made'
import { toast } from 'react-toastify'
import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material'
import { InformationTextChoices } from '../../../store/interfaces/PreviousEmployeeSafetyPerformanceHistory/prospective-employer-information'
import { useAuth } from '../../../app/modules/auth'


interface IProps {
    member?: IMember
}

const initialValues: Partial<IAttemptsMadeDto> = {
    this_form_was: InformationTextChoices.EMAILED,
    signature: '',
    date: '',
    attempts_made: "",
}

// const validationSchema = Yup.object({
//     this_form_was: Yup.string().required('Required'),
//     signature: Yup.string().required('Required'),
//     date: Yup.string().required('Required'),
//     attempts_made: Yup.string().required('Required'),
// })

const AttemptsMade = ({ member }: IProps) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data } = useGetAttemptMadeByUserIdQuery(member?.user?.id ?? 0, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
        refetchOnFocus: true
    })
    const [createAttemptsMade, { isLoading: isCreating }] = useCreateAttemptMadeMutation()
    const [updateAttemptsMade] = useUpdateAttemptMadeMutation()
    const [deleteAttemptsMade] = useDeleteAttemptMadeMutation()

    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { resetForm }) => {
            if (data) {
                await updateAttemptsMade({
                    ...data,
                    ...values,
                }).then(() => {
                    toast.success('Form Updated')
                }).catch(err => {
                    console.log(err)
                    toast.error('Something went wrong')
                })
            } else {
                await createAttemptsMade({
                    ...values,
                    // @ts-ignore
                    company: member?.company?.id,
                    // @ts-ignore
                    member: member?.id,
                }).then(() => {
                    resetForm()
                }).catch(() => {
                    toast.error('Something went wrong')
                })
            }

        }
    })

    React.useEffect(() => {
        if (data) {
            formik.setValues(data)
        } else if (member) {
            formik.setValues(initialValues)
        }
        // eslint-disable-next-line
    }, [data, member])

    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h5" gutterBottom component="h4">
                Attempts Made and Signature
            </Typography>
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        This form was
                    </Typography>
                    <TextField
                        fullWidth
                        id="date"
                        size="small"
                        variant="outlined"
                        value={formik.values.this_form_was}
                        select
                        onChange={(e) => {
                            formik.setFieldValue('this_form_was', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.this_form_was && Boolean(formik.errors.this_form_was) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.this_form_was && Boolean(formik.errors.this_form_was) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.this_form_was && Boolean(formik.errors.this_form_was) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InformationTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        By (Signature)
                    </Typography>
                    <TextField
                        fullWidth
                        id="date"
                        size="small"
                        variant="outlined"
                        placeholder='Signature'
                        value={formik.values.signature}
                        onChange={(e) => {
                            formik.setFieldValue('signature', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.signature && Boolean(formik.errors.signature) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.signature && Boolean(formik.errors.signature) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.signature && Boolean(formik.errors.signature) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                fontStyle: 'italic'
                            }
                        }}
                    />
                </Grid>
                <Grid item sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Date
                    </Typography>
                    <TextField
                        fullWidth
                        id="date"
                        size="small"
                        variant="outlined"
                        type='date'
                        value={formik.values.date}
                        onChange={(e) => {
                            formik.setFieldValue('date', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Attempts Made
                    </Typography>
                    <TextField
                        fullWidth
                        id="attempts_made"
                        size="small"
                        variant="outlined"
                        placeholder='Attempts Made'
                        value={formik.values.attempts_made}
                        onChange={(e) => {
                            formik.setFieldValue('attempts_made', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.attempts_made && Boolean(formik.errors.attempts_made) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.attempts_made && Boolean(formik.errors.attempts_made) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.attempts_made && Boolean(formik.errors.attempts_made) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                {!data || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role)) ? (

                    <Grid item xs={12} sm={12}>
                        <Button fullWidth disabled={isCreating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                            Save
                        </Button>
                    </Grid>
                ) : null}
                {data && currentUser?.role === "SuperAdmin" && <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={!!member?.terminated_at} onClick={() => {
                        const res = window.confirm("Are you sure you want to delete this form?")
                        if (res && data) {
                            deleteAttemptsMade(data.id)
                        }
                    }} variant="contained" color="primary">
                        Delete Form
                    </Button>
                </Grid>}
            </Grid>
        </div>
    )
}

export default AttemptsMade