import {companyDriverInformationApi} from './company-driver-information'
import {violationAndActualKnowledgeApi} from './violation-and-actual-knowledge'
import {affidavitApi, witnessAndOwnerSignatureApi} from './witness-and-owner-signature'
import {docsOfActualKnowledgeProgress} from './progress'

export const DocsOfActualKnowledgeReducers = {
  [companyDriverInformationApi.reducerPath]: companyDriverInformationApi.reducer,
  [violationAndActualKnowledgeApi.reducerPath]: violationAndActualKnowledgeApi.reducer,
  [affidavitApi.reducerPath]: affidavitApi.reducer,
  [witnessAndOwnerSignatureApi.reducerPath]: witnessAndOwnerSignatureApi.reducer,
  [docsOfActualKnowledgeProgress.reducerPath]: docsOfActualKnowledgeProgress.reducer,
}

export const DocsOfActualKnowledgeMiddleware = [
  companyDriverInformationApi.middleware,
  violationAndActualKnowledgeApi.middleware,
  affidavitApi.middleware,
  witnessAndOwnerSignatureApi.middleware,
  docsOfActualKnowledgeProgress.middleware,
]
