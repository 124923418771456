import { useIntl } from 'react-intl';
import { PageTitle } from '../../../compliancemasters/layout/core';
import MemberTable from '../../../compliancemasters/partials/widgets/tables/MemberTable/MemberTable';
import React from 'react';
import { useCompany } from '../../../context/CompanyContext';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { StripeProduct } from '../../modules/models';
import { getCompanySubscription } from '../../../shared';
import { TermsModal } from '../../modules/auth/components/TermsModal';

export default function CompanyDashboard() {
    const { currentCompany } = useCompany()
    const [companySub, setCompanySub] = React.useState<StripeProduct[]>([])
    const [loading, setLoading] = React.useState(true)
    const [termsModal, setTermsModal] = React.useState(false)
    const intl = useIntl();

    const getCompanySub = async () => {
        setLoading(true)
        const sub = await getCompanySubscription()
        if (sub) {
            const uniqueProducts = sub.filter(
                (function () {
                    const seenIds = new Set<string>();
                    return (product: StripeProduct) => {
                        if (!seenIds.has(product.name)) {
                            seenIds.add(product.name);
                            return true;
                        }
                        return false;
                    };
                })()
            )
            setCompanySub(uniqueProducts)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        getCompanySub()
    }, [])

    React.useEffect(() => {
        if (!loading && companySub.length !== currentCompany?.package?.length && currentCompany?.accepted_terms_and_conditions) {
            // Redirect to Subscription and Payment Page
            toast.error("Please subscribe to all your selected packages to continue")
            setTimeout(() => {
                window.location.assign("/subscription/plan")
            }, 4000)
        }
        if (!loading && currentCompany && !currentCompany?.accepted_terms_and_conditions) {
            // Open terms and conditions Modal
            setTermsModal(true)
        }
    }, [loading, companySub, currentCompany])

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <div className="row gy-5 g-xl-8">
                <div className="col-xl-12">
                    {loading ? (
                        <div className="row gy-5 g-xl-8">
                            <div className="col-xl-12">
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    height: "100vh"
                                }}>
                                    <CircularProgress />
                                </div>
                            </div>
                        </div>
                    ) : companySub.length === currentCompany?.package.length && !loading ? (
                        <MemberTable subscriptions={companySub} />
                    ) : (
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            height: "100vh"
                        }}>
                            <div className="alert alert-danger text-center" role="alert">
                                You have not subscribed to all your selected packages. Please subscribe to all your selected packages to continue.
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <TermsModal
                open={termsModal}
                setOpen={setTermsModal}
                company={currentCompany}
            />
        </>
    );
}
