import { Button, Dialog, DialogContent, DialogTitle, Grid, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import { toast } from 'react-toastify';
import { IMaintenanceMember } from '../../../store/interfaces/PreventableMaintenance/maintenance.members';
import { useDeleteMaintenanceMemberMutation } from '../../../store/reducers/PreventableMaintenance/maintenance.member';

interface IProps {
    open: boolean;
    setOpen: (val: boolean) => void;
    member?: IMaintenanceMember;
    setMember?: (val?: IMaintenanceMember) => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const DeleteMaintenanceMemberModal = ({ open, setOpen, setMember, member }: Readonly<IProps>) => {
    const [deleteMainntenanceMember, { isLoading }] = useDeleteMaintenanceMemberMutation();

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpen(false)}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle
                sx={{
                    textAlign: 'center',
                    backgroundColor: '#F5F5F5',
                    color: '#000000',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    padding: '20px 20px 20px 20px'
                }}
            >
                Are you sure you want to delete this maintenance member {member?.first_name}?
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Button
                            size="small"
                            disabled={isLoading}
                            fullWidth
                            variant="contained"
                            color="error"
                            onClick={() => {
                                deleteMainntenanceMember(Number(member?.id)).then(() => {
                                    setMember?.(undefined);
                                    toast.success('Member deleted successfully');
                                    setOpen(false);
                                });
                            }}
                        >
                            Delete Maintenance Member
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            size="small"
                            disabled={isLoading}
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
