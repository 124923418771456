import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MaintenanceMemberDashboard from '../pages/preventableMaintenance/MaintenanceMemberDashboard'
import MaintenanceMemberReport from '../pages/preventableMaintenance/MaintenanceMemberReport'

const MaintenanceMemberPrivateRoutes = () => {
    return (
        <Routes>
            <Route path='/preventable-maintenance/*'>
                <Route path="dashboard" element={<MaintenanceMemberDashboard />} />
                <Route path="report/:id" element={<MaintenanceMemberReport />} />
            </Route>
        </Routes>
    )
}

export default MaintenanceMemberPrivateRoutes