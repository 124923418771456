import {ICompany, IMember} from '../../../models'
import {IAbstract} from '../abstract'

export enum ReasonForLeaving {
  DISMISSED = 'DISMISSED',
  VOLUNTARY_QUIT = 'VOLUNTARY_QUIT',
  OTHER = 'OTHER',
}

export interface ITerminationOfEmploymentDto {
  date_terminated: string
  department_released_from: string
  reason_for_leaving: ReasonForLeaving
  supervisor: string
  member: IMember
  company: ICompany
}

export interface ITerminationOfEmployment extends IAbstract, ITerminationOfEmploymentDto {}
