import { toAbsoluteUrl } from '../../helpers';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';

export function DashboardLink({ size }: { size: string }) {
    return (
        <Link to="dashboard" className={'menu-item me-lg-1 text-black'}>
            <SVG src={toAbsoluteUrl('/media/logos/compliance-01.svg')} className={`h-${size}px w-${size}px`} />

            <span className="text-dark">Compliance Masters</span>
        </Link>
    );
}
