import React, { useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
    IMaintenanceVehicle,
    VehicleType
} from '../../../store/interfaces/PreventableMaintenance/maintenance.vehicles';
import { useUpdateMaintenanceVehicleMutation } from '../../../store/reducers/PreventableMaintenance/maintenance.vehicle';

interface IProps {
    open: boolean;
    setOpen: (val: boolean) => void;
    vehicle?: IMaintenanceVehicle;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initialValues: Partial<IMaintenanceVehicle> = {
    vehicle_number: '',
    vehicle_make: '',
    vehicle_model: '',
    current_odometer_reading: 0,
    vehicle_type: VehicleType.SEDAN,
    company: undefined
};

const validationSchema = Yup.object().shape({});

export const AssignPmProgramModal: React.FunctionComponent<IProps> = ({ open, setOpen, vehicle }) => {
    const [updateMaintenanceVehicle] = useUpdateMaintenanceVehicleMutation();
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            updateMaintenanceVehicle({
                ...values
            }).then(() => {
                setOpen(false);
            });
        }
    });

    useEffect(() => {
        if (vehicle && open) {
            formik.setValues(vehicle);
        } else if (!open) {
            formik.resetForm();
        }
        // eslint-disable-next-line
    }, [vehicle, open]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpen(false)}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle
                sx={{
                    textAlign: 'center',
                    backgroundColor: '#F5F5F5',
                    color: '#000000',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    padding: '20px 20px 20px 20px'
                }}
            >
                Assign PM Program
            </DialogTitle>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    {/* <Grid container spacing={2} p={3}>
                        <Grid item xs={12}>
                            <InputLabel>PM Program</InputLabel>
                            <TextField
                                fullWidth
                                id='name'
                                size='small'
                                select
                                onChange={(e) => {
                                    const serviceProgram = servicePrograms.find((serviceProgram) => Number(serviceProgram.id) === Number(e.target.value))
                                    if (serviceProgram && formik?.values?.service_programs) {
                                        formik.setFieldValue('service_programs', [...formik?.values?.service_programs, serviceProgram])
                                    }
                                }}
                            >
                                {servicePrograms.map((serviceProgram) => (
                                    <MenuItem key={serviceProgram.id} value={serviceProgram.id}>
                                        {serviceProgram.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {formik?.values?.service_programs && formik?.values?.service_programs?.length > 0 ? <Grid item xs={12}>
                            <Box display='flex' justifyContent='space-between' component={Paper} p={3}>
                                {formik?.values?.service_programs?.map((serviceProgram) => (
                                    <Chip key={serviceProgram.id} label={serviceProgram.name} onDelete={(serviceProgram) => {
                                        formik.setFieldValue('service_programs', formik?.values?.service_programs?.filter((program) => program.id !== serviceProgram.id))
                                    }} />
                                ))}
                            </Box>
                        </Grid> : null}
                        <Grid item xs={12}>
                            <Button type='submit' variant='contained' size='small' fullWidth>Assign PM Program</Button>
                        </Grid>
                    </Grid> */}
                </form>
            </DialogContent>
        </Dialog>
    );
};
