/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { getFilesByMemberId } from '../../../../shared';
import { useParams } from 'react-router-dom';
import { IFile, IMember } from '../../../../models';
import FilesTable from '../../../../compliancemasters/partials/widgets/tables/FilesTable/FilesTable';
import MemberForms from './Forms/FormTable';
import { StripeProduct } from '../../models';

interface IProps {
    memberSubscription?: StripeProduct
    member?: IMember
}

export function NonCurrentDocuments({ member, memberSubscription }: Readonly<IProps>) {
    const { memberId } = useParams();
    const location = 'Non-Current';
    const [files, setFiles] = useState<IFile[]>([]);

    useEffect(() => {
        if (memberId) {
            getFiles();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getFiles() {
        getFilesByMemberId(memberId!, location).then((files) => {
            setFiles(files);
        });
    }

    return (
        <>
            {memberSubscription?.name !== "Drug and Alcohol Package" ? <div className="row g-5 g-xxl-8">
                <div className="col-xl-12">
                    {member ? <MemberForms member={member} /> : null}
                </div>
            </div> : null}
            <div className="row g-5 g-xxl-8">
                <div className="col-xl-12">
                    {member ? <FilesTable files={files} member={member} memberId={memberId!} onAddNewFile={getFiles} location={location} /> : null}
                </div>
            </div>
        </>
    );
}
