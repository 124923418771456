import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    ICheckOfDrivingRecordsDriverSignature,
    ICheckOfDrivingRecordsDriverSignatureDto
} from '../../interfaces/RequestForCheckOfDrivingRecords/driver-signature';

export const driverSignatureApi = createApi({
    reducerPath: 'driving_records_driver_signature',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/request-driver-signature`
    }),
    tagTypes: ['CheckOfDrivingRecordsDriverSignature'],
    endpoints: (builder) => ({
        createDriverSignature: builder.mutation<
            ICheckOfDrivingRecordsDriverSignature,
            ICheckOfDrivingRecordsDriverSignatureDto
        >({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                { type: 'CheckOfDrivingRecordsDriverSignature', id: 'CheckOfDrivingRecordsDriverSignature' }
            ]
        }),
        updateDriverSignature: builder.mutation<
            ICheckOfDrivingRecordsDriverSignature,
            ICheckOfDrivingRecordsDriverSignature
        >({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                { type: 'CheckOfDrivingRecordsDriverSignature', id: 'CheckOfDrivingRecordsDriverSignature' }
            ]
        }),
        deleteDriverSignature: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                { type: 'CheckOfDrivingRecordsDriverSignature', id: 'CheckOfDrivingRecordsDriverSignature' }
            ]
        }),
        getDriverSignature: builder.query<ICheckOfDrivingRecordsDriverSignature, number | void>({
            query: (id) => ({
                url: `/get-driver-signature/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'CheckOfDrivingRecordsDriverSignature', id: 'CheckOfDrivingRecordsDriverSignature' }]
        }),
        getDriverSignatureByUser: builder.query<ICheckOfDrivingRecordsDriverSignature, void>({
            query: () => ({
                url: `/get-driver-signature-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'CheckOfDrivingRecordsDriverSignature', id: 'CheckOfDrivingRecordsDriverSignature' }]
        }),
        getDriverSignatureByUserId: builder.query<ICheckOfDrivingRecordsDriverSignature, number | void>({
            query: (id) => ({
                url: `/get-driver-signature-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'CheckOfDrivingRecordsDriverSignature',
                    id: 'CheckOfDrivingRecordsDriverSignature'
                }
            ]
        })
    })
});

export const {
    useCreateDriverSignatureMutation,
    useUpdateDriverSignatureMutation,
    useGetDriverSignatureQuery,
    useGetDriverSignatureByUserQuery,
    useGetDriverSignatureByUserIdQuery,
    useDeleteDriverSignatureMutation
} = driverSignatureApi;
