import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import { IServiceTypes } from '../../interfaces/PreventableMaintenance/service.types';

export const serviceTypesApi = createApi({
    reducerPath: 'pm_service_type',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/auth/api/preventable-maintenance/service-types`
    }),
    tagTypes: ['ServiceTypes', 'PmPrograms'],
    endpoints: (builder) => ({
        createServiceType: builder.mutation<IServiceTypes, Partial<IServiceTypes>>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                { type: 'ServiceTypes', id: 'ServiceTypes' },
                { type: 'PmPrograms', id: 'PmPrograms' }
            ]
        }),
        updateServiceType: builder.mutation<IServiceTypes, IServiceTypes>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'ServiceTypes', id: 'ServiceTypes' }]
        }),
        deleteServiceType: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'ServiceTypes', id: 'ServiceTypes' }]
        }),
        getServiceType: builder.query<IServiceTypes, number | void>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ServiceTypes', id: 'ServiceTypes' }]
        }),
        getServiceTypes: builder.query<IServiceTypes[], void>({
            query: () => ({
                url: '/',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ServiceTypes', id: 'ServiceTypes' }]
        }),
        getServiceTypesByProgramId: builder.query<IServiceTypes[], number | void>({
            query: (pm_program_id) => ({
                url: `/get-by-pm-program?pm_program_id=${pm_program_id}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ServiceTypes', id: 'ServiceTypes' }]
        })
    })
});

export const {
    useCreateServiceTypeMutation,
    useUpdateServiceTypeMutation,
    useDeleteServiceTypeMutation,
    useGetServiceTypeQuery,
    useGetServiceTypesQuery,
    useGetServiceTypesByProgramIdQuery
} = serviceTypesApi;
