import { Button, Dialog, DialogContent, DialogTitle, Grid, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { IMaintenanceVehicle } from "../../../store/interfaces/PreventableMaintenance/maintenance.vehicles";
import { useDeleteMaintenanceVehicleMutation } from "../../../store/reducers/PreventableMaintenance/maintenance.vehicle";
import { toast } from "react-toastify";

interface IProps {
    open: boolean;
    setOpen: (val: boolean) => void;
    vehicle?: IMaintenanceVehicle;
    setVehicle?: (val?: IMaintenanceVehicle) => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const DeleteVehicleModal = ({ open, setOpen, setVehicle, vehicle }: Readonly<IProps>) => {
    const [deleteVehicle, { isLoading }] = useDeleteMaintenanceVehicleMutation();

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpen(false)}
            maxWidth='sm'
            fullWidth
        >
            <DialogTitle sx={{
                textAlign: "center",
                backgroundColor: '#F5F5F5',
                color: '#000000',
                fontSize: '16px',
                fontWeight: 'bold',
                padding: '20px 20px 20px 20px',
            }}>
                Are you sure you want to delete this vehicle {vehicle?.vehicle_number}?
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Button size="small" disabled={isLoading} fullWidth variant='contained' color='error' onClick={() => {
                            deleteVehicle(Number(vehicle?.id)).then(() => {
                                setVehicle?.(undefined);
                                toast.success('Vehicle deleted successfully');
                                setOpen(false);
                            });
                        }}>
                            Delete Vehicle
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button size="small" disabled={isLoading} fullWidth variant='contained' color='primary' onClick={() => {
                            setOpen(false);
                        }}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};