import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Button, Grid, InputLabel, MenuItem, TextField } from '@mui/material';
import { useAuth } from '../../../app/modules/auth';
import { sendOptToPhoneNumber, verifyOtp } from '../../../shared';
import { toast } from 'react-toastify';

interface IProps {
    open: boolean,
    setOpen: (val: boolean) => void
    memberId: string | number;
}

type OtpChannel = "sms" | "email" | "whatsapp"

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewDriverFileModel({ open, setOpen, memberId }: IProps) {
    const [phoneNumber, setPhoneNumber] = React.useState<string>("")
    const [otpChannel, setOtpChannel] = React.useState<OtpChannel>("sms")
    const [isSending, setIsSending] = React.useState<boolean>(false)
    const [sent, setSent] = React.useState<boolean>(false)
    const [isVerifying, setIsVerifying] = React.useState<boolean>(false)
    const [otp, setOtp] = React.useState<string>("")
    const { currentUser } = useAuth()
    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        if (open && currentUser?.role === "Owner" && currentUser?.company?.phone_number && otpChannel === "sms") {
            setPhoneNumber(currentUser?.company?.phone_number)
        } else if (open && currentUser?.role === "Owner" && currentUser?.email && otpChannel === "email") {
            setPhoneNumber(currentUser?.email)
        }
    }, [open, currentUser, otpChannel])

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle style={{
                textAlign: 'center',
                backgroundColor: 'gray',
                color: 'white'
            }}>
                Please Verify your identity. An OTP will be sent to your registered phone number.
            </DialogTitle>
            <DialogContent sx={{ m: 2 }}>
                <Grid container spacing={2} mb={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <InputLabel>
                            Select OTP Channel
                        </InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={otpChannel}
                            onChange={(e) => setOtpChannel(e.target.value as OtpChannel)}
                            select
                        >
                            {Object.keys({ sms: "SMS", whatsapp: "Whatsapp", email: "email" }).map((channel) => (
                                <MenuItem key={channel} value={channel}>
                                    {channel}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <InputLabel>
                            Enter Your {otpChannel === "sms" ? "Phone Number" : otpChannel === "email" ? "Email" : "Whatsapp Number"}
                        </InputLabel>
                        <TextField
                            fullWidth
                            // disabled={isSending || sent || ["Admin", "SuperAdmin"].includes(currentUser?.role!)}
                            size="small"
                            variant="outlined"
                            placeholder={`Enter your ${otpChannel === "sms" ? "Phone Number" : otpChannel === "email" ? "Email" : "Whatsapp Number"}`}
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button
                            variant="outlined"
                            fullWidth
                            disabled={isSending || sent}
                            onClick={() => {
                                setIsSending(true)
                                sendOptToPhoneNumber(phoneNumber, otpChannel).then((res) => {
                                    toast.success("OTP Sent Successfully")
                                    setIsSending(false)
                                    setSent(true)
                                }).catch((err) => {
                                    toast.error("Error Sending OTP")
                                    setIsSending(false)
                                    setSent(false)
                                })
                            }}>
                            Send OTP
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <InputLabel>
                            OTP
                        </InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            placeholder='Enter OTP'
                            variant="outlined"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button
                            variant="outlined"
                            fullWidth
                            disabled={isVerifying || !sent}
                            onClick={() => {
                                setIsVerifying(true)
                                verifyOtp(phoneNumber, otp).then((res) => {
                                    toast.success("OTP Verified Successfully")
                                    setIsVerifying(false)
                                    setPhoneNumber("")
                                    setOtp("")
                                    setSent(false)
                                    setTimeout(() => {
                                        window.location.assign(`/driver-pdf/${memberId}`)
                                    }, 3000)
                                    setOpen(false)
                                }).catch((err) => {
                                    toast.error("Error Verifying OTP")
                                    setIsVerifying(false)
                                })
                            }}>
                            Verify
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
