import {backingAndParkingApi} from './backing-and-parking'
import {couplingAndUncouplingApi} from './coupling-and-uncoupling'
import {driverInformationApi} from './driver-information'
import {miscellaneousApi} from './miscellaneous'
import {operatingInTrafficPassingAndTurningApi} from './operating-in-traffic-passing-turning'
import {performanceRatingAndOwnerSignatureApi} from './performance-rating-owner-signature'
import {placingVehiclesInMotionAndUseOfControlsApi} from './placing-vehicles-in-motion-use-of-controls'
import {preTripInspectionAndEmergencyEquipmentApi} from './pre-trip-inspection-emergency-equipemnt'
import {slowingAndStoppingApi} from './slowing-and-stopping'
import {recordOfRoadTestProgress} from './progress'

export const RecordOfRoadTestReducers = {
  [backingAndParkingApi.reducerPath]: backingAndParkingApi.reducer,
  [couplingAndUncouplingApi.reducerPath]: couplingAndUncouplingApi.reducer,
  [driverInformationApi.reducerPath]: driverInformationApi.reducer,
  [miscellaneousApi.reducerPath]: miscellaneousApi.reducer,
  [operatingInTrafficPassingAndTurningApi.reducerPath]:
    operatingInTrafficPassingAndTurningApi.reducer,
  [performanceRatingAndOwnerSignatureApi.reducerPath]:
    performanceRatingAndOwnerSignatureApi.reducer,
  [placingVehiclesInMotionAndUseOfControlsApi.reducerPath]:
    placingVehiclesInMotionAndUseOfControlsApi.reducer,
  [preTripInspectionAndEmergencyEquipmentApi.reducerPath]:
    preTripInspectionAndEmergencyEquipmentApi.reducer,
  [slowingAndStoppingApi.reducerPath]: slowingAndStoppingApi.reducer,
  [recordOfRoadTestProgress.reducerPath]: recordOfRoadTestProgress.reducer,
}

export const RecordOfRoadTestMiddleware = [
  backingAndParkingApi.middleware,
  couplingAndUncouplingApi.middleware,
  driverInformationApi.middleware,
  miscellaneousApi.middleware,
  operatingInTrafficPassingAndTurningApi.middleware,
  performanceRatingAndOwnerSignatureApi.middleware,
  placingVehiclesInMotionAndUseOfControlsApi.middleware,
  preTripInspectionAndEmergencyEquipmentApi.middleware,
  slowingAndStoppingApi.middleware,
  recordOfRoadTestProgress.middleware,
]
