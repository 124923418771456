import { TableCell } from '@mui/material';
import React, { FC } from 'react';
import { IDVIReport } from '../../../../store/interfaces/PreventableMaintenance/dvir.report';

interface IProps {
    dvir: IDVIReport;
}

function formatSignatures(dvir: IDVIReport): string {
    switch (dvir.condition_satisfactory) {
        case true:
            return 'N/A';
        case false:
            if (dvir.mechanic_signature && dvir.next_driver_signature) {
                return 'Complete';
            }
            if (dvir.mechanic_signature) {
                return 'Awaiting Next Driver Signature';
            }
            if (dvir.next_driver_signature) {
                return 'Awaiting Mechanic Signature';
            }
            return 'Pending both Signatures';
    }
}

const SignatureField: FC<IProps> = ({ dvir }) => {
    return (
        <TableCell align="center">
            <span style={{}}>{formatSignatures(dvir)}</span>
        </TableCell>
    );
};

export default SignatureField;
