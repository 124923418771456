import React from 'react'
import { IMember } from '../../../models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Grid, TextField, Typography } from '@mui/material'
import {
    IPerformanceHistoryDriverIdentificationDto
} from '../../../store/interfaces/PreviousEmployeeSafetyPerformanceHistory/driver-identification'
import {
    useCreateDriverIdentificationMutation,
    useUpdateDriverIdentificationMutation,
    useGetDriverIdentificationByUserIdQuery,
    useDeleteDriverIdentificationMutation
} from '../../../store/reducers/PreviousEmployeeSafetyPerformanceHistory/driver-identification'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'
import OwnerDates from './OwnerDates'
import { changeStringLast4 } from '../../../shared'


const initialValues: Partial<IPerformanceHistoryDriverIdentificationDto> = {
    name_of_previous_employee: '',
    social_security_number: '',
    date_of_birth: '',
}


const validationSchema = Yup.object({
    name_of_previous_employee: Yup.string().required('Required'),
    social_security_number: Yup.string().required('Required'),
    date_of_birth: Yup.string().required('Required'),
})


interface IProps {
    member?: IMember;
}

const DriverIdentification = ({ member }: IProps) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data } = useGetDriverIdentificationByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })

    const [createDriverIdentification, { isLoading: isCreating }] = useCreateDriverIdentificationMutation()
    const [updateDriverIdentification, { isLoading: isUpdating }] = useUpdateDriverIdentificationMutation()
    const [deleteDriverIdentification] = useDeleteDriverIdentificationMutation()


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                updateDriverIdentification({
                    ...values,
                    // @ts-ignore
                    id: data.id,
                })
            } else {
                createDriverIdentification({
                    ...values,
                    // @ts-ignore
                    company: member?.company?.id,
                    // @ts-ignore
                    member: member?.id,
                })
            }
        },
        validateOnBlur: true,
        validateOnChange: true,
    })

    React.useEffect(() => {
        if (data) {
            formik.setValues({
                ...data,
                social_security_number: ["Member", "Owner"].includes(currentUser?.role!) ? changeStringLast4(data?.social_security_number, "*")! : data?.social_security_number,
            })
        } else if (member) {
            const utcDate = new Date(member?.date_of_birth!);

            const offsetMinutes = utcDate.getTimezoneOffset();
            utcDate.setMinutes(utcDate.getMinutes() + offsetMinutes);

            const year = utcDate.getFullYear();
            const month = String(utcDate.getMonth() + 1).padStart(2, '0');
            const day = String(utcDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            formik.setValues({
                ...initialValues,
                name_of_previous_employee: `${member?.user?.first_name} ${member?.user?.last_name}`,
                social_security_number: member?.social_security_number,
                date_of_birth: formattedDate,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [member, data])

    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                Driver Identification
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify" }} variant="body1" gutterBottom component="p">
                NOTE: THIS FORM WILL BE COMPLETED BY COMPLIANCE MASTERS FOR EACH DRIVER Section 391.23 -
                All motor carriers must investigate each driver's safety performance history with previous
                DOT-regulated employers of the preceding three years.
            </Typography>
            <Grid container spacing={2}>
                <>
                    <OwnerDates member={member} />
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="body1" gutterBottom component="p">
                            Driver Identification
                        </Typography>
                    </Grid >
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                            Employee Name
                        </Typography>
                        <TextField
                            fullWidth
                            id="name_of_previous_employee"
                            size="small"
                            variant="outlined"
                            placeholder='Employee Name'
                            value={formik.values.name_of_previous_employee}
                            // onChange={(e) => {
                            //     formik.setFieldValue('name_of_previous_employee', e.target.value)
                            // }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.name_of_previous_employee && Boolean(formik.errors.name_of_previous_employee) ? "red" : "green",
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.name_of_previous_employee && Boolean(formik.errors.name_of_previous_employee) ? "red" : "green",
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.name_of_previous_employee && Boolean(formik.errors.name_of_previous_employee) ? "red" : "green",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }
                            }}
                            disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                            InputProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }
                            }}
                        />
                    </Grid >
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                            Social Security Number
                        </Typography>
                        <TextField
                            fullWidth
                            id="social_security_number"
                            size="small"
                            variant="outlined"
                            placeholder='Social Security Number'
                            value={formik.values.social_security_number}
                            onChange={(e) => {
                                formik.setFieldValue('social_security_number', e.target.value)
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.social_security_number && Boolean(formik.errors.social_security_number) ? "red" : "green",
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.social_security_number && Boolean(formik.errors.social_security_number) ? "red" : "green",
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.social_security_number && Boolean(formik.errors.social_security_number) ? "red" : "green",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }
                            }}
                            disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                            InputProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }
                            }}
                        />
                    </Grid >
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                            Date of Birth
                        </Typography>
                        <TextField
                            fullWidth
                            id="date_of_birth"
                            size="small"
                            variant="outlined"
                            type='date'
                            value={formik.values.date_of_birth}
                            onChange={(e) => {
                                formik.setFieldValue('date_of_birth', e.target.value)
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth) ? "red" : "green",
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth) ? "red" : "green",
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor: formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth) ? "red" : "green",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }
                            }}
                            disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                            InputProps={{
                                style: {
                                    color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                }
                            }}
                        />
                    </Grid >
                    {!data || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role)) ? <Grid item xs={12} sm={12}>
                        <Button fullWidth disabled={isCreating || isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                            Save
                        </Button>
                        {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                            <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                                Once you click save, you will not be able to edit this form.
                            </Typography>
                        )}
                    </Grid > : null}
                    {data && currentUser?.role === "SuperAdmin" && <Grid item xs={12} sm={12}>
                        <Button fullWidth disabled={!!member?.terminated_at} onClick={() => {
                            const res = window.confirm("Are you sure you want to delete this form?")
                            if (res && data) {
                                deleteDriverIdentification(data.id)
                            }
                        }} variant="contained" color="primary">
                            Delete Form
                        </Button>
                    </Grid>}
                </>
            </Grid >
        </div >
    )
}

export default DriverIdentification