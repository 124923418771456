import React from 'react'
import { IMember } from '../../../models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Grid, TextField, Typography } from '@mui/material'
import {
    IDriverAuthorizationAndSignatureDto
} from '../../../store/interfaces/LimitedQueriesDrugAndAlcoholClearinghouse/DriverAuthorizationAndSignature'
import {
    useCreateDriverAuthorizationAndSignatureMutation,
    useGetDriverAuthorizationAndSignatureByUserIdQuery,
    useUpdateDriverAuthorizationAndSignatureMutation,
    useDeleteDriverAuthorizationAndSignatureMutation
} from '../../../store/reducers/LimitedQueriesDrugAndAlcoholClearinghouse/driver-authorization-and-signature'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'
import { useGetProcessRecordByUserIdQuery } from '../../../store/reducers/DriversApplicationForEmployment/process-record'
import { useGetCertificationByUserIdQuery } from '../../../store/reducers/CertificateOfComplianceWithDriverLicenseRequirements/certification'
import { changeStringLast4 } from '../../../shared'
import { StripeProduct } from '../../../app/modules/models'


const initialValues: Partial<IDriverAuthorizationAndSignatureDto> = {
    drivers_name: '',
    company_name: '',
    queries_conducted_by_company_name: '',
    consent_for_company_name: '',
    clearinghouse_company_name: '',
    drivers_license_number: '',
    drivers_signature: '',
    date: '',
}

const validationSchema = Yup.object({
    drivers_name: Yup.string().required('Required'),
    company_name: Yup.string().required('Required'),
    queries_conducted_by_company_name: Yup.string().required('Required'),
    consent_for_company_name: Yup.string().required('Required'),
    clearinghouse_company_name: Yup.string().required('Required'),
    drivers_license_number: Yup.string().required('Required'),
    drivers_signature: Yup.string().required('Required'),
    date: Yup.string().required('Required'),
})


interface IProps {
    member?: IMember
    subscription?: StripeProduct
    isDriverFile?: boolean
}

const DriverAuthorizationAndSignature = ({ member, subscription, isDriverFile }: IProps) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data } = useGetDriverAuthorizationAndSignatureByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })

    const { data: processRecord } = useGetProcessRecordByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    })

    const { data: cert } = useGetCertificationByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })

    const [createDriverAuthorizationAndSignature, { isLoading: isCreating }] = useCreateDriverAuthorizationAndSignatureMutation()
    const [updateDriverAuthorizationAndSignature, { isLoading: isUpdating }] = useUpdateDriverAuthorizationAndSignatureMutation()
    const [deleteDriverAuthorizationAndSignature] = useDeleteDriverAuthorizationAndSignatureMutation()

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                updateDriverAuthorizationAndSignature({
                    ...values,
                    id: data.id,
                })
            } else {
                createDriverAuthorizationAndSignature({
                    ...values,
                    // @ts-ignore
                    company: member?.company?.id,
                    // @ts-ignore
                    member: member?.id,
                })
            }
            resetForm()
        },
        validateOnBlur: true,
        validateOnChange: true,
    })

    React.useEffect(() => {
        if (data) {
            formik.setValues({
                ...data,
                drivers_license_number: ["Member", "Owner"].includes(currentUser?.role!) ? changeStringLast4(data?.drivers_license_number, "*")! : data?.drivers_license_number,
            })
        } else if (member) {
            formik.setValues({
                ...initialValues,
                drivers_name: `${member?.user?.first_name} ${member?.user?.last_name}`,
                company_name: `${member?.company?.name}`,
                queries_conducted_by_company_name: `${member?.company?.name}`,
                consent_for_company_name: `${member?.company?.name}`,
                clearinghouse_company_name: `${member?.company?.name}`,
                drivers_license_number: member?.drivers_license_number,
            })
            if (processRecord) {
                formik.setFieldValue('date', processRecord?.date_employed)
            } else if (cert) {
                formik.setFieldValue("date", cert.date_of_hire)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, member, processRecord, cert])

    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                Driver Authorization and Signature
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify" }} variant="body1" gutterBottom component="p">
                NOTE to Driver: The Commercial Driver's License (CDL) Drug & Alcohol Clearinghouse
                is a federal database that contains information about CDL drivers who have violated
                the FMCSA's drug or alcohol regulations in 49 CFR Part 382.
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify", mt: 2 }} variant="body1" gutterBottom component="p">
                NOTE to Motor Carrier: This consent form authorizes you to run a "limited query" to check
                whether the Clearinghouse has information about the driver identified below.
                If information exists, then you must obtain a full Clearinghouse record within 24 hours, per Section 382.701(B).
                This consent form must be retained for 3 years after the date of the last limited query was performed for this driver.
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify", mt: 2 }} variant="body1" gutterBottom component="p">
                This consent is valid from the date provided below until employment with the above-named motor
                carrier ceases or until employee is no longer subject to the drug and alcohol testing rules in
                49 CFR Part 382 for the above-named motor carrier.
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        I (Employee's printed name)
                    </Typography>
                    <TextField
                        fullWidth
                        id="drivers_name"
                        size="small"
                        variant="outlined"
                        placeholder="Employee's Name"
                        value={formik.values.drivers_name}
                        // onChange={(e) => {
                        //     formik.setFieldValue('drivers_name', e.target.value)
                        // }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_name && Boolean(formik.errors.drivers_name) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_name && Boolean(formik.errors.drivers_name) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_name && Boolean(formik.errors.drivers_name) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        hereby authorize (Company name)
                    </Typography>
                    <TextField
                        fullWidth
                        id="company_name"
                        size="small"
                        variant="outlined"
                        placeholder='Company Name'
                        value={formik.values.company_name}
                        // onChange={(e) => {
                        //     formik.setFieldValue('company_name', e.target.value)
                        // }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.company_name && Boolean(formik.errors.company_name) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.company_name && Boolean(formik.errors.company_name) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.company_name && Boolean(formik.errors.company_name) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        to conduct a limited query of the FMSCA Commercial Driver's License Drug and
                        Alcohol Clearinghouse to determine whether drug or alcohol violation information about me exists in the Clearinghouse.
                    </Typography>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        I understand that if any of my limited queries conducted by (Company name)
                    </Typography>
                    <TextField
                        fullWidth
                        id="queries_conducted_by_company_name"
                        size="small"
                        variant="outlined"
                        placeholder='Company Name'
                        value={formik.values.queries_conducted_by_company_name}
                        // onChange={(e) => {
                        //     formik.setFieldValue('queries_conducted_by_company_name', e.target.value)
                        // }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.queries_conducted_by_company_name && Boolean(formik.errors.queries_conducted_by_company_name) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.queries_conducted_by_company_name && Boolean(formik.errors.queries_conducted_by_company_name) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.queries_conducted_by_company_name && Boolean(formik.errors.queries_conducted_by_company_name) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        reveal that drug or alcohol violation information about me exists in the Clearinghouse,
                        FMSCA will not disclose that information to (Company name entered) additional specific consent from me.
                    </Typography>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        I further understand that if I refuse to provide consent for (Company name)
                    </Typography>
                    <TextField
                        fullWidth
                        id="consent_for_company_name"
                        size="small"
                        variant="outlined"
                        placeholder='Company Name'
                        value={formik.values.consent_for_company_name}
                        // onChange={(e) => {
                        //     formik.setFieldValue('consent_for_company_name', e.target.value)
                        // }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.consent_for_company_name && Boolean(formik.errors.consent_for_company_name) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.consent_for_company_name && Boolean(formik.errors.consent_for_company_name) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.consent_for_company_name && Boolean(formik.errors.consent_for_company_name) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        conduct a limited query of the Clearinghouse, (Company name)
                    </Typography>
                    <TextField
                        fullWidth
                        id="clearinghouse_company_name"
                        size="small"
                        variant="outlined"
                        placeholder='Company Name'
                        value={formik.values.clearinghouse_company_name}
                        // onChange={(e) => {
                        //     formik.setFieldValue('clearinghouse_company_name', e.target.value)
                        // }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.clearinghouse_company_name && Boolean(formik.errors.clearinghouse_company_name) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.clearinghouse_company_name && Boolean(formik.errors.clearinghouse_company_name) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.clearinghouse_company_name && Boolean(formik.errors.clearinghouse_company_name) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        without first obtaining must prohibit me from performing safety-sensitive functions,
                        including driving a commercial motor vehicle, as required by FMSCA's drug and alcohol
                        program regulations from safety-sensitive duties.
                    </Typography>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Driver's Signature
                    </Typography>
                    <TextField
                        fullWidth
                        id="drivers_signature"
                        size="small"
                        variant="outlined"
                        placeholder='Driver Signature'
                        value={formik.values.drivers_signature}
                        onChange={(e) => {
                            formik.setFieldValue('drivers_signature', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_signature && Boolean(formik.errors.drivers_signature) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_signature && Boolean(formik.errors.drivers_signature) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_signature && Boolean(formik.errors.drivers_signature) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                fontStyle: 'italic'
                            }
                        }}
                    />
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Driver's License Number
                    </Typography>
                    <TextField
                        fullWidth
                        id="drivers_license_number"
                        size="small"
                        variant="outlined"
                        placeholder='Drivers License Number'
                        value={formik.values.drivers_license_number}
                        onChange={(e) => {
                            formik.setFieldValue('drivers_license_number', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_license_number && Boolean(formik.errors.drivers_license_number) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_license_number && Boolean(formik.errors.drivers_license_number) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_license_number && Boolean(formik.errors.drivers_license_number) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        {subscription?.name === "Drug and Alcohol Package" && !member?.pre_employment_screened ? "Date" : "Date of Hire"}
                    </Typography>
                    <TextField
                        fullWidth
                        id="date"
                        size="small"
                        variant="outlined"
                        type='date'
                        value={formik.values.date}
                        onChange={(e) => {
                            formik.setFieldValue('date', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                {(!data || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role))) && !isDriverFile ? <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={isCreating || isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                        Save
                    </Button>
                    {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                        <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                            Once you click save, you will not be able to edit this form.
                        </Typography>
                    )}
                </Grid > : null}
                {data && currentUser?.role === "SuperAdmin" && <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={!!member?.terminated_at} onClick={() => {
                        const res = window.confirm("Are you sure you want to delete this form?")
                        if (res && data) {
                            deleteDriverAuthorizationAndSignature(data.id)
                        }
                    }} variant="contained" color="primary">
                        Delete Form
                    </Button>
                </Grid>}
            </Grid >
        </div >
    )
}

export default DriverAuthorizationAndSignature