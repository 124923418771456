import axios from 'axios';
import { IBulkMemberRequestInput, IMemberRequestInput, IMemberRequestUpdate, IPostAccidentRequest } from '../../models';
import client from '../Client';

export async function getMemberRequests() {
    return await client('auth/api/member-request/');
}

export async function getMemberRequestByMember(id: number, requestType?: string) {
    return await client(`auth/api/member-request/member/${id}${requestType ? `?request_type=${requestType}` : ''}`);
}

export async function getRequestStatuses() {
    return await client('auth/api/member-request/request-status');
}

export async function getRequestTypes() {
    return await client('auth/api/member-request/request-type');
}

export async function paymentSuccessLink(id: number, isBulk?: boolean) {
    return await client(`auth/api/member-request/payment-success?request_id=${id}${isBulk ? '&isBulk=true' : ''}`);
}

export async function paymentFailedLink(id: number) {
    return await client(`auth/api/member-request/payment-failed?request_id=${id}`);
}

export async function createMemberRequest(
    memberRequest: IMemberRequestInput &
        Partial<IPostAccidentRequest> & { driver_phone_number?: string; owner_phone_number?: string }
) {
    return await client('auth/api/member-request/', {
        body: memberRequest
    });
}

export const createBulkMemberRequest = async (memberRequest: IBulkMemberRequestInput) => {
    return await client('auth/api/member-request/create-bulk-request/', {
        body: memberRequest
    });
};

export async function updateMemberRequest({
    memberRequest,
    request_type,
    subscription
}: {
    memberRequest: Partial<IMemberRequestUpdate>;
    request_type?: string;
    subscription?: string;
}) {
    return await (
        await axios.put(`${process.env.REACT_APP_API_BASE_URL}/auth/api/member-request/update/${memberRequest.id}/`, {
            ...memberRequest,
            request_type,
            subscription
        })
    ).data;
}
