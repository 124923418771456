import React from 'react'
import { Grid, Typography, Button, FormControlLabel, Checkbox } from '@mui/material'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useThemeMode } from '../../partials'
import { IMember } from '../../../models'
import { IPerformanceHistoryCompleteDto } from '../../../store/interfaces/PreviousEmployeeSafetyPerformanceHistory/driver-identification'
import { useCreatePerformanceHistoryCompleteMutation, useUpdatePerformanceHistoryCompleteMutation, useGetPerformanceHistoryCompleteByUserIdQuery } from '../../../store/reducers/PreviousEmployeeSafetyPerformanceHistory/driver-identification'
import { useAuth } from '../../../app/modules/auth'

const initialValues: Partial<IPerformanceHistoryCompleteDto> = {
    performance_history_complete: false,
}

const validationSchema = Yup.object({
    performance_history_complete: Yup.boolean().required('Required'),
})

interface IProps {
    member?: IMember;
}

const PerformanceHistoryComplete = ({ member }: IProps) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data } = useGetPerformanceHistoryCompleteByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    })

    const [createPerformanceHistoryComplete, { isLoading: isCreating }] = useCreatePerformanceHistoryCompleteMutation()
    const [updatePerformanceHistoryComplete, { isLoading: isUpdating }] = useUpdatePerformanceHistoryCompleteMutation()

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                updatePerformanceHistoryComplete({
                    ...data,
                    ...values,
                    id: data.id
                })
            } else {
                createPerformanceHistoryComplete({
                    ...values,
                    // @ts-ignore
                    company: member?.company?.id,
                    // @ts-ignore,
                    member: member?.id
                })
            }
        },
        validateOnBlur: true,
        validateOnChange: true
    })

    React.useEffect(() => {
        if (data) {
            formik.setValues(data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <>
            {(data && ['Admin', 'SuperAdmin', 'Owner'].includes(currentUser?.role!)) || (!data && ['Admin', 'SuperAdmin'].includes(currentUser?.role!)) ? (
                <div style={{
                    padding: 10,
                    margin: 10
                }}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                        Set Performance History Form Complete
                    </Typography>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="body1" gutterBottom component="p">
                        NOTE: This is to be checked if you are unable to contact the previous employer.
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <FormControlLabel
                                onChange={() => {
                                    formik.setFieldValue("performance_history_complete", !formik.values.performance_history_complete)
                                }}
                                control={<Checkbox checked={formik?.values?.performance_history_complete} />}
                                label="Performance History Forms Complete"
                                disabled={isCreating || isUpdating || Boolean(member?.terminated_at) || !['Admin', 'SuperAdmin'].includes(currentUser?.role!)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <Button fullWidth disabled={isCreating || isUpdating || Boolean(member?.terminated_at) || !['Admin', 'SuperAdmin'].includes(currentUser?.role!)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            ) : null}
        </>
    )
}

export default PerformanceHistoryComplete