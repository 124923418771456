import React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Divider, Grid, TextField, Typography } from '@mui/material'
import { IMember } from '../../../../../../models';
import { IDriverMedicalExaminerInformationAndOwnerSignature, } from '../../../../../../store/interfaces/DriverMedicalExaminerInformationAndOwnerSignature/DriverMedicalExaminerInformationAndOwnerSignature';
import { UserModel, useAuth } from '../../../../auth';
import { useThemeMode } from '../../../../../../compliancemasters/partials';
import { useGetNonCurrentDriverMedicalExaminerInformationAndOwnerSignatureByUserIdQuery } from '../../../../../../store/reducers/DriverMedicalExaminerRegistryVerification/driver-medical-examiner-information-and-owner-signature';




const validationSchema = Yup.object({
    drivers_name: Yup.string().required('Required'),
    drivers_license_number: Yup.string().required('Required'),
    certificate_expiration_date: Yup.string().required('Required'),
    medical_examiner_name: Yup.string().required('Required'),
    national_registry_number: Yup.string().required('Required'),
    motor_carrier: Yup.string().required('Required'),
    location: Yup.string().required('Required'),
    motor_carrier_representative: Yup.string().required('Required'),
    date: Yup.string().required('Required'),
})


const MedicalExamItem = ({ item, currentUser, member }: { item: IDriverMedicalExaminerInformationAndOwnerSignature, currentUser?: UserModel, member?: IMember }) => {
    const { mode } = useThemeMode()
    const formik = useFormik({
        initialValues: item,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            // resetForm()
        },
        validateOnBlur: true,
        validateOnChange: true,
    })
    return (
        <>
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Employee's Name
                </Typography>
                <TextField
                    fullWidth
                    id="drivers_name"
                    placeholder="Employee's Name"
                    size="small"
                    variant="outlined"
                    value={formik.values.drivers_name}
                    disabled={!currentUser || (currentUser && ["Owner", "Member"].includes(currentUser.role)) || (!!member?.terminated_at)}
                    // onChange={(e) => {
                    //     formik.setFieldValue('drivers_name', e.target.value)
                    // }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: formik.touched.drivers_name && Boolean(formik.errors.drivers_name) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: formik.touched.drivers_name && Boolean(formik.errors.drivers_name) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: formik.touched.drivers_name && Boolean(formik.errors.drivers_name) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}

                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Driver's License Number
                </Typography>
                <TextField
                    fullWidth
                    id="drivers_license_number"
                    placeholder='Drivers License Number'
                    size="small"
                    variant="outlined"
                    value={formik.values.drivers_license_number}
                    disabled={!currentUser || (currentUser && ["Owner", "Member"].includes(currentUser.role)) || (!!member?.terminated_at)}
                    onChange={(e) => {
                        formik.setFieldValue('drivers_license_number', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: formik.touched.drivers_license_number && Boolean(formik.errors.drivers_license_number) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: formik.touched.drivers_license_number && Boolean(formik.errors.drivers_license_number) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: formik.touched.drivers_license_number && Boolean(formik.errors.drivers_license_number) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}

                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Expiration Date of Medical Examiner's Certificate
                </Typography>
                <TextField
                    fullWidth
                    id="certificate_expiration_date"
                    size="small"
                    variant="outlined"
                    value={formik.values.certificate_expiration_date}
                    type='date'
                    disabled={!currentUser || (currentUser && ["Owner", "Member"].includes(currentUser.role)) || (!!member?.terminated_at)}
                    onChange={(e) => {
                        formik.setFieldValue('certificate_expiration_date', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: formik.touched.certificate_expiration_date && Boolean(formik.errors.certificate_expiration_date) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: formik.touched.certificate_expiration_date && Boolean(formik.errors.certificate_expiration_date) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: formik.touched.certificate_expiration_date && Boolean(formik.errors.certificate_expiration_date) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}

                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Medical Examiners Name
                </Typography>
                <TextField
                    fullWidth
                    id="medical_examiner_name"
                    placeholder='Medical Examiners Name'
                    size="small"
                    variant="outlined"
                    value={formik.values.medical_examiner_name}
                    disabled={!currentUser || (currentUser && ["Owner", "Member"].includes(currentUser.role)) || (!!member?.terminated_at)}
                    onChange={(e) => {
                        formik.setFieldValue('medical_examiner_name', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: formik.touched.medical_examiner_name && Boolean(formik.errors.medical_examiner_name) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: formik.touched.medical_examiner_name && Boolean(formik.errors.medical_examiner_name) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: formik.touched.medical_examiner_name && Boolean(formik.errors.medical_examiner_name) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}

                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    National Registry Number
                </Typography>
                <TextField
                    fullWidth
                    id="national_registry_number"
                    placeholder='National Registry Number'
                    size="small"
                    variant="outlined"
                    value={formik.values.national_registry_number}
                    disabled={!currentUser || (currentUser && ["Owner", "Member"].includes(currentUser.role)) || (!!member?.terminated_at)}
                    onChange={(e) => {
                        formik.setFieldValue('national_registry_number', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: formik.touched.national_registry_number && Boolean(formik.errors.national_registry_number) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: formik.touched.national_registry_number && Boolean(formik.errors.national_registry_number) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: formik.touched.national_registry_number && Boolean(formik.errors.national_registry_number) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}

                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Motor Carrier
                </Typography>
                <TextField
                    fullWidth
                    id="motor_carrier"
                    placeholder='Motor Carrier'
                    size="small"
                    variant="outlined"
                    value={formik.values.motor_carrier}
                    disabled={!currentUser || (currentUser && ["Owner", "Member"].includes(currentUser.role)) || (!!member?.terminated_at)}
                    // onChange={(e) => {
                    //     formik.setFieldValue('motor_carrier', e.target.value)
                    // }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: formik.touched.motor_carrier && Boolean(formik.errors.motor_carrier) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: formik.touched.motor_carrier && Boolean(formik.errors.motor_carrier) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: formik.touched.motor_carrier && Boolean(formik.errors.motor_carrier) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}

                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Location
                </Typography>
                <TextField
                    fullWidth
                    id="location"
                    placeholder='Location'
                    size="small"
                    variant="outlined"
                    value={formik.values.location}
                    disabled={!currentUser || (currentUser && ["Owner", "Member"].includes(currentUser.role)) || (!!member?.terminated_at)}
                    onChange={(e) => {
                        formik.setFieldValue('location', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: formik.touched.location && Boolean(formik.errors.location) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: formik.touched.location && Boolean(formik.errors.location) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: formik.touched.location && Boolean(formik.errors.location) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}

                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Verified By Motor Carrier Representative Signature
                </Typography>
                <TextField
                    fullWidth
                    id="motor_carrier_representative"
                    placeholder="Verified By Motor Carrier Representative Signature"
                    size="small"
                    variant="outlined"
                    value={formik.values.motor_carrier_representative}
                    disabled={!currentUser || (currentUser && ["Owner", "Member"].includes(currentUser.role)) || (!!member?.terminated_at)}
                    onChange={(e) => {
                        formik.setFieldValue('motor_carrier_representative', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: formik.touched.motor_carrier_representative && Boolean(formik.errors.motor_carrier_representative) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: formik.touched.motor_carrier_representative && Boolean(formik.errors.motor_carrier_representative) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: formik.touched.motor_carrier_representative && Boolean(formik.errors.motor_carrier_representative) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}

                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            fontStyle: "italic"
                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Date
                </Typography>
                <TextField
                    fullWidth
                    id="date"
                    size="small"
                    variant="outlined"
                    value={formik.values.date}
                    type='date'
                    disabled={!currentUser || (currentUser && ["Owner", "Member"].includes(currentUser.role)) || (!!member?.terminated_at)}
                    onChange={(e) => {
                        formik.setFieldValue('date', e.target.value)
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}

                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    FormHelperTextProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Divider sx={{ bordercolor: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', mt: 2, mb: 2 }} />
            </Grid>
        </>
    )
}

const MedicalExaminerNationalRegistryVerification = ({ member }: { member?: IMember }) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data = [] } = useGetNonCurrentDriverMedicalExaminerInformationAndOwnerSignatureByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })


    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                Motor Carrier's Medical Examiner National Registry Verification
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h6" gutterBottom component="h6">
                (This form is to be kept in the driver's qualification file for 3 years)
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify" }} variant="body1" gutterBottom component="p">
                Section 391.23 and Section 391.51 For all Medical Examiner's Certificate
                issued to a commercial motor vehicle driver,
                the motor carrier must verify that the medical
                examiner who signed the driver's medical card is listed on the National Registry.
            </Typography>
            <ul style={{
                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
            }}>
                <li>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify" }} variant="body1" gutterBottom component="p">
                        Section 391.23: The motor carrier must obtain an original or copy of the medical examiner's
                        certificate issued in accordance with Section 391.43, and any medical variance on which
                        the certification is based, and verify the driver was certified by a
                        medical examiner listed on the National Registry of Certified Medical
                        Examiners as of the date issuance of the medical examiner's certificate.
                        Place the records in the driver qualification file before allowing the driver
                        to operate a commercial motor vehicle.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify" }} variant="body1" gutterBottom component="p">
                        Section 391.51: For drivers not required to have a CDL,
                        a note relating to verification of medical examiner listing
                        on the National Registry of Certified Medical Examiners is
                        required by Section 391.23(m)(1). For drivers required to have a CDL,
                        a note relating to verification of medical examiner listing on the
                        National Registry of Certified Medical Examiners is required by
                        Section 391.23(m)(2).
                    </Typography>
                </li >
                <li>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify" }} variant="body1" gutterBottom component="p">
                        MOTOR Carrier VERIFICATION: The following medical examiner has
                        been verified as being listed on the National Registry of
                        Certified Medical Examiners (NRCME) as of the date of the
                        issuance of the medical examiner's certificate for the named driver.
                    </Typography>
                </li >
            </ul >
            <Grid container spacing={2}>
                {data.filter((item) => !item.is_current).map((item, index) => <MedicalExamItem member={member} key={item.id} item={item} currentUser={currentUser} />)}
            </Grid >
        </div >
    )
}

export default MedicalExaminerNationalRegistryVerification