import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../../../app/modules/auth';
import { INotification } from '../../../../../../models/INotification';
import { getNotifications } from '../../../../../../shared/client/notifications';
import { KTSVG } from '../../../../../helpers';
import { HeaderNotificationsMenu } from '../../../../../partials';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
    toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
    toolbarButtonIconSizeClass = 'svg-icon-1';

export default function NotificationHeader() {
    const { currentUser } = useAuth();

    const [notifications, setNotificatioon] = useState<INotification[]>([]);

    useEffect(() => {
        getNotifications().then((res) => {
            setNotificatioon(res);
        });
    }, []);

    if (currentUser?.role === 'Member') return null;

    return (
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
            <div
                className={clsx(
                    'btn btn-icon btn-active-light-primary btn-custom position-relative',
                    toolbarButtonHeightClass
                )}
                data-kt-menu-trigger="click"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
                data-kt-menu-flip="bottom"
            >
                <KTSVG path="/media/icons/duotune/general/gen007.svg" className={toolbarButtonIconSizeClass} />
                {notifications.find((n) => !n.viewed) ? (
                    <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>
                ) : null}
            </div>
            <HeaderNotificationsMenu notifications={notifications} />
        </div>
    );
}
