import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    IRequestForCheckLetter,
    IRequestForCheckLetterDto
} from '../../interfaces/RequestForCheckOfDrivingRecords/request-for-check-letter';

export const requestForCheckLetterApi = createApi({
    reducerPath: 'request_for_check_letter',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/request-for-check-letter`
    }),
    tagTypes: ['RequestForCheckLetter'],
    endpoints: (builder) => ({
        createRequestForCheckLetter: builder.mutation<IRequestForCheckLetter, IRequestForCheckLetterDto>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'RequestForCheckLetter', id: 'RequestForCheckLetter' }]
        }),
        updateRequestForCheckLetter: builder.mutation<IRequestForCheckLetter, IRequestForCheckLetter>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'RequestForCheckLetter', id: 'RequestForCheckLetter' }]
        }),
        deleteRequestForCheckLetter: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'RequestForCheckLetter', id: 'RequestForCheckLetter' }]
        }),
        getRequestForCheckLetter: builder.query<IRequestForCheckLetter, number | void>({
            query: (id) => ({
                url: `/get-request-for-check-letter/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'RequestForCheckLetter', id: 'RequestForCheckLetter' }]
        }),
        getRequestForCheckLetterByUser: builder.query<IRequestForCheckLetter, void>({
            query: () => ({
                url: `/get-request-for-check-letter-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'RequestForCheckLetter', id: 'RequestForCheckLetter' }]
        }),
        getRequestForCheckLetterByUserId: builder.query<IRequestForCheckLetter, number | void>({
            query: (id) => ({
                url: `/get-request-for-check-letter-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'RequestForCheckLetter', id: 'RequestForCheckLetter' }]
        })
    })
});

export const {
    useCreateRequestForCheckLetterMutation,
    useUpdateRequestForCheckLetterMutation,
    useGetRequestForCheckLetterQuery,
    useGetRequestForCheckLetterByUserQuery,
    useGetRequestForCheckLetterByUserIdQuery,
    useDeleteRequestForCheckLetterMutation
} = requestForCheckLetterApi;
