import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { CompanyInvitation } from '../../../context/CompanyInvitation/company.invitation.types';
import { Button, Grid } from '@mui/material';
import { useQuery } from 'react-query';
import { StripeProduct } from '../../modules/models';
import { getSubscriptionPlans } from '../../../shared';
import useCompanyInvitations from '../../../context/CompanyInvitation/CompanyInvitationContextProvider';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    invitation?: CompanyInvitation
}

export default function CompanyInvitationViewModal({ open, setOpen, invitation }: Readonly<IProps>) {
    const { createPaymentLink } = useCompanyInvitations()
    // eslint-disable-next-line
    const { data } = useQuery<{ data: StripeProduct[] }>('packages', async () => getSubscriptionPlans())

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                View Invitation
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        User Name:
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {invitation?.first_name} {invitation?.last_name}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        Email:
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {invitation?.email}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        Email Sent:
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {invitation?.email_sent ? 'Yes' : 'No'}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        Package:
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {/* {data?.data.find((item) => item.price_id === invitation?.company_package)?.name} */}
                    </Grid>
                    {invitation?.payment_link && (
                        <>
                            <Grid item xs={12} sm={6}>
                                Payment Link:
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {invitation?.payment_link}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                Payment Status:
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {invitation?.payment_status}
                            </Grid>
                        </>
                    )}
                    {!invitation?.payment_link && (
                        <Grid item xs={12} sm={12} onClick={() => createPaymentLink(invitation?.email!)}>
                            <Button variant='contained' size="small" fullWidth>
                                Create Payment Link
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
