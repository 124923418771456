import * as React from 'react';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Checkbox, FormControlLabel, Grid, Paper } from '@mui/material';
import { IMember } from '../../../../../models';
import { toast } from 'react-toastify';
import useCompanyInvitations from '../../../../../context/CompanyInvitation/CompanyInvitationContextProvider';
import { useCompany } from '../../../../../context/CompanyContext';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../../app/modules/auth';

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

interface IInviteDriversModal {
    email_list: string[];
}

const initialValues: IInviteDriversModal = {
    email_list: []
};

const memberRequestSchema = Yup.object().shape({
    email_list: Yup.array().min(1).required('Members are required')
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function InviteDriversModal({ open, setOpen }: Readonly<IProps>) {
    const [allSelected, setAllSelected] = React.useState<boolean>(false);
    const { id } = useParams<{ id: string }>();
    const { currentUser } = useAuth();
    const { inviteDrivers } = useCompanyInvitations();
    const { currentCompany, getCurrentCompanyById } = useCompany();

    const handleClose = () => {
        setAllSelected(false);
        formik.resetForm();
        setOpen(false);
    };

    const formik = useFormik({
        initialValues,
        validationSchema: memberRequestSchema,
        onSubmit: async (values) => {
            try {
                await inviteDrivers(values.email_list, Number(currentCompany?.id));
                toast.success('Request Sent');
                setOpen(false);
            } catch (error) {
                console.error('Error creating request', error);
                toast.error('Error creating request');
            }
        }
    });

    React.useEffect(() => {
        if (open && id && currentUser?.role && ['Admin', 'SuperAdmin'].includes(currentUser?.role)) {
            getCurrentCompanyById(Number(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, open]);

    React.useEffect(() => {
        if (allSelected) {
            formik.setFieldValue(
                'email_list',
                currentCompany?.members.map((x) => x.user?.email)
            );
        } else {
            formik.setFieldValue('email_list', []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allSelected]);

    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ textAlign: 'center' }}>Invite Drivers</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <FormControlLabel
                            control={<Checkbox checked={allSelected} onChange={() => setAllSelected(!allSelected)} />}
                            label="Select All"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            component={Paper}
                            elevation={2}
                            boxShadow={3}
                            sx={{
                                minHeight: 300,
                                maxHeight: 300,
                                overflowY: 'auto',
                                padding: 2,
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            {currentCompany?.members.map((member: IMember) => (
                                <FormControlLabel
                                    key={member.id}
                                    control={
                                        <Checkbox
                                            checked={formik.values.email_list.includes(member.user?.email)}
                                            onChange={() => {
                                                if (formik.values.email_list.includes(member.user?.email)) {
                                                    formik.setFieldValue(
                                                        'email_list',
                                                        formik.values.email_list.filter((x) => x !== member.user?.email)
                                                    );
                                                } else {
                                                    formik.setFieldValue('email_list', [
                                                        ...formik.values.email_list,
                                                        member.user?.email
                                                    ]);
                                                }
                                            }}
                                        />
                                    }
                                    label={`${member.user?.first_name} ${member.user?.last_name} - ${member.user?.email}`}
                                />
                            ))}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={formik.isSubmitting || !formik.isValid || formik.values.email_list.length === 0}
                            onClick={formik.submitForm}
                        >
                            Invite Drivers
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
