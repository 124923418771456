import {ICompany, IMember} from '../../../models'
import {IAbstract} from '../abstract'

export enum VehicleTextChoices {
  STRAIGHT_TRUCK = 'Straight Truck',
  TRACTOR_SEMITRAILER = 'Tractor-Semitrailer',
  BUS = 'Bus',
  CARGO_TRUCK = 'Cargo Truck',
  DOUBLES_TRIPLES = 'Doubles/Triples',
  OTHER_SPECIFY = 'Other (Specify)',
}
export enum ReasonForLeavingEmployTextChoices {
  DISCHARGED = 'Discharged',
  RESIGNED = 'Resignation',
  LAY_OFF = 'Lay Off',
  MILITARY_DUTY = 'Military Duty',
}

export interface ISafetyPerformanceHistoryDto {
  safety_performance_history_to_report: boolean
  driver_operated_a: VehicleTextChoices
  if_other_specify: string
  operated_commercial_motor_vehicle: boolean
  reason_for_leaving_employ: ReasonForLeavingEmployTextChoices
  date: string
  location: string
  number_of_injuries: number
  number_of_fatalities: number
  hazmat_spill: boolean
  company: ICompany
  member: IMember
}

export interface ISafetyPerformanceHistory extends ISafetyPerformanceHistoryDto, IAbstract {}

export interface IDriverAccidentHistoryDto {
  driver_has_accident_record: boolean
  other_accident_information_enclosed: boolean
  other_accident_information: string
  signature: string
  date: string
  title: string
  company: ICompany
  member: IMember
}

export interface IDriverAccidentHistory extends IDriverAccidentHistoryDto, IAbstract {}
