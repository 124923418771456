import React from 'react'
import { IMember } from '../../../models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material'
import {
    IMiscellaneousDto
} from '../../../store/interfaces/RecordOfRoadTest/miscellaneous'
import {
    useCreateMiscellaneousMutation,
    useGetMiscellaneousByUserIdQuery,
    useUpdateMiscellaneousMutation,
    useDeleteMiscellaneousMutation
} from '../../../store/reducers/RecordOfRoadTest/miscellaneous'
import { InspectionTextChoices } from '../../../store/interfaces/RecordOfRoadTest/backing-and-parking'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'


const initialValues: Partial<IMiscellaneousDto> = {
    consistently_alert_and_attentive: InspectionTextChoices.NA,
    adjusts_driving_to_meet_changing_conditions: InspectionTextChoices.NA,
    performs_routine_functions_without_taking_eyes_from_road: InspectionTextChoices.NA,
    checks_instruments_regularly_while_driving: InspectionTextChoices.NA,
    willing_to_take_instructions_and_suggestions: InspectionTextChoices.NA,
    is_not_easily_angered: InspectionTextChoices.NA,
    positive_attitude: InspectionTextChoices.NA,
    good_personal_appearance: InspectionTextChoices.NA,
    good_physical_stamina: InspectionTextChoices.NA,
    checks_freight_properly: InspectionTextChoices.NA,
    handles_and_loads_freight_properly: InspectionTextChoices.NA,
    handles_bills_properly: InspectionTextChoices.NA,
    breaks_download_as_required: InspectionTextChoices.NA,
    knowledge_of_company_rules: InspectionTextChoices.NA,
    knowledge_of_regulations_federal_state_local: InspectionTextChoices.NA,
    knowledge_of_special_truck_routes: InspectionTextChoices.NA,
    specify_use_of_special_equipment: InspectionTextChoices.NA,
    remarks: '',
}


const validationSchema = Yup.object({
    consistently_alert_and_attentive: Yup.string().required('Required'),
    adjusts_driving_to_meet_changing_conditions: Yup.string().required('Required'),
    performs_routine_functions_without_taking_eyes_from_road: Yup.string().required('Required'),
    checks_instruments_regularly_while_driving: Yup.string().required('Required'),
    willing_to_take_instructions_and_suggestions: Yup.string().required('Required'),
    is_not_easily_angered: Yup.string().required('Required'),
    positive_attitude: Yup.string().required('Required'),
    good_personal_appearance: Yup.string().required('Required'),
    good_physical_stamina: Yup.string().required('Required'),
    checks_freight_properly: Yup.string().required('Required'),
    handles_and_loads_freight_properly: Yup.string().required('Required'),
    handles_bills_properly: Yup.string().required('Required'),
    breaks_download_as_required: Yup.string().required('Required'),
    knowledge_of_company_rules: Yup.string().required('Required'),
    knowledge_of_regulations_federal_state_local: Yup.string().required('Required'),
    knowledge_of_special_truck_routes: Yup.string().required('Required'),
    specify_use_of_special_equipment: Yup.string().required('Required'),
})


interface IProps {
    member?: IMember
    isDriverFile?: boolean
}


export const Miscellaneous: React.FC<IProps> = ({ member, isDriverFile }) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data } = useGetMiscellaneousByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })

    const [createMiscellaneous, { isLoading: isCreating }] = useCreateMiscellaneousMutation()
    const [updateMiscellaneous, { isLoading: isUpdating }] = useUpdateMiscellaneousMutation()
    const [deleteMiscellaneous] = useDeleteMiscellaneousMutation()

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                updateMiscellaneous({
                    ...values,
                    id: data.id,
                })
            } else {
                // @ts-ignore
                createMiscellaneous({
                    ...values,
                    // @ts-ignore
                    company: member?.company?.id,
                    // @ts-ignore
                    member: member?.id,
                })
            }
            resetForm()
        },
        validateOnBlur: true,
        validateOnChange: true,
    })

    React.useEffect(() => {
        if (data) {
            formik.setValues(data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const setAllAsSatisfactory = () => {
        formik.setValues({
            adjusts_driving_to_meet_changing_conditions: InspectionTextChoices.SATISFACTORY,
            breaks_download_as_required: InspectionTextChoices.SATISFACTORY,
            checks_freight_properly: InspectionTextChoices.SATISFACTORY,
            checks_instruments_regularly_while_driving: InspectionTextChoices.SATISFACTORY,
            consistently_alert_and_attentive: InspectionTextChoices.SATISFACTORY,
            good_personal_appearance: InspectionTextChoices.SATISFACTORY,
            good_physical_stamina: InspectionTextChoices.SATISFACTORY,
            handles_and_loads_freight_properly: InspectionTextChoices.SATISFACTORY,
            handles_bills_properly: InspectionTextChoices.SATISFACTORY,
            is_not_easily_angered: InspectionTextChoices.SATISFACTORY,
            knowledge_of_company_rules: InspectionTextChoices.SATISFACTORY,
            knowledge_of_regulations_federal_state_local: InspectionTextChoices.SATISFACTORY,
            knowledge_of_special_truck_routes: InspectionTextChoices.SATISFACTORY,
            performs_routine_functions_without_taking_eyes_from_road: InspectionTextChoices.SATISFACTORY,
            positive_attitude: InspectionTextChoices.SATISFACTORY,
            specify_use_of_special_equipment: InspectionTextChoices.SATISFACTORY,
            willing_to_take_instructions_and_suggestions: InspectionTextChoices.SATISFACTORY
        })
    }

    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                Miscellaneous
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify" }} variant="body1" gutterBottom component="p">
                For the items that apply, please mark the driver's performance accordingly.
                Explain unsatisfactory items under Remarks.
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Button size="small" fullWidth variant="contained" onClick={setAllAsSatisfactory}>
                        Set all As satisfactory
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        A. General Driving Ability and Habits
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Consistently alert and attentive
                    </Typography>
                    <TextField
                        fullWidth
                        id="consistently_alert_and_attentive"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.consistently_alert_and_attentive}
                        onChange={(e) => {
                            formik.setFieldValue('consistently_alert_and_attentive', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.consistently_alert_and_attentive && Boolean(formik.errors.consistently_alert_and_attentive) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.consistently_alert_and_attentive && Boolean(formik.errors.consistently_alert_and_attentive) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.consistently_alert_and_attentive && Boolean(formik.errors.consistently_alert_and_attentive) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Adjust driving to meet changing conditions
                    </Typography>
                    <TextField
                        fullWidth
                        id="adjusts_driving_to_meet_changing_conditions"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.adjusts_driving_to_meet_changing_conditions}
                        onChange={(e) => {
                            formik.setFieldValue('adjusts_driving_to_meet_changing_conditions', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.adjusts_driving_to_meet_changing_conditions && Boolean(formik.errors.adjusts_driving_to_meet_changing_conditions) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.adjusts_driving_to_meet_changing_conditions && Boolean(formik.errors.adjusts_driving_to_meet_changing_conditions) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.adjusts_driving_to_meet_changing_conditions && Boolean(formik.errors.adjusts_driving_to_meet_changing_conditions) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Performs routine functions without taking eyes from road
                    </Typography>
                    <TextField
                        fullWidth
                        id="performs_routine_functions_without_taking_eyes_from_road"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.performs_routine_functions_without_taking_eyes_from_road}
                        onChange={(e) => {
                            formik.setFieldValue('performs_routine_functions_without_taking_eyes_from_road', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.performs_routine_functions_without_taking_eyes_from_road && Boolean(formik.errors.performs_routine_functions_without_taking_eyes_from_road) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.performs_routine_functions_without_taking_eyes_from_road && Boolean(formik.errors.performs_routine_functions_without_taking_eyes_from_road) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.performs_routine_functions_without_taking_eyes_from_road && Boolean(formik.errors.performs_routine_functions_without_taking_eyes_from_road) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Checks instruments regularly while driving
                    </Typography>
                    <TextField
                        fullWidth
                        id="checks_instruments_regularly_while_driving"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.checks_instruments_regularly_while_driving}
                        onChange={(e) => {
                            formik.setFieldValue('checks_instruments_regularly_while_driving', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.checks_instruments_regularly_while_driving && Boolean(formik.errors.checks_instruments_regularly_while_driving) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.checks_instruments_regularly_while_driving && Boolean(formik.errors.checks_instruments_regularly_while_driving) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.checks_instruments_regularly_while_driving && Boolean(formik.errors.checks_instruments_regularly_while_driving) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Willing to take instructions and suggestions, adequate self confidence in driving
                    </Typography>
                    <TextField
                        fullWidth
                        id="willing_to_take_instructions_and_suggestions"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.willing_to_take_instructions_and_suggestions}
                        onChange={(e) => {
                            formik.setFieldValue('willing_to_take_instructions_and_suggestions', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.willing_to_take_instructions_and_suggestions && Boolean(formik.errors.willing_to_take_instructions_and_suggestions) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.willing_to_take_instructions_and_suggestions && Boolean(formik.errors.willing_to_take_instructions_and_suggestions) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.willing_to_take_instructions_and_suggestions && Boolean(formik.errors.willing_to_take_instructions_and_suggestions) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Is not easily angered
                    </Typography>
                    <TextField
                        fullWidth
                        id="is_not_easily_angered"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.is_not_easily_angered}
                        onChange={(e) => {
                            formik.setFieldValue('is_not_easily_angered', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.is_not_easily_angered && Boolean(formik.errors.is_not_easily_angered) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.is_not_easily_angered && Boolean(formik.errors.is_not_easily_angered) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.is_not_easily_angered && Boolean(formik.errors.is_not_easily_angered) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Positive attitude
                    </Typography>
                    <TextField
                        fullWidth
                        id="positive_attitude"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.positive_attitude}
                        onChange={(e) => {
                            formik.setFieldValue('positive_attitude', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.positive_attitude && Boolean(formik.errors.positive_attitude) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.positive_attitude && Boolean(formik.errors.positive_attitude) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.positive_attitude && Boolean(formik.errors.positive_attitude) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Good personal appearance, manner, cleanliness
                    </Typography>
                    <TextField
                        fullWidth
                        id="good_personal_appearance"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.good_personal_appearance}
                        onChange={(e) => {
                            formik.setFieldValue('good_personal_appearance', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.good_personal_appearance && Boolean(formik.errors.good_personal_appearance) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.good_personal_appearance && Boolean(formik.errors.good_personal_appearance) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.good_personal_appearance && Boolean(formik.errors.good_personal_appearance) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Good physical stamina
                    </Typography>
                    <TextField
                        fullWidth
                        id="good_physical_stamina"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.good_physical_stamina}
                        onChange={(e) => {
                            formik.setFieldValue('good_physical_stamina', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.good_physical_stamina && Boolean(formik.errors.good_physical_stamina) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.good_physical_stamina && Boolean(formik.errors.good_physical_stamina) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.good_physical_stamina && Boolean(formik.errors.good_physical_stamina) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        B. Handling of Freight
                    </Typography>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Checks freight properly
                    </Typography>
                    <TextField
                        fullWidth
                        id="checks_freight_properly"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.checks_freight_properly}
                        onChange={(e) => {
                            formik.setFieldValue('checks_freight_properly', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.checks_freight_properly && Boolean(formik.errors.checks_freight_properly) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.checks_freight_properly && Boolean(formik.errors.checks_freight_properly) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.checks_freight_properly && Boolean(formik.errors.checks_freight_properly) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Handles and loads freight properly
                    </Typography>
                    <TextField
                        fullWidth
                        id="handles_and_loads_freight_properly"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.handles_and_loads_freight_properly}
                        onChange={(e) => {
                            formik.setFieldValue('handles_and_loads_freight_properly', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.handles_and_loads_freight_properly && Boolean(formik.errors.handles_and_loads_freight_properly) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.handles_and_loads_freight_properly && Boolean(formik.errors.handles_and_loads_freight_properly) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.handles_and_loads_freight_properly && Boolean(formik.errors.handles_and_loads_freight_properly) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Handles bills properly
                    </Typography>
                    <TextField
                        fullWidth
                        id="handles_bills_properly"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.handles_bills_properly}
                        onChange={(e) => {
                            formik.setFieldValue('handles_bills_properly', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.handles_bills_properly && Boolean(formik.errors.handles_bills_properly) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.handles_bills_properly && Boolean(formik.errors.handles_bills_properly) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.handles_bills_properly && Boolean(formik.errors.handles_bills_properly) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Breaks download as required
                    </Typography>
                    <TextField
                        fullWidth
                        id="breaks_download_as_required"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.breaks_download_as_required}
                        onChange={(e) => {
                            formik.setFieldValue('breaks_download_as_required', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.breaks_download_as_required && Boolean(formik.errors.breaks_download_as_required) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.breaks_download_as_required && Boolean(formik.errors.breaks_download_as_required) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.breaks_download_as_required && Boolean(formik.errors.breaks_download_as_required) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        C. Rules and Regulations
                    </Typography>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Knowledge of company rules
                    </Typography>
                    <TextField
                        fullWidth
                        id="knowledge_of_company_rules"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.knowledge_of_company_rules}
                        onChange={(e) => {
                            formik.setFieldValue('knowledge_of_company_rules', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.knowledge_of_company_rules && Boolean(formik.errors.knowledge_of_company_rules) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.knowledge_of_company_rules && Boolean(formik.errors.knowledge_of_company_rules) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.knowledge_of_company_rules && Boolean(formik.errors.knowledge_of_company_rules) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Knowledge of regulations: federal, state, local
                    </Typography>
                    <TextField
                        fullWidth
                        id="knowledge_of_regulations_federal_state_local"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.knowledge_of_regulations_federal_state_local}
                        onChange={(e) => {
                            formik.setFieldValue('knowledge_of_regulations_federal_state_local', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.knowledge_of_regulations_federal_state_local && Boolean(formik.errors.knowledge_of_regulations_federal_state_local) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.knowledge_of_regulations_federal_state_local && Boolean(formik.errors.knowledge_of_regulations_federal_state_local) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.knowledge_of_regulations_federal_state_local && Boolean(formik.errors.knowledge_of_regulations_federal_state_local) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Knowledge of special truck routes
                    </Typography>
                    <TextField
                        fullWidth
                        id="knowledge_of_special_truck_routes"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.knowledge_of_special_truck_routes}
                        onChange={(e) => {
                            formik.setFieldValue('knowledge_of_special_truck_routes', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.knowledge_of_special_truck_routes && Boolean(formik.errors.knowledge_of_special_truck_routes) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.knowledge_of_special_truck_routes && Boolean(formik.errors.knowledge_of_special_truck_routes) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.knowledge_of_special_truck_routes && Boolean(formik.errors.knowledge_of_special_truck_routes) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        D. Use of Special Equipment
                    </Typography>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Specify Use of Special Equipment
                    </Typography>
                    <TextField
                        fullWidth
                        id="specify_use_of_special_equipment"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.specify_use_of_special_equipment}
                        onChange={(e) => {
                            formik.setFieldValue('specify_use_of_special_equipment', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.specify_use_of_special_equipment && Boolean(formik.errors.specify_use_of_special_equipment) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.specify_use_of_special_equipment && Boolean(formik.errors.specify_use_of_special_equipment) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.specify_use_of_special_equipment && Boolean(formik.errors.specify_use_of_special_equipment) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Remarks
                    </Typography>
                    <TextField
                        fullWidth
                        id="remarks"
                        size="small"
                        placeholder='Remarks'
                        multiline
                        rows={4}
                        variant="outlined"
                        value={formik.values.remarks}
                        onChange={(e) => {
                            formik.setFieldValue('remarks', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.remarks && Boolean(formik.errors.remarks) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.remarks && Boolean(formik.errors.remarks) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.remarks && Boolean(formik.errors.remarks) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                {(!data || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role))) && !isDriverFile ? <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={isCreating || isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                        Save
                    </Button>
                    {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                        <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                            Once you click save, you will not be able to edit this form.
                        </Typography>
                    )}
                </Grid > : null}
                {data && currentUser?.role === "SuperAdmin" && <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={!!member?.terminated_at} onClick={() => {
                        const res = window.confirm("Are you sure you want to delete this form?")
                        if (res && data) {
                            deleteMiscellaneous(data.id)
                        }
                    }} variant="contained" color="primary">
                        Delete Form
                    </Button>
                </Grid>}
            </Grid >
        </div >
    )
}