import React from 'react'
import RandomTestingTables from '../../../compliancemasters/partials/widgets/tables/RandomTestingTable/RandomTestingTables'

const RandomTestingSelections = () => {
    return (
        <div className="row gy-5 g-xl-8">
            <div className="col-xl-12">
                <RandomTestingTables />
            </div>
        </div>
    )
}

export default RandomTestingSelections