interface IProps {
    progress: number;
    size: number;
    direction?: 'left' | 'right' | 'top' | 'bottom';
    fontSize?: number;
    backgroundColor?: string;
}

export default function ProgressBar({ progress, size, direction = 'top', fontSize = 7, backgroundColor }: IProps) {
    function getProgressColor() {
        switch (true) {
            case progress > 0 && progress < 65:
                return 'bg-warning';
            case progress > 65:
                return 'bg-success';
            default:
                return 'bg-secondary';
        }
    }

    function ProgressText() {
        let classText = '';
        switch (direction) {
            case 'left':
                classText = 'me-2';
                break;
            case 'right':
                classText = 'mx-2';
                break;
            case 'top':
                classText = 'mb-2';
                break;
            case 'bottom':
                classText = 'mt-2';
                break;
        }
        return (
            <div className={`d-flex flex-stack ${classText}`}>
                <span className={`text-muted fs-${fontSize} fw-semibold`}>{`${progress.toFixed(0)}%`}</span>
            </div>
        );
    }

    return (
        <div className={`d-flex ${['top', 'bottom'].includes(direction) ? 'flex-column' : ''} w-100`}>
            {['top', 'left'].includes(direction) ? ProgressText() : <></>}
            <div
                className={`progress h-${size}px w-100 ${backgroundColor} ${
                    ['left', 'right'].includes(direction) ? 'align-self-center' : ''
                }`}
            >
                <div
                    className={`progress-bar ${getProgressColor()}`}
                    role="progressbar"
                    style={{ width: `${progress.toFixed(0)}%` }}
                ></div>
            </div>
            {['bottom', 'right'].includes(direction) ? ProgressText() : <></>}
        </div>
    );
}
