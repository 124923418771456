import axios from 'axios'

const API_URL = `${process.env.REACT_APP_API_BASE_URL}`
const DJANGO_URL = `${process.env.REACT_APP_API_BASE_URL}`

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${DJANGO_URL}/auth/api/user/`
export const REQUEST_PASSWORD_URL = `${DJANGO_URL}/auth/api/password_reset/`
export const REQUEST_PASSWORD_URL_2 = `${DJANGO_URL}/auth/api/password-management/request-password-reset/`
// export const CONFIRM_PASSWORD_URL = `${DJANGO_URL}/api/password_reset/confirm/`
export const CONFIRM_PASSWORD_URL = `${DJANGO_URL}/auth/api/password-management/reset-password/`

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  const headers = {
    'Content-Type': 'application/json',
  }
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL_2, {
    headers: headers,
    email,
  })
}

export function changePassword(passsword: string, token: string) {
  return axios.post<{result: boolean}>(CONFIRM_PASSWORD_URL, {
    token: token,
    password: passsword,
  })
}
