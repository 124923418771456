import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    IHoursWorked,
    IHoursWorkedDto,
    ITotalHours,
    ITotalHoursDto
} from '../../interfaces/DriversStatementDutyHours/application-info-and-hours';

export const totalHoursApi = createApi({
    reducerPath: 'total_hours',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/total-hours`
    }),
    tagTypes: ['TotalHours'],
    endpoints: (builder) => ({
        createTotalHours: builder.mutation<ITotalHours, ITotalHoursDto>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'TotalHours', id: 'TotalHours' }]
        }),
        updateTotalHours: builder.mutation<ITotalHours, ITotalHours>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'TotalHours', id: 'TotalHours' }]
        }),
        deleteTotalHours: builder.mutation<void, number | void>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'TotalHours', id: 'TotalHours' }]
        }),
        getTotalHours: builder.query<ITotalHours, number | void>({
            query: (id) => ({
                url: '/get-total-hours/',
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'TotalHours', id: 'TotalHours' }]
        }),
        getTotalHoursByUser: builder.query<ITotalHours, void>({
            query: () => ({
                url: '/get-total-hours-by-user/',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'TotalHours', id: 'TotalHours' }]
        }),
        getTotalHoursByUserId: builder.query<ITotalHours, number | void>({
            query: (id) => ({
                url: '/get-total-hours-by-user-id/',
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'TotalHours', id: 'TotalHours' }]
        })
    })
});

export const hoursWorkedApi = createApi({
    reducerPath: 'hours_worked',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/hours-worked`
    }),
    tagTypes: ['HoursWorked'],
    endpoints: (builder) => ({
        createHoursWorked: builder.mutation<IHoursWorked, IHoursWorkedDto>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'HoursWorked', id: 'HoursWorked' }]
        }),
        updateHoursWorked: builder.mutation<IHoursWorked, IHoursWorked>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'HoursWorked', id: 'HoursWorked' }]
        }),
        getHoursWorked: builder.query<IHoursWorked[], number | void>({
            query: (id) => ({
                url: '/get-hours-worked/',
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'HoursWorked', id: 'HoursWorked' }]
        }),
        getHoursWorkedByUser: builder.query<IHoursWorked[], void>({
            query: () => ({
                url: '/get-hours-worked-by-user/',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'HoursWorked', id: 'HoursWorked' }]
        }),
        getHoursWorkedByUserId: builder.query<IHoursWorked[], number | void>({
            query: (id) => ({
                url: '/get-hours-worked-by-user-id/',
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'HoursWorked', id: 'HoursWorked' }]
        }),
        deleteHoursWorked: builder.mutation<void, number | void>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'HoursWorked', id: 'HoursWorked' }]
        })
    })
});

export const {
    useCreateTotalHoursMutation,
    useUpdateTotalHoursMutation,
    useGetTotalHoursQuery,
    useGetTotalHoursByUserQuery,
    useGetTotalHoursByUserIdQuery,
    useDeleteTotalHoursMutation
} = totalHoursApi;
export const {
    useCreateHoursWorkedMutation,
    useUpdateHoursWorkedMutation,
    useGetHoursWorkedQuery,
    useGetHoursWorkedByUserQuery,
    useGetHoursWorkedByUserIdQuery,
    useDeleteHoursWorkedMutation
} = hoursWorkedApi;
