/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IUser } from '../../../../../../models';
import { useState } from 'react';
import { useAuth } from '../../../../../../app/modules/auth';
import Button from '../../../../../../app/modules/buttons/Button';
import { KTSVG } from '../../../../../helpers';
import clsx from 'clsx';
import { useNavigation } from '../../../../../../shared';
import DeleteUserModalButton from '../../../../modals/delete-user/DeleteUserModalButton';
import { deleteSalesRep } from '../../../../../../shared/client/sales-rep';

interface IProps {
    user: IUser;
    onActionComplete: () => void;
}
export default function EmployeeRow({ user, onActionComplete }: IProps) {
    const { deleteUser, currentUser } = useAuth();
    const { navigateToCompanyMemberProfile } = useNavigation();

    const [loadingDelete, setLoadingDelete] = useState(false);

    function handleDelete(type: 'soft' | 'hard') {
        setLoadingDelete(true);
        deleteUser(user.email, type).then(() => {
            setLoadingDelete(false);
            onActionComplete();
        });
    }

    function handleDeleteSalesRep(delete_type: 'soft' | 'hard') {
        setLoadingDelete(true);
        deleteSalesRep(Number(user?.sales_rep?.id), delete_type).then(() => {
            setLoadingDelete(false);
            onActionComplete();
        });
    }

    function goToProfile() {
        if (user.role === 'Member') navigateToCompanyMemberProfile(user.role_id);
    }

    return (
        <tr>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column" onClick={goToProfile}>
                        <span
                            className={clsx('text-dark fw-bold fs-6', {
                                'text-hover-primary cursor-pointer': user.role === 'Member'
                            })}
                        >
                            <a className="text-decoration-none text-reset">{user?.first_name && user.last_name ? `${user?.first_name} ${user?.last_name}` : ""}</a>
                        </span>
                    </div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column" onClick={goToProfile}>
                        <span
                            className={clsx('text-dark fw-bold fs-6', {
                                'text-hover-primary cursor-pointer': user.role === 'Member'
                            })}
                        >
                            <a className="text-decoration-none text-reset">{user.email}</a>
                        </span>
                    </div>
                </div>
            </td>
            <td className="text-end ml-5">
                <div className="d-flex flex-column w-100 me-2">
                    <div className="d-flex flex-stack mb-2">
                        <span className="text-dark fw-bold fs-6">{user.role}</span>
                    </div>
                </div>
            </td>
            <td className="d-flex justify-content-end">
                <div className="row gx-2">
                    {user.role === 'Member' ? (
                        <div className="col-auto" onClick={goToProfile}>
                            <Button className={'btn btn-icon btn-bg-light btn-active-color-primary'}>
                                <KTSVG path="/media/icons/duotune/arrows/arr064.svg" className="svg-icon-2" />
                            </Button>
                        </div>
                    ) : null}
                    {currentUser!.role === 'SuperAdmin' ? (
                        <div className="col-auto">
                            <DeleteUserModalButton
                                user={user}
                                handleDelete={user?.sales_rep ? (x) => handleDeleteSalesRep(x) : (x) => handleDelete(x)}
                                loadingDelete={loadingDelete}
                            />
                        </div>
                    ) : null}
                </div>
            </td>
        </tr>
    );
}
