import { lazy, FC, Suspense } from 'react';
import { Route, Routes, } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import { getCSSVariableValue } from '../../compliancemasters/assets/ts/_utils';
import { WithChildren } from '../../compliancemasters/helpers';

export default function NotificationPrivateRoutes() {
    const NotificationPage = lazy(() => import('../modules/notifications/NotificationPage'));

    return (
        <Routes>
            <Route
                path="*"
                element={
                    <SuspensedView>
                        <NotificationPage />
                    </SuspensedView>
                }
            />
        </Routes>
    );
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue('--kt-primary');
    TopBarProgress.config({
        barColors: {
            '0': baseColor
        },
        barThickness: 1,
        shadowBlur: 5
    });
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};
