import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    IPrevPreEmploymentDrugAndAlcoholTestStatement,
    IPrevPreEmploymentDrugAndAlcoholTestStatementDto
} from '../../interfaces/PreviousPreEmploymentDrugAndAlcoholTestStatement/PreviousPreEmploymentEmployeeDrugAndAlcoholTestStatement';

export const prevPreEmploymentDrugAndAlcoholTestStatementApi = createApi({
    reducerPath: 'prev_pre_employment_drug_and_alcohol_test_statement',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/previous-drug-and-alcohol-test-statement`
    }),
    tagTypes: ['PrevPreEmploymentDrugAndAlcoholTestStatement'],
    endpoints: (builder) => ({
        createPrevPreEmploymentDrugAndAlcoholTestStatement: builder.mutation<
            IPrevPreEmploymentDrugAndAlcoholTestStatement,
            IPrevPreEmploymentDrugAndAlcoholTestStatementDto
        >({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'PrevPreEmploymentDrugAndAlcoholTestStatement',
                    id: 'PrevPreEmploymentDrugAndAlcoholTestStatement'
                }
            ]
        }),
        updatePrevPreEmploymentDrugAndAlcoholTestStatement: builder.mutation<
            IPrevPreEmploymentDrugAndAlcoholTestStatement,
            IPrevPreEmploymentDrugAndAlcoholTestStatement
        >({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'PrevPreEmploymentDrugAndAlcoholTestStatement',
                    id: 'PrevPreEmploymentDrugAndAlcoholTestStatement'
                }
            ]
        }),
        deletePrevPreEmploymentDrugAndAlcoholTestStatement: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'PrevPreEmploymentDrugAndAlcoholTestStatement',
                    id: 'PrevPreEmploymentDrugAndAlcoholTestStatement'
                }
            ]
        }),
        getPrevPreEmploymentDrugAndAlcoholTestStatement: builder.query<
            IPrevPreEmploymentDrugAndAlcoholTestStatement,
            number | void
        >({
            query: (id) => ({
                url: `/get-previous-drug-and-alcohol-test-statement/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'PrevPreEmploymentDrugAndAlcoholTestStatement',
                    id: 'PrevPreEmploymentDrugAndAlcoholTestStatement'
                }
            ]
        }),
        getPrevPreEmploymentDrugAndAlcoholTestStatementByUser: builder.query<
            IPrevPreEmploymentDrugAndAlcoholTestStatement,
            void
        >({
            query: () => ({
                url: `/get-previous-drug-and-alcohol-test-statement-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'PrevPreEmploymentDrugAndAlcoholTestStatement',
                    id: 'PrevPreEmploymentDrugAndAlcoholTestStatement'
                }
            ]
        }),
        getPrevPreEmploymentDrugAndAlcoholTestStatementByUserId: builder.query<
            IPrevPreEmploymentDrugAndAlcoholTestStatement,
            number
        >({
            query: (id) => ({
                url: `/get-previous-drug-and-alcohol-test-statement-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'PrevPreEmploymentDrugAndAlcoholTestStatement',
                    id: 'PrevPreEmploymentDrugAndAlcoholTestStatement'
                }
            ]
        })
    })
});

export const {
    useCreatePrevPreEmploymentDrugAndAlcoholTestStatementMutation,
    useUpdatePrevPreEmploymentDrugAndAlcoholTestStatementMutation,
    useGetPrevPreEmploymentDrugAndAlcoholTestStatementQuery,
    useGetPrevPreEmploymentDrugAndAlcoholTestStatementByUserQuery,
    useGetPrevPreEmploymentDrugAndAlcoholTestStatementByUserIdQuery,
    useDeletePrevPreEmploymentDrugAndAlcoholTestStatementMutation
} = prevPreEmploymentDrugAndAlcoholTestStatementApi;
