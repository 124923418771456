import {driverIdentificationApi} from './driver-identification'
import {previousEmployerInformationApi} from './previous-employer-information'
import {prospectiveEmployerInformationApi} from './prospective-employer-information'
import {
  driverAccidentHistoryApi,
  safetyPerformanceHistoryApi,
} from './safety-and-performance-history'
import {previousEmployeeSafetyPerformanceHistoryProgress} from './progress'
import {ownerDatesApi} from './owner-dates'
import {attemptsMadeApi} from './attempts-made'
import {performanceHistoryFormsCompleteApi} from './performance-history-forms-complete'

export const PerformanceHistoryReducers = {
  [driverIdentificationApi.reducerPath]: driverIdentificationApi.reducer,
  [previousEmployerInformationApi.reducerPath]: previousEmployerInformationApi.reducer,
  [prospectiveEmployerInformationApi.reducerPath]: prospectiveEmployerInformationApi.reducer,
  [driverAccidentHistoryApi.reducerPath]: driverAccidentHistoryApi.reducer,
  [safetyPerformanceHistoryApi.reducerPath]: safetyPerformanceHistoryApi.reducer,
  [previousEmployeeSafetyPerformanceHistoryProgress.reducerPath]:
    previousEmployeeSafetyPerformanceHistoryProgress.reducer,
  [ownerDatesApi.reducerPath]: ownerDatesApi.reducer,
  [attemptsMadeApi.reducerPath]: attemptsMadeApi.reducer,
  [performanceHistoryFormsCompleteApi.reducerPath]: performanceHistoryFormsCompleteApi.reducer,
}

export const PerformanceHistoryMiddleware = [
  driverIdentificationApi.middleware,
  previousEmployerInformationApi.middleware,
  prospectiveEmployerInformationApi.middleware,
  driverAccidentHistoryApi.middleware,
  safetyPerformanceHistoryApi.middleware,
  previousEmployeeSafetyPerformanceHistoryProgress.middleware,
  ownerDatesApi.middleware,
  attemptsMadeApi.middleware,
  performanceHistoryFormsCompleteApi.middleware,
]
