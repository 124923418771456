import { IFormStatus } from '../../../models';

interface IProps {
    status: IFormStatus;
    className?: string;
}

export default function StatusTag({ status, className }: IProps) {
    function getStatusColor() {
        switch (status) {
            case 'Expired':
                return 'badge-danger';
            case 'In Progress':
                return 'badge-warning';
            case 'Completed':
                return 'badge-success';
            default:
                return 'badge-secondary';
        }
    }
    return (
        <span
            className={`badge ${getStatusColor()} ${
                className !== undefined ? className : ''
            }`}
        >
            {status}
        </span>
    );
}
