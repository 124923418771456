import { lazy, FC, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import { getCSSVariableValue } from '../../compliancemasters/assets/ts/_utils';
import { WithChildren } from '../../compliancemasters/helpers';
import DvirRoutes from './DvirRoutes';

export default function MemberPrivateRoutes() {
    const ProfilePage = lazy(() => import('../modules/profile/MemberDashboard'));

    return (
        <Routes>
            {/* Pages */}

            <Route
                path="dashboard/*"
                element={
                    <SuspensedView>
                        <ProfilePage />
                    </SuspensedView>
                }
            />
            <Route
                path="driver-inspection-report/*"
                element={<DvirRoutes />}
            />
            <Route path="*" element={<Navigate to="/error/404" />} />
        </Routes>
    );
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue('--kt-primary');
    TopBarProgress.config({
        barColors: {
            '0': baseColor
        },
        barThickness: 1,
        shadowBlur: 5
    });
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};
