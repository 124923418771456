import { Box } from "@mui/material";
import { Link } from "react-router-dom";

export interface ILinkRow1 {
    name: string;
    value: number;
}

export interface IProps {
    current: number
    setCurrent: (value: number) => void;
    links: ILinkRow1[];
}



export const TabRow = ({ current, links, setCurrent }: IProps) => {
    return (
        <div className="d-flex overflow-auto h-75px">
            {links.map((item) => (<ul
                key={item.value}
                className="nav nav-stretch nav-line-tabs nav-line-tabs-3x border-transparent fs-5 fw-bolder flex-nowrap"
            >
                <li className="nav-item">
                    <Link className={`nav-link text-active-primary me-10 ${current === item.value && "active"}`} to="#" onClick={() => setCurrent(item.value)}>
                        {item.name}
                    </Link>
                </li>
            </ul>))}
        </div>
    )
}



export const links: ILinkRow1[] = [
    {
        name: "Overview",
        value: 0
    },
]

export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <>{children}</>
                </Box>
            )}
        </div>
    );
}