import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers'
import {
  IDachCertifiedRecipientDriversSignature,
  IDachCertifiedRecipientDriversSignatureDto,
} from '../../interfaces/DrugandAlcoholEmployeesCertifiedReceipt/dach-certified-recipient-drivers-signature'

export const dachCertifiedRecipientDriversSignatureApi = createApi({
  reducerPath: 'dach_certified_recipient_drivers_signature',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/dach-certified-receipt-drivers-signature`,
  }),
  tagTypes: ['DachCertifiedRecipientDriversSignature'],
  endpoints: (builder) => ({
    createDachCertifiedRecipientDriversSignature: builder.mutation<
      IDachCertifiedRecipientDriversSignature,
      IDachCertifiedRecipientDriversSignatureDto
    >({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {
          type: 'DachCertifiedRecipientDriversSignature',
          id: 'DachCertifiedRecipientDriversSignature',
        },
      ],
    }),
    updateDachCertifiedRecipientDriversSignature: builder.mutation<
      IDachCertifiedRecipientDriversSignature,
      IDachCertifiedRecipientDriversSignature
    >({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {
          type: 'DachCertifiedRecipientDriversSignature',
          id: 'DachCertifiedRecipientDriversSignature',
        },
      ],
    }),
    getDachCertifiedRecipientDriversSignature: builder.query<
      IDachCertifiedRecipientDriversSignature,
      number | void
    >({
      query: (id) => ({
        url: '/get-dach-certified-receipt-drivers-signature/',
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {
          type: 'DachCertifiedRecipientDriversSignature',
          id: 'DachCertifiedRecipientDriversSignature',
        },
      ],
    }),
    getDachCertifiedRecipientDriversSignatureByUser: builder.query<
      IDachCertifiedRecipientDriversSignature,
      void
    >({
      query: () => ({
        url: '/get-dach-certified-receipt-drivers-signature-by-user/',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {
          type: 'DachCertifiedRecipientDriversSignature',
          id: 'DachCertifiedRecipientDriversSignature',
        },
      ],
    }),
    getDachCertifiedRecipientDriversSignatureByUserId: builder.query<
      IDachCertifiedRecipientDriversSignature,
      number | void
    >({
      query: (id) => ({
        url: '/get-dach-certified-receipt-drivers-signature-by-user-id/',
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {
          type: 'DachCertifiedRecipientDriversSignature',
          id: 'DachCertifiedRecipientDriversSignature',
        },
      ],
    }),
    deleteDachCertifiedRecipientDriversSignature: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {
          type: 'DachCertifiedRecipientDriversSignature',
          id: 'DachCertifiedRecipientDriversSignature',
        },
      ],
    }),
  }),
})

export const {
  useCreateDachCertifiedRecipientDriversSignatureMutation,
  useUpdateDachCertifiedRecipientDriversSignatureMutation,
  useGetDachCertifiedRecipientDriversSignatureQuery,
  useGetDachCertifiedRecipientDriversSignatureByUserQuery,
  useGetDachCertifiedRecipientDriversSignatureByUserIdQuery,
  useDeleteDachCertifiedRecipientDriversSignatureMutation,
} = dachCertifiedRecipientDriversSignatureApi
