import React from 'react'
import { IMember } from '../../../models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material'
import {
    IPreTripInspectionAndEmergencyEquipmentDto,

} from '../../../store/interfaces/RecordOfRoadTest/pre-trip-inspection-and-emergency-equipment'
import {
    useCreatePreTripInspectionAndEmergencyEquipmentMutation,
    useUpdatePreTripInspectionAndEmergencyEquipmentMutation,
    useGetPreTripInspectionAndEmergencyEquipmentByUserIdQuery,
    useDeletePreTripInspectionAndEmergencyEquipmentMutation
} from '../../../store/reducers/RecordOfRoadTest/pre-trip-inspection-emergency-equipemnt'
import { InspectionTextChoices } from '../../../store/interfaces/RecordOfRoadTest/backing-and-parking'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'

const initialValues: Partial<IPreTripInspectionAndEmergencyEquipmentDto> = {
    check_general_condition_approaching_unit: InspectionTextChoices.NA,
    look_for_leakage_of_coolants_fuel_lubricants: InspectionTextChoices.NA,
    check_under_hood: InspectionTextChoices.NA,
    check_around_unit: InspectionTextChoices.NA,
    test_brake_action: InspectionTextChoices.NA,
    check_horn: InspectionTextChoices.NA,
    check_instruments_for_normal_readings: InspectionTextChoices.NA,
    check_dash_warning_lights: InspectionTextChoices.NA,
    clean_mirrors_windoes_lights: InspectionTextChoices.NA,
    review_and_sign_previous_report: InspectionTextChoices.NA,
    remarks: '',
}

const validationSchema = Yup.object({
    check_general_condition_approaching_unit: Yup.string().required('Required'),
    look_for_leakage_of_coolants_fuel_lubricants: Yup.string().required('Required'),
    check_under_hood: Yup.string().required('Required'),
    check_around_unit: Yup.string().required('Required'),
    test_brake_action: Yup.string().required('Required'),
    check_horn: Yup.string().required('Required'),
    check_instruments_for_normal_readings: Yup.string().required('Required'),
    check_dash_warning_lights: Yup.string().required('Required'),
    clean_mirrors_windoes_lights: Yup.string().required('Required'),
    review_and_sign_previous_report: Yup.string().required('Required'),
})

const PreTripInspectionAndEmergencyEquipemnt = ({ member, isDriverFile }: { member?: IMember, isDriverFile?: boolean }) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data } = useGetPreTripInspectionAndEmergencyEquipmentByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    })

    const [createPreTripInspectionAndEmergencyEquipment, { isLoading: isCreating }] = useCreatePreTripInspectionAndEmergencyEquipmentMutation()
    const [updatePreTripInspectionAndEmergencyEquipment, { isLoading: isUpdating }] = useUpdatePreTripInspectionAndEmergencyEquipmentMutation()
    const [deletePreTripInspectionAndEmergencyEquipment] = useDeletePreTripInspectionAndEmergencyEquipmentMutation()


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                updatePreTripInspectionAndEmergencyEquipment({
                    ...values,
                    id: data.id,
                })
            } else {
                createPreTripInspectionAndEmergencyEquipment({
                    ...values,
                    // @ts-ignore
                    member: member?.id,
                    // @ts-ignore
                    company: member?.company?.id,
                })
            }
            resetForm()
        },
        validateOnBlur: true,
        validateOnChange: true,
    })

    React.useEffect(() => {
        if (data) {
            formik.setValues(data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const setAllAsSatisfactory = () => {
        formik.setValues({
            check_around_unit: InspectionTextChoices.SATISFACTORY,
            check_dash_warning_lights: InspectionTextChoices.SATISFACTORY,
            check_general_condition_approaching_unit: InspectionTextChoices.SATISFACTORY,
            check_horn: InspectionTextChoices.SATISFACTORY,
            check_instruments_for_normal_readings: InspectionTextChoices.SATISFACTORY,
            check_under_hood: InspectionTextChoices.SATISFACTORY,
            clean_mirrors_windoes_lights: InspectionTextChoices.SATISFACTORY,
            look_for_leakage_of_coolants_fuel_lubricants: InspectionTextChoices.SATISFACTORY,
            review_and_sign_previous_report: InspectionTextChoices.SATISFACTORY,
            test_brake_action: InspectionTextChoices.SATISFACTORY
        })
    }

    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                Pre-Trip Inspection and Emergency Equipment
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify" }} variant="body1" gutterBottom component="p">
                For the items that apply, please mark the driver's performance accordingly.
                Explain unsatisfactory items under Remarks.
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Button size="small" fullWidth variant="contained" onClick={setAllAsSatisfactory}>
                        Set all As satisfactory
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Check general condition approaching unit
                    </Typography>
                    <TextField
                        fullWidth
                        id="check_general_condition_approaching_unit"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.check_general_condition_approaching_unit}
                        onChange={(e) => {
                            formik.setFieldValue('check_general_condition_approaching_unit', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.check_general_condition_approaching_unit && Boolean(formik.errors.check_general_condition_approaching_unit) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.check_general_condition_approaching_unit && Boolean(formik.errors.check_general_condition_approaching_unit) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.check_general_condition_approaching_unit && Boolean(formik.errors.check_general_condition_approaching_unit) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Look for leakage of coolants, fuel, lubricants
                    </Typography>
                    <TextField
                        fullWidth
                        id="look_for_leakage_of_coolants_fuel_lubricants"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.look_for_leakage_of_coolants_fuel_lubricants}
                        onChange={(e) => {
                            formik.setFieldValue('look_for_leakage_of_coolants_fuel_lubricants', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.look_for_leakage_of_coolants_fuel_lubricants && Boolean(formik.errors.look_for_leakage_of_coolants_fuel_lubricants) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.look_for_leakage_of_coolants_fuel_lubricants && Boolean(formik.errors.look_for_leakage_of_coolants_fuel_lubricants) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.look_for_leakage_of_coolants_fuel_lubricants && Boolean(formik.errors.look_for_leakage_of_coolants_fuel_lubricants) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Check under hood - oil, water, general condition of engine compartment, steering
                    </Typography>
                    <TextField
                        fullWidth
                        id="check_under_hood"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.check_under_hood}
                        onChange={(e) => {
                            formik.setFieldValue('check_under_hood', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.check_under_hood && Boolean(formik.errors.check_under_hood) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.check_under_hood && Boolean(formik.errors.check_under_hood) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.check_under_hood && Boolean(formik.errors.check_under_hood) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Check around unit - tires, lights, trailer hookup, brake and light lines, body, doors, horn, windshield wipers
                    </Typography>
                    <TextField
                        fullWidth
                        id="check_around_unit"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.check_around_unit}
                        onChange={(e) => {
                            formik.setFieldValue('check_around_unit', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.check_around_unit && Boolean(formik.errors.check_around_unit) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.check_around_unit && Boolean(formik.errors.check_around_unit) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.check_around_unit && Boolean(formik.errors.check_around_unit) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Test brake action, tractor protection valve, and parking (hand) brake
                    </Typography>
                    <TextField
                        fullWidth
                        id="test_brake_action"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.test_brake_action}
                        onChange={(e) => {
                            formik.setFieldValue('test_brake_action', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.test_brake_action && Boolean(formik.errors.test_brake_action) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.test_brake_action && Boolean(formik.errors.test_brake_action) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.test_brake_action && Boolean(formik.errors.test_brake_action) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Check horn, windshield wipers, mirrors, emergency equipment; reflectors,
                        flares, fuses, tire chains (if necessary), fire extinguisher
                    </Typography>
                    <TextField
                        fullWidth
                        id="check_horn"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.check_horn}
                        onChange={(e) => {
                            formik.setFieldValue('check_horn', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.check_horn && Boolean(formik.errors.check_horn) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.check_horn && Boolean(formik.errors.check_horn) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.check_horn && Boolean(formik.errors.check_horn) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Check instruments for normal readings
                    </Typography>
                    <TextField
                        fullWidth
                        id="check_instruments_for_normal_readings"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.check_instruments_for_normal_readings}
                        onChange={(e) => {
                            formik.setFieldValue('check_instruments_for_normal_readings', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.check_instruments_for_normal_readings && Boolean(formik.errors.check_instruments_for_normal_readings) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.check_instruments_for_normal_readings && Boolean(formik.errors.check_instruments_for_normal_readings) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.check_instruments_for_normal_readings && Boolean(formik.errors.check_instruments_for_normal_readings) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Check dashboard warning lights for proper functioning
                    </Typography>
                    <TextField
                        fullWidth
                        id="check_dash_warning_lights"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.check_dash_warning_lights}
                        onChange={(e) => {
                            formik.setFieldValue('check_dash_warning_lights', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.check_dash_warning_lights && Boolean(formik.errors.check_dash_warning_lights) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.check_dash_warning_lights && Boolean(formik.errors.check_dash_warning_lights) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.check_dash_warning_lights && Boolean(formik.errors.check_dash_warning_lights) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Clean windshield, windows, mirrors, lights, reflectors
                    </Typography>
                    <TextField
                        fullWidth
                        id="clean_mirrors_windoes_lights"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.clean_mirrors_windoes_lights}
                        onChange={(e) => {
                            formik.setFieldValue('clean_mirrors_windoes_lights', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.clean_mirrors_windoes_lights && Boolean(formik.errors.clean_mirrors_windoes_lights) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.clean_mirrors_windoes_lights && Boolean(formik.errors.clean_mirrors_windoes_lights) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.clean_mirrors_windoes_lights && Boolean(formik.errors.clean_mirrors_windoes_lights) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Review and sign previous report
                    </Typography>
                    <TextField
                        fullWidth
                        id="review_and_sign_previous_report"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.review_and_sign_previous_report}
                        onChange={(e) => {
                            formik.setFieldValue('review_and_sign_previous_report', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.review_and_sign_previous_report && Boolean(formik.errors.review_and_sign_previous_report) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.review_and_sign_previous_report && Boolean(formik.errors.review_and_sign_previous_report) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.review_and_sign_previous_report && Boolean(formik.errors.review_and_sign_previous_report) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Remarks
                    </Typography>
                    <TextField
                        fullWidth
                        id="remarks"
                        size="small"
                        variant="outlined"
                        multiline
                        rows={4}
                        value={formik.values.remarks}
                        onChange={(e) => {
                            formik.setFieldValue('remarks', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.remarks && Boolean(formik.errors.remarks) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.remarks && Boolean(formik.errors.remarks) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.remarks && Boolean(formik.errors.remarks) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid >
                {(!data || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role))) && !isDriverFile ? <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={isCreating || isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                        Save
                    </Button>
                    {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                        <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                            Once you click save, you will not be able to edit this form.
                        </Typography>
                    )}
                </Grid > : null}
                {data && currentUser?.role === "SuperAdmin" && <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={!!member?.terminated_at} onClick={() => {
                        const res = window.confirm("Are you sure you want to delete this form?")
                        if (res && data) {
                            deletePreTripInspectionAndEmergencyEquipment(data.id)
                        }
                    }} variant="contained" color="primary">
                        Delete Form
                    </Button>
                </Grid>}
            </Grid >
        </div >
    )
}

export default PreTripInspectionAndEmergencyEquipemnt