import React from 'react'
import { IMember } from '../../../models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material'
import { ITrafficConvictions, ITrafficConvictionsDto } from '../../../store/interfaces/EmploymentHistory/traffic-convictions'
import {
    useCreateTrafficConvictionsMutation,
    useDeleteTrafficConvictionsMutation,
    useGetTrafficConvictionsByUserIdQuery,
    useUpdateTrafficConvictionsMutation,
    useHasTrafficConvictionsMutation,
    useGetHasTrafficConvictionsQuery,

} from '../../../store/reducers/EmploymentHistory/traffic-convictions'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'


const initialValues: Partial<ITrafficConvictionsDto> = {
    location: '',
    date: '',
    violation: ''
}

const validationSchema = Yup.object({
    location: Yup.string().required('Required'),
    date: Yup.string().required('Required'),
    violation: Yup.string().required('Required')
})


const TrafficConvictionItem = ({ item, member, isDriverFile }: { item: ITrafficConvictions; member?: IMember, isDriverFile?: boolean }) => {
    const { currentUser } = useAuth()
    const [updateTrafficConviction, { isLoading: isUpdating }] = useUpdateTrafficConvictionsMutation()
    const { mode } = useThemeMode()
    const [deleteTrafficConviction, { isLoading: isDeleting }] = useDeleteTrafficConvictionsMutation()

    const formik = useFormik({
        initialValues: item,
        validationSchema,
        onSubmit: (values) => {
            updateTrafficConviction(values)
            formik.resetForm()
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate: (values) => {
            const errors: Partial<ITrafficConvictionsDto> = {}
            if (!values.location) {
                errors.location = 'Required'
            }
            if (!values.date) {
                errors.date = 'Required'
            }
            if (!values.violation) {
                errors.violation = 'Required'
            }
            return errors
        }
    })

    React.useEffect(() => {
        formik.setValues(item)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])

    return (
        <>
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Location
                </Typography>
                <TextField
                    fullWidth
                    id="location"
                    placeholder="Location"
                    size="small"
                    variant="outlined"
                    {...formik.getFieldProps('location')}
                    className={formik.touched.location && Boolean(formik.errors.location) ? "green" : "red"}
                    error={formik.touched.location && Boolean(formik.errors.location)}
                    helperText={formik.touched.location && formik.errors.location}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: formik.touched.location && Boolean(formik.errors.location) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: formik.touched.location && Boolean(formik.errors.location) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: formik.touched.location && Boolean(formik.errors.location) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    disabled={!!member?.terminated_at}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Date
                </Typography>
                <TextField
                    fullWidth
                    id="date"
                    placeholder="Date"
                    size="small"
                    variant="outlined"
                    {...formik.getFieldProps('date')}
                    type='date'
                    className={formik.touched.date && Boolean(formik.errors.date) ? "green" : "red"}
                    error={formik.touched.date && Boolean(formik.errors.date)}
                    helperText={formik.touched.date && formik.errors.date}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    disabled={!!member?.terminated_at}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                />
            </Grid >
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                    Violation
                </Typography>
                <TextField
                    fullWidth
                    id="violation"
                    placeholder="Violation"
                    size="small"
                    variant="outlined"
                    {...formik.getFieldProps('violation')}
                    className={formik.touched.violation && Boolean(formik.errors.violation) ? "green" : "red"}
                    error={formik.touched.violation && Boolean(formik.errors.violation)}
                    helperText={formik.touched.violation && formik.errors.violation}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: formik.touched.violation && Boolean(formik.errors.violation) ? "red" : "green",
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: formik.touched.violation && Boolean(formik.errors.violation) ? "red" : "green",
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: formik.touched.violation && Boolean(formik.errors.violation) ? "red" : "green",
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                    disabled={!!member?.terminated_at}
                    InputProps={{
                        style: {
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }
                    }}
                />
            </Grid >
            {(!item || (item && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role))) && !isDriverFile ? <>
                <Grid item xs={12} sm={6}>
                    <Button fullWidth disabled={isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                        Update
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button fullWidth disabled={isDeleting || (!!member?.terminated_at)} onClick={() => {
                        const res = window.confirm('Are you sure you want to delete this item?')
                        if (res) {
                            deleteTrafficConviction(item.id)
                        }
                    }} variant="contained" color="error">
                        Delete
                    </Button>
                </Grid>
            </> : null}
        </>
    )
}


const TrafficConvictions = ({ member, isDriverFile }: { member?: IMember, isDriverFile?: boolean }) => {
    const { data = [] } = useGetTrafficConvictionsByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    })
    const { data: hasTrafficConvictions } = useGetHasTrafficConvictionsQuery(Number(member?.id), {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    })
    const { currentUser } = useAuth()
    const [hasTrafficConvictionsMutation] = useHasTrafficConvictionsMutation()
    const [createTrafficConviction, { isLoading: isCreating }] = useCreateTrafficConvictionsMutation()
    const { mode } = useThemeMode()
    const [addNewItem, setAddNewItem] = React.useState(true)

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            createTrafficConviction({
                ...values,
                // @ts-ignore
                member: member?.id,
                // @ts-ignore
                company: member?.company?.id
            })
            formik.resetForm()
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate: (values) => {
            const errors: Partial<ITrafficConvictionsDto> = {}
            if (!values.location) {
                errors.location = 'Required'
            }
            if (!values.date) {
                errors.date = 'Required'
            }
            if (!values.violation) {
                errors.violation = 'Required'
            }
            return errors
        }
    })



    React.useEffect(() => {
        if (data.length > 0) {
            setAddNewItem(false)
        } else {
            setAddNewItem(true)
        }
    }, [data])

    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                Traffic Convictions
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                Traffic Convictions and forfeitures for the past three years or more
            </Typography>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <FormControlLabel
                    sx={{
                        color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                    }}
                    control={
                        <Checkbox sx={{
                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        }}
                            onClick={() => {
                                hasTrafficConvictionsMutation({
                                    company: Number(member?.company?.id),
                                    member: Number(member?.id),
                                    has_traffic_convictions: !hasTrafficConvictions?.has_traffic_convictions,
                                    id: hasTrafficConvictions?.id
                                })
                            }}
                            disabled={member?.terminated_at || data.length > 0 ? true : false}
                            checked={hasTrafficConvictions?.has_traffic_convictions ? true : false} />
                    }
                    label="Do you have traffic convictions?"

                />
            </div>
            {
                hasTrafficConvictions && hasTrafficConvictions?.has_traffic_convictions ? (
                    <Grid container spacing={3}>
                        {data.map((item) => (
                            <TrafficConvictionItem member={member} key={item.id} item={item} />
                        ))}
                        {data.length > 0 && <Grid item xs={12} sm={12} sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <FormControlLabel sx={{
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }} control={<Checkbox sx={{
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }} checked={addNewItem} />} onChange={() => setAddNewItem(!addNewItem)} label="Add Another Traffic Conviction" />
                        </Grid>}
                        {addNewItem && <>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                    Location
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="location"
                                    placeholder="Location"
                                    size="small"
                                    variant="outlined"
                                    {...formik.getFieldProps('location')}
                                    className={formik.touched.location && Boolean(formik.errors.location) ? "green" : "red"}
                                    error={formik.touched.location && Boolean(formik.errors.location)}
                                    helperText={formik.touched.location && formik.errors.location}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.location && Boolean(formik.errors.location) ? "red" : "green",
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.location && Boolean(formik.errors.location) ? "red" : "green",
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.location && Boolean(formik.errors.location) ? "red" : "green",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                    InputProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                    Date of Traffic Conviction
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="date"
                                    size="small"
                                    variant="outlined"
                                    {...formik.getFieldProps('date')}
                                    type='date'
                                    className={formik.touched.date && Boolean(formik.errors.date) ? "green" : "red"}
                                    error={formik.touched.date && Boolean(formik.errors.date)}
                                    helperText={formik.touched.date && formik.errors.date}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                    InputProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                    Violation
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="violation"
                                    placeholder="Violation"
                                    size="small"
                                    variant="outlined"
                                    {...formik.getFieldProps('violation')}
                                    className={formik.touched.violation && Boolean(formik.errors.violation) ? "green" : "red"}
                                    error={formik.touched.violation && Boolean(formik.errors.violation)}
                                    helperText={formik.touched.violation && formik.errors.violation}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.violation && Boolean(formik.errors.violation) ? "red" : "green",
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.violation && Boolean(formik.errors.violation) ? "red" : "green",
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor: formik.touched.violation && Boolean(formik.errors.violation) ? "red" : "green",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                    InputProps={{
                                        style: {
                                            color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                        }
                                    }}
                                />
                            </Grid >
                            {!isDriverFile && <Grid item xs={12} sm={12}>
                                <Button fullWidth disabled={isCreating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                                    Save
                                </Button>
                                {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                                    <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                                        Once you click save, you will not be able to edit this form.
                                    </Typography>
                                )}
                            </Grid>}
                        </>}
                    </Grid >
                ) : null}
        </div >
    )
}

export default TrafficConvictions