import React from 'react'
import { IMember } from '../../../models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Grid, TextField, Typography } from '@mui/material'
import {
    ICheckOfDrivingRecordsDriverSignatureDto
} from '../../../store/interfaces/RequestForCheckOfDrivingRecords/driver-signature'
import {
    useCreateDriverSignatureMutation,
    useUpdateDriverSignatureMutation,
    useGetDriverSignatureByUserIdQuery,
    useDeleteDriverSignatureMutation
} from '../../../store/reducers/RequestForCheckOfDrivingRecords/driver-signature'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'
import { useGetProcessRecordByUserIdQuery } from '../../../store/reducers/DriversApplicationForEmployment/process-record'
import { useGetCertificationByUserIdQuery } from '../../../store/reducers/CertificateOfComplianceWithDriverLicenseRequirements/certification'


const initialValues: Partial<ICheckOfDrivingRecordsDriverSignatureDto> = {
    authorize_employer: '',
    drivers_signature: '',
    date: '',
}

const validationSchema = Yup.object({
    authorize_employer: Yup.string().required('Required'),
    drivers_signature: Yup.string().required('Required'),
    date: Yup.string().required('Required'),
})

const DriversSignature = ({ member, isDriverFile }: { member?: IMember, isDriverFile?: boolean }) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data } = useGetDriverSignatureByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })

    const { data: cert } = useGetCertificationByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })

    const { data: processRecord } = useGetProcessRecordByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    })

    const [createDriverSignature, { isLoading: isCreating }] = useCreateDriverSignatureMutation()
    const [updateDriverSignature, { isLoading: isUpdating }] = useUpdateDriverSignatureMutation()
    const [deleteDriverSignature] = useDeleteDriverSignatureMutation()

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                await updateDriverSignature({
                    // @ts-ignore
                    ...values,
                    // @ts-ignore
                    id: data.id,
                })
            } else {
                await createDriverSignature({
                    ...values,
                    // @ts-ignore
                    member: member?.id,
                    // @ts-ignore
                    company: member?.company?.id
                })
            }
            resetForm()
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate: (values) => {
            const errors: Partial<ICheckOfDrivingRecordsDriverSignatureDto> = {}
            if (!values.authorize_employer) {
                errors.authorize_employer = 'Required'
            }
            if (!values.drivers_signature) {
                errors.drivers_signature = 'Required'
            }
            if (!values.date) {
                errors.date = 'Required'
            }
            return errors
        }
    })

    React.useEffect(() => {
        if (data) {
            formik.setValues(data)
        } else if (member) {
            formik.setFieldValue("authorize_employer", member?.company?.name)
            if (processRecord) {
                formik.setFieldValue("date", processRecord.date_employed)
            } else if (cert) {
                formik.setFieldValue("date", cert.date_of_hire)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, member, processRecord, cert])

    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                Driver Signature
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h5" gutterBottom component="h5">
                REQUEST FOR CHECK OF DRIVING RECORDS
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify", mt: 5 }} variant="h6" gutterBottom component="h6">
                NOTE: This form can only be used in states that don't have their own required form.
                Section (391.23)(391.25) – Each motor carrier is required to investigate each applicant's
                driving record for the preceding 3 years. A copy of the record will be automatically uploaded
                into the necessary driver's file within 30 days of employee's start date.
                Regulations also require motor carriers to obtain annual driving records for each
                driver and obtain a driving record within 15 days of a driver's medical examination.
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify", mt: 5 }} variant="h6" gutterBottom component="h6">
                When using a third-party administrator to obtain background information (motor vehicle records,
                previous employers, criminal background checks, or credit reporting) on existing employees or new applicants,
                you are subject the Fair Credit Reporting Act.
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        I hereby authorize you to release the following information to (Employer):
                    </Typography>
                    <TextField
                        fullWidth
                        id="authorize_employer"
                        placeholder='Employer'
                        size="small"
                        variant="outlined"
                        value={formik.values.authorize_employer}
                        // onChange={(e) => {
                        //     formik.setFieldValue('authorize_employer', e.target.value)
                        // }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.authorize_employer && Boolean(formik.errors.authorize_employer) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.authorize_employer && Boolean(formik.errors.authorize_employer) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.authorize_employer && Boolean(formik.errors.authorize_employer) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify", mt: 5 }} variant="h6" gutterBottom component="h6">
                        For purposes of investigation, required by Sections 391.23 and 391.25 of the
                        Federal Motor Carrier Safety Regulations, you are released from all liability
                        which may result from providing such information
                    </Typography>
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Drivers Signature
                    </Typography>
                    <TextField
                        fullWidth
                        id="drivers_signature"
                        placeholder='Drivers Signature'
                        size="small"
                        variant="outlined"
                        value={formik.values.drivers_signature}
                        onChange={(e) => {
                            formik.setFieldValue('drivers_signature', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_signature && Boolean(formik.errors.drivers_signature) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_signature && Boolean(formik.errors.drivers_signature) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drivers_signature && Boolean(formik.errors.drivers_signature) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                fontStyle: "italic"
                            }
                        }}
                    />
                </Grid >
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Date of Hire
                    </Typography>
                    <TextField
                        fullWidth
                        id="date"
                        placeholder='Date'
                        size="small"
                        variant="outlined"
                        type='date'
                        value={formik.values.date}
                        onChange={(e) => {
                            formik.setFieldValue('date', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                {(!data || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role))) && !isDriverFile ? <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={isCreating || isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                        Save
                    </Button>
                    {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                        <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                            Once you click save, you will not be able to edit this form.
                        </Typography>
                    )}
                </Grid > : null}
                {data && currentUser?.role === "SuperAdmin" && <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={!!member?.terminated_at} onClick={() => {
                        const res = window.confirm("Are you sure you want to delete this form?")
                        if (res && data) {
                            deleteDriverSignature(data.id)
                        }
                    }} variant="contained" color="primary">
                        Delete Form
                    </Button>
                </Grid>}
            </Grid >
        </div >
    )
}

export default DriversSignature