import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import { IBackingAndParking, IBackingAndParkingDto } from '../../interfaces/RecordOfRoadTest/backing-and-parking';

export const backingAndParkingApi = createApi({
    reducerPath: 'backing_and_parking',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/backing-and-parking`
    }),
    tagTypes: ['BackingAndParking'],
    endpoints: (builder) => ({
        createBackingAndParking: builder.mutation<IBackingAndParking, IBackingAndParkingDto>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'BackingAndParking', id: 'BackingAndParking' }]
        }),
        updateBackingAndParking: builder.mutation<IBackingAndParking, IBackingAndParking>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'BackingAndParking', id: 'BackingAndParking' }]
        }),
        deleteBackingAndParking: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'BackingAndParking', id: 'BackingAndParking' }]
        }),
        getBackingAndParking: builder.query<IBackingAndParking, number | void>({
            query: (id) => ({
                url: `/get-backing-and-parking/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'BackingAndParking', id: 'BackingAndParking' }]
        }),
        getBackingAndParkingByUser: builder.query<IBackingAndParking, void>({
            query: () => ({
                url: `/get-backing-and-parking-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'BackingAndParking', id: 'BackingAndParking' }]
        }),
        getBackingAndParkingByUserId: builder.query<IBackingAndParking, number | void>({
            query: (id) => ({
                url: `/get-backing-and-parking-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'BackingAndParking', id: 'BackingAndParking' }]
        })
    })
});

export const {
    useCreateBackingAndParkingMutation,
    useUpdateBackingAndParkingMutation,
    useGetBackingAndParkingQuery,
    useGetBackingAndParkingByUserQuery,
    useGetBackingAndParkingByUserIdQuery,
    useDeleteBackingAndParkingMutation
} = backingAndParkingApi;
