/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useIntl } from 'react-intl';
import { AsideMenuItem } from '../AsideMenuItem';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface IPayloadProps {
    pmProgramEnabled: boolean;
    pmProgramEnabledUrl: { urls: string[] };
    customPricingActive: {
        enabled: boolean;
        urls: string[];
    };
}

export function SuperAdminAsideMenu() {
    const intl = useIntl();
    const { customPricingActive } = useFlags<IPayloadProps>();

    console.log('Flags: ', customPricingActive);

    return (
        <>
            <AsideMenuItem
                to="/dashboard"
                icon="/media/icons/duotune/art/art002.svg"
                title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
                fontIcon="bi-app-indicator"
            />
            <AsideMenuItem
                to="/memberrequests"
                icon="/media/icons/duotune/art/art002.svg"
                title={'Member Requests'}
                fontIcon="bi-app-indicator"
            />
            <AsideMenuItem
                to="/expiring-files"
                icon="/media/icons/duotune/files/fil012.svg"
                title="Expiring Files"
                fontIcon="bi-app-indicator"
            />
            <AsideMenuItem
                to="/preemployment-requests"
                icon="/media/icons/duotune/art/art007.svg"
                title={'Pre-Employment Requests'}
                fontIcon="bi-app-indicator"
            />
            <AsideMenuItem
                to="/user-roles"
                icon="/media/icons/duotune/art/art008.svg"
                title={'User Roles'}
                fontIcon="bi-app-indicator"
            />
            <AsideMenuItem
                to="/sales-reps"
                icon="/media/icons/duotune/art/art009.svg"
                title={'Sales Reps'}
                fontIcon="bi-app-indicator"
            />
            <AsideMenuItem
                to="/sales-rep-training"
                icon="/media/icons/duotune/art/art009.svg"
                title={'Sales Rep Training'}
                fontIcon="bi-app-indicator"
            />
            <AsideMenuItem
                title={'Random Drug and Alcohol Testing Selections'}
                to="/random-testing-selections"
                icon="/media/icons/duotune/medicine/med001.svg"
                fontIcon="bi-app-indicator"
            />
            <AsideMenuItem
                title={'Billing and Products'}
                to="/billing"
                icon="/media/icons/duotune/finance/fin010.svg"
                fontIcon="bi-app-indicator"
            />
        </>
    );
}
