import { KTSVG } from '../../../helpers';

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}
export default function NewRandomTestingSelection({ open, setOpen }: IProps) {
    return (
        <>
            <div
                className="btn btn-sm btn-light-primary d-inline-flex align-items-center"
                onClick={() => setOpen(true)}
            >
                <KTSVG path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
                Add a random selection notification
            </div>
        </>
    );
}
