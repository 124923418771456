import * as React from 'react';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { ISalesRep, updateSalesRepCompanies } from '../../../shared/client/sales-rep';
import { toast } from 'react-toastify';
import { Box, Chip, Grid, InputLabel, MenuItem, Paper, TextField } from '@mui/material';
import { ICompany } from '../../../models';
import { getCompanies } from '../../../shared';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const initialValues: {
    companies: number[]
    id: number
} = {
    companies: [],
    id: 0,
}

const validationSchema = Yup.object({
    companies: Yup.array().required('Required'),
    id: Yup.number().required('Required'),
})

export default function UpdateSalesRepCompanies({
    open,
    setOpen,
    salesRep,
    getSalesReps,
}: {
    open: boolean
    setOpen: (open: boolean) => void
    salesRep?: ISalesRep
    getSalesReps: () => void
}) {
    const [companies, setCompanies] = React.useState<ICompany[]>([])
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (salesRep) {
                await updateSalesRepCompanies({
                    companies: values.companies,
                    id: values.id,
                }).then((res) => {
                    toast.success('Sales Rep updated successfully')
                    setOpen(false)
                    getSalesReps()
                    resetForm()
                }).catch((err) => {
                    toast.error(err.message)
                })
            }
            getSalesReps()
            setOpen(false)
        },
    })

    const handleClose = () => {
        setOpen(false);
        formik.resetForm()
    };

    React.useEffect(() => {
        if (salesRep && open) {
            getCompanies().then((res) => {
                setCompanies(res)
                formik.setValues({
                    id: salesRep.id,
                    companies: salesRep.companies.map((company) => Number(company.id)),
                })
            }).catch((err) => {
                toast.error(err.message)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, salesRep])

    console.log(formik.errors)

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            maxWidth='sm'
            fullWidth
        >
            <DialogTitle sx={{
                backgroundColor: '#1e1e2d',
                color: 'white',
                textAlign: 'center'
            }}>
                {salesRep ? `Update Sales Rep profile for ${salesRep?.user?.first_name} ${salesRep.user?.last_name}` : 'Add New Sales Rep'}
            </DialogTitle>
            <DialogContent sx={{
                p: 4
            }}>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12} sm={12}>
                        <InputLabel>
                            Company
                        </InputLabel>
                        <TextField
                            fullWidth
                            size='small'
                            id="company"
                            name="company"
                            onChange={(e) => {
                                // Check if company is already in the list
                                const comp = formik.values.companies.find(x => Number(x) === Number(e.target.value))
                                if (comp) {
                                    return toast.error('Company already added')
                                } else {
                                    const company = companies.find(x => Number(x.id) === Number(e.target.value))
                                    if (company) {
                                        formik.setFieldValue('companies', [company.id, ...formik.values.companies])
                                    }
                                }
                            }}
                            select
                        >
                            {companies.filter(x => +x.id !== formik.values.companies.find(t => t === Number(x.id))).map((company: ICompany) => (
                                <MenuItem key={company.id} value={company.id}>
                                    {company.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            padding: 3,
                        }} component={Paper}>
                            {formik.values.companies.map((company) => {
                                const companyObj = companies.find((comp) => Number(comp.id) === Number(company))
                                return (
                                    <Chip label={companyObj?.name} variant="outlined" onDelete={() => {
                                        formik.setFieldValue('companies', formik.values.companies.filter(x => x !== company))
                                    }} />
                                )
                            })}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{
                                backgroundColor: '#1e1e2d',
                            }}
                            color="primary"
                            onClick={() => {
                                formik.handleSubmit()
                            }}
                        >
                            {salesRep ? 'Update Sales Rep' : 'Add Sales Rep'}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
