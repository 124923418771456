import { createRoot } from 'react-dom/client';

// Axios
import axios from 'axios';
import { Chart, registerables } from 'chart.js';
import { QueryClient, QueryClientProvider } from 'react-query';
// Apps
import { MetronicI18nProvider } from './compliancemasters/i18n/Metronici18n';
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './compliancemasters/assets/sass/style.scss';
import './compliancemasters/assets/sass/plugins.scss';
import './compliancemasters/assets/sass/style.react.scss';
import 'react-toastify/dist/ReactToastify.css';
import { AppRoutes } from './app/routing/AppRoutes';
import { AuthProvider, setupAxios } from './app/modules/auth';
import { Provider } from 'react-redux';
import { store } from './store';
import { ToastContainer } from 'react-toastify';
import { ThemeModeProvider } from './compliancemasters/partials';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

const AppRoot = () => {
    return (
        <ThemeModeProvider>
            <QueryClientProvider client={queryClient}>
                <MetronicI18nProvider>
                    <AuthProvider>
                        <Provider store={store}>
                            <AppRoutes />
                        </Provider>
                    </AuthProvider>
                </MetronicI18nProvider>
                {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                <ToastContainer />
            </QueryClientProvider>
        </ThemeModeProvider>
    );
};

const LDProvider = withLDProvider({
    clientSideID: '659ef5fa6f94d4103794d24c',
    options: { bootstrap: 'localStorage' }
})(AppRoot);

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios);
Chart.register(...registerables);

const queryClient = new QueryClient();
const container = document.getElementById('root');
if (container) {
    createRoot(container).render(<LDProvider />);
}
