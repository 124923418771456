import { ICompany } from './ICompany';
import { DriverType, IUser } from './IUser';

export enum InvestigationType {
    PRE_EMPLOYMENT_FORMS = 'Pre-Employment Forms',
    PRE_EMPLOYMENT_FULL_DACH_QUERY = 'Pre-Employment DACH Query',
    PRE_EMPLOYMENT_MVR = 'Pre-Employment Motor Vehicle Record',
    PRE_EMPLOYMENT_DRUG_TEST = 'Pre-Employment Drug Test with negative result',
    PRE_EMPLOYMENT_CRIMINAL_BACKGROUND_CHECK = 'Pre-Employment Criminal Background Check (Optional)',
    PRE_EMPLOYMENT_SCREENING_PROGRAM = 'Pre-Employment Screening Program (PSP) (Optional)'
}

export enum MemberInvestigationStatus {
    CANCELLED = 'Cancelled',
    PRE_EMPLOYMENT_FORMS = 'Pre-Employment Forms',
    PRE_EMPLOYMENT_FULL_DACH_QUERY = 'Pre-Employment DACH Query',
    PRE_EMPLOYMENT_MVR = 'Pre-Employment Motor Vehicle Record',
    PRE_EMPLOYMENT_DRUG_TEST = 'Pre-Employment Drug Test with negative result',
    PRE_EMPLOYMENT_CRIMINAL_BACKGROUND_CHECK = 'Pre-Employment Criminal Background Check (Optional)',
    PRE_EMPLOYMENT_SCREENING_PROGRAM = 'Pre-Employment Screening Program (PSP) (Optional)'
}

export enum PaymentStatus {
    PENDING = 'Pending',
    PAID = 'Paid',
    FAILED = 'Failed',
    CANCELLED = 'Cancelled',
    NONE = 'N/A'
}

export enum IOptionalInvestigation {
    PRE_EMPLOYMENT_CRIMINAL_BACKGROUND_CHECK = 'Pre-Employment Criminal Background Check (Optional)',
    PRE_EMPLOYMENT_SCREENING_PROGRAM = 'Pre-Employment Screening Program (PSP) (Optional)',
    BOTH = 'Both'
}

export interface IPreEmploymentInvestigation {
    id: number;
    member: IMember;
    type: InvestigationType;
    investigation_type: InvestigationType;
    is_current_investigation: boolean;
    requires_payment: boolean;
    payment: PaymentStatus | string;
    date_started?: Date;
    date_completed?: Date;
    screened: boolean;
    days_since_started: number;
    days_left: number;
    state?: string;
    city?: string;
    zip_code?: string;
    test_date?: Date;
}

export interface IPreEmploymentInvestigationDto {
    member: number;
    investigation_type: InvestigationType;
    is_current_investigation: boolean;
    requires_payment: boolean;
    payment: PaymentStatus;
    state?: string;
    city?: string;
    zip_code?: string;
    test_date?: Date;
}

export interface IMember {
    id: string;
    user: IUser;
    company: ICompany;
    progress: number;
    terminated_at?: Date;
    pre_employment_investigation_status: MemberInvestigationStatus;
    pre_employment_screened: boolean;
    pre_employment_investigation: IPreEmploymentInvestigation[];
    pre_employment_investigation_complete: boolean;
    product_id: string;
    date_of_birth: string;
    drivers_license_number: string;
    social_security_number: string;
    state: string;
    created_at: Date | string;
    updated_at: Date | string;
    phone_number?: string;
    forms_done?: boolean;
    driver_type: DriverType;
    date_screening_completed?: Date;
    optional_investigation_selected?: IOptionalInvestigation;
}
