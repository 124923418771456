import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { TransitionProps } from '@mui/material/transitions';
import { Grid, InputLabel, TextField } from '@mui/material';
import { IPreEmploymentInvestigationDto, InvestigationType, PaymentStatus } from '../../../../models';
import { createPreEmploymentInvestigation } from '../../../../shared/client/pre-employment';
import { createCheckoutSessionPreEmployment } from '../../../../shared';
import { StripeProduct } from '../../models';
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface ICurrentLocation {
    state: string;
    city: string;
    zip_code: string;
    test_date: Date;
}

const initialValues: ICurrentLocation = {
    state: '',
    city: '',
    zip_code: '',
    test_date: new Date()
}

const validationSchema = yup.object({
    state: yup.string().required('State is required'),
    city: yup.string().required('City is required'),
    zip_code: yup.string().required('Zip Code is required'),
    test_date: yup.date().required('Test Date is required')
})


interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    investigation: InvestigationType
    disableContinue: boolean
    memberId: string
    selectedPlan: StripeProduct
    payableInvestigations: string[]
}

export default function CurrentLocationModal({
    investigation,
    open,
    setOpen,
    disableContinue,
    memberId,
    selectedPlan,
    payableInvestigations
}: Readonly<IProps>) {


    const handleClose = () => {
        setOpen(false);
    };

    const checkout = async (id: number) => {
        if (selectedPlan) {
            let url = await createCheckoutSessionPreEmployment(
                selectedPlan.price_id,
                selectedPlan.type,
                selectedPlan.tiers_mode,
                id
            )
            window.open(url?.url, '_blank')
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            if (investigation && !disableContinue) {
                const payload: IPreEmploymentInvestigationDto = {
                    investigation_type: investigation as InvestigationType,
                    is_current_investigation: true,
                    member: Number(memberId),
                    requires_payment: payableInvestigations.includes(investigation),
                    payment: payableInvestigations.includes(investigation) ? PaymentStatus.PENDING : PaymentStatus.NONE,
                    city: values.city,
                    state: values.state,
                    test_date: values.test_date,
                    zip_code: values.zip_code
                }
                const investigationObj = await createPreEmploymentInvestigation(payload)
                if (investigationObj && investigationObj.requires_payment) {
                    checkout(investigationObj.id)
                }
            } else if (disableContinue) {
                toast.warn("The investigation can’t be continued until Compliance Masters has completed the Previous Employee Safety Performance History investigation. You will be notified when this has been completed and available to review")
            }
        }
    })

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle sx={{ textAlign: 'center' }}>
                Current Location for {investigation}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} p={1}>
                    <Grid item xs={12} sm={12}>
                        <TextField label="State" value={formik.values.state} onChange={formik.handleChange} name='state' variant="outlined" fullWidth size='small' />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField label="City" value={formik.values.city} onChange={formik.handleChange} name='city' variant="outlined" fullWidth size='small' />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField label="Zip Code" value={formik.values.zip_code} onChange={formik.handleChange} name='zip_code' variant="outlined" fullWidth size='small' />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel>
                            Available Test Date
                        </InputLabel>
                        <TextField name='test_date' helperText="The Date entered in this field will be the date the drug test is scheduled on." value={formik.values.test_date} onChange={formik.handleChange} type='date' variant="outlined" fullWidth size='small' />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button variant="contained" color="primary" fullWidth size='small' onClick={() => formik.handleSubmit()}>
                            Continue
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
