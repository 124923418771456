import {authorizedEmployerRepresentativeSignatureApi} from './authorized-employer-representative-signature'
import {dachCertifiedRecipientDriversSignatureApi} from './dach-certified-receipt-drivers-signature'
import {dachEmployeeCertifiedReceiptApi} from './drug-and-alcohol-employees-certified-receipt'
import {providedEducationMaterialsApi} from './provided-educational-materials'
import {dachEmployeesCertifiedReceiptProgress} from './progress'

export const DachEmployeesCertifiedReceiptReducers = {
  [authorizedEmployerRepresentativeSignatureApi.reducerPath]:
    authorizedEmployerRepresentativeSignatureApi.reducer,
  [dachCertifiedRecipientDriversSignatureApi.reducerPath]:
    dachCertifiedRecipientDriversSignatureApi.reducer,
  [dachEmployeeCertifiedReceiptApi.reducerPath]: dachEmployeeCertifiedReceiptApi.reducer,
  [providedEducationMaterialsApi.reducerPath]: providedEducationMaterialsApi.reducer,
  [dachEmployeesCertifiedReceiptProgress.reducerPath]:
    dachEmployeesCertifiedReceiptProgress.reducer,
}

export const DachEmployeesCertifiedReceiptMiddleware = [
  authorizedEmployerRepresentativeSignatureApi.middleware,
  dachCertifiedRecipientDriversSignatureApi.middleware,
  dachEmployeeCertifiedReceiptApi.middleware,
  providedEducationMaterialsApi.middleware,
  dachEmployeesCertifiedReceiptProgress.middleware,
]
