import React from 'react'
import { IMember } from '../../../models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Grid, TextField, Typography } from '@mui/material'
import {
    IRequesterSignatureDto
} from '../../../store/interfaces/RequestForCheckOfDrivingRecords/requester-signature'
import {
    useCreateRequesterSignatureMutation,
    useUpdateRequesterSignatureMutation,
    useGetRequestBySignatureByUserIdQuery,
    useDeleteRequesterSignatureMutation
} from '../../../store/reducers/RequestForCheckOfDrivingRecords/requester-signature'
import { useAuth } from '../../../app/modules/auth'
import { useThemeMode } from '../../partials'
import { useGetProcessRecordByUserIdQuery } from '../../../store/reducers/DriversApplicationForEmployment/process-record'
import { useGetCertificationByUserIdQuery } from '../../../store/reducers/CertificateOfComplianceWithDriverLicenseRequirements/certification'

const initialValues: Partial<IRequesterSignatureDto> = {
    signature_of_requester: '',
    date: '',
}

const validationSchema = Yup.object({
    signature_of_requester: Yup.string().required('Required'),
    date: Yup.string().required('Required'),
})

const RequesterSignature = ({ member, isDriverFile }: { member?: IMember, isDriverFile?: boolean }) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()
    const { data } = useGetRequestBySignatureByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })
    const { data: processRecord } = useGetProcessRecordByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    })

    const { data: cert } = useGetCertificationByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })

    const [createRequesterSignature, { isLoading: isCreating }] = useCreateRequesterSignatureMutation()
    const [updateRequesterSignature, { isLoading: isUpdating }] = useUpdateRequesterSignatureMutation()
    const [deleteRequesterSignature] = useDeleteRequesterSignatureMutation()

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                await updateRequesterSignature({
                    // @ts-ignore
                    ...values,
                    // @ts-ignore
                    id: data.id,
                })
            } else {
                await createRequesterSignature({
                    ...values,
                    // @ts-ignore
                    member: member?.id,
                    // @ts-ignore
                    company: member?.company?.id
                })
            }
            resetForm()
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate: (values) => {
            const errors: Partial<IRequesterSignatureDto> = {}
            if (!values.signature_of_requester) {
                errors.signature_of_requester = 'Required'
            }
            if (!values.date) {
                errors.date = 'Required'
            }
            return errors
        }
    })

    React.useEffect(() => {
        if (data) {
            formik.setValues(data)
        } else if (processRecord) {
            formik.setFieldValue("date", processRecord?.date_employed)
        } else if (cert) {
            formik.setFieldValue("date", cert?.date_of_hire)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, processRecord, cert])

    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h4" gutterBottom component="h4">
                Requester Signature
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h5" gutterBottom component="h5">
                REQUEST FOR CHECK OF DRIVING RECORDS
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify", mt: 5 }} variant="h6" gutterBottom component="h6">
                NOTE: This form can only be used in states that don't have their own required form. Section (391.23)(391.25) –
                Each motor carrier is required to investigate each applicant's driving record for the preceding 3 years.
                A copy of the record will be automatically uploaded into the necessary driver's file within 30 days of employee's start date.
                Regulations also require motor carriers to obtain annual driving records for each driver and obtain a driving
                record within 15 days of a driver's medical examination.
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify", mt: 5 }} variant="h6" gutterBottom component="h6">
                When using a third-party administrator to obtain background information (motor vehicle records,
                previous employers, criminal background checks, or credit reporting) on existing employees or new applicants,
                you are subject the Fair Credit Reporting Act.
            </Typography>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "justify", mt: 5 }} variant="h6" gutterBottom component="h6">
                I hereby certify that this report request and the driver's release notice signed above meet the definition of
                "permissible uses" of state motor vehicle records required under the Driver's Privary Protection Act of 1994.
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Signature of Requester
                    </Typography>
                    <TextField
                        fullWidth
                        id="signature_of_requester"
                        placeholder='Signature of Requester'
                        size="small"
                        variant="outlined"
                        value={formik.values.signature_of_requester}
                        onChange={(e) => {
                            formik.setFieldValue('signature_of_requester', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.signature_of_requester && Boolean(formik.errors.signature_of_requester) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.signature_of_requester && Boolean(formik.errors.signature_of_requester) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.signature_of_requester && Boolean(formik.errors.signature_of_requester) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                fontStyle: "italic"
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Date of Hire
                    </Typography>
                    <TextField
                        fullWidth
                        id="date"
                        placeholder='Date'
                        size="small"
                        variant="outlined"
                        type="date"
                        value={formik.values.date}
                        onChange={(e) => {
                            formik.setFieldValue('date', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={member?.terminated_at || (data && !["Admin", "SuperAdmin"].includes(currentUser?.role!)) ? true : false}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid >
                {(!data || (data && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role))) && !isDriverFile ? <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={isCreating || isUpdating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                        Save
                    </Button>
                    {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                        <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                            Once you click save, you will not be able to edit this form.
                        </Typography>
                    )}
                </Grid > : null}
                {data && currentUser?.role === "SuperAdmin" && <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={!!member?.terminated_at} onClick={() => {
                        const res = window.confirm("Are you sure you want to delete this form?")
                        if (res && data) {
                            deleteRequesterSignature(data.id)
                        }
                    }} variant="contained" color="primary">
                        Delete Form
                    </Button>
                </Grid>}
            </Grid >
        </div >
    )
}

export default RequesterSignature