import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    IPerformanceHistoryDriverIdentification,
    IPerformanceHistoryDriverIdentificationDto,
    IPerformanceHistoryComplete,
    IPerformanceHistoryCompleteDto
} from '../../interfaces/PreviousEmployeeSafetyPerformanceHistory/driver-identification';

export const driverIdentificationApi = createApi({
    reducerPath: 'driver_identification',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/driver-identification`
    }),
    tagTypes: ['DriverIdentification'],
    endpoints: (builder) => ({
        createDriverIdentification: builder.mutation<
            IPerformanceHistoryDriverIdentification,
            IPerformanceHistoryDriverIdentificationDto
        >({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'DriverIdentification', id: 'DriverIdentification' }]
        }),
        updateDriverIdentification: builder.mutation<
            IPerformanceHistoryDriverIdentification,
            IPerformanceHistoryDriverIdentification
        >({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'DriverIdentification', id: 'DriverIdentification' }]
        }),
        getDriverIdentification: builder.query<IPerformanceHistoryDriverIdentification, number | void>({
            query: (id) => ({
                url: '/get-performance-history-driver-identification/',
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'DriverIdentification', id: 'DriverIdentification' }]
        }),
        getDriverIdentificationByUser: builder.query<IPerformanceHistoryDriverIdentification, void>({
            query: () => ({
                url: '/get-performance-history-driver-identification-by-user/',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'DriverIdentification', id: 'DriverIdentification' }]
        }),
        getDriverIdentificationByUserId: builder.query<IPerformanceHistoryDriverIdentification, number | void>({
            query: (id) => ({
                url: '/get-performance-history-driver-identification-by-user-id/',
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'DriverIdentification', id: 'DriverIdentification' }]
        }),
        deleteDriverIdentification: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'DriverIdentification', id: 'DriverIdentification' }]
        }),
        createPerformanceHistoryComplete: builder.mutation<IPerformanceHistoryComplete, IPerformanceHistoryCompleteDto>(
            {
                query: (body) => ({
                    url: '/create-performance-history-complete/',
                    method: 'POST',
                    body,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                    }
                }),
                invalidatesTags: [{ type: 'DriverIdentification', id: 'DriverIdentification' }]
            }
        ),
        updatePerformanceHistoryComplete: builder.mutation<IPerformanceHistoryComplete, IPerformanceHistoryComplete>({
            query: (body) => ({
                url: '/update-performance-history-complete/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'DriverIdentification', id: 'DriverIdentification' }]
        }),
        getPerformanceHistoryCompleteByUserId: builder.query<IPerformanceHistoryComplete, number | void>({
            query: (id) => ({
                url: '/get-performance-history-complete-by-user-id/',
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'DriverIdentification', id: 'DriverIdentification' }]
        })
    })
});

export const {
    useCreateDriverIdentificationMutation,
    useUpdateDriverIdentificationMutation,
    useGetDriverIdentificationQuery,
    useGetDriverIdentificationByUserQuery,
    useGetDriverIdentificationByUserIdQuery,
    useDeleteDriverIdentificationMutation,
    useCreatePerformanceHistoryCompleteMutation,
    useUpdatePerformanceHistoryCompleteMutation,
    useGetPerformanceHistoryCompleteByUserIdQuery
} = driverIdentificationApi;
