import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    IReleaseFormApplicantSignature,
    IReleaseFormApplicantSignatureDto
} from '../../interfaces/ReleaseFormBackgroundCheck/ReleaseFormBackgroundChecks';

export const releaseFormApplicantSignatureApi = createApi({
    reducerPath: 'release_form_applicant_signature',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/release-form-applicant-signature`
    }),
    tagTypes: ['ReleaseFormApplicantSignature'],
    endpoints: (builder) => ({
        createReleaseFormApplicantSignature: builder.mutation<
            IReleaseFormApplicantSignature,
            IReleaseFormApplicantSignatureDto
        >({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'ReleaseFormApplicantSignature',
                    id: 'ReleaseFormApplicantSignature'
                }
            ]
        }),
        updateReleaseFormApplicantSignature: builder.mutation<
            IReleaseFormApplicantSignature,
            IReleaseFormApplicantSignature
        >({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'ReleaseFormApplicantSignature',
                    id: 'ReleaseFormApplicantSignature'
                }
            ]
        }),
        deleteReleaseFormApplicantSignature: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'ReleaseFormApplicantSignature',
                    id: 'ReleaseFormApplicantSignature'
                }
            ]
        }),
        getReleaseFormApplicantSignature: builder.query<IReleaseFormApplicantSignature, number>({
            query: (id) => ({
                url: `/get-applicant-signature/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'ReleaseFormApplicantSignature',
                    id: 'ReleaseFormApplicantSignature'
                }
            ]
        }),
        getReleaseFormApplicantSignatureByUser: builder.query<IReleaseFormApplicantSignature, void>({
            query: (id) => ({
                url: `/get-applicant-signature-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'ReleaseFormApplicantSignature',
                    id: 'ReleaseFormApplicantSignature'
                }
            ]
        }),
        getReleaseFormApplicantSignatureByUserId: builder.query<IReleaseFormApplicantSignature, number>({
            query: (id) => ({
                url: `/get-applicant-signature-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'ReleaseFormApplicantSignature',
                    id: 'ReleaseFormApplicantSignature'
                }
            ]
        }),
        getReleaseFormApplicantSignatureProgress: builder.query<IReleaseFormApplicantSignature, number>({
            query: (id) => ({
                url: `/get-total-progress/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'ReleaseFormApplicantSignature',
                    id: 'ReleaseFormApplicantSignature'
                }
            ]
        }),
        getMemberReleaseFormApplicantSignatureProgress: builder.query<IReleaseFormApplicantSignature, number>({
            query: (id) => ({
                url: `/get-member-progress/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'ReleaseFormApplicantSignature',
                    id: 'ReleaseFormApplicantSignature'
                }
            ]
        })
    })
});

export const {
    useCreateReleaseFormApplicantSignatureMutation,
    useUpdateReleaseFormApplicantSignatureMutation,
    useGetReleaseFormApplicantSignatureQuery,
    useGetReleaseFormApplicantSignatureByUserQuery,
    useGetReleaseFormApplicantSignatureProgressQuery,
    useGetMemberReleaseFormApplicantSignatureProgressQuery,
    useGetReleaseFormApplicantSignatureByUserIdQuery,
    useDeleteReleaseFormApplicantSignatureMutation
} = releaseFormApplicantSignatureApi;
