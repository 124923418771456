import {useNavigate} from 'react-router-dom'
import {CompanyNavigation} from './navigations'

export default function useNavigation() {
  const navigate = useNavigate()

  function navigateToDashboard() {
    navigate('/dashboard')
  }

  function navigateToLogin() {
    navigate('/auth/login')
  }

  function navigateToCompany() {
    navigate('/auth/create-company')
  }

  function navigateTo404ErrorPage() {
    navigate('/error/404')
  }

  function navigateToForm(id: string | number) {
    navigate(`/form/${id}`)
  }

  function navigateToFormSection(formId: string | number, sectionOrderNumber: string | number) {
    navigate(`/form/${formId}/section/${sectionOrderNumber}`)
  }

  function navigateToPayment() {
    navigate('/subscription/payment')
  }

  function navigateToFormElementGroup(
    formId: string | number,
    sectionOrderNumber: string | number,
    elementGroupOrderNumber: string | number
  ) {
    navigate(`/form/${formId}/section/${sectionOrderNumber}/elg/${elementGroupOrderNumber}`)
  }

  return {
    navigateToDashboard,
    navigateToCompany,
    navigateTo404ErrorPage,
    navigateToForm,
    navigateToFormSection,
    navigateToFormElementGroup,
    navigateToLogin,
    navigateToPayment,
    ...CompanyNavigation(navigate),
  }
}
