/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from 'react-router-dom';
import { INotification } from '../../../../../../models/INotification';
import { handleAction } from '../../../../../../shared/helpers/notifications/handleAction';

export default function Notification({ notification }: { notification: INotification }) {
    const navigate = useNavigate();
    function getMessage() {
        if (notification.message.length > 30) {
            return `${notification.message.substring(0, 30)}...`;
        }
        return notification.message;
    }

    function handleOnClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        e.preventDefault()
        handleAction({ notification, navigate });
    }


    return (
        <div className="d-flex flex-stack py-4">
            <div className="d-flex align-items-center">
                <div className="mb-0 me-2">
                    <a
                        href="#"
                        className="fs-6 text-gray-800 text-hover-primary fw-bolder cursor-pointer"
                        onClick={handleOnClick}
                    >
                        {getMessage()}
                    </a>
                </div>
            </div>

            <span className="badge badge-light fs-8">{notification.created_at}</span>
        </div>
    );
}
