import React from 'react'
import { IEmploymentVerification, IEmploymentVerificationDto } from '../../../store/interfaces/PreviousEmployeeSafetyPerformanceHistory/previous-employer-information'
import { VehicleTextChoices } from '../../../store/interfaces/PreviousEmployeeSafetyPerformanceHistory/safety-performance-history'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useThemeMode } from '../../partials'
import { IMember } from '../../../models'
import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material'
import { IEmploymentInformation } from '../../../store/interfaces/EmploymentHistory/employer-information'
import { useAuth } from '../../../app/modules/auth'
import { useCreateEmploymentVerificationMutation, useUpdateEmploymentVerificationMutation, useDeleteEmployerInformationMutation } from '../../../store/reducers/EmploymentHistory/employer-information'

const initialValues: IEmploymentVerificationDto = {
    employer_information: undefined,
    applicant_employed: false,
    position: '',
    employed_from: '',
    employed_to: '',
    employed_as: '',
    drove_motor_vehicle: false,
    vehicle_type: VehicleTextChoices.BUS,
    reason_for_leaving: '',
    name_of_person_contacted: '',
    other_vehicle_type: ""
}

const validationSchema = Yup.object({
    employer_information: Yup.string().required('Required'),
    applicant_employed: Yup.boolean().required('Required'),
    // position: Yup.string().required('Required'),
    employed_from: Yup.string().required('Required'),
    employed_to: Yup.string().required('Required'),
    employed_as: Yup.string().required('Required'),
    drove_motor_vehicle: Yup.boolean().required('Required'),
    //  Add vehicle type if above is true
    vehicle_type: Yup.string().when('drove_motor_vehicle', {
        is: true,
        then: Yup.string().required('Required')
    }),
    reason_for_leaving: Yup.string().required('Required'),
    name_of_person_contacted: Yup.string().required('Required'),
    //  If vehicle type is other
    other_vehicle_type: Yup.string().when('vehicle_type', {
        is: VehicleTextChoices.OTHER_SPECIFY,
        then: Yup.string().required('Required')
    })
})

interface IProps {
    member?: IMember
    id?: number
    employment_verification?: IEmploymentVerification
    history?: IEmploymentInformation
}

const EmploymentVerification = ({ member, employment_verification, id, history }: IProps) => {
    const { mode } = useThemeMode()
    const { currentUser } = useAuth()

    const [createEmploymentVerification, { isLoading: isCreating }] = useCreateEmploymentVerificationMutation()
    const [updateEmploymentVerification] = useUpdateEmploymentVerificationMutation()
    const [deleteEmployerInformation] = useDeleteEmployerInformationMutation()

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => {
            if (employment_verification) {
                updateEmploymentVerification({
                    ...employment_verification,
                    ...values
                })
            } else {
                createEmploymentVerification(values)
            }
        }
    })

    console.log(employment_verification)

    React.useEffect(() => {
        if (employment_verification) {
            formik.setValues(employment_verification)
        }
        if (id) {
            formik.setFieldValue('employer_information', id)
        }
        if (!employment_verification && history) {
            formik.setFieldValue("employed_from", history?.start_date)
            formik.setFieldValue("employed_to", history?.end_date)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employment_verification, id, history])

    return (
        <div style={{
            padding: 10,
            margin: 10
        }}>
            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: "center" }} variant="h5" gutterBottom component="h4">
                Employment Verification
            </Typography>
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        The applicant named above was or is employed or used by us
                    </Typography>
                    <TextField
                        fullWidth
                        id="applicant_employed"
                        size="small"
                        variant="outlined"
                        value={formik.values.applicant_employed ? "Yes" : "No"}
                        onChange={(e) => {
                            formik.setFieldValue('applicant_employed', e.target.value === "Yes" ? true : false)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.applicant_employed && Boolean(formik.errors.applicant_employed) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.applicant_employed && Boolean(formik.errors.applicant_employed) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.applicant_employed && Boolean(formik.errors.applicant_employed) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                        select
                    >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </TextField>
                </Grid>
                <Grid item sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Employed As
                    </Typography>
                    <TextField
                        fullWidth
                        id="employed_as"
                        size="small"
                        variant="outlined"
                        value={formik.values.employed_as}
                        onChange={(e) => {
                            formik.setFieldValue('employed_as', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.employed_as && Boolean(formik.errors.employed_as) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.employed_as && Boolean(formik.errors.employed_as) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.employed_as && Boolean(formik.errors.employed_as) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item sm={6}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Employed From
                    </Typography>
                    <TextField
                        fullWidth
                        id="employed_from"
                        size="small"
                        variant="outlined"
                        type="date"
                        value={formik.values.employed_from}
                        onChange={(e) => {
                            formik.setFieldValue('employed_from', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.employed_from && Boolean(formik.errors.employed_from) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.employed_from && Boolean(formik.errors.employed_from) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.employed_from && Boolean(formik.errors.employed_from) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item sm={6}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Employed To
                    </Typography>
                    <TextField
                        fullWidth
                        id="employed_to"
                        size="small"
                        variant="outlined"
                        type="date"
                        value={formik.values.employed_to}
                        onChange={(e) => {
                            formik.setFieldValue('employed_to', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.employed_to && Boolean(formik.errors.employed_to) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.employed_to && Boolean(formik.errors.employed_to) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.employed_to && Boolean(formik.errors.employed_to) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Did the applicant drive a motor vehicle for you?
                    </Typography>
                    <TextField
                        fullWidth
                        id="drove_motor_vehicle"
                        size="small"
                        variant="outlined"
                        value={formik.values.drove_motor_vehicle ? "Yes" : "No"}
                        onChange={(e) => {
                            formik.setFieldValue('drove_motor_vehicle', e.target.value === "Yes" ? true : false)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drove_motor_vehicle && Boolean(formik.errors.drove_motor_vehicle) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drove_motor_vehicle && Boolean(formik.errors.drove_motor_vehicle) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.drove_motor_vehicle && Boolean(formik.errors.drove_motor_vehicle) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                        select
                    >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </TextField>
                </Grid>
                {formik.values.drove_motor_vehicle ? (
                    <>
                        <Grid item sm={12}>
                            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                Vehicle Type
                            </Typography>
                            <TextField
                                fullWidth
                                id="vehicle_type"
                                size="small"
                                variant="outlined"
                                value={formik.values.vehicle_type}
                                onChange={(e) => {
                                    formik.setFieldValue('vehicle_type', e.target.value)
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor: formik.touched.vehicle_type && Boolean(formik.errors.vehicle_type) ? "red" : "green",
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor: formik.touched.vehicle_type && Boolean(formik.errors.vehicle_type) ? "red" : "green",
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor: formik.touched.vehicle_type && Boolean(formik.errors.vehicle_type) ? "red" : "green",
                                        },
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                    }
                                }}
                                select
                            >
                                {Object.values(VehicleTextChoices).map((choice, index) => (
                                    <MenuItem key={index} value={choice}>{choice}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {formik.values.vehicle_type === VehicleTextChoices.OTHER_SPECIFY && ["Admin", "SuperAdmin"].includes(currentUser?.role!) && <Grid item sm={12}>
                            <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                                Other Vehicle Type
                            </Typography>
                            <TextField
                                fullWidth
                                id="other_vehicle_type"
                                size="small"
                                variant="outlined"
                                value={formik.values.other_vehicle_type}
                                onChange={(e) => {
                                    formik.setFieldValue('other_vehicle_type', e.target.value)
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor: formik.touched.other_vehicle_type && Boolean(formik.errors.other_vehicle_type) ? "red" : "green",
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor: formik.touched.other_vehicle_type && Boolean(formik.errors.other_vehicle_type) ? "red" : "green",
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor: formik.touched.other_vehicle_type && Boolean(formik.errors.other_vehicle_type) ? "red" : "green",
                                        },
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                    }
                                }}
                            />
                        </Grid>}
                    </>
                ) : null}
                <Grid item sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Reason for leaving
                    </Typography>
                    <TextField
                        fullWidth
                        id="reason_for_leaving"
                        size="small"
                        variant="outlined"
                        multiline
                        rows={3}
                        value={formik.values.reason_for_leaving}
                        onChange={(e) => {
                            formik.setFieldValue('reason_for_leaving', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.reason_for_leaving && Boolean(formik.errors.reason_for_leaving) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.reason_for_leaving && Boolean(formik.errors.reason_for_leaving) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.reason_for_leaving && Boolean(formik.errors.reason_for_leaving) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                <Grid item sm={12}>
                    <Typography sx={{ color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', }} variant="body1" gutterBottom component="p">
                        Name of contacted person
                    </Typography>
                    <TextField
                        fullWidth
                        id="name_of_person_contacted"
                        size="small"
                        variant="outlined"
                        value={formik.values.name_of_person_contacted}
                        onChange={(e) => {
                            formik.setFieldValue('name_of_person_contacted', e.target.value)
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.name_of_person_contacted && Boolean(formik.errors.name_of_person_contacted) ? "red" : "green",
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.name_of_person_contacted && Boolean(formik.errors.name_of_person_contacted) ? "red" : "green",
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.name_of_person_contacted && Boolean(formik.errors.name_of_person_contacted) ? "red" : "green",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            }
                        }}
                    />
                </Grid>
                {!employment_verification || (employment_verification && currentUser && ["Admin", "SuperAdmin"].includes(currentUser?.role)) ? (
                    <Grid item xs={12} sm={12}>
                        <Button fullWidth disabled={isCreating || (!!member?.terminated_at)} onClick={() => formik.handleSubmit()} variant="contained" color="primary">
                            Save
                        </Button>
                        {currentUser?.role !== "Admin" && currentUser?.role !== "SuperAdmin" && (
                            <Typography sx={{ color: "red", textAlign: "center", mt: 2 }} variant="body1" gutterBottom component="p">
                                Once you click save, you will not be able to edit this form.
                            </Typography>
                        )}
                    </Grid>
                ) : null}
                {employment_verification && currentUser?.role === "SuperAdmin" && <Grid item xs={12} sm={12}>
                    <Button fullWidth disabled={!!member?.terminated_at} onClick={() => {
                        const res = window.confirm("Are you sure you want to delete this form?")
                        if (res && employment_verification) {
                            deleteEmployerInformation(employment_verification.id)
                        }
                    }} variant="contained" color="primary">
                        Delete Form
                    </Button>
                </Grid>}
            </Grid>
        </div>
    )
}

export default EmploymentVerification